import { http_client_with_status } from "@/plugins/http_client";

const getSubgruposList = (params = {}) => http_client_with_status(`/api/v1/cm-subgrupo`, params, "get");

const postSubgrupos = (params = {}) => http_client_with_status(`/api/v1/cm-subgrupo`, params, "post");

const putSubgrupos = (id_subgrupo, params = {}) => http_client_with_status(`/api/v1/cm-subgrupo/${id_subgrupo}`, params, "put");

const deleteSubgrupos = (id_subgrupo, params = {}) => http_client_with_status(`/api/v1/cm-subgrupo/${id_subgrupo}`, params, "delete");

const patchSubgruposEstado = (id_subgrupo, params = {}) => http_client_with_status(`/api/v1/cm-subgrupo/estado/${id_subgrupo}`, params, "patch");

export default {
  getSubgruposList,
  postSubgrupos,
  putSubgrupos,
  deleteSubgrupos,
  patchSubgruposEstado
};
