import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from "@/utils/loadable";
import CmAprobacionSolicitudCompra from '@/services/AprobacionSolicitudCompraElectronica';
import { cloneDeep  } from 'lodash'

export default {
    namespaced: true,
    state: {
        cart: createLoadable(null),
        funds: createLoadable([]),
        certification: createLoadable(null),
        certificationFileUpload: createLoadable(null),
        certificationFilePreview: createLoadable(null),
        certificationFileDeletion: createLoadable(null),
        // Productos
        productosPorProveedor: createLoadable([]),
        adjuntosUcp: new Map(),
    },
    mutations: {
        startCartRequest(state) {
            toggleLoadable(state.cart);
        },
        handleCartResponse(state, data) {
            setLoadableResponse(state.cart, data);
        },
        startFundsRequest(state) {
            toggleLoadable(state.funds);
        },
        iniciarProductos(state) {
            toggleLoadable(state.productosPorProveedor);
        },
        resetearProductosProveedor(state) {
            state.productosPorProveedor = createLoadable([]);
            state.adjuntosUcp = new Map();
        },
        handleFundsResponse(state, data) {
            setLoadableResponse(state.funds, data, { initialValue: [] });
        },
        handleProductoProveedorResponse(state, data) {
            const adjuntosPrevios = state.adjuntosUcp;
            
            if (isResponseSuccesful(data)) {
                const mapaDocumentos = new Map();
                data.data.forEach((proveedor) => {
                    const loadable = adjuntosPrevios.get(proveedor.id);
                    mapaDocumentos.set(proveedor.id, loadable ?? createLoadable([]));
                });
                state.adjuntosUcp = mapaDocumentos;
            }

            setLoadableResponse(state.productosPorProveedor, data, { initialValue: [] });
        },
        startCertificacionRequest(state) {
            toggleLoadable(state.certification);
        },
        startCertificacionRequestAll(state) {
            toggleLoadable(state.certification);
        },

        handleCertificacionResponse(state, data) {
            setLoadableResponse(state.certification, data);
        },
        
        handleCertificacionAllResponse(state, data) {
            setLoadableResponse(state.certification, data);
        },
        injectCertification(state, payload) {
            const { certificacion, idFondo, idProducto } = payload;
            const fund = state.funds.data.find((fund) => fund.id === idFondo);
            const productoIndex = fund.productos.findIndex((producto) => producto.id === idProducto);

            if (productoIndex === -1) return;

            const producto = fund.productos[productoIndex];
            fund.productos.splice(productoIndex, 1, {
                ...producto,
                certificacion,
            });
        },
        startCertificationFileUploadRequest(state) {
            toggleLoadable(state.certificationFileUpload);
        },
        handleCertificationFileUploadResponse(state, payload) {
            const { data, idFondoSolicitudNecesidad } = payload;
            setLoadableResponse(state.certificationFileUpload, data);

            if (!isResponseSuccesful(data)) return;
            const funds = state.funds.data;
            const fundIndex = funds.findIndex((f) => f.id === idFondoSolicitudNecesidad);
            if (!fundIndex === -1) return;

            const fund = funds[fundIndex];
            fund.adjuntos = [data.data];
            state.funds.data.splice(fundIndex, 1, fund);
        },
        startCertificationFilePreviewRequest(state) {
            toggleLoadable(state.certificationFilePreview);
        },
        handleCertificationFilePreviewResponse(state, response) {
            const { data, headers } = response;
            setLoadableResponse(state.certificationFilePreview, data, { headers, isFile: true });
        },
        startCertificationFileDeletionRequest(state) {
            toggleLoadable(state.certificationFileDeletion);
        },
        handleCertificationFileDeletionResponse(state, payload) {
            const { data, idFondoSolicitudNecesidad } = payload;
            setLoadableResponse(state.certificationFileDeletion, data);

            if (!isResponseSuccesful(data)) return;
            const funds = state.funds.data;
            const fundIndex = funds.findIndex((f) => f.id === idFondoSolicitudNecesidad);
            if (!fundIndex === -1) return;

            const fund = funds[fundIndex];
            fund.adjuntos = [];
            state.funds.data.splice(fundIndex, 1, fund);
        },
        // 
        empezarPeticionAdjuntosUCP(state, idProveedor) {
            const adjuntosUcp = cloneDeep(state.adjuntosUcp);
            const documentos = adjuntosUcp.get(idProveedor);

            if (!documentos) return;

            toggleLoadable(documentos);
            state.adjuntosUcp = adjuntosUcp;
        },
        manejarRespuestaAdjuntosUCP(state, payload) {
            const adjuntosUcp = cloneDeep(state.adjuntosUcp);
            const documentos = adjuntosUcp.get(payload.idProveedor);
            
            if (!documentos) return;
            
            setLoadableResponse(documentos, payload.data);
            state.adjuntosUcp = adjuntosUcp;
        },
    },
    actions: {
        loadCart: async({ commit }, requestId) => {
            commit('startCartRequest');
            const { data } = await CmAprobacionSolicitudCompra.detalle(requestId);
            commit('handleCartResponse', data);
        },
        loadItemsGroupByFund: async({ commit }, requestId) => {
            commit('startFundsRequest');
            const { data } = await CmAprobacionSolicitudCompra.loadRequestItemsGroupByFund(requestId);
            commit('handleFundsResponse', data);
        },
        cargarProductoPorProveedor: async({ commit }, requestId) => {
            commit('iniciarProductos');
            const { data } = await CmAprobacionSolicitudCompra.productosPorProveedor(requestId);
            commit('handleProductoProveedorResponse', data);
        },
        certifyItem: async({ commit }, payload) => {
            commit('startCertificacionRequest');
            const { idFondoSolicitudNecesidad, idProducto, idSolicitudCompra, monto } = payload;
            const { data } = await CmAprobacionSolicitudCompra.certifyFundOfRequestItem(
                idFondoSolicitudNecesidad,
                idSolicitudCompra,
                idProducto,
                { monto },
            );
            commit('handleCertificacionResponse', data);

            if (isResponseSuccesful(data)) {
                commit('injectCertification', { certificacion: data.data, idFondo: idFondoSolicitudNecesidad, idProducto });
            }

            return data;
        },
        certifyItemAll: async({ commit }, payload) => {
            commit('startCertificacionRequestAll');
            const { idFondoSolicitudNecesidad, idSolicitudCompra, productos } = payload;

            const { data } = await CmAprobacionSolicitudCompra.certifyFundOfRequestItemAll(
                idFondoSolicitudNecesidad,
                idSolicitudCompra,
                {productos},
            );
            
            commit('handleCertificacionAllResponse', data);
            if (isResponseSuccesful(data)) {
                commit('injectCertification', { certificacion: data.data, idFondo: idFondoSolicitudNecesidad });
            }

            return data;
        },
        uploadCertificationFile: async({ commit }, payload) => {
            commit('startCertificationFileUploadRequest');
            const { data, idSolicitudCompra, idFondoSolicitudNecesidad } = payload;
            const response = await CmAprobacionSolicitudCompra.uploadCertificationFile(idFondoSolicitudNecesidad, idSolicitudCompra, data);

            commit('handleCertificationFileUploadResponse', { data: response.data, idFondoSolicitudNecesidad });
            
            return response.data;
        },
        previewCertificationFile: async({ commit }, certificationFileId) => {
            commit('startCertificationFilePreviewRequest');
            const response = await CmAprobacionSolicitudCompra.previewCertificationFile(certificationFileId);
            commit('handleCertificationFilePreviewResponse', response);
        },
        deleteCertificationFile: async({ commit }, payload) => {
            const { certificationFileId, idFondoSolicitudNecesidad } = payload;
            commit('startCertificationFileDeletionRequest');
            const { data } = await CmAprobacionSolicitudCompra.deleteCertificationFile(certificationFileId);
            commit('handleCertificationFileDeletionResponse', { data, idFondoSolicitudNecesidad });

            return data;
        },
        cargarAdjuntosUCP: async({ commit }, payload) => {
            const { idProveedor, idSolicitud } = payload;
            
            const filters = { 
                pagination: false, 
                id_proveedor: idProveedor,
            };

            commit('empezarPeticionAdjuntosUCP', idProveedor);
            const { data } = await CmAprobacionSolicitudCompra.listAuthFiles(idSolicitud, filters);
            commit('manejarRespuestaAdjuntosUCP',{ data, idProveedor });
        }
    },
}
