import {http_client} from "../plugins/http_client";

const token = localStorage.getItem("token");

const postsolicitudProveedores = async (parametos = {}) => {
    try {
        return await http_client(
            "/api/v1/solicitudes/proveedores",
            parametos,
            "post",
            {
                Authorization: `Bearer ${token}`,
            },
            true,
            "json",
            true,
            true

        );
    } catch (error) {
        return error.response;
    }
};

const PutGuardarProveedor = async (idprovee, parametros) => {
    try {
        return await http_client(
            `/api/v1/solicitudes/proveedores/${idprovee}`,
            parametros,
            "put",
            {
                Authorization: `Bearer ${token}`,
            },
            true,
            "json",
            true,
            true
        );
    } catch (error) {
        return error.response;
    }
};

const getDeclaracionJurada = async () => {
    return await http_client(
        `/api/v1/documentos/declaracion`,
        {},
        "get",
        {
            Authorization: `Bearer ${token}`,
        },
        true,
        "blob",
        true,
        true
    );
};

const postDeclaracionJurada = async (data) => {
    try {
        return await http_client(
            `/api/v1/documentos/declaracion`,
            data,
            "post",
            {
                Authorization: `Bearer ${token}`,
            },
            true,
            "json",
            true,
            true
        );
    } catch (e) {
        return e.response;
    }
};

const getSolicitudesEstados = async () => {
    return await http_client(
        `/api/v1/solicitudes/proveedores/estados`,
        {},
        "get",
        {
            Authorization: `Bearer ${token}`,
        },
        true,
        "json",
        true,
        true
    );
};

const getObservaciones = async (id, token) => {
    try {
        return await http_client(
            `/api/v1/solicitudes/proveedores/${id}/observaciones`,
            {},
            "get",
            {
                Authorization: `Bearer ${token}`,
            },
            true,
            "json",
            true,
            true
        );
    } catch (error) {
        return error.response;
    }
};

const getDeclaracionJuradaObservacion = async (id, token) => {
    try {
        return await http_client(
            `/api/v1/solicitudes/proveedores/${id}/observacion/declaracion`,
            {},
            "get",
            {
                Authorization: `Bearer ${token}`,
            },
            true,
            "json",
            true,
            true
        );
    } catch (error) {
        return error.response;
    }
};

const putObservaciones = async (id, token, parametros) => {
    try {
        return await http_client(
            `/api/v1/solicitudes/proveedores/${id}/observacion`,

            parametros,
            "put",
            {
                Authorization: `Bearer ${token}`,
            },
            true,
            "json",
            true,
            true
        );
    } catch (error) {
        return error.response;
    }
};

export default {
    postsolicitudProveedores,
    PutGuardarProveedor,
    getDeclaracionJurada,
    getSolicitudesEstados,
    getObservaciones,
    getDeclaracionJuradaObservacion,
    putObservaciones,
    postDeclaracionJurada,
};
