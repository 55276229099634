import { http_client } from "@/plugins/http_client";

const url = "/api/v1/instituciones/fuentes";
const getFuentes = async (params = {}) => await http_client(url, params, "get");
const storeFuente = async (body) => await http_client(url, body, "post");
const getFuente = async (id) => await http_client(`${url}/${id}`);
const updateFuente = async (id, body) =>
  await http_client(`${url}/${id}`, body, "put");
const deleteFuente = async (id) =>
  await http_client(`${url}/${id}`, {}, "delete");

export default {
  getFuentes,
  storeFuente,
  getFuente,
  updateFuente,
  deleteFuente,
};
