import configuracionTechoPresupuestarioRoute from "./configuracionTechosPresupuestarios/router";

const routerTechosPresupuestarios = [
  {
    path: "techos-presupuestarios",
    name: "techos-presupuestarios",
    component: () =>
      import(
        /* webpackChunkName: "institucionesView" */ "./institucionesView.vue"
      ), 
    children: [],
  },
  /* {
    path: "/techos-presupuestarios/:id/unidades",
    name: "techos-presupuestarios-unidades",
    component: () =>
      import(
    "./vistaUnidadesView.vue"
      ),
  }, */
  /* {
    path: "/techos-presupuestarios/:id/asignacion",
    name: "techos-presupuestarios-asignacion",
    component: () =>
      import(
        "./asignacionTechoPresupuestario.vue"
      ),
  }, */
  ...configuracionTechoPresupuestarioRoute,
];

export default routerTechosPresupuestarios;
