import { http_client } from "../plugins/http_client";

const getSolicitudesCompraLinea = async (filters) => {
    return await http_client("/api/v1/compra/linea",filters,"get")
}

const getDetalleSolicitudCompra = async (id_compra_linea,filters={}) => {
    return await http_client(`/api/v1/compra/linea/detalle/${id_compra_linea}`,filters,"get")
}

const postSolicitudCompraLinea = async (body) => {
    return await http_client("/api/v1/compra/linea",body,"post")
}

const getCompraEnLinea = async (id_compra_linea) => await http_client(`/api/v1/compra/linea/${id_compra_linea}`,{},"get");

const getDetalleCompraLinea = async (id_detalle_compra_linea,filters) => {
    return await http_client(`/api/v1/compra/linea/detalle/${id_detalle_compra_linea}/especificacion`,filters,"get")
}

const getSegmentos = async (filter) => {
    return await http_client("/api/v1/obs/segmentos",filter,"get")
}

const getProductoSegmento = async (id_segmento,filter) => {
    return await http_client(`/api/v1/obs/segmentos/${id_segmento}/productos`,filter,"get")
}

const putCompraLinea = async(id_compra_linea,body) => {
    return await http_client(`/api/v1/compra/linea/${id_compra_linea}`,body,"put")
}

const postDetalleCompra = async (id_solicitud_compra_linea,body) => {
    return await http_client(`/api/v1/compra/linea/detalle/${id_solicitud_compra_linea}`,body,"post")
}

const putDetalleCompra = async (id_solicitud_compra_linea, id_detalle, body) => {
    return await http_client(`/api/v1/compra/linea/detalle/${id_solicitud_compra_linea}/${id_detalle}`,body,"put")
}

const deleteSolicitudCompra = async (id_compra_linea, filter) => {
    return await http_client(`/api/v1/compra/linea/${id_compra_linea}`,filter,"delete")
}

const deleteDetalleCompraLinea = async (id_solicitud_compra_linea, id_detalle,filter) => {
    return await http_client(`/api/v1/compra/linea/detalle/${id_solicitud_compra_linea}/${id_detalle}`,filter,"delete")
}

const putEstado = async (id_compra_linea,body) => {
    return await http_client(`/api/v1/compra/linea/${id_compra_linea}/aprobacion`,body,"put")
}

const putFinanciamiento = async (id_compra_linea,body) => {
    return await http_client(`/api/v1/compra/linea/${id_compra_linea}/financiamiento`,body,"put")
}
export default {
    getSolicitudesCompraLinea,
    getDetalleSolicitudCompra,
    postSolicitudCompraLinea,
    getCompraEnLinea,
    getDetalleCompraLinea,
    putCompraLinea,
    getSegmentos,
    getProductoSegmento,
    postDetalleCompra,
    putDetalleCompra,
    deleteSolicitudCompra,
    deleteDetalleCompraLinea,
    putEstado,
    putFinanciamiento,
}