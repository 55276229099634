/*
    prefijo Set: para asignar un valor a una state
    prefijo reset: para reiniciar el valor de una state
*/

export const SetListSolicPendtMt = (state, payload) => {
    state.list_solic_pendt_st = payload
}

export const ResetListSolicPendtMt = (state) => {
    state.list_solic_pendt_st = []
}

//  paginacion de solicitudes pendientes
export const SetListSolicPendtPagMt = (state, payload) => {
    state.list_solic_pendt_pag_st.page = Number(payload.page)
    state.list_solic_pendt_pag_st.per_page = Number(payload.per_page)
    state.list_solic_pendt_pag_st.total_rows = Number(payload.total_rows)
}

export const ResetListSolicPendtPagMt = (state) => {
    state.list_solic_pendt_pag_st.page = 1
    state.list_solic_pendt_pag_st.per_page = 10
    state.list_solic_pendt_pag_st.total_rows = 0
}

export const SetListSolicAprobMt = (state, payload) => {
    state.list_solic_aprob_st = payload
}

export const ResetListSolicAprobMt = (state) => {
    state.list_solic_aprob_st = []
}

//  paginacion de solicitudes aprobadas
export const SetListSolicAprobPagMt = (state, payload) => {
    state.list_solic_aprob_pag_st.page = Number(payload.page)
    state.list_solic_aprob_pag_st.per_page = Number(payload.per_page)
    state.list_solic_aprob_pag_st.total_rows = Number(payload.total_rows)
}

export const ResetListSolicAprobPagMt = (state) => {
    state.list_solic_aprob_pag_st.page = 1
    state.list_solic_aprob_pag_st.per_page = 10
    state.list_solic_aprob_pag_st.total_rows = 0
}
