import { http_client_with_status } from "@/plugins/http_client";

const obtenerDepartamentoUsuarioLogueado = () => 
    http_client_with_status('/api/v1/seguimiento-procesos/departamento', null, 'get');

const obtenerTecnicosDepartamento = (filtros) => 
    http_client_with_status('/api/v1/seguimiento-procesos/departamento/tecnicos', filtros, 'get');

const obtenerEstados = () => 
    http_client_with_status('/api/v1/seguimiento-procesos/catalogo/estados', null, 'get');

const obtenerDepartamentos = () => 
    http_client_with_status('/api/v1/seguimiento-procesos/catalogo/departamentos', null, 'get');

const guardarSeguimiento = (idProcesoCompra, data) => 
    http_client_with_status(`/api/v1/seguimiento-procesos/asignar/${idProcesoCompra}`, data, 'post');

const cargarAsignaciones = (filtros) => http_client_with_status('/api/v1/seguimiento-procesos/asignaciones', filtros, 'get');

const generarReporteAsignaciones = () => http_client_with_status(
    '/api/v1/seguimiento-procesos/asignaciones/reporte', 
    null, 
    'post',
    {}, 
);

const buscarAsignacion = (idAsignacion) => http_client_with_status(`/api/v1/seguimiento-procesos/asignacion/${idAsignacion}`, null, 'get');

const cargarHistorial = (idAsignacion, filtros) => http_client_with_status(`/api/v1/seguimiento-procesos/asignacion/historial/${idAsignacion}`, filtros, 'get');

const cargarCatalogoTipoRegistros = (filtros) => http_client_with_status('/api/v1/seguimiento-procesos/catalogo/bitacoras', filtros, 'get');

const crearBitacora = (idAsignacion, data) => http_client_with_status(`/api/v1/seguimiento-procesos/bitacoras/crear/${idAsignacion}`, data, 'POST', {
    'Content-Type': 'multipart/form-data',
});

const cambiarEstado = (idAsignacion, idEstado) => 
    http_client_with_status(`/api/v1/seguimiento-procesos/asignacion/cambio-estado/${idAsignacion}/${idEstado}`, null, 'patch');

const visualizacionPdf = (idBitacora) => http_client_with_status(`/api/v1/seguimiento-procesos/bitacoras/archivo/${idBitacora}`, null, 'get', {}, { responseType: 'blob' });

const cambiarVisibilidad = (idSeguimiento, data) =>
    http_client_with_status(`/api/v1/seguimiento-procesos/asignacion/cambiar-visibilidad/${idSeguimiento}`, data, 'patch');

const cambiarVisibilidadIndividualmente = (idSeguimiento, data) =>
    http_client_with_status(`/api/v1/seguimiento-procesos/asignacion/cambiar-visibilidad-individualmente/${idSeguimiento}`, data, 'patch')

const cargarEstadosSeguimiento = (idProcesoCompra) => http_client_with_status(`/api/v1/seguimiento-procesos/seguimientos-departamentales/${idProcesoCompra}`, null, 'get'); 

// Observatorio
const actualizarEstadoSeguimientoObservatorio = (idProcesoCompra, idEstado) => 
    http_client_with_status(`/api/v1/seguimiento-procesos/seguimiento-observatorio/actualizar-estado/${idProcesoCompra}/${idEstado}`, null, 'put');

const cargarCatalogoEstadosObservatorio = () => http_client_with_status('/api/v1/seguimiento-procesos/seguimiento-observatorio/catalogo-estados', null, 'get');

const buscarSeguimientoObservatorioPorProceso = (idProcesoCompra) => 
    http_client_with_status(`/api/v1/seguimiento-procesos/seguimiento-observatorio/seguimiento/${idProcesoCompra}`, null, 'get');

export default {
    obtenerDepartamentoUsuarioLogueado,
    obtenerEstados,
    obtenerTecnicosDepartamento,
    guardarSeguimiento,
    cargarAsignaciones,
    buscarAsignacion,
    cargarHistorial,
    cargarCatalogoTipoRegistros,
    crearBitacora,
    cambiarEstado,
    visualizacionPdf,
    obtenerDepartamentos,
    cambiarVisibilidad,
    cambiarVisibilidadIndividualmente,
    generarReporteAsignaciones,
    cargarEstadosSeguimiento,
    actualizarEstadoSeguimientoObservatorio,
    cargarCatalogoEstadosObservatorio,
    buscarSeguimientoObservatorioPorProceso,
};
