/**
 * @description Constante que contiene los tipos de acciones que se pueden realizar en las vistas
 * @type {Object}
 * @property {String} create - Accion de crear
 * @property {String} edit - Accion de editar
 * @property {String} view - Accion de ver
 */
const TYPE_VIEW_CONST = {
  create: "create",
  edit: "edit",
  view: "view",
};

const routesProcCompReserva = [
  {
    path: "/proceso-compra-reserva/list-procesos",
    name: "proceso-compra-reserva-list-procesos",
    component: () =>
      import(
          /* webpackChunkName: "ListProcesoCompra" */ "./listProcesosView.vue"
      ),
  },
  {
    path: "/proceso-compra-reserva/crear-proceso",
    name: "proceso-compra-reserva-crear-proceso",
    component: () =>
      import(
          /* webpackChunkName: "CrearProcesoCompra" */ "./mainProcesoView.vue"
      ),
    meta: {
      type_action: TYPE_VIEW_CONST.create,
    }
  },
  {
    path: "/proceso-compra-reserva/editar-proceso/:id_proceso",
    name: "proceso-compra-reserva-editar-proceso",
    component: () =>
      import(
          /* webpackChunkName: "EditarProcesoCompra" */ "./mainProcesoView.vue"
      ),
    meta: {
      type_action: TYPE_VIEW_CONST.edit,
    }
  },
  {
    path: "/proceso-compra-reserva/ver-proceso/:id_proceso",
    name: "proceso-compra-reserva-ver-proceso",
    component: () =>
      import(
          /* webpackChunkName: "VerProcesoCompra" */ "./mainProcesoView.vue"
      ),
    meta: {
      type_action: TYPE_VIEW_CONST.view,
    }
  },
  {
    path: "/proceso-compra-reserva/:id_proceso/editar-solicitud/:id_solicitud",
    name: "proceso-compra-reserva-editar-solicitud",
    component: () =>
      import(
          /* webpackChunkName: "EditarSolicitud" */ "./Solicitud/mainSolicitudView.vue"
      ),
  },
  {
    path: "/proceso-compra-reserva/:id_proceso/solicitud/:id_solicitud/agregar-insumo",
    name: "proceso-compra-reserva-agregar-insumo",
    component: () =>
      import(
          /* webpackChunkName: "CrearProcesoCompra" */ "./Solicitud/formInsumoView.vue"
      ),
    meta: {
      type_action: TYPE_VIEW_CONST.create,
    }
  },
  {
    path: "/proceso-compra-reserva/:id_proceso/solicitud/:id_solicitud/editar-insumo/:id_insumo",
    name: "proceso-compra-reserva-editar-insumo",
    component: () =>
      import(
          /* webpackChunkName: "EditarProcesoCompra" */ "./Solicitud/formInsumoView.vue"
      ),
    meta: {
      type_action: TYPE_VIEW_CONST.edit,
    }
  },
];

export default routesProcCompReserva;
