const routerConfiguracionTechosPresupuestarios = [
  {
    path: "/configuracion-techo-presupuestario",
    name: "configuracion-techo-presupuestario",
    component: () =>
      import(
        /* webpackChunkName: "configuracionTechoPresupuestario" */ "./configuracionTechoPresupuestarioView.vue"
      ),
  },
];

export default routerConfiguracionTechosPresupuestarios;
