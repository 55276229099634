import {http_client_with_status} from "@/plugins/http_client";

const obtenerListado = async (params = {}) => {
    return await http_client_with_status('/api/v1/cm-solicitudes-compra/listadoSolicitudes', params, 'get')
}
const listadoSolicitudesVistas = async (params = {}) => {
    return await http_client_with_status('/api/v1/cm-solicitudes-compra/listadoSolicitudesVistas', params, 'get')
}
/*se separa el resultado del endpoint en 3 listados diferentes**/
const obtenerListadoPendientesAprobacion = async (params = {}) => {
    return await http_client_with_status('/api/v1/cm-solicitudes-compra/listadoSolicitudesPendientesAprobacion', params, 'get')
}

const detalle = async (id, params = {}) => {
    return await http_client_with_status(`/api/v1/cm-solicitudes-compra/detalle/${id}`, params, 'get')
}

const alterarEstado = async (id, params = {}) => {
    return await http_client_with_status(`/api/v1/cm-solicitudes-compra/alterarEstado/${id}`, params, 'patch', {
        'Content-Type': 'multipart/form-data',
    })
}

const obtenerBotones = async (id,params = {}) => {
    return await http_client_with_status(`/api/v1/cm-solicitudes-compra/obtenerBotones/${id}`, params, 'get')
}
const historial = async (id, params = {}) => {
    return await http_client_with_status(`/api/v1/cm-solicitudes-compra/historial/${id}`, params, 'get')
}

const listadoSolicitudesAprobadas = async (params = {}) => {
    return await http_client_with_status('/api/v1/cm-solicitudes-compra/listadoSolicitudesCumplimiento', params, 'get')
}
const detalleSolicitudAprobada = async (id, params = {}) => {
    return await http_client_with_status(`/api/v1/cm-solicitudes-compra/cumplimiento/${id}`, params, 'get')
}

const loadAttachment = (params) => http_client_with_status('api/v1/cm-solicitudes-productos/cargar-documento', params, 'get', {},  { responseType: "blob" });
const loadAttachmentSolicitud = (params) => http_client_with_status('api/v1/cm-solicitudes-compra/cargar-documento', params, 'get', {},  { responseType: "blob" });

const loadRequestItemsGroupByFund = (requestId) => http_client_with_status(`api/v1/cm-solicitudes-compra/insumos-agrupados-por-fondo/${requestId}`, null, 'get');

const certifyFundOfRequestItem = (idFondoSolicitudNecesidad, idSolicitudCompra, idProductoSolicitud, data) => 
    http_client_with_status(`api/v1/cm-solicitudes-compra/certificar-insumo/${idFondoSolicitudNecesidad}/${idSolicitudCompra}/${idProductoSolicitud}`, data, 'post');

const certifyFundOfRequestItemAll = (idFondoSolicitudNecesidad, idSolicitudCompra, data) => 
    http_client_with_status(`api/v1/cm-solicitudes-compra/certificar-todos-los-insumos/${idFondoSolicitudNecesidad}/${idSolicitudCompra}`, data, 'post');

const uploadCertificationFile = (idFondoSolicitudNecesidad, idSolicitudCompra, data) => 
    http_client_with_status(`api/v1/cm-solicitudes-compra/subir-archivo-certificacion/${idFondoSolicitudNecesidad}/${idSolicitudCompra}`, data, 'post', {
        'Content-Type': 'multipart/form-data',
    });

const previewCertificationFile = (idArchivoCertificacion) => http_client_with_status(`api/v1/cm-solicitudes-compra/visualizar-archivo-certificacion/${idArchivoCertificacion}`, null, 'get', {}, {
    responseType: "blob",
});

const deleteCertificationFile = (idArchivoCertification) => http_client_with_status(`api/v1/cm-solicitudes-compra/eliminar-archivo-certificacion/${idArchivoCertification}`, null, 'delete');

// Archivos de autorizacion 
const listAuthFiles = (idSolicitudCompra, params) => http_client_with_status(`/api/v1/cm-solicitudes-compra/archivos-autorizacion/listar/${idSolicitudCompra}`, params, 'get');

const previewAuthFile = (idArchivo) => http_client_with_status(`api/v1/cm-solicitudes-compra/archivos-autorizacion/visualizar/${idArchivo}`, null, 'get', {}, {
    responseType: "blob",
});

const saveAuthFile = (idSolicitudCompra, data) => http_client_with_status(
    `api/v1/cm-solicitudes-compra//archivos-autorizacion/guardar/${idSolicitudCompra}`, 
    data, 
    'post', 
    { 'Content-Type': 'multipart/form-data' },
);

const deleteAuthFile = (idArchivo) => http_client_with_status(`api/v1/cm-solicitudes-compra/archivos-autorizacion/eliminar/${idArchivo}`, null, 'delete');

const productosPorProveedor = (requestId) => http_client_with_status(`api/v1/cm-solicitudes-compra/productos-por-proveedor/${requestId}`, null, 'get');

const crearBitacora = (idSolicitud, data) => http_client_with_status(`/api/v1/cm-solicitudes-compra/bitacoras/crear/${idSolicitud}`, data, 'POST', {
    'Content-Type': 'multipart/form-data',
});

const generarOrdenesPendientes = async (params = {}) => {
    return await http_client_with_status(`/api/v1/cm-solicitudes-compra/generar-job-oc`, params, "post");
  };

export default {
    obtenerListado,
    obtenerListadoPendientesAprobacion,
    detalle,
    alterarEstado,
    obtenerBotones,
    historial,
    listadoSolicitudesAprobadas,
    detalleSolicitudAprobada,
    listadoSolicitudesVistas,
    loadAttachment,
    loadAttachmentSolicitud,
    loadRequestItemsGroupByFund,
    certifyFundOfRequestItem,
    certifyFundOfRequestItemAll,
    uploadCertificationFile,
    previewCertificationFile,
    deleteCertificationFile,
    // Archivos de autorizacion
    listAuthFiles,
    previewAuthFile,
    saveAuthFile,
    deleteAuthFile,
    productosPorProveedor,
    crearBitacora,
    generarOrdenesPendientes
};
