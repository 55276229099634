import Sanciones from "../../../services/Sanciones.services";
import Vue from "vue";
export default {
  namespaced: true,
  state: {
    reportes: [],
    reporteDetail: {},
    contrato:{},
    filters: {
      page: 1,
      per_page: 10,
      total_rows: null,
      paginacion: "true",
      no_proceso: null,
      estado: null,
      modalidad_compra: null,
    },
  },
  mutations: {
    setReportes(state, payload) {
      state.reportes = payload;
    },
    setPagination(state, payload) {
      state.filters.page = Number(payload.page);
      state.filters.per_page = Number(payload.per_page);
      state.filters.total_rows = Number(payload.total_rows);
    },
    setReporteDetail(state, payload) {
      state.reporteDetail = payload;
    },
    setContrato(state, payload){
      state.contrato = payload
    },
    setFilters(state, payload){
      state.filters = payload
    }
  },
  actions: {
    async getReportesProveedor({ commit, state }) {
      Vue.prototype.showLoader()
      const response = await Sanciones.getReporteSancionInstitucion(
        state.filters
      ).catch(() => {
        Vue.prototype.hideLoader()
      });
      if( response.status === 200 ) {
        commit("setReportes", response.data);
        commit("setPagination", response.headers);
      }
      Vue.prototype.hideLoader()
    },
    async getReporteDetail({ commit }, id_reporte_sancion) {
      Vue.prototype.showLoader()
      const { data } = await Sanciones.showReporteSancion(id_reporte_sancion).catch(() => {
        Vue.prototype.hideLoader()
      });;
      commit("setReporteDetail", data);
      Vue.prototype.hideLoader()
    },
  },
};
