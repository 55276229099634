import SolicitudCompraServices from "../../../../services/SolicitudCompra.services";

export default {
    namespaced: true,
    state: {
        id_proceso_compra_st: null,
        show_modal_insumo_detail_st: false,
        list_insumos_st: [],
        solicitud: null,
    },
    mutations: {
        SetIdProcesoCompraMt(state, payload) {
            state.id_proceso_compra_st = payload;
        },
        ResetIdProcesoCompraMt(state) {
            state.show_modal_insumo_detail_st = false;
            state.id_proceso_compra_st = null;
        },
        SetShowModalInsumoDetailMt(state, payload) {
            state.show_modal_insumo_detail_st = payload;
        },
        ResetShowModalInsumoDetailMt(state) {
            state.show_modal_insumo_detail_st = false;
        },
        SetListInsumosMt(state, payload) {
            state.list_insumos_st = payload;
        },
        ResetListInsumosMt(state) {
            state.list_insumos_st = [];
        },
        SetSolicitud(state, payload) {
            state.solicitud = payload
        },
        SetItemListInsumosMt(state, payload) {
            state.list_insumos_st.push({
                ...payload,
                total: payload.solicitud_compra_detalle_obs[0].cantidad * payload.precio_unitario,
                num: state.list_insumos_st.length + 1,
            })
        },
        RemoveItemListInsumosByIndexMt(state, payload) {
            const index = state.list_insumos_st.findIndex(item => item.id == payload)
            state.list_insumos_st.splice(index, 1)

            state.list_insumos_st = state.list_insumos_st.map((item, index) => {
                return {
                    ...item,
                    num: index + 1,
                }
            }
            )
        },
        UpdateItemListInsumosMt(state, payload) {
            const index = state.list_insumos_st.findIndex(item => item.id == payload.id)
            state.list_insumos_st.splice(index, 1, {
                ...payload,
                total: payload.solicitud_compra_detalle_obs[0].cantidad * payload.precio_unitario,
                num: index + 1,
            })
        },
        ChangeIdEstadoInsumoMt(state, payload) {
            let estado = {}
            switch (Number(payload.id_estado)) {
                case 4:
                    estado = {
                        id_estado: 4,
                        color: "#EF5350",
                        estado: "Descartado por jefe de unidad",
                    }
                    break;

                case 3:
                    estado = {
                        id_estado: 3,
                        color: "#C1ECD9",
                        estado: "Aprobado por jefe de unidad",
                    }
                    break;

                case 1:
                    estado = {
                        id_estado: 2,
                        color: "#E9ECEF",
                        estado: "Pendiente",
                    }
                    break;

                default:
                    return
            }

            const index = state.list_insumos_st.findIndex(item => item.id == payload.id_insumo)
            state.list_insumos_st.splice(index, 1, {
                ...state.list_insumos_st[index],
                ...estado
            })
        }
    },
    actions: {
        async FetchSolicitudesNecesidadesAc({ commit }, id_solicitud_compra) {
            const RESP_SC_CONST = await SolicitudCompraServices.getDetailSolicitudCompra(id_solicitud_compra);
            if (RESP_SC_CONST) {
                const list_insumos = RESP_SC_CONST.data.solicitud_compra_obs.map(
                    (item, index) => {
                        return {
                            ...item,
                            total:
                                item.solicitud_compra_detalle_obs[0].cantidad *
                                item.precio_unitario,
                            num: index + 1,
                        };
                    }
                );
                commit("SetListInsumosMt", list_insumos);
            }
        }
    },
}