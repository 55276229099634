import { http_client_with_status } from "@/plugins/http_client";

const generarReporteAdjudicaciones = () => http_client_with_status(`/api/v1/inteligencia-mercado/reportes/adjudicaciones`, null, 'post');

const generarReporteSolicitudesObs = (filtros) => http_client_with_status(`/api/v1/inteligencia-mercado/reportes/solicitudes-obs`, filtros, 'post');

const cargarSolicitudesObs = (filtros) => http_client_with_status('/api/v1/inteligencia-mercado/solicitudes-obs', filtros, 'get');

const cargarCatalogoObs = (filtros) => http_client_with_status('/api/v1/inteligencia-mercado/catalogo-obs/menor-jerarquia', filtros, 'get');

export default {
    generarReporteAdjudicaciones,
    generarReporteSolicitudesObs,
    cargarSolicitudesObs,
    cargarCatalogoObs,
}
