export const setShareholders = (state, payload) => {
  state.shareholders = payload;
};

export const setCurrentPercentage = (state, payload) => {
  state.currentPercentage = payload;
};

export const setTotalRows = (state, payload) => {
  state.totalRows = payload;
}

export const setPage = (state, payload) => {
  state.page = payload;
}

export const setPerPage = (state, payload) => {
  state.perPage = payload;
}
