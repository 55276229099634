export default {
  namespaced: true,
  state: {
    solicitudCompras: [
      {
        id: 1,
        correlativo: "01-2022-S001",
        unidadSolicitante: "Unidad de Retención de Talento Humano",
        fechaCreacion: "07/03/2022 3:05PM",
        tipoCompra: "Por proceso",
        estado: { id: 1, nombre: "Aprobado por unidad" },
      },
      {
        id: 2,
        correlativo: "01-2022-S422",
        unidadSolicitante: "Unidad de Retención de Talento Humano",
        fechaCreacion: "25/04/2022 10:00AM",
        tipoCompra: "Por proceso",
        estado: { id: 2, nombre: "Con observación" },
      },
      {
        id: 3,
        correlativo: "01-2022-S423",
        unidadSolicitante: "Unidad de Retención de Talento Humano",
        fechaCreacion: "25/04/2022 10:00AM",
        tipoCompra: "Compra en linea",
        estado: { id: 3, nombre: "Elaborada" },
      },
    ],

    fondos: [
      {
        id: 1,
        fondo: "$23,733.00",
        titulo: "Fondo general disponible",
        color: "green accent-3",
      },
      {
        id: 2,
        fondo: "$125.00",
        titulo: "Fondo necesarios para solicitud",
        color: "yellow lighten-1",
      },
      {
        id: 3,
        fondo: "$23,566.40",
        titulo: "Saldo de fondos general",
        color: "green accent-3",
      },
    ],
  },

  mutations: {},
  actions: {},
};
