<template>
  <v-card class="app-time-line" :loading="loading">
    <slot name="title"></slot>
    <v-card-actions v-if="showActions">
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="$emit('cerrar')">Cerrar</v-btn>
    </v-card-actions>
    <v-card-text>
      <v-timeline>
        <v-timeline-item
            v-for="(item, index) in items"
            :key="index"
            small
            :color="item.color "
            fill-dot
        >
          <slot name="item" :item="item" :index="index">
            <span class="text-break" style="line-height: 24px;">
               {{ item.fecha }} - {{ item.descripcion }}
              <strong v-if="item.comentario"> - Comentario:</strong> <span class="text--primary"> {{ item.comentario ?? '' }}</span>
            </span>
            <slot name="suffix" :item="item"></slot>
          </slot>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
    },
  },

}
</script>
