const configuraciones = [
    {
        path: "/configuracion-catalogo-docuemento",
        name: "configuracion-catalogo-docuemento",
        component: () =>
            import(/* webpackChunkName: "configuracion-catalogo-docuemento" */ "./ConfiguracionView.vue"),
    },

];

export default configuraciones;
