import { http_client } from "@/plugins/http_client";

const listatributo = async (params = {}) => {
  return await http_client(`/api/v1/cm-atributo`, params, "get");
};

const getatributo = async (id) => {
  return await http_client(`/api/v1/cm-atributo/${id}`, "get");
};

const storeatributo = async (form) => {
  return await http_client(`/api/v1/cm-atributo`, form, "post");
};

const updateatributo = async (id, form = {}) => {
  return await http_client(`/api/v1/cm-atributo/${id}`, form, "put");
};

const deleteatributo = async (id, params={}) => {
  return await http_client(`/api/v1/cm-atributo/${id}`, params, "delete");
};

export default {
  listatributo,
  getatributo,
  deleteatributo,
  storeatributo,
  updateatributo,
};
