import { isResponseSuccesful } from '@/utils/loadable';
import cmProducto from '../../../services/cmProducto.services'
import moment from "moment";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        listado_regiones: [],
        listado_ofertas: [],
        listado_atributos: [],
        firmantes: [],
        firmantesDinac: [],
        firmanteDinacEdit: null,
        direcciones: [],
        institucion: {
            id: null,
            nombre: null,
        },
        fecha_convenio: moment().format("DD-MM-YYYY"),
        tipo_convenio: null,
        firmanteEdit: null,
        regionEdit: null,
        ofertaEdit: null,
        direccionEdit: null,
        telefono: null,
        pdf: null,
        ofertaDireccion: null,
        ofertaRegion: null,
    },
    mutations: {
        actualizarOfertaDireccion(state, payload) {
            state.ofertaDireccion = payload;
        },
        actualizarOfertaRegion(state, payload) {
            state.ofertaRegion = payload;
        },
        obtenerProductoSubGrupo(state, payload) {
            state.listado_atributos = payload;
        },
        resetDireccionEdit(state) {
            state.direccionEdit = null;
        },
        resetRegionEdit(state) {
            state.regionEdit = null;
        },
        agregarRegion(state, payload) {
            state.listado_regiones.push(payload)
            state.regionEdit = null;
        },
        removerRegion(state, payload) {
            state.listado_regiones.find((item, index) => {
                if (item?.id_memoria === payload) {
                    state.listado_regiones.splice(index, 1);
                }
            });
        },
        agregarOferta(state, payload) {
            state.listado_ofertas.push(payload)
            state.ofertaEdit = null;
        },
        removerOferta(state, payload) {
            state.listado_ofertas.find((item, index) => {
                if (item?.id_memoria === payload) {
                    state.listado_ofertas.splice(index, 1);
                }
            });
        },
        setListadoAtributos(state, payload) {
            state.listado_atributos = payload;
        },
        setCrearDirecciones(state, payload) {
            state.direcciones = payload;
        },
        agregarFirmante(state, payload) {
            state.firmantes.push(payload)
            state.firmanteEdit = null;
        },
        removerFirmante(state, payload) {
            state.firmantes.find((item, index) => {
                if (item?.id_memoria === payload) {
                    state.firmantes.splice(index, 1);
                }
            })
        },
        setInstitucion(state, payload) {
            state.institucion = payload;
        },
        setTipoConvenio(state, payload) {
            state.tipo_convenio = payload;
        },
        setFechaConvenio(state, payload) {
            state.fecha_convenio = payload;
        },
        editarFirmante(state, payload) {
            state.firmanteEdit = payload;
            state.firmantes.find((item, index) => {
                if (item?.id_memoria === payload?.id_memoria) {
                    state.firmantes.splice(index, 1);
                }
            })
        },
        editarRegion(state, payload) {
            state.regionEdit = payload;
            state.listado_regiones.find((item, index) => {
                if (item?.id === payload?.id) {
                    state.listado_regiones.splice(index, 1);
                }
            })
        },
        editarOferta(state, payload) {
            state.ofertaEdit = payload;
            state.listado_ofertas.find((item, index) => {
                if (item?.id_memoria === payload?.id_memoria) {
                    state.listado_ofertas.splice(index, 1);
                }
            })
        },
        agregarDireccion(state, payload) {
            state.direcciones.push(payload)
            state.direccionEdit = null;
        },

        editarDireccion(state, payload) {
            state.direccionEdit = payload;
            state.direcciones.find((item, index) => {
                if (item?.id === payload?.id) {
                    state.direcciones.splice(index, 1);

                }
            })
        },
        agregarFirmanteDinac(state, payload) {
            state.firmantesDinac.push(payload)
        },
        removerFirmanteDinac(state, payload) {
            state.firmantesDinac.find((item, index) => {
                if (item?.id_memoria === payload) {
                    state.firmantesDinac.splice(index, 1);
                }
            })
        },
        editarFirmanteDinac(state, payload) {
            state.firmanteDinacEdit = payload;
            state.firmantesDinac.find((item, index) => {
                if (item?.id_memoria === payload?.id_memoria) {
                    state.firmantesDinac.splice(index, 1);
                }
            })
        },
        setFirmantes(state, payload) {
            const {firmantes_oferente = null, firmantes_dinac = null} = payload
            state.firmantes = firmantes_oferente
            state.firmantesDinac = firmantes_dinac
        },
        setRegiones(state, payload) {
            state.listado_regiones = payload
        },
        cargarDirecciones(state, payload) {
            state.direcciones = payload
        },
        setOfertas(state, payload) {
            state.listado_ofertas = payload
        },
        setTelefono(state, payload) {
            state.telefono = payload
        },
        setOferta(state, payload) {
            state.oferta = payload
        },
        setPdf(state, payload) {
            state.pdf = payload;
        },
        resetState(state) {
            state.listado_regiones = [];
            state.listado_ofertas = [];
            state.listado_atributos = [];
            state.firmantes = [];
            state.firmantesDinac = [];
            state.firmanteDinacEdit = null;
            state.direcciones = [];
            state.direccionesOferta = [];
            state.institucion = {
                id: null,
                nombre: null,
            };
            state.fecha_convenio = moment().format("DD-MM-YYYY");
            state.tipo_convenio = null;
            state.firmanteEdit = null;
            state.regionEdit = null;
            state.ofertaEdit = null;
            state.direccionEdit = null;
            state.telefono = null;
            state.pdf = null;
            state.ofertaDireccion = null;
            state.ofertaRegion = null;
        },
        
    },
    actions: {
        resetDireccionEdit({ commit }) {
            commit('resetDireccionEdit');
        },
        resetRegionEdit({ commit }) {
            commit('resetRegionEdit');
        },
        agregarRegion({commit}, payload) {
            commit("agregarRegion", payload);
        },
        removerRegion({commit}, payload) {
            commit("removerRegion", payload);
        },
        agregarOferta({commit}, payload) {
            commit("agregarOferta", payload);
        },
        removerOferta({commit}, payload) {
            commit("removerOferta", payload);
        },
        setListadoAtributosLocal({commit}, payload) {
            commit("setListadoAtributos", payload);
        },
        async setListadoAtributosInicial({commit}, id) {
            const {data} = await cmProducto.listAtributosProducto(id);
            commit("setListadoAtributos", data.data);
        },
        agregarFirmante({commit}, payload) {
            commit("agregarFirmante", payload);
        },
        removerFirmante({commit}, payload) {
            commit("removerFirmante", payload);
        },
        setInstitucion({commit}, payload) {
            commit("setInstitucion", payload);
        },
        setTipoConvenio({commit}, payload) {
            commit("setTipoConvenio", payload);
        },
        setFechaConvenio({commit}, payload) {
            commit("setFechaConvenio", payload);
        },
        setPdf({ commit }, payload) {
            commit('setPdf', payload);
        },
        editarFirmante({commit}, payload) {
            commit("editarFirmante", payload);
        },
        editarRegion({commit}, payload) {
            commit("editarRegion", payload);
        },
        editarOferta({commit}, payload) {
            commit("editarOferta", payload);
        },
        agregarDireccion({commit}, payload) {
            commit("agregarDireccion", payload);
        },
        removerDireccion({commit}, payload) {
            commit("removerDireccion", payload);
        },
        editarDireccion({commit}, payload) {
            commit("editarDireccion", payload);
        },
        agregarFirmanteDinac({commit}, payload) {
            commit("agregarFirmanteDinac", payload);
        },
        removerFirmanteDinac({commit}, payload) {
            commit("removerFirmanteDinac", payload);
        },
        editarFirmanteDinac({commit}, payload) {
            commit("editarFirmanteDinac", payload);
        },
        setFirmantes({commit}, payload) {
            commit("setFirmantes", payload)
        },
        setRegiones({commit}, payload) {
            commit('setRegiones', payload)
        },
        setOfertas({commit}, payload) {
            commit('setOfertas', payload)
        },
        setTelefono({commit}, payload) {
            commit('setTelefono', payload)
        },
        resetState({commit}) {
            commit('resetState')
        },
        // Direcciones
        async cargarDirecciones({ commit }, id) {
            const { data } = await cmProducto.obtenerOfertaDireccion(id);
            commit("cargarDirecciones", data.data);
        },
        async agregarOfertaDireccion({commit}, ofertaDireccion) {
          const { data } =  await cmProducto.agregarOfertaDireccion(ofertaDireccion);
          commit("actualizarOfertaDireccion", data.data);
          return data;
        },
        async eliminarDireccion({dispatch}, payload) {
            const { direccion, idOferta } = payload;
            const { data } =  await cmProducto.deleteDireccion(direccion.id);
            if (isResponseSuccesful(data)) {
                dispatch('cargarDirecciones', idOferta);
            }
            return data;
        },

        // Regiones
        async cargarRegiones({ commit }, id) {
            const { data } = await cmProducto.obtenerOfertaRegion(id);
            commit("setRegiones", data.data);
        },
        async agregarOfertaRegion({commit}, ofertaRegion) {
            const { data } =  await cmProducto.agregarOfertaRegion(ofertaRegion);
            commit("actualizarOfertaRegion", data.data);
            return data;
        },
        async eliminarRegion({dispatch}, payload) {
            const { region, idOferta } = payload;
            const { data } =  await cmProducto.deleteRegion(region.id);
            if (isResponseSuccesful(data)) {
                dispatch('cargarRegiones', idOferta);
            }
            return data;
        },
        // async cargarProductoSubGrupo({ commit }, id) {
        //     const { data } = await cmProducto.cargarProductoSubGrupo(id);
        //     commit("obtenerProductoSubGrupo", data.data);
        //     return data;
        // },
    },
    getters: {
        getListadoRegiones(state) {
            return state.listado_regiones;
        },
        getListadoOfertas(state) {
            return state.listado_ofertas;
        },
        getListadoAtributos(state) {
            return state.listado_atributos;
        },
        getFirmantes(state) {
            return state.firmantes;
        },
        getInstitucion(state) {
            return state.institucion;
        },
        getTipoConvenio(state) {
            return state.tipo_convenio;
        },
        getFechaConvenio(state) {
            return state.fecha_convenio;
        },
        getFirmanteEdit(state) {
            return state.firmanteEdit;
        },
        getRegionEdit(state) {
            return state.regionEdit;
        },
        getOfertaEdit(state) {
            return state.ofertaEdit;
        },
        getDirecciones(state) {
            return state.direcciones;
        },
        getDireccionEdit(state) {
            return state.direccionEdit;
        },
        getFirmantesDinac(state) {
            return state.firmantesDinac;
        },
        getFirmanteDinacEdit(state) {
            return state.firmanteDinacEdit;
        },
        getTelefono(state) {
            return state.telefono;
        },
        getPdf(state) {
            return state.pdf;
        },
        getOferta(state) {
            return state.oferta;
        },
    }
};
