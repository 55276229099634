<template>
  <!-- <v-navigation-drawer
    mini-variant-width="80"
    v-model="drawer"
    permanent
    app
    @update:mini-variant="setDrawer"
  > -->
  <v-navigation-drawer
    :expand-on-hover="!$vuetify.breakpoint.smAndDown"
    mini-variant-width="80"
    :mini-variant="!$vuetify.breakpoint.smAndDown ? drawerInput : false"
    :permanent="!$vuetify.breakpoint.smAndDown"
    :temporary="$vuetify.breakpoint.smAndDown"
    v-model="drawerInput"
    app
    @update:mini-variant="setDrawer"
    style="z-index: 200"
  >
    <v-list dense nav class="pt-3 px-0 d-flex flex-column" height="100%">
      <div style="overflow-y: auto">
        <span v-for="(item, i) in customMenu" :key="i">
          <v-tooltip
            :disabled="$vuetify.breakpoint.xs"
            v-if="item.childrens && item.childrens.length"
            right
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-group
                class="list-group-menu"
                v-bind="attrs"
                v-on="on"
                active-class="primary--text"
              >
                <template #activator>
                  <v-list-item-title class="text-no-style">
                    {{ item.nombre }}
                  </v-list-item-title>
                </template>
                <template #prependIcon>
                  <v-icon color="primary">{{ item.icono }}</v-icon>
                </template>

                <div>
                  <v-list-item
                    v-for="(child, j) in item.childrens"
                    :key="j"
                    :to="child.uri"
                    active-class="primary--text"
                  >
                    <v-tooltip
                      :disabled="$vuetify.breakpoint.xs"
                      right
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-icon v-bind="attrs" v-on="on">
                          <v-icon>{{ child.icono }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title
                          class="text-no-style"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span>{{ child.nombre }}</span>
                        </v-list-item-title>
                      </template>
                      <span>{{ child.nombre }}</span>
                    </v-tooltip>
                  </v-list-item>
                </div>
              </v-list-group>
            </template>

            <span>{{ item.nombre }}</span>
          </v-tooltip>

          <v-list-item
            v-else
            :to="item.uri"
            class="list-group-menu"
            active-class="primary--text"
          >
            <v-tooltip :disabled="$vuetify.breakpoint.xs" right color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon v-bind="attrs" v-on="on">
                  <v-icon>{{ item.icono }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title
                  class="text-no-style"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.nombre }}
                </v-list-item-title>
              </template>
              <span>{{ item.nombre }}</span>
            </v-tooltip>
          </v-list-item>
        </span>
      </div>

      <div
        v-if="!drawerInput"
        style="height: 25%; overflow: hidden"
        id="img-container"
      >
        <v-list-item class="mt-auto" v-if="!drawerInput">
          <img
            class="mx-auto"
            :src="require(`@/assets/img/logo_512_negro.png`)"
            :width="breakImage"
            height="auto"
          />
        </v-list-item>
        <v-list-item class="mt-auto" v-else>
          <img
            class="mx-auto hidden-md-and-up"
            :src="require(`@/assets/img/logo_512_negro.png`)"
            width="50%"
            height="auto"
          />
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  name: "AppAsideBarComponent",
  data: () => ({
    isMovil: false,
  }),
  props: {
    menu: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    ...mapMutations("utils", ["setSideBar"]),
    ...mapActions("utils", ["toggleDrawer", "setDrawer"]),
    setTooltip(item) {
      let index = this.customMenu.indexOf(item);
      this.customMenu[index].show = !this.customMenu[index].show;
      this.$forceUpdate();
    },
  },
  computed: {
    ...mapState("utils", ["drawer"]),
    customMenu() {
      return this.menu.map((obj) => ({ ...obj, show: false }));
    },

    breakImage() {
      // retorna el tamaño de la imagen de acuerdo al tamaño de la pantalla xs, sm, md, lg, xl
      // xs
      if (this.$vuetify.breakpoint.xsOnly) {
        return "55%";
      }
      // sm
      if (this.$vuetify.breakpoint.smOnly) {
        return "65%";
      }
      // md
      if (this.$vuetify.breakpoint.mdOnly) {
        return "75%";
      }
      // lg
      if (this.$vuetify.breakpoint.lgAndUp) {
        return "85%";
      }
    },

    drawerInput: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.setDrawer(value);
      },
    },
  },
};
</script>

<style>
.v-list-item {
  flex: 0;
  /* margin-top: -200px; */
}

.list-group-menu .v-list-group__header {
  padding: 0px 15px !important;
}

.list-group-menu.v-list-item {
  padding: 0px 15px !important;
}

@media (max-height: 795px) {
  #img-container {
    display: none;
  }
}
</style>
