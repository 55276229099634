const CifradoPresupuestario = [
  {
    path: "/cifrado-presupuestario",
    name: "cifrado-presupuestario",
    component: () =>
      import(/* webpackChunkName: "cifrado-presupuestario" */ "./institucionesView.vue"),
  },
  {
    path: "/asignacion-presupuestaria/:id_institucion",
    name: "techos-presupuestarios-asignacion",
    component: () =>
      import(/* webpackChunkName: "cifrado-presupuestario" */ "./asignacionView.vue"),
  },
  {
    path: "/techos-presupuestarios/:id/unidades",
    name: "techos-presupuestarios-unidades",
    component: () =>
      import(/* webpackChunkName: "cifrado-presupuestario" */ "./listUnidadesView.vue"),
  },
];

export default CifradoPresupuestario;
