import {http_client} from "@/plugins/http_client";

const getClasificacionEmpres = async(params = {}) =>{
    return await http_client("/api/v1/clasificaciones/empresas", params,"get")
    //ClasificacionEmpresaServices.js
}

export default{
    getClasificacionEmpres
}

//ClasificacionEmpresa