<template>
  <v-dialog
      v-model="showDialog"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          outlined
          v-model="icon.spanish"
          :prepend-inner-icon="selectedIcon"
          label="Seleccione un ícono"
          v-bind="attrs"
          v-on="on"
          color="deep-purple"
          readonly
      >
      </v-text-field>
    </template>
    <v-card
    >
      <v-card-title>
        <v-text-field
            v-model="searchIcon"
            placeholder="Busca un ícono"
            prepend-icon="mdi-magnify"
            type="text"
            onautocomplete="off"
            dense
        />
      </v-card-title>
      <v-card-text
          class="overflow-y-hidden"
      >
        <v-col cols="12">
          <v-btn
              v-for="(item, i) in (filteredIcons.length===0?allIcons:filteredIcons)"
              :key="i"
              @click="saveIcon(item)"
              color="white"
              class="mr-2 mb-2 "
              fab
              small
              depressed
          >
            <v-icon color="grey darken-3"> {{ item.icon }}</v-icon>
            <v-tooltip activator="parent" location="top">{{ item.spanish }}</v-tooltip>
          </v-btn>
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import iconsJson from '../assets/json/icons.json'

export default {
  name: 'AppIconSelector',
  data: () => ({
    showDialog: false,
    icon: '',
    searchIcon: '',
    selectedIcon: 'mdi-check-outline',
    allIcons: iconsJson,
    filteredIcons: [],
  }),
  props: {
    icon_edit: {
      type: String,
      default: ''
    }
  },
  methods: {
    saveIcon: function (icon) {
      this.icon = icon;
      this.selectedIcon = "mdi-" + icon;
      this.showDialog = false;
      this.$emit('icon', this.icon.icon);
    }
  },
  watch: {
    searchIcon: function (oldSearchIcon, newSearchIcon) {
      this.filteredIcons = this.searchIcon === '' ? [] : this.allIcons.filter(i => i.spanish.includes(this.searchIcon));
    }
  },
  updated() {
    if (this.icon_edit !== '') {
      this.icon = "mdi-" + this.icon_edit;
    }
  }
}
</script>
