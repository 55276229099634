import {http_client} from "@/plugins/http_client";


const getMensajes = async(id_solicitud, params ={}) =>{
    return await http_client(`/api/v1/grupos/${id_solicitud}/mensajes`, params,"get")
};

export default{
    getMensajes
}
