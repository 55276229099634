const asignacionCDP = [
    {
      path: "/asignacion-cdp-procesos",
      name: "asignacion-cdp-procesos",
      component: () =>
        import(
          /* webpackChunkName: "AsignacionCDP" */ "./CDPListView.vue"
        ),
    },
    {
        path: "/emision-cdp-procesos/:idProceso",
        name: "emision-cdp-procesos",
        component: () =>
          import(
            /* webpackChunkName: "EmisionCDP" */ "./EmisionCDPView.vue"
          ),
      },
  ];
  
  export default asignacionCDP;