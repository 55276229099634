<template>
    <Fragment>
        <v-alert v-if="isExpired" dense outlined type="error" color="var(--v-error-lighten2)">
            <strong>La vigencia del carrito ha caducado</strong>.
        </v-alert>
        <p v-if="!isExpired && tick" class="ticker mx-0 my-0">{{ tick }}</p>
    </Fragment>
</template>
<script>
import { Fragment } from 'vue-fragment';

export default {
    name: 'TickerComponent',
    components: {
        Fragment,
    },
    props: {
        isExpired: { type: Boolean },
        tick: { type: String },
    },
}
</script>