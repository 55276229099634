import PaacServices from "../../../../services/Paac.services";
import moment from "moment-timezone";
//configuacion subasta
export const getSalasConfiguracionSubasta = async ({ commit }, payload) => {
  const { id_proceso, filters } = payload;
  const response = await PaacServices.getSalasConfiguracionSubasta(
    id_proceso,
    filters
  );
  commit("setSalas", response?.data?.salas);
  return response;
};

export const postSalasConfiguracionSubasta = async ({ commit }, payload) => {
  const { id_proceso, nombreSala } = payload;
  const response = await PaacServices.postSalasConfiguracionSubasta(
    id_proceso,
    { nombre: nombreSala }
  );
  return response;
};

export const putSalasConfiguracionSubasta = async ({ commit }, payload) => {
  const { id_sala, nombreSala } = payload;
  const response = await PaacServices.putSalasConfiguracionSubasta(id_sala, {
    nombre: nombreSala,
  });
  return response;
};

export const deleteSalaConfiguracionSubasta = async ({ commit }, payload) => {
  const response = await PaacServices.deleteSalaConfiguracionSubasta(payload);
  return response;
};

//Configuracion de salas
export const getConfiguracionSala = async ({ commit }, payload) => {
  const { id_sala, filters } = payload;
  const response = await PaacServices.getConfiguracionSala(id_sala, filters);
  commit("setSubastas", response.data);
  return response;
};

export const getProcesoSalaObs = async ({ commit }, payload) => {
  const response = await PaacServices.getProcesoSalaObs(payload);
  commit("setObss", response?.data);
};
export const getProcesoSalaPresentacion = async ({ commit }, payload) => {
  const response = await PaacServices.getProcesoSalaPresentacion(payload);
  commit("setPresentaciones", response?.data);
};
export const getProcesoSalaUnidades = async ({ commit }, payload) => {
  const response = await PaacServices.getProcesoSalaUnidades(payload);
  commit("setUnidades", response?.data);
};

export const getDetalleSolicitudes = async ({ commit, state }, payload) => {
  const response = await PaacServices.getDetalleSolicitudes(
    payload.salaId,
    payload?.payload
  );
  commit("setSolicitudes", response?.data);
};

export const postCrearSubasta = async ({ commit }, payload) => {
  const { id_sala, datos } = payload;
  datos.salto_ofertas = parseFloat(datos.salto_ofertas);
  datos.monto_inicial = parseFloat(datos.monto_inicial);
  const response = await PaacServices.postCrearSubasta(id_sala, datos);
  return response;
};

export const deleteSubasta = async ({ commit }, payload) => {
  const response = await PaacServices.deleteSubasta(payload);
  return response;
};

export const getConfigSubasta = async ({ commit }, payload) => {
  const response = await PaacServices.getConfigSubasta(payload);
  const {
    nombre,
    detalle,
    fecha_hora_inicio,
    fecha_hora_final,
    salto_oferta,
    tiempo_oferta,
    monto_inicial,
    id_presentacion,
    id_unidad,
    id_obs,
    listado_detalles,
  } = response?.data;

  //preparar valor entero para  setearlo a la propiedad tiempo_oferta_bk del objeto form
  let splitTiempo = tiempo_oferta.split(":");
  let tiempo;
  let tipoTiempo;

  splitTiempo.forEach((i, index) => {
    if (i != "00") {
      if (Number(i) < 10) {
        let splitNum = i.split("");
        tiempo = Number(splitNum[1]);
      } else {
        tiempo = Number(i);
      }
      if (index === 0) tipoTiempo = "hours";
      if (index === 1) tipoTiempo = "minutes";
      if (index === 2) tipoTiempo = "seconds";
    }
  });

  const formatSaltoOff = Intl.NumberFormat("en-US", {
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(salto_oferta);
  const formatMonto = Intl.NumberFormat("en-US", {
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(monto_inicial);
  let dataForm = {
    nombre,
    detalle,
    fecha_hora_inicio,
    id_presentacion,
    id_unidad,
    id_obs,
    listado_detalles,
    tiempo_oferta: null,
    tiempo_oferta_bk: tiempo,
    unidad_tiempo: tipoTiempo,
    monto_inicial: formatMonto,
    salto_ofertas: formatSaltoOff,
    fecha_hora_fin: fecha_hora_final,
  };

  commit("setForm", dataForm);
  return response;
};

export const editConfiguracionSubasta = async ({ commit }, payload) => {
  const { id_subasta, datos } = payload;
  datos.salto_ofertas = parseFloat(datos.salto_ofertas);
  datos.monto_inicial = parseFloat(datos.monto_inicial);
  const response = await PaacServices.editConfiguracionSubasta(
    id_subasta,
    datos
  );
  return response;
};

/**
 * Función que obtiene el rango de fechas en el que se encuentra la subasta
 *
 * @param {Object} payload id de la sala
 */
export const getRangoFechasSubasta = async ({ commit }, payload) => {
  const response = await PaacServices.getProcesoSalasFecha(payload);
  if (response.status === 200) {
    commit("setRangoFechas", response.data);
  }
  return response;
};

// Actualizar fecha maxima en el input de fecha y hora de inicio
export const updateFechaMaxima = async ({ commit }, payload) => {
  commit("setFechaMaximaInicio", payload);
};

// Actualizar fecha minima en el input de fecha y hora de finalizacion
export const updateFechaMinima = async ({ commit }, payload) => {
  commit("setFechaMinimaFin", payload);
};

export const filtrarHoras = async ({ commit, state }, payload) => {
  const { fecha_hora_inicio, fecha_hora_fin } = state.form;

  // Usamos moment para obtener fecha inicio y fecha fin sin la hora
  const fechaInicio = moment(fecha_hora_inicio).format("YYYY-MM-DD");
  const fechaFin = moment(fecha_hora_fin).format("YYYY-MM-DD");
  const horaInicioMinima = parseInt(
    moment(state.rangoFechasInicio.inicio).format("HH")
  );
  const horaFinMaxima = parseInt(moment(state.rangoFechasFin.fin).format("HH"));

  const horaInicioMaxima =
    moment(state.rangoFechasInicio.inicio).format("YYYY-MM-DD") ==
    moment(state.rangoFechasFin.fin).format("YYYY-MM-DD")
      ? parseInt(moment(state.rangoFechasFin.fin).format("HH"))
      : 23;
  const horaFinMinima =
    moment(state.rangoFechasInicio.inicio).format("YYYY-MM-DD") ==
    moment(state.rangoFechasFin.fin).format("YYYY-MM-DD")
      ? parseInt(moment(state.rangoFechasInicio.inicio).format("HH"))
      : 0;

  let horasValidasInicio = { min: horaInicioMinima, max: horaInicioMaxima };
  let horasValidasFin = { min: horaFinMinima, max: horaFinMaxima };

  if (fecha_hora_fin !== null) {
    // Comparamos las fechas para saber si son iguale sin importar la hora
    if (fechaInicio === fechaFin) {
      // Obtenemos la hora de la fecha final con moment y la pasamos a entero
      const hora = moment(fecha_hora_fin).format("HH");
      horasValidasInicio.max = parseInt(hora);
    }
  }

  commit("setHorasValidasInicio", horasValidasInicio);

  if (fecha_hora_inicio !== null) {
    // Comparamos las fechas para saber si son iguale sin importar la hora
    if (fechaInicio === fechaFin) {
      // Obtenemos la hora de la fecha inicial con moment y la pasamos a entero
      const hora = moment(fecha_hora_inicio).format("HH");
      horasValidasFin.min = parseInt(hora);
    }
  }

  commit("setHorasValidasFin", horasValidasFin);
};
