export default () => ({
    // Vista de listado de proveedores en lista negra
    list_prov_sanc_st: [],
    list_prov_sanc_pag_st: {
        page: 1,
        per_page: 10,
        total_rows: 0,
    },

    // Vista de listado de sanciones de un proveedor
    detail_prov_st: {},
    detail_sancion_st: {},
    list_sancion_prov_st: [],
    list_sancion_prov_pag_st: {
        page: 1,
        per_page: 10,
        total_rows: 0,
    },

    // View -> modal detalle de sancion
    modal_sancion_prov_st: false,
    id_sancion_st: null,
});
