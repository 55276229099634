import reporteriaSubastas from '@/services/reporteriaSubastas';

export default {
    namespaced: true,
    state: {
        listado: [],
        filtros: {
            id_institucion: null,
            fecha_programada: null,
            proveedor: null,
        },
        filtrosInstitucion: {
            pagination: true,
            per_page: 10,
            page: 1,
            busqueda: null,
        },
        paginacion: {
            page: 1,
            perPage: 10,
            total: 0,
            pagination: true,
        },
        cargando: false,
        instituciones: [],
        proveedores: [],
        get_global_subasta: {},
        cargandoTotales: false,
    },
    mutations: {
        setListado(state, listado) {
            state.listado = listado;
        },
        setFiltros(state, filtros) {
            state.filtros = filtros;
        },
        setPaginacion(state, paginacion) {
            state.paginacion = paginacion;
        },
        setFiltrosInstitucion(state, filtrosInstitucion) {
            state.filtrosInstitucion = filtrosInstitucion;
        },
        setCargando(state, cargando) {
            state.cargando = cargando;
        },
        setListadoInstituciones(state, listado) {
            state.instituciones = listado;
        },
        setListadoProveedores(state, listado) {
            state.proveedores = listado;
        },
        setGlobalSubasta(state, globalSubasta) {
            state.get_global_subasta = globalSubasta;
        },
        setCargandoTotales(state, cargando) {
            state.cargandoTotales = cargando;
        },
    },
    actions: {
        async obtenerInstituciones({commit}) {
            const {data} = await reporteriaSubastas.institucionesSubastas();
            commit('setListadoInstituciones', data);
        },
        async obtenerListado({commit}, params) {
            commit('setCargando', true);
            const {data, headers} = await reporteriaSubastas.obtenerListado(params);
            commit('setListado', data);
            commit('setPaginacion', {
                page: Number(headers['page']),
                perPage: Number(headers['per_page']),
                total: Number(headers['total_rows']),
            });
            commit('setCargando', false);
        },
        async setCargando({commit}, cargando) {
            commit('setCargando', cargando);
        },
        async setPagination({commit}, paginacion) {
            const {
                pagina,
                cantidad_por_pagina,
                pagination
            } = paginacion;
            commit('setPaginacion', {
                page: pagina,
                perPage: cantidad_por_pagina,
                pagination,
            });
        },
        async setInstitucion({commit}, id_institucion) {
            commit('setFiltros', {
                ...this.state.filtros,
                id_institucion,
            });
        },
        async setFiltros({commit}, filtros) {
            commit('setFiltros', filtros);
        },
        async obtenerProveedores({commit}) {
            const {data} = await reporteriaSubastas.proveedoresParticipandoSubasta()
            commit('setListadoProveedores', data);
        },
        async obtenerTotales({commit}, params) {
            commit('setCargandoTotales', true);
            const {data: [data]} = await reporteriaSubastas.obtenerTotales(params);
            const {get_global_subasta, get_global_subasta_institucion} = data
            commit('setGlobalSubasta', get_global_subasta ?? get_global_subasta_institucion);
            commit('setCargandoTotales', false);
        }
    },
};
