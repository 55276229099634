import { http_client_with_status } from "@/plugins/http_client";

const cargarGestiones = (filtros) => http_client_with_status('/api/v1/canales-de-comunicacion/gestiones', filtros, "get");

// const cargarGestionesPendientes = (filtros) => http_client_with_status('/api/v1/canales-de-comunicacion/gestiones/pendientes', filtros, "get");

// const cargarGestionesVistas = (filtros) => http_client_with_status('/api/v1/canales-de-comunicacion/gestiones/vistas', filtros, "get");

const cargarTiposDeGestion = () => http_client_with_status('/api/v1/canales-de-comunicacion/tipos', null, "get");

const crearGestion = (data) => http_client_with_status('/api/v1/canales-de-comunicacion/crear-gestion', data, 'post', {
  'Content-Type': 'multipart/form-data',
}); 

// const aprobarGestion = (idGestion) => http_client_with_status(`/api/v1/canales-de-comunicacion/aceptar/${idGestion}`, null, 'patch');
  
// const rechazarGestion = (idGestion) => http_client_with_status(`/api/v1/canales-de-comunicacion/rechazar/${idGestion}`, null, 'patch');

// const cerrarGestion = (idGestion) => http_client_with_status(`/api/v1/canales-de-comunicacion/cerrar/${idGestion}`, null, 'patch');

// const buscarGestion = (idGestion) => http_client_with_status(`/api/v1/canales-de-comunicacion/buscar-gestion/${idGestion}`, null, 'get');

// const cargarBitacorasGestion = (idGestion) => http_client_with_status(`/api/v1/canales-de-comunicacion/bitacoras/${idGestion}`, null, 'get');

// const crearBitacoraGestion = (idGestion, data) => http_client_with_status(`/api/v1/canales-de-comunicacion/bitacoras/crear/${idGestion}`, data, 'post', {
//   'Content-Type': 'multipart/form-data',
// });

// const cargarAdjuntoBitacora = (idBitacora) => http_client_with_status(`/api/v1/canales-de-comunicacion/obtener-adjunto/bitacora/${idBitacora}`, null, "get", {}, { responseType: 'blob' });

const visualizarAdjuntoCanalComunicacion = (idGestion) => http_client_with_status(`/api/v1/canales-de-comunicacion/obtener-adjunto/gestion/${idGestion}`, null, "get", {}, { responseType: 'blob' });

// const editarAdjuntoGestion = (idGestion, data) => 
// http_client_with_status(`/api/v1/canales-de-comunicacion/actualizar-adjunto/gestion/${idGestion}`, data, 'put', { 'Content-Type': 'multipart/form-data' });

export default {
  cargarGestiones,
  // cargarGestionesPendientes,
  // cargarGestionesVistas,
  cargarTiposDeGestion,
  crearGestion,
  // aprobarGestion,
  // rechazarGestion,
  // cerrarGestion,
  // buscarGestion,
  // cargarBitacorasGestion,
  // crearBitacoraGestion,
  // cargarAdjuntoBitacora,
  visualizarAdjuntoCanalComunicacion,
  // editarAdjuntoGestion,
};
  