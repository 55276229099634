const cmContactosProveedorRoutes = [
    {
        path: '/cm-contactos-proveedor/:id_familia',
        name: "cm-contactos-proveedor",
        component: () =>
            import(
                /* webpackChunkName: "cmContactosProveedor" */ "./index.vue"
                ),
    },
];

  export default cmContactosProveedorRoutes;
