import { http_client } from "@/plugins/http_client";

const url = "/api/v1/instituciones/financiadores";
const getOrganismos = async (params = {}) =>
  await http_client(url, params, "get");
const storeOrganismo = async (body) => await http_client(url, body, "post");
const getOrganismo = async (id) => await http_client(`${url}/${id}`);
const updateOrganismo = async (id, body) =>
  await http_client(`${url}/${id}`, body, "put");
const deleteOrganismo = async (id) =>
  await http_client(`${url}/${id}`, {}, "delete");

export default {
  getOrganismos,
  storeOrganismo,
  getOrganismo,
  updateOrganismo,
  deleteOrganismo,
};
