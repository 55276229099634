import { http_client_with_status } from "@/plugins/http_client";

const obtenerReporteriaProducto = (params) => http_client_with_status('/api/v1/cm-solicitudes-productos/reporteria', params, 'get');

const obtenerEstadisticasCmReporte = (params) => http_client_with_status('/api/v1/cm-solicitudes-productos/reporteriaTotales', params, 'get');

const cargarEstadosNoDevolucion = (params) => http_client_with_status('/api/v1/cm-solicitudes-productos/estados-no-devolucion', params, 'get');

const cargarProcesosCompra = (params) => http_client_with_status(`/api/v1/cm-solicitudes-productos/lista/procesos-compra`, params, 'get');

const cargarTopProductos = (params) => http_client_with_status(`/api/v1/cm-solicitudes-productos/reporteria/top-productos`, params, 'get');

const cargarMontosPorConvenio = (params) => http_client_with_status('/api/v1/cm-solicitudes-productos/reporteria/montos-por-convenio', params, 'get');

const cargarMontosPorAnio = (params) => http_client_with_status(`/api/v1/cm-solicitudes-productos/reporteria/montos-por-anio`, params, 'get');

export default {
    obtenerReporteriaProducto,
    obtenerEstadisticasCmReporte,
    cargarEstadosNoDevolucion,
    cargarProcesosCompra,
    cargarTopProductos,
    cargarMontosPorConvenio,
    cargarMontosPorAnio,
}
