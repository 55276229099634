import ConveniosServices from "../../../../../services/Convenios.services";

export default {
  async getListaConvenios({ commit }, filters = {}) {
    filters.id_tipo_financiacion = 2;
    const response = await ConveniosServices.getConvenios(filters);
    if (response.status === 200) {
      commit("setListaConvenios", response?.data);
      commit("setPaginationData", response?.headers);
    } else {
      commit("setListaConvenios", []);
    }
  },

  async storeConvenio({ commit }, data) {
    const response = await ConveniosServices.storeConvenio(data);
    return response;
  },

  async updateConvenio({ commit }, data) {
    const response = await ConveniosServices.updateConvenio(data);
    return response;
  },
  async deleteConvenio({ commit }, id) {
    const response = await ConveniosServices.deleteConvenio(id);
    return response;
  },
};
