<template>
  <v-app>
    <v-main class="pa-0 ma-0 myFont">
      <AppMessagesQueue />
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { AppMessagesQueue } from '@/components/utils';
import { mapGetters, mapState, mapMutations } from "vuex";

export default {
  name: "App",
  components: { AppMessagesQueue },
  data: () => ({
    drawer: null,
    opciones: [
      {
        title: "Perfil",
        icon: "mdi-account",
      },
      {
        title: "Cerrar Session",
        icon: "mdi-logout",
      },
    ],
  }),
  computed: {
    ...mapState(['serverTime']),
  },
  methods: {
    ...mapGetters({ alert: "utils/getAlert" }),
  },
  // created(){
    // window.onstorage = (event) => {
    //     const { oldValue, newValue, key } = event;
    //     const refrescado = localStorage.getItem('refrescado');
    //     if(  key === 'token' && oldValue && newValue ){
    //       localStorage.removeItem('refrescado');
    //       console.log('object :>> ', {
    //         oldValue,
    //         newValue,
    //         event,
    //       });
    //     }
    //   };
  // }
};
</script>

<style lang="scss">
.shepherd-element {
  margin: 0px 15px;
  font-family: 'MuseoSans';
}
.shepherd-footer {
  justify-content: space-between !important;
}
</style>
