import { http_client } from "../plugins/http_client";

const postEvaluacionProveedor = async (body={}) => {
    return await http_client("/api/v1/proveedores/evaluaciones",
     body,
     "post")
}

const getEvaluacionProveedor = async (id_proveedor, params={}) =>{
    return await http_client(`/api/v1/proveedores/${id_proveedor}/evaluaciones`,params,"get")
}

const putEvaluacionProveedor = async (id_evaluacion, params={}) => {
    return await http_client(`/api/v1/proveedores/evaluaciones/${id_evaluacion}`,params,"put")
}

export default {
    postEvaluacionProveedor,
    getEvaluacionProveedor,
    putEvaluacionProveedor
}