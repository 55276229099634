const reasignacionContratosVue = [
  {
    path: "/reasignacion-contractual",
    name: "reasignacion-contractual",
    component: () =>
      import(/* webpackChunkName: "reasignacionContractual" */ "../main.vue"),
  },
];

export default reasignacionContratosVue;
