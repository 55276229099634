const routerPrevenciones = [
  {
    path: "/prevenciones-procesos",
    name: "prevenciones-procesos",
    component: () =>
      import(
        /* webpackChunkName: "prevencionesProcesos" */ "./ListaPrevencionView.vue"
      ),
  },
];

export default routerPrevenciones;
