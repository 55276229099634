export default () => ({
  id_linea_trabajo: null,
  id_unidad: null,
  lineaTrabajoSeleccionada: [],
  records: [],
  filters: {
    page: 1,
    per_page: 10,
    total_rows: null,
  },
  instituciones: [],
  financiamientos: [],
  dataUnidad: {},
  historialMovimientos: [],
  reloadInstitutions: false,

  /*
    Models para los filtros de fuente de financiamiento y obs de la vista de 
    asignación de techos presupuestarios en el archivo 
    CardLineaTrabajoComponent.vue 
  */
    filterCards: {
      id_obs: null,
      id_fuente_financiamiento: null,
    },
});
