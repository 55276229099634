const catalogoEnLineaRoutes = [
    {
      path: '/catalogo-electronico/familia/:familyId',
      name: 'familia-de-productos',
      component: () => 
        import(/* webpackChunkName: "ProductsCatalogue" */ "./purchaseFlow/ProductsView.vue"),
    },
    {
      path: '/catalogo-electronico/producto/:productId',
      name: 'ofertas-de-producto',
      component: () => 
        import(/* webpackChunkName: "OffersCatalogue" */ "./purchaseFlow/ProductOffersView.vue"),
    },
    {
      path: '/catalogo-electronico/producto/editar/:requestId/:productId',
      name: 'ofertas-de-producto-edit',
      component: () => 
        import(/* webpackChunkName: "OffersCatalogue-edit" */ "./purchaseFlow/OfferListEdit.vue"),
    },
    {
      path: "/catalogo-electronico",
      name: "lista-de-familias",
      exact: true,
      component: () =>
        import(/* webpackChunkName: "FamiliesCatalogue" */ "./purchaseFlow/FamiliesView.vue"),
    },
];

export default catalogoEnLineaRoutes
