export class PaginationHandler {
    _size;

    _page;

    constructor(size = 10, page = 1) {
        this._size = size;
        this._page = page;
    }

    // Setters and getters
    get size() {
        return this._size;
    }

    set size(value) {
        this._size = value;
    }

    get currentPage() {
        return this._page;
    }

    set currentPage(value) {
        this._page = value;
    }

    // Methods
    previousPage() {
        this._page--;

        return this;
    }

    nextPage() {
        this._page++;

        return this;
    }

    toApiFormat() {
        return {
            per_page: this._size,
            page: this._page,
        };
    }    
}
