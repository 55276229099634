export const SetListProvMt = (state, payload) => {
    state.list_prov_sanc_st = payload
}

export const resetListProvMt = (state) => {
    state.list_prov_sanc_st = []
}

//  mutacion que actualiza la paginacion de la lista de proveedores ( parsea los datos a numeros )
export const SetListProvPagMt = (state, payload) => {
    state.list_prov_sanc_pag_st.page = Number(payload.page)
    state.list_prov_sanc_pag_st.per_page = Number(payload.per_page)
    state.list_prov_sanc_pag_st.total_rows = Number(payload.total_rows)
}

export const resetListProvPagMt = (state) => {
    state.list_prov_sanc_pag_st.page = 1
    state.list_prov_sanc_pag_st.per_page = 10
    state.list_prov_sanc_pag_st.total_rows = 0
}

//  mutacion que actualiza el detalle de un proveedor
export const SetDetailProvMt = (state, payload) => {
    state.detail_prov_st = payload
}

export const resetDetailProvMt = (state) => {
    state.detail_prov_st = {}
}

//  mutacion que actualiza el detalle de una sancion
export const SetDetailSancionMt = (state, payload) => {
    state.detail_sancion_st = payload
}

export const resetDetailSancionMt = (state) => {
    state.detail_sancion_st = {}
}

//  mutacion que actualiza la lista de sanciones de un proveedor
export const SetListSancionProvMt = (state, payload) => {
    state.list_sancion_prov_st = payload
}

export const resetListSancionProvMt = (state) => {
    state.list_sancion_prov_st = []
}

//  mutacion que actualiza el estado del modal de sancion de proveedor
export const SetModalSancionProvMt = (state, payload) => {
    state.modal_sancion_prov_st = payload
}

export const resetModalSancionProvMt = (state) => {
    state.modal_sancion_prov_st = false
}

//  mutacion que actualiza el id de la sancion
export const SetIdSancionMt = (state, payload) => {
    state.id_sancion_st = payload
}

export const resetIdSancionMt = (state) => {
    state.id_sancion_st = null
}

//  mutacion que actualiza la paginacion de la lista de sanciones de un proveedor ( parsea los datos a numeros )
export const SetListSancionProvPagMt = (state, payload) => {
    state.list_sancion_prov_pag_st.page = Number(payload.page)
    state.list_sancion_prov_pag_st.per_page = Number(payload.per_page)
    state.list_sancion_prov_pag_st.total_rows = Number(payload.total_rows)
}

export const resetListSancionProvPagMt = (state) => {
    state.list_sancion_prov_pag_st.page = 1
    state.list_sancion_prov_pag_st.per_page = 10
    state.list_sancion_prov_pag_st.total_rows = 0
}
