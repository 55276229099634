const busquedaInformacionRpnRoutes = [
    {
      path: "/buscar-informacion-rpn",
      name: "buscar-informacion-rpn",
      component: () =>
        import(
          /* webpackChunkName: "busquedaInformacionRpn" */ "./index.vue"
        ),
    },
];

export default busquedaInformacionRpnRoutes;
