const adminInstituciones = [
    {
      path: "/administracion-instituciones",
      name: "administracion-instituciones",
      component: () =>
        import(
          /* webpackChunkName: "adm-instituciones" */ "./AdminInstitucionesView.vue"
        ),
    },
  ];
  
  export default adminInstituciones;
  