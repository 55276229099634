import { http_client } from "../plugins/http_client";

const http_client_slim = async (url, parametros = {}, metodo = "get") =>
  http_client(`/api/v1/solicitudes/necesidad/${url}`, parametros, metodo);

const listSN = async (id_proceso_compra, parametros) =>
  http_client_slim(`${id_proceso_compra}`, parametros);

const addSolicitudNProcesoPAC = async (id_proceso_compra, parametros = {}) => 
  http_client_slim(`${id_proceso_compra}/solicitudes`, parametros, "post");

const deleteSolicitudNProcesoPAC = async (
  id_proceso_compra,
  id_solicitud_necesidad_pac,
  parametros = {}
) => 
  http_client_slim(
    `${id_proceso_compra}/solicitudes/${id_solicitud_necesidad_pac}`,
    parametros,
    "delete"
  );

const getSolicitudesObs = async (id_proceso_compra, parametros = {}) => 
  http_client_slim(`${id_proceso_compra}/solicitudes/obs`, parametros);

const getUnidadesProceso = async (id_proceso_compra, parametros = {}) =>
  await http_client_slim(`${id_proceso_compra}/unidades`, parametros);

export default {
  listSN,
  addSolicitudNProcesoPAC,
  deleteSolicitudNProcesoPAC,
  getSolicitudesObs,
  getUnidadesProceso
};
