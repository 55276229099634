import { http_client } from "@/plugins/http_client";

const getUsuariosInstitucion = async (params = {}) => {
  return await http_client("/api/v1/", params, "get");
};

const guardarPrevencion = async (params = {}) => {
  return await http_client("/api/v1/prevenciones", params, "post");
};

const getPrevencion = async (id_prevencion, params = {}) => {
  return await http_client(
    `/api/v1/prevenciones/${id_prevencion}`,
    params,
    "get"
  );
};

const getPrevenciones = async (params = {}) => {
  return await http_client("/api/v1/prevenciones", params, "get");
};

const cambiarEstadoPrevencion = async (
  id_prevencion,
  id_estado,
  params = {}
) => {
  return await http_client(
    `/api/v1/prevenciones/${id_prevencion}/estado/${id_estado}`,
    params,
    "put"
  );
};

const addSeguimientoPrevencion = async (id_prevencion, params = {}) => {
  return await http_client(
    `/api/v1/prevenciones/${id_prevencion}/seguimiento`,
    params,
    "post"
  );
};

const getSeguimientoPrevencion = async (id_prevencion, params = {}) => {
  return await http_client("/api/v1/", params, "get");
};

const actualizarPrevencion = async (id_prevencion, params = {}) => {
  return await http_client("/api/v1/", params, "put");
};

const getEstadosPrevencion = async (params = {}) => {
  return await http_client("/api/v1/prevenciones/estados", params, "get");
};

export default {
  getUsuariosInstitucion,
  guardarPrevencion,
  getPrevencion,
  getPrevenciones,
  cambiarEstadoPrevencion,
  addSeguimientoPrevencion,
  getSeguimientoPrevencion,
  actualizarPrevencion,
  getEstadosPrevencion,
};
