const cmCumplimientoSolicitudesRoutes = [
    {
        path: "/cm-cumplimiento-de-solicitud",
        name: 'cm-cumplimiento-de-solicitud',
        component: () =>
            import(/* webpackChunkName: "RequestsFulfillment" */ "./cmOrdenesCompraCumplimiento.vue"),
    },
    {
        path: "/cm-cumplimiento-de-solicitud/productos/:id/:id_orden",
        name: 'cm-cumplimiento-de-solicitud-productos',
        component: () =>
            import(/* webpackChunkName: "RequestsFulfillment" */ "./CmCumplimientoSolicitudesView.vue"),
    },
    {
        path: "/cm-cumplimiento-solicitud-compra",
        name: "cm-cumplimiento-solicitud-compra",
        component: () =>
            import(
                /* webpackChunkName: "listadoCumplimiento" */ "./listadoCumplimiento.vue"
                ),
    }
];

export default cmCumplimientoSolicitudesRoutes;
