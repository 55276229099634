const rutasReporteria = [
    {
        path: "/subasta-inversa-reporteria",
        name: "subasta-inversa-reporteria",
        component: () =>
            import(/* webpackChunkName: "subastaInversaReporteria" */ "./subastaInversa/main.vue"),
    }
];

export default rutasReporteria;
