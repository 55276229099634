<template>
    <Fragment>
        <v-btn 
            color="secondary" 
            @click.stop="showConfirmation"             
            large 
            style="width: 100%;" 
        >
            {{ isRequestExpired ? 'Cerrar' : 'Descartar' }} solicitud
        </v-btn>
        <ConfirmationModalComponent
            :isOpen="confirmationOpen" 
            description="¿Desea continuar con la acción?" 
            @confirm="closeRequest" 
            @cancel="confirmationOpen = false"  
            :isLoading="requestClosing.isLoading"
        />
    </Fragment>
</template>
<script>
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import { ConfirmationModalComponent } from '@/components/utils';
import { Observable } from '@/utils/observable.js';
import { Fragment } from 'vue-fragment';
import { mapMutations, mapState  } from "vuex";

export default {
    name: 'RequestCancellerComponent',
    components: { ConfirmationModalComponent, Fragment },
    data: () => ({
        requestClosing: createLoadable(null),
        confirmationOpen: false,
    }),
    props: {
        timer: { type: Object },
        isRequestExpired: { type: Boolean },
        request: { type: Object, },
        isEditAction: { type: Boolean, default: false,}
    },
    computed: {
        ...mapState("catalogoEnLinea", ["productOffers","agendamientos"]),
    },
    methods: {
        ...mapMutations("catalogoEnLinea", ["clearInCartRequest", "setTimerStatus", "setFeedbackModalConfig","eliminarAgendamiento"]),
        ...mapMutations("utils", ["setCartDrawer"]),
        showConfirmation() {
            this.confirmationOpen = true;
        },
        async closeRequest() {
            toggleLoadable(this.requestClosing);
            const requestId = this.request.id;
            const { data }  = await this.services.PurchaseRequest.markRequestAsClosed(requestId);

            this.productOffers.data.forEach(offer => {
                const idProducto = offer.CMProducto.id;
                this.agendamientos.forEach(agendamiento => {
                    this.eliminarAgendamiento({
                        idProducto,
                        agendamiento: { ...agendamiento },
                    });
                });
            });
            setLoadableResponse(this.requestClosing, data, { skipOnSuccess: true });
            this.confirmationOpen = false;
            if (!isResponseSuccesful(data)) return;
            
            this.setCartDrawer(false);
            this.clearInCartRequest();
            this.setTimerStatus("empty-request");
            const ruta = this.isEditAction ? "/cm-solicitudes-con-observacion/" : "/catalogo-electronico/"
            Observable.emit('refresh-requests-to-modify');
            this.setFeedbackModalConfig({
                isOpen: true,
                type: "success",
                description: data.message,
                buttonText: 'Continuar',
                onClose: () => this.$router.push({ path: ruta, replace: true }),
            });
        },
    },
};
</script>