export default {
  setListaEstructuras(state, payload) {
    state.listadoEstructuras = payload;
  },
  setPaginationData(state, payload) {
    state.filters.page = Number(payload.page);
    state.filters.perPage = Number(payload.per_page);
    state.filters.totalRows = Number(payload.total_rows);
  },
  setCtlEstructuraPresupuestaria(state, payload) {
    state.ctl_estructura_presupuestaria = payload;
  },
};
