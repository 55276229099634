<template>
    <v-overlay v-if="spinning" class="spinner">
        <v-progress-circular indeterminate color="white" :size="70"></v-progress-circular>
        <br />
        <p class="mt-4">{{ text }}</p>
    </v-overlay>
</template>
<script>
export default {
    name: 'SpinnerComponent',
    props: {
        text: { type: String, required: true },
        spinning: { type: Boolean, required: false, default: 'Loading...' }
    }
}
</script>
<style>
    .spinner {
        text-align: center;
    }
</style>