<template>
  <v-dialog v-model="showDialog" persistent max-width="700">
    <v-card class="pa-4" max-width="700">
      <v-card-text>
        <section>
          <p class="text-h6 secondary--text">Cambiar contraseña</p>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                type="password"
                label="Contraseña actual *"
                outlined
                v-model="form.password_actual"
                @input="$v.form.password_actual.$touch()"
                :error-messages="oldPasswordErrors"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                type="password"
                label="Nueva contraseña *"
                outlined
                v-model="form.password"
                @input="$v.form.password.$touch()"
                :error-messages="passwordErrors"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                type="password"
                label="Confirmar contraseña *"
                outlined
                v-model="form.confirm_password"
                @input="$v.form.confirm_password.$touch()"
                :error-messages="confirmPasswordErrors"
              />
            </v-col>
          </v-row>
        </section>
      </v-card-text>
      <v-card-actions>
        <v-btn color="secondary" outlined @click="clearData(), close()">
          Cerrar
        </v-btn>
        <v-btn color="secondary white--text" @click="savePassword()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { required, minLength, sameAs, helpers } from "vuelidate/lib/validators";

const alpha = helpers.regex(
  "alpha",
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#$!%*?&-_.:])([A-Za-zd$@$!%*?&]|[^ d]){8,20}$/
);

export default {
  name: "CambioContraComponent",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      password_actual: null,
      password: null,
      confirm_password: null,
    },
  }),
  validations: {
    form: {
      password_actual: { required },
      password: { required, minLength: minLength(8), alpha },
      confirm_password: {
        required,
        minLength: minLength(8),
        sameAsPassword: sameAs("password"),
      },
    },
  },
  computed: {
    oldPasswordErrors() {
      const errors = [];
      if (!this.$v.form.password_actual.$dirty) return errors;
      !this.$v.form.password_actual.required &&
        errors.push("La contraseña actual es obligatoria");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required &&
        errors.push("La contraseña es obligatoria");
      !this.$v.form.password.minLength &&
        errors.push("El mínimo de caracteres 8");
      !this.$v.form.password.alpha &&
        errors.push("Tiene que tener mayúsculas, minúsculas y números");
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.form.confirm_password.$dirty) return errors;
      !this.$v.form.confirm_password.required &&
        errors.push("La contraseña es obligatoria");
      !this.$v.form.confirm_password.minLength &&
        errors.push("El mínimo de caracteres 8");
      !this.$v.form.confirm_password.sameAsPassword &&
        errors.push("Las contraseñas no coinciden");
      return errors;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    clearData() {
      this.form.password_actual = null;
      this.form.password = null;
      this.form.confirm_password = null;

      this.$v.$reset();
    },
    async savePassword() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const { status } = await this.services.Auth.changePasswordUser(
          this.form
        );

        if (status == 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Su contraseña ha sido actualizada con éxito.",
          });
          this.clearData();
          this.close();
        }
      }
    },
  },
};
</script>
