import { http_client, http_client_with_status } from "@/plugins/http_client";

const getObs = (params = {}) => http_client_with_status("/api/v1/obs", { ...params, modernResponse: true }, "get");

const getObsList = async (params = {}) => {
  return await http_client("/api/v1/obs", params, "get");
};

const getObsCategoriaList = async (params = {}) => {
  return await http_client("/api/v1/obs/clasificaciones");
};

const getObsIndex = async (id, params = {}) => {
  return await http_client(`/api/v1/obs/${id}`, params, "get");
};

const postObs = async (params = {}) => {
  return await http_client("/api/v1/obs", params, "post");
};

const updateObs = async (params = {}) => {
  return await http_client(`/api/v1/obs/${params.id}`, params, "put");
};

const postObsDocument = async (params = {}) => {
  return await http_client("/api/v1/obs/catalogo", params, "post");
};

const getObsExcelDocument = async (params = {}) => {
  return await http_client(
    "/api/v1/documentos/obs",
    params,
    "get",
    {},
    true,
    "arraybuffer"
  );
};

const getObss = async (params = {}) => {
  return await http_client("/api/v1/instituciones/obs", params, "get");
};

const getObsDetail = async (id, params = {}) => {
  return await http_client(`/api/v1/obs/obs_info/${ id }`, params, "get");
};

export default {
  getObs,
  getObsList,
  getObsCategoriaList,
  updateObs,
  postObs,
  getObsIndex,
  postObsDocument,
  getObsExcelDocument,
  getObss,
  getObsDetail
};
