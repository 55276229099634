import Proveedor from "../../../../services/Proveedores.services";
import VerificarDocumento from "../../../../services/VerificarDocumento.services";

export default {
  namespaced: true,

  state: {
    provider: {},
    providerLogo: null,
    newContact: {
      id_tipo_documento: null,
      numero_documento: null,
      nombre: null,
      correo_electronico: null,
      id_tipo_contacto: null,
      numero_telefono: null,
      numero_telefono_secundario: null,
      direccion: null,
      cotizacion_uaci: false,
      obs: [],
    },
    is_dui_verify_loading: false,
    is_verify_dui_btn_disabled: false,
    dui_verified: false,
    newContactObsList: [],
    obsToConfig: {},
    formPresentacion: {
      nombre: null,
      etiquetas: [],
      precio_unitario: null,
      archivos: [],
      id_presentacion: null,
    },
    presentationFilters: {
      page: 1,
      per_page: 10,
      total_rows: null,
    },
  },
  mutations: {
    setProviderInfo(state, payload) {
      state.provider = payload;
    },
    setObsInfo(state, payload) {
      state.obsToConfig = payload;
    },
    setProviderLogo(state, payload) {
      state.providerLogo = payload;
    },
    setUpdatedContact(state, payload) {
      Object.keys(state.newContact).forEach((key) => {
        state.newContact[key] = payload[key];
      });
      // Concatenamos el codigo al nombre de cada obs antes de enviarlo al state
      state.newContactObsList = payload.obs.map((obs) => {
        return {
          ...obs,
          nombre: `${obs.codigo} - ${obs.nombre}`,
        };
      });
      // state.newContactObsList = payload.obs;
      state.newContact.id = payload.id;
      state.newContact.numero_telefono = payload.rutas_comunicacion.find(
        (target) => target.titulo === "Teléfono"
      )?.contacto;
      state.newContact.numero_telefono_secundario =
        payload.rutas_comunicacion.find(
          (target) => target.titulo === "Teléfono secundario"
        )?.contacto;
    },
    clearContactInfo(state) {
      state.newContact = {
        id_tipo_documento: null,
        numero_documento: null,
        nombre: null,
        correo_electronico: null,
        id_tipo_contacto: null,
        numero_telefono: null,
        numero_telefono_secundario: null,
        direccion: null,
        cotizacion_uaci: false,
        obs: [],
      };
    },
    clearNewObsList(state, payload) {
      if (payload) {
        state.newContactObsList.filter(
          (option) => option.nombre !== payload.nombre
        );
      } else {
        state.newContactObsList = [];
      }
    },
    setDuiVerified(state, payload) {
      state.dui_verified = payload;
    },
    setDuiLoading(state, payload) {
      state.is_dui_verify_loading = payload;
    },
    setVerifyBtnState(state, payload) {
      state.is_verify_dui_btn_disabled = payload;
    },
    setNewContactName(state, payload) {
      state.newContact.nombre = payload;
    },
    setObsContactList(state, payload) {
      state.newContactObsList = payload;
    },
    setformPresentacion(state, payload) {
      Object.keys(state.formPresentacion).forEach((key) => {
        state.formPresentacion[key] = payload[key];
        if (key == "etiquetas") {
          state.formPresentacion[key] = payload[key].map((col) => col.nombre);
        }
        state.formPresentacion.archivos = [];
      });
      state.formPresentacion.id = payload.id;
      state.formPresentacion.obs_fotos = payload.obs_fotos;
    },
    clearOBSPresentacion(state) {
      Object.keys(state.formPresentacion).forEach((key) => {
        state.formPresentacion[key] =
          key == "archivos" || key == "etiquetas" ? [] : null;
      });
    },
    setPaginationData(state, payload) {
      state.presentationFilters.page = Number(payload.page);
      state.presentationFilters.per_page = Number(payload.per_page);
      state.presentationFilters.total_rows = Number(payload.total_rows);
    },
  },
  actions: {
    async getProvider({ commit }, idProveedor) {
      if (idProveedor) {
        const res = await Proveedor.getProviderInfo(idProveedor);
        if (res.status === 200) {
          commit("setProviderInfo", res.data);
          return res;
        }
      }
      const response = await Proveedor.getProviderInfo();
      if (response.status === 200) {
        commit("setProviderInfo", response.data);
        return response;
      }
    },
    async uploadProviderLogo({ commit }, { id, image }) {
      let formData = new FormData();
      formData.append("image", image);
      const response = await Proveedor.putProviderLogo(id, formData);
      if (response.status === 201) {
        return response;
      }
    },
    async getProviderLogo({ commit }, idProveedor) {
      const response = await Proveedor.getProviderLogo(idProveedor);

      if (response != undefined && response.status === 200) {
        const blob = new Blob([response.data]);
        const image = URL.createObjectURL(blob);
        commit("setProviderLogo", image);
        return response;
      } else {
        commit("setProviderLogo", null);
      }
    },
    async saveProviderDocument({ commit }, data) {
      const response = await Proveedor.postProviderDocument(data);
      if (response.status === 201) {
        return response;
      }
    },
    async updateProviderDocument({ commit }, data) {
      const response = await Proveedor.putProviderDocument(data.id, data.data);
      if (response.status === 200) {
        return response;
      }
    },
    async verifyDui({ commit, state }) {
      commit("setDuiVerified", false);

      if (
        state.newContact.numero_documento != null &&
        state.newContact.numero_documento != ""
      ) {
        commit("setDuiVerified", false);
        commit("setDuiLoading", true);
        const response = await VerificarDocumento.verificarDocumento(
          state.newContact.numero_documento
        );
        if (response.status === 200) {
          commit("setDuiVerified", true);
          // Desactivar boton de verificar
          commit("setVerifyBtnState", true);

          const full_name = `${response.data.primer_nombre ?? ""} ${
            response.data.segundo_nombre ?? ""
          } ${response.data.primer_apellido ?? ""} ${
            response.data.segundo_apellido ?? ""
          }`;

          commit("setNewContactName", full_name);
        }
        commit("setDuiLoading", false);
      }
    },
    clearVerifyDui({ commit }) {
      commit("setDuiVerified", false);
      commit("setVerifyBtnState", false);
    },
    async deleteObsContactItem({ commit, state }, id_obs) {
      // Obtenemos el obs a eliminar
      const obs = state.newContactObsList.find(
        (option) => option.id === id_obs
      );

      if (obs.local) {
        const obsContactList = state.newContactObsList.filter(
          (option) => option.id !== id_obs
        );

        commit("setObsContactList", obsContactList);
      } else {
        const response = await Proveedor.deleteObsContact(
          id_obs,
          state.newContact.id
        );
        if (response.status === 200) {
          const obsContactList = state.newContactObsList.filter(
            (option) => option.id !== id_obs
          );
          commit("setObsContactList", obsContactList);
        }
      }
    },
  },
};
