const CMSolicitudesConObservacionRoutes = [
    {
        path: "/cm-solicitudes-con-observacion",
        name: "cm-solicitudes-con-observacion",
        component: () =>
        import(/* webpackChunkName: "SolicitudesConObservacion" */ "./CMSolicitudesConObservacionView.vue"),
    },
    {
        path: "/cm-solicitudes-con-observacion/:id",
        name: "cm-detalle-solicitud-con-observacion",
        component: () =>
        import(/* webpackChunkName: "DetalleSolicitudConObservacion" */ "./Detalle.vue"),
    },
    {
        path: "/cm-solicitudes/editar/:id",
        name: "cm-solicitudes-editar",
        component: () =>
        import(/* webpackChunkName: "EditarSolicitudConObservacion" */ "./EditarSolicitud.vue"),
    },
];

export default CMSolicitudesConObservacionRoutes;
