<template>
  <div>
    <div v-if="value != null">
      <v-row class="pt-5">
        <v-tabs
          :value="value"
          @change="handleInput"
          background-color="bgMinsal"
        >
          <v-tabs-slider color="secondary"></v-tabs-slider>
          <div v-for="(item, index) in items" :key="index">
            <v-tab v-if="!item.hide" class="secondary--text">
              <v-icon>{{ item.icono }}</v-icon>
              <span class="pl-1">{{ item.text }}</span>
            </v-tab>
          </div>
        </v-tabs>
      </v-row>
      <v-row>
        <v-tabs-items :value="value" @change="handleInput" style="width: 100%">
          <div v-for="(item, index) in contenido" :key="index">
            <v-tab-item v-if="!item.hide">
              <component class="mt-3 pr-3" :is="item.content" />
            </v-tab-item>
          </div>
        </v-tabs-items>
      </v-row>
    </div>
    <div v-else>
      <v-row class="pt-5">
        <v-tabs v-model="tab" background-color="bgMinsal">
          <v-tabs-slider color="secondary"></v-tabs-slider>
          <div v-for="(item, index) in items" :key="index">
            <v-tab v-if="!item.hide" class="secondary--text">
              <v-icon>{{ item.icono }}</v-icon>
              <span class="pl-1">{{ item.text }}</span>
            </v-tab>
          </div>
        </v-tabs>
      </v-row>
      <v-row>
        <v-tabs-items v-model="tab" style="width: 100%">
          <div v-for="(item, index) in contenido" :key="index">
            <v-tab-item v-if="!item.hide" class="px-6">
              <component class="mt-3 pr-3" :is="item.content" :proceso="item?.props?.proceso" :id="id"/>
            </v-tab-item>
          </div>
        </v-tabs-items>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: null,
    },
    value: {
      type: String | Number,
      default: null,
    },
    contenido: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  name: "AppTabs",
  data: () => ({
    tab: null,
  }),
  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
  computed: {
    tabValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
