<template>
  <v-dialog v-model="internalOpen" max-width="1400px" persistent>
    <p style="background-color: #FFFFFF;" class="py-6 px-6 my-0 mx-0" v-if="sourceLoadable.isLoading">Cargando documento...</p>
    <div v-else class="px-4 py-0 pb-10" style="background-color: #FFFFFF; position: relative; line-height: 0;" >
        <div class="py-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
          <v-btn
              style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;"
              class="px-4 py-4"
              @click.stop="internalOpen = false"
              text
              rounded
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
          <v-tooltip v-if="allowDownload" bottom>
            <template v-slot:activator="{ on, attrs }">
              <a 
                v-if="!fileLoadingEmpty"  
                class="d-flex align-center justify-center px-2 py-2 rounded-pill"
                style="width: 36px; height: 36px; float: right; background-color: var(--v-primary-base);"
                v-bind="attrs"
                v-on="on"
                target="_blank" :href="src" :download="downloadName"
              >
                <v-icon color="#FFFFFF !important" small>mdi mdi-download</v-icon>
              </a>
            </template>
            <span>Descargar documento</span>
          </v-tooltip>
        </div>
        
        <vue-pdf-embed style="max-width: 100%; clear: both;" :source="src" v-if="!fileLoadingEmpty && src">
          Test
        </vue-pdf-embed>
        <div v-if="fileLoadingFailed">
          <v-card>
            <v-card-title>No se pudo cargar</v-card-title>
            <v-card-text>
              Si se sigue presentando este error, por favor contacte al administrador del sistema.
            </v-card-text>
          </v-card>
        </div>
    </div>
  </v-dialog>
</template>
<script>
import { isNil } from 'lodash';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  name: 'PdfModal',
  props: {
    isOpen: { type: Boolean },
    sourceLoadable: { type: Object, required: true },
    filename: { type: String },
    allowDownload: { type: Boolean, default: true },
  },
  components: {
    VuePdfEmbed,
  },
  data: () => ({
    internalOpen: false,
  }),
  computed: {
    fileLoadingEmpty() {
      return this.fileLoadingFinished && !this.$props.sourceLoadable.data;
    },
    fileLoadingFailed() {
      return this.fileLoadingFinished && this.$props.sourceLoadable.error;
    },
    fileLoadingFinished() {
      return !this.$props.sourceLoadable.isLoading && this.$props.sourceLoadable.isDirty;
    },
    src() {
      const blob = this.$props.sourceLoadable.data;

      if (!blob) return null;
      return URL.createObjectURL(blob);
    },
    downloadName() {
      return this.$props.filename || 'documento';
    },
  },
  watch: {
    isOpen(value) {
      if (isNil(value)) return;

      this.internalOpen = value;
    },
    internalOpen(value) {
      this.$emit('on-visibility-change', value);
    },
  },
}
</script>