import state from "./state";
import * as mutations from "./mutations";
import * as actions from "./actions";
import ProcesoTradicionalStore from '../ProcesoTradicional/store'

const SolicitudCompraStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  modules: {
    ProcesoTradicionalStore
  }
}

export default SolicitudCompraStore
