const routerReporteria = [
  {
    path: "/reporteria",
    name: "reporteria",
    component: () =>
      import(
          /* webpackChunkName: "ReporteriaComponent" */ "./ReporteriaComponent.vue"
      ),
  },
  {
    path: "/reporteria-comparacion-precio/:id_subproceso/obs/:id_obs",
    name: "reporteriaComparacion",
    component: () =>
      import(
            /* webpackChunkName: "ReporteComparacionPrecio" */ "./ReporteComparacionPrecio.vue"
      ),
  },
];

export default routerReporteria;
