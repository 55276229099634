const cmProveedoresDashboardRoutes = [
    {
        path: "/cm-solicitudes-proveedor",
        name: "cm-solicitudes-proveedor",
        component: () =>
          import(/* webpackChunkName: "SupplierDashboard" */ "./CmProveedoresDashboardView.vue"),
      },
      {
          path: '/cm-solicitudes-proveedor/:id',
          name: "cm-detalle-item-solicitud-proveedor",
          component: () =>
            import(/* webpackChunkName: "SupplierRequestItemDetails" */ "./CmRequestDetailsView.vue"),
      },
];

export default cmProveedoresDashboardRoutes;
