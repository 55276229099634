import { http_client_with_status } from "@/plugins/http_client"

const getRequestsWithObservation = async (filters) => await http_client_with_status('/api/v1/cm-solicitudes-compra/obtenerSolicitudesConObservaciones', filters);

const markRequestAsActive = async (requestId) => await http_client_with_status(`/api/v1/cm-solicitudes-compra/activarSolicitud/${requestId}`, null, 'PATCH');

export default {
    getRequestsWithObservation,
    markRequestAsActive,
}
