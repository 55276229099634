export const setListaInstituciones = (state, payload) => {
    state.listaInstituciones = payload;
};
export const setListaInstitucionesConjuntas = (state, payload) => {
    state.listaInstitucionesConjuntas = payload;
};


export const setTipoEntidades = (state, payload) => {
    state.tipoEntidades = payload;
};

export const setTipoContrato = (state, payload) => {
    state.tipoContratos = payload;
};

export const setInfoObs = (state, payload) => {
    state.infoObs = payload;
};

export const setObsList = (state, payload) => {
    state.obsList = payload;
};

export const setInfoGeneral = (state, payload) => {
    state.infoGeneral = payload;
};

export const setListaDocumentos = (state, payload) => {
    state.listaDocumentos = payload;
};

export const setListaEtapas = (state, payload) => {
    state.listaEtapas = payload;
};

export const setIdProceso = (state, payload) => {
    state.id_proceso_compra = payload;
};

export const setTotalRowsPaac = (state, payload) => {
    state.total_rows_pac = payload;
};
export const setTotalRowsPaacConjuntas = (state, payload) => {
    state.total_rows_pac_conjuntas = payload;
};
