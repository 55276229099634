import moment from "moment";
import solicitudUsuario from "@/services/SolicitudesUsuarios.services";
import usuariosCreados from "@/services/UsuariosCreados.services";

export const consultarSolicitudUsuario = async ({ commit }, filtros = {}) => {
  const solicitudesUsuarios = await solicitudUsuario.listarSolicitudes(filtros);
  let correlativo = 0;
  if (solicitudesUsuarios?.status === 200) {
    const datosSolicitud = solicitudesUsuarios?.data;
    const cabecera = solicitudesUsuarios?.headers;

    if (cabecera?.page !== "1") {
      correlativo = Number(cabecera.per_page) * Number(cabecera.page - 1);
    }

    datosSolicitud.forEach((solicitud) => {
      correlativo += 1;
      solicitud.correlativo = correlativo;
      solicitud.unidades = solicitud.unidades.map((unidad) => unidad.nombre);
      solicitud.unidades = solicitud.unidades.join(", ");
      solicitud.fecha_hora_solicitud = moment(
        solicitud.fecha_hora_solicitud
      ).format("DD/MM/YYYY");
    });

    commit("setSolicitudesUsuario", datosSolicitud);
    commit("setPaginacion", solicitudesUsuarios.headers);
  } else {
    commit("setSolicitudesUsuario", []);
    commit("setPaginacion", []);
  }
};

export const consultarUsuariosCreados = async ({ commit }, filtros = {}) => {
  commit("setLoading", true)
  const response = await usuariosCreados.listarUsuarios(filtros);
  let correlativo = 0;
  if (response?.status === 200) {
    const usuarios = response?.data;
    const cabecera = response?.headers;

    if (cabecera?.page !== "1") {
      correlativo = Number(cabecera.per_page) * Number(cabecera.page - 1);
    }

    usuarios.forEach((usuario) => {
      correlativo += 1;
      usuario.correlativo = correlativo;
      usuario.empleado = usuario.empleado;
      usuario.usuario_perfiles = usuario.perfiles;
      usuario.perfiles =
        (Array.isArray(usuario?.perfiles) &&
          usuario?.perfiles.map((perfil) => perfil.nombre).join(", ")) ||
        "";

      usuario.unidades =
        (Array.isArray(usuario?.unidades_solicitantes) &&
          usuario?.unidades_solicitantes
            .map((unidad) => unidad.nombre)
            .join(", ")) ||
        "";

      usuario.estado = usuario.estado;
      usuario.institucion = usuario?.unidades_solicitantes[0]?.institucion;
      usuario.codigo_institucion = usuario?.unidades_solicitantes[0]?.instituciones?.codigo;
    });

    commit("setUsuariosCreados", usuarios);
    commit("setPaginacion", response.headers);
    commit("setLoading", false);
  } else {
    commit("setUsuariosCreados", []);
    commit("setPaginacion", []);
  }
};
