import SolicitudCompra from "../../../../services/SolicitudCompra.services";
import Vue from "vue";

export const obtenerProductos = async ({ commit, state }) => {
  commit("loadingEstado", true);

  const response = await SolicitudCompra.getSolicitudesObsPresentaciones(
    state.productosFilters
  );
  if (response.status === 200) {
    commit("obtenerProductos", response.data);
    commit("setPaginationData", response.headers);
    commit("loadingEstado", false);
    if(response.data.length == 0){
      Vue.prototype.temporalAlert({
        show: true,
        message:
          "No se encuentran convenios marcos creados para el OBS / Proveedor seleccionado",
        type: "error",
      });
    }
  }
};

export const obtenerProducto = async ({ commit }, val) => {
  const id_producto = Number.parseInt(val);
  commit("cargarProductoEstado", true);
  const response = await SolicitudCompra.getSolicitudesObsPresentacionesDetalle(
    id_producto
  );
  if (response.status === 200) {
    let newData = {
      codigo: response.data.codigo,
      descripcion: response.data.obs_descripcion,
      detalle: response.data.etiquetas,
      nombre_obs: response.data.obs,
      unidad_presentacion: response.data.nombre_presentacion,
      titulo: response.data.nombre,
      obs_foto: response.data.obs_fotos,
      ...response.data
    }
    commit("cambiarProductoSeleccionado", newData);
    commit("cargarProductoEstado", false);
  }
};
