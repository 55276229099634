import proveedoresServices from "../../../../services/Proveedores.services"

const etapaRecepcionOfertas = {
    namespaced: true,
    state: {
        listaRecepcionOfertas: [],
        listaEspecificacionObs: [],
        detalleSubprocesoObs: {},
        tieneOferta: null,
        formOfertar: {
            oferta: {},
            precio_unitario: null,
            cantidad: null,
            total_oferta: null
        },
        obsOfertar:[{
            monto_obs:null,
            nombre:null,
            total_obs:null,
        }]
    },

    mutations: {
        setOfertas (state, payload) {
            if(payload) state.listaRecepcionOfertas = payload
        },
        
        setEspecificacionObs (state, payload) {
            state.listaEspecificacionObs = payload
        },

        setTieneOferta (state, payload) {
            state.tieneOferta = payload
        },
        
        setDetalleSubprocesoObs (state, payload) {
            state.detalleSubprocesoObs = payload
            // const format = (arg) => Intl.NumberFormat('en-Us', { currency: 'USD', minimumFractionDigits: 2 }).format(arg)
            if (state.tieneOferta) {
                state.formOfertar.precio_unitario = payload?.ofertas[0]?.precio_unitario
                state.formOfertar.cantidad = payload?.ofertas[0]?.SubProcesoOb.total_sub_proceso
            } else {
                state.formOfertar.cantidad = payload?.total_sub_proceso
            }
            // state.formOfertar.total_oferta = format(payload?.monto_sub_proceso_obs)
        },
        setObsOfertar(state, payload) {
            state.obsOfertar = payload       }
    },

    actions: {
        async getOfertas ({ commit }, payload) {payload?.ofertas[0]?.SubProcesoOb.total_sub_proceso
            const recepcionOfertas = await this.services.Paac.getEtapaActual(payload.procesoCompraId, payload.filtros)
            commit('setOfertas', recepcionOfertas.data)
            return recepcionOfertas
        },
        
        async getEspecificacionObs ({ commit }, payload) {
            const obsPaac = await proveedoresServices.getEspecificacionObs(payload.procesoCompraId, payload.procesoObsId, payload.filtros)
            commit('setEspecificacionObs', obsPaac?.data)
            return obsPaac
        },
        
        async getDetalleSubProcesoPaac ({ commit }, payload) {
            const detalleSubprocesoObs = await proveedoresServices.getDetalleSubProcesoPaac(
                payload.id_proceso_compra,
                payload.id_proceso_obs, 
                payload.id_sub_proceso_obs
            )
            commit('setDetalleSubprocesoObs', detalleSubprocesoObs.data)
            return detalleSubprocesoObs.data
        },

        async getDetalleSubProcesoPaacOferta ({ commit }, payload) {
            const detalleSubprocesoObs = await proveedoresServices.getDetalleSubProcesoPaacOferta(
                payload.id_proceso_compra,
                payload.id_proceso_obs, 
                payload.id_sub_proceso_obs
            )
            commit('setDetalleSubprocesoObs', detalleSubprocesoObs.data)
            return detalleSubprocesoObs.data
        },
    }
}

export default etapaRecepcionOfertas