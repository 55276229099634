<template>
    <Fragment>
        <v-row no-gutters class="product-view-filters-wrapper mx-0 my-0 align-center justify-end">
            <v-col cols="12" md="11" lg="7">
                <v-row class="flex-nowrap flex-column flex-md-row mx-0 my-0" style="gap: 10px;">
                    <v-col cols="12" md="6" class="px-0 py-0 flex-shrink-1 flex-grow-1">
                        <v-select
                            :items="listarTiposOrdenamiento"
                            label="Ordenar producto"
                            item-text="nombre"
                            item-value="id"
                            placeholder="Ordenar producto"
                            clearable
                            outlined
                            v-model="ordenar"
                            @change="handleSelectChange('ordenar', $event)"
                        />
                    </v-col>
                    <v-col cols="12" md="4" class="px-0 py-0 flex-shrink-1 flex-grow-1">
                        <v-select
                            :items="groupsLoadable.data"
                            :loading="groupsLoadable.isLoading"
                            label="Grupo"
                            item-text="nombre"
                            item-value="id"
                            placeholder="Grupo"
                            clearable
                            outlined
                            v-model="id_grupo"
                            @change="handleSelectChange('id_grupo', $event)"
                        />
                    </v-col>
                    <v-col cols="12" md="4" class="px-0 py-0 flex-shrink-1 flex-grow-1">
                        <v-select
                            :items="subGroupsLoadable.data"
                            :loading="subGroupsLoadable.isLoading"
                            label="Sub grupo"
                            item-text="nombre"
                            item-value="id"
                            placeholder="Sub grupo"
                            clearable
                            outlined
                            v-model="id_subgrupo"
                            :disabled="!Boolean(values.id_grupo)"
                            @change="handleSelectChange('id_subgrupo', $event)"
                        />
                    </v-col>
                    <v-col cols="12" md="4" class="px-0 py-0 flex-shrink-1 flex-grow-1">
                        <v-text-field
                            label="Buscar"
                            placeholder="Buscar producto"
                            :loading="isNameLoading"
                            outlined
                            prepend-inner-icon="mdi mdi-magnify"
                            v-model="nombre"
                            @input="debouncedSearch"
                        />
                    </v-col>
                </v-row>
            </v-col>  
            <v-col cols="12" md="1" class="mt-2 mt-md-0 pl-md-4">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs" 
                            v-on="on" 
                            color="primary" 
                            x-large
                            style="min-width: 100% !important; padding: 0 !important;"
                            @click.stop="resetFilters"
                            class="d-block reset-product-filters"
                        >
                            <v-icon color="#FFF !important">mdi-backup-restore</v-icon>
                        </v-btn>
                    </template>
                    <span>Limpiar filtros</span>
                </v-tooltip>
            </v-col>          
        </v-row>
    </Fragment>
</template>
<script>
import { Fragment } from 'vue-fragment';
import { debounce } from 'lodash';

export default {
    name: 'ProductViewFiltersComponent',
    components: { Fragment },
    props: {
        groupsLoadable: { type: Object, required: true },
        subGroupsLoadable: { type: Object, required: true },
        isNameLoading: { type: Boolean, default: true },
        values: { type: Object, },
    },
    data: () => ({
        ordenar:null,
        id_grupo: null,
        id_subgrupo: null,
        nombre: null,
    }),
    emits: ['update:filter-change', 'reset'],
    computed:{
        listarTiposOrdenamiento(){
            return [
                { id: 'precioMenorDesde', nombre: 'Precio menor' },
                { id: 'precioMayorDesde', nombre: 'Precio mayor' },
                { id: 'ordenarAZ', nombre: 'Ordenar de A-Z' },
                { id: 'ordenarZA', nombre: 'Ordenar de Z-A' }
            ];
        }
    },
    methods: {
        emitName (value) {
            this.$emit('filter-change', 'nombre', value);
        },
        handleSelectChange(name, value) {
            this.$emit('filter-change', name, value);
        },
        handleFiltersChange(filters) {
            this.id_grupo = filters?.id_grupo ? Number(filters.id_grupo) : null;
            this.id_subgrupo = filters?.id_subgrupo ? Number(filters.id_subgrupo) : null;
            this.nombre = filters?.nombre;
        },
        resetFilters() {
            this.$emit('reset');
        },
    },
    watch: {
        values(filters) {
            this.handleFiltersChange(filters);
        },
    },
    created() {
        this.debouncedSearch = debounce(this.emitName, 600);
        this.handleFiltersChange(this.$props.values);
    },
}
</script>
<style>
    .product-view-filters-wrapper .v-text-field .v-text-field__details {
        display: none;
    }

    .product-view-filters-wrapper .v-text-field .v-input__slot {
        margin-bottom: 0 !important;
    }

    @media (max-width: 960px) {
        .reset-product-filters {
            width: 100%;
        }
    }
</style>