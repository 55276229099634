import { http_client } from "../plugins/http_client";

const listarSolicitudes = async (parametros = {}) => {
    return await http_client('/api/v1/solicitudes/usuario/', parametros, 'get');
}

const ctlTipoSolicitud = async () => {
    return await http_client('/api/v1/solicitudes/usuario/tipos', '', 'get');
}

const ctlEstadoSolicitud = async () => {
    return await http_client('/api/v1/solicitudes/usuario/estados', '', 'get');
}

const validarJefes = async (params) => {
    return await http_client('/api/v1/solicitudes/usuario/validar-jefes', params, 'get');
}

const updateUsuario = async (idUsuario, params) => {
    return await http_client(`/api/v1/users/${idUsuario}`, params, 'put');
}

const restablecerPasswordUsuario = async (params) => {
    return await http_client(`/api/v1/usuarios/password`, params, 'put');
}

export default {
    listarSolicitudes,
    ctlTipoSolicitud,
    ctlEstadoSolicitud,
    validarJefes,
    updateUsuario,
    restablecerPasswordUsuario,
}