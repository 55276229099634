<template>
  <v-icon @click="goBack" color="primary" large>
    mdi mdi-arrow-left-circle
  </v-icon>
</template>
  
<script>
export default {
  props: {
    to: { type: String },
  },
  methods: {
    goBack() {
      if (this.to) {
        this.$router.push(this.to);
        return;
      }

      if (window.history.length > 1) {
        this.$router.back();
      } else {
        this.$router.push('/');
      }
    },
  },
};
</script>