const routesConfiguracion = [
    {
        path: "configuracion",
        name: "configuracion",
        component: () =>
          import(
            /* webpackChunkName: "ConfiguracionVuew" */ './ConfiguracionView.vue'
          ),
    },
    {
        path: "/administracion-obs-oeg",
        name: "administracion-obs-oeg",
        component: () =>
          import(
            /* webpackChunkName: "ConfiguracionVuew" */ './AdministracionObsOegView.vue'
          ),
    },
    {
        path: "/unidades/medida",
        name: "admin-unidades-medida",
        component: () =>
          import(
            /* webpackChunkName: "adminUnidadesMedidaVuew" */ './AdminUnidadesMedidaView.vue'
          ),
    },
];

export default routesConfiguracion;
