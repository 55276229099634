import paacServices from "../../../../services/Paac.services";

export const setIdProcesoResultadosPreliminares = ({ commit }, payload) => {
  commit("setIdProceso", payload);
};

export const obtenerResultadosPreliminares = async ({ commit, state }) => {
  const params = {
    pagination: true,
    per_page: state.cantidad_por_pagina,
    page: state.pagina_actual,
    id_proveedor: state.id_proveedor,
    especificacion: state.especificacion,
  };
  const response = await paacServices.getEtapaActualPaginacion(
    state.id_proceso,
    params
  );

  if (response.status === 200 && (response.data.id_etapa === 10 || response.data.id_etapa === 13)) {
    commit("setResultadosPreliminares", response.data.resultados);
    commit("setTotalRegistros", response.data.count);
  }
};

export const obtenerParticipante = async ({ commit, state }, payload) => {
  const response = await paacServices.getParticipacionResultados(payload);
  if (response.status === 200) {
    commit("setParticipante", response.data);
  }
};

export const obtenerProveedores = async ({ commit, state }) => {
  const response = await paacServices.getProveedoresAperturaOferta(
    state.id_proceso
  );
  if (response.status === 200) {
    commit("setProveedorOptions", response.data);
  }
};

export const descargarDocumento = async ({ commit, state }) => {
  const response = await paacServices.downloadDocumentoProcesoCompra(
    state.participante.ruta_documento
  );

  if (response.status === 200) {
    // Descargar el archivo pdf que se encuentra dentro de response.data como blob

    const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
    const fileLink = document.createElement("a");
    fileLink.href = fileUrl;
    fileLink.setAttribute("download", `documento.pdf`);
    document.body.appendChild(fileLink);
    fileLink.click();
  }
};
