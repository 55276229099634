const routerPaac = [
  {
    path: "/paac-proceso-listado",
    name: "paac",
    component: () =>
      import(
        /* webpackChunkName: "paac-proceso-listado" */ "./procesosListView.vue"
      ),
  },
  {
    path: "/configuracion-proceso-paac/:id",
    name: "configuracion-proceso-paac",
    component: () =>
      import(
        /* webpackChunkName: "configuracion-proceso-paac" */ "./configuracionProceso.vue"
      ),
  },
  {
    path: "/proceso-de-compra",
    name: "proceso-de-compra",
    component: () =>
      import(
        /* webpackChunkName: "proceso-de-compra" */ "./creacionProcesoCompraView.vue"
      ),
  },
  {
    path: "/paac-proceso-creacion",
    name: "paac-proceso-creacion",
    component: () =>
      import(
        /* webpackChunkName: "paac-proceso-creacion" */ "./creacionProcesoPaac.vue"
      ),
  },
  {
    path: "/recepcion-ofertas-configuracion/:id_proceso_compra/:etapa",
    name: "recepcion-oferta",
    component: () =>
      import(
        /* webpackChunkName: "recepcion-oferta" */ "./ofertaRecepcionList.vue"
      ),
  },
  {
    path: "/recepcion-oferta-detalle/:id_proceso_compra/:id_proceso_obs",
    name: "recepcion-oferta-detalle",
    component: () =>
      import(
        /* webpackChunkName: "recepcion-oferta-detalle" */ "./ofertaRecepcionDetalle.vue"
      ),
  },
  {
    path: "/configuracion-subasta-inversa/:id_process",
    name: "configuracion-subasta-inversa",
    component: () =>
      import(
        /* webpackChunkName: "configuracion-subasta-inversa" */ "./configuracionSubastaInversaView.vue"
      ),
  },
  // {
  //   path: "/subasta-inversa-agregar-sala/:id",
  //   name: "subasta-inversa-agregar-sala",
  //   component: () => import(/* webpackChunkName: "configuracion-subasta-inversa-sala" */ "./agregarSalasView.vue"),
  // },
  // {
  //   path: "/subasta-inversa-configuracion-sala/:id_sala",
  //   name: "subasta-inversa-configuracion-sala",
  //   props: true,
  //   component: () => import(/* webpackChunkName: "configuracion-subasta-inversa-sala" */ "./configuracionSalaView.vue"),


  // },
  {
    path: "/insumo-agregado/:id_obs",
    name: "insumo-agregado",
    component: () =>
      import(
        /* webpackChunkName: "insumo-agregado/" */ "./insumoAgregadosObs.vue"
      ),
  },
  {
    path: "/proceso-compra/crear-contrato/:tipo",
    name: "crear-contrato-compra",
    component: () =>
      import(
        /* webpackChunkName: "crearContratoCompras" */ "./crearContratoCompraView.vue"
      ),
  },
  {
    path: "/proceso-compra/crear-etapa-contrato",
    name: "crear-etapa-contrato-compra",
    component: () =>
      import(
        /* webpackChunkName: "crearContratoCompras" */ "./crearEtapaOrdenCompraView.vue"
      ),
  },
  {
    path: "/detalle-insumo-paac/:id_unidad",
    name: "detalle-insumo-paac",
    component: () =>
      import(
        /* webpackChunkName: "detalle-insumo-paac/" */ "./detalleInsumo.vue"
      )
  },
  {
    path: "/resumen-liquidacion-paac/:id_proceso",
    name: "resumen-liquidacion-paac",
    component: () =>
      import(
        /* webpackChunkName: "resumen-liquidacion-paac/" */ "./resumenLiquidacionView.vue"
      )
  },

  // RUTAS EXTRAÍDAS DE LA ANTERIOR CARPETA PAAC UACI
  {
    path: "/oferta-realizada-paac-uaci/:id_obs",
    name: "oferta-realizada-paac-uaci",
    component: () =>
      import(
        /* webpackChunkName: "recepcion-oferta" */ "./ofertaRecepcionList.vue"
      ),
  },

  {
    path: "/seguimiento-contratos/:id_proceso",
    name: "seguimiento-contratos",
    component: () =>
      import(
        /* webpackChunkName: "seguimiento-contratos" */ "./seguimientoContratosView.vue"
      ),
  },
  {
    path: "/aceptacion-evaluacion-proceso-uaci/:id_proceso/:id_proceso_obs/:id_sub_proceso(\\d+)",
    name: "aceptacion-evaluacion-proceso-uaci",
    component: () =>
      import(
        /* webpackChunkName: "aceptacion-evaluacion-proceso-uaci" */ "./ofertasRealizadasView.vue"
      ),
  },
  {
    path: "/evaluacion-uaci-ofertas/:id_proveedor_participacion_obs(\\d+)",
    name: "evaluacion-uaci-ofertas",
    component: () =>
      import(
        /* webpackChunkName: "evaluacion-uaci-ofertas" */ "./components/OfertaEvaluacionComponent.vue"
      ),
  },

  {
    path: "/listar-ofertas-subprocesos-paac/:id_proceso/:id_proceso_obs",
    name: "listar-ofertas-subprocesos-paac",
    component: () =>
      import(
        /* webpackChunkName: "listar-ofertas-subprocesos-paac" */ "../PaacUaci/listarOfertasView.vue"
      ),
  },
  {
    path: "/resumen-procesos/:id_proceso",
    name: "resumen-procesos",
    component: () =>
      import(
        /* webpackChunkName: "resumen-procesos" */ "./resumenProcesoView.vue"
      ),
  },
  {
    path: "/oferta-presentada/:id/proveedor/:id_proveedor",
    name: "oferta-presentada",
    component: () =>
      import(
        /* webpackChunkName: "oferta-presentada" */ "../PaacUaci/OfertaPresentada.vue"),
  },
];

export default routerPaac;
