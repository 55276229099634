import { http_client } from "../plugins/http_client";

const storeRecursoProveedor = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/impugnaciones`,
    params,
    "post"
  );
};

const getRecursosProveedorPorTipo = async (
  id_proceso_compra,
  id_tipo,
  params = {}
) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/impugnaciones/${id_tipo}/proveedor`,
    params
  );
};

const getImpugnacionInstitucion = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/impugnaciones/institucion`,
    params
  );
};

const getPronunciamiento = async (id_impugnacion, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/impugnaciones/${id_impugnacion}/pronunciamiento/`,
    params
  );
};

const getRecursosRevisionUcp = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `api/v1/compra/proceso/${id_proceso_compra}/impugnaciones/institucion`,
    params,
    "get"
  );
};

const getComisionAltoNivel = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/impugnaciones/comision/participante`,
    params,
    "get"
  );
};

const postParticipacionComision = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/impugnaciones/comision/participante`,
    params,
    "post"
  );
};
const getSeguimientos = async (id_estado_impugnacion, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/impugnaciones/tipo/${id_estado_impugnacion}`,
    params,
    "get"
  );
};

const putEstadoImpugnacion = async (
  id_proceso_compra,
  id_impugnacion,
  body
) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/impugnaciones/${id_impugnacion}`,
    body,
    "put"
  );
};

const getEtapaSiguiente = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/etapa-siguente`,
    params,
    "get"
  );
};

const continuarProceso = async (id_proceso_compra, id_estado, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/cambio-estado/${id_estado}`,
    params,
    "put"
  );
};

const getTipoEtapaPrevia = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/procesos/compra/${id_proceso_compra}/etapas/validar-previa`,
    params,
    "get"
  );
};

export default {
  storeRecursoProveedor,
  getRecursosProveedorPorTipo,
  getImpugnacionInstitucion,
  getPronunciamiento,
  getRecursosRevisionUcp,
  getComisionAltoNivel,
  postParticipacionComision,
  getSeguimientos,
  putEstadoImpugnacion,
  getEtapaSiguiente,
  continuarProceso,
  getTipoEtapaPrevia
};
