const routesPac = [
  {
    path: "/listado-procesos",
    name: "listado-procesos",
    component: () =>
      import(
        /* webpackChunkName: "listadoProceso" */ "./listadoProcesosView.vue"
      ),
  },
  {
    path: "/solicitudes-necesidades",
    name: "solicitudes-necesidades",
    component: () =>
      import(
        /* webpackChunkName: "solicitudesNecesidades" */ "./SolicitudesNecesidadesView.vue"
      ),
  },
  {
    path: "/agregar-solicitudes-necesidades/:idSolicitudNecesidad?",
    name: "agregar-solicitudes-necesidades",
    component: () =>
      import(
        /* webpackChunkName: "AddSolicitudesNecesidades" */ "./AddNecesidadesView.vue"
      ),
  },
  {
    path: "/list-solicitudes-necesidades",
    name: "list-solicitudes-necesidades",
    component: () =>
      import(
        /* webpackChunkName: "listadoSolicitudesNecesidades" */ "./AprobacionSolicitudes/mainSolicitudesNecesidadesView.vue"
      ),
  },
  {
    path: "/detalle-solicitud-necesidad/:id_solicitud",
    name: "detalle-solicitud-necesidad",
    component: () =>
      import(
        /* webpackChunkName: "detalleSolicitudNecesidad" */ "./AprobacionSolicitudes/detalleSolicitudNecesidadView.vue"
      ),
  },
  {
    path: "/pac/procesos",
    name: "listado-procesos-pac",
    component: () =>
      import(
        /* webpackChunkName: "listadoProceso" */ "./listadoProcesosView.vue"
      ),
  },
  {
    path: "/pac/creacion-procesos/:idProceso?",
    name: "creacion-procesos-pac",
    component: () =>
      import(
        /* webpackChunkName: "creacionProceso" */ "./creacionProcesoCompraView.vue"
      ),
  },
  {
    path: "/pac/ajuste-solicitudes",
    name: "ajuste-solicitudes-necesidades",
    component: () =>
      import(
        /* webpackChunkName: "AjusteSolicitudesNecesidades" */ "./AjustesPac/AjusteSolicitudesView.vue"
      ),
  },
  {
    path: "/pac/ajuste-necesidades/:idSolicitudNecesidad/:onlyView?",
    name: "ajuste-necesidades",
    component: () =>
      import(
        /* webpackChunkName: "AjusteNecesidades" */ "./AjustesPac/AddNecesidadesAjustesView.vue"
      ),
  },
];

export default routesPac;
