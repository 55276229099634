export const setSolicitudesUsuario = (state, payload) => {
    state.dataSolicitudes = payload
}

export const setPaginacion = (state, payload) => {
    state.totalPaginas = Number(payload.total_rows);
}

export const setUsuariosCreados = (state, payload) => {
  state.dataUsuarios = payload;
};

export const setBuscarSolicitud = (state, payload) => {
  state.buscarSolicitud = payload;
};

export const setShowEditarUsuario = (state, payload) => {
  state.showEditarUsuario = payload;
};

export const setDataUsuario = (state, payload) => {
  state.dataUsuario = payload;
}

export const setProfesiones = (state, payload) => {
  state.profesiones = payload;
}

export const setPerfiles = (state, payload) => {
  state.perfiles = payload;
}

export const setLoading = (state, payload) => {
  state.loadingUsers = payload;
}

export const setInstituciones = (state, payload) => {
  state.instituciones = payload;
}