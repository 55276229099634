import moment from 'moment';

// Usamos singleton para manejar un solo timer a la vez, de esta forma evitamos setIntervals infinitos o sin cancelar
// Usamos builder para poder actualizar un componente a la vez, si deseamos actualizar el now y la fecha a comparar por ejemplo
export class Countdown {
    _now = null;

    _duration = null;

    _handleTick = null;

    _handleExpired = null;

    _handleStart = null;

    _worker = new Worker('/time-worker.js');

    // Singleton
    static _instance = null;

    static getInstance() {
        if (!Countdown._instance) {
            Countdown._instance = new Countdown();
        }

        return Countdown._instance;
    };

    // Builder
    setNow() {
        this._now = moment.utc();

        return this;
    }

    setDuration(duration) {
        this._duration = duration;

        return this;
    }

    setTickHandler(handler) {
        this._handleTick = handler;

        return this;
    }

    setExpiredHandler (handler) {
        this._handleExpired = handler;

        return this;
    }

    setOnStartHandler (handler) {
        this._handleStart = handler;

        return this;
    }

    // Logica
    run () {
        // this.clear();
        
        if (this._duration === 0) return this._handleExpired();

        const durationObject = moment.duration(this._duration, 'seconds');
        this._handleStart();

        this._worker.postMessage('start');
        this._worker.addEventListener('message', (e) => {
          if (e.data === 'tick') this._validateTimer(durationObject);
        });
    }

    clear() {
        this._worker.postMessage('stop');
    }

    // Metodos privados
    _validateTimer(remainingDuration) {
        remainingDuration.subtract(1, 'second');

        const remainingSeconds = remainingDuration.asSeconds();
            
        if (remainingSeconds > 0) {
            this._handleTick(remainingDuration);
        } else {
            this._handleExpired(remainingDuration);
            this.clear();
        }
    }
}

export const transformDuration = (duration) => duration ? `
    ${duration.hours().toString().padStart(2, '0')} : 
    ${duration.minutes().toString().padStart(2, '0')} : 
    ${duration.seconds().toString().padStart(2, '0')}
` : null;

export const obtenerDuracionEnDias = (duration) => duration ? `
    ${Math.abs(duration.months()).toString()} mes${Math.abs(duration.months()) !== 1 ? 'es' : ''}
    ${Math.abs(duration.days()).toString()} días
    ${Math.abs(duration.hours()).toString().padStart(2, '0')} h 
    ${Math.abs(duration.minutes()).toString().padStart(2, '0')} m
    ${Math.abs(duration.seconds()).toString().padStart(2, '0')} s
` : null;

