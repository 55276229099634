import { http_client, http_client_with_status } from "@/plugins/http_client";

const guardarConvenio = async (params = {}) => {
    return await http_client_with_status(`/api/v1/cm-convenio-marco`, params, "post");
};

const convenioProveedores = async (params = {}) => {
    return await http_client(`/api/v1/cm-convenio-marco/proveedores`, params, "get");
};

const listarConvenios = async (params = {}) => {
    return await http_client_with_status(`/api/v1/cm-convenio-marco`, params, "get");
}
const obtenerConvenio = async (id,params={})=>{
    return await http_client(`/api/v1/cm-convenio-marco/${id}`, params, 'get')
}

const obtenerDocumentoConvenio = async (params = {}) => {
    return await http_client_with_status(`/api/v1/cm-convenio-marco/obtenerDocumentoConvenio`, params, "get", {}, { responseType: 'blob' });
}

const buscarConvenios = (params) => http_client_with_status('/api/v1/cm-convenio-marco/convenios', params, 'get');

export default {
    guardarConvenio,
    convenioProveedores,
    listarConvenios,
    obtenerConvenio,
    obtenerDocumentoConvenio,
    buscarConvenios,
}
