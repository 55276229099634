import { http_client } from "../plugins/http_client"

const getIdentificadorFiscal = async(filters={})=>{
    return await http_client("/api/v1/identificaciones/tributarias",filters,"get")
}

export default{
    getIdentificadorFiscal
}




//IdentificadorFiscal