// Mutaciones para llenar los catálogos ---------------------------------------
export const setCtlUnidad = (state, payload) => {
  state.ctlUnidad = payload;
};

export const setCtlTipoObs = (state, payload) => {
  state.ctlTipoObs = payload;
};

export const setCtlObs = (state, payload) => {
  state.ctlObs = payload;
};

// Mutaciones para los models de los filtros ----------------------------------
export const setFltUnidadSolicitud = (state, payload) => {
  state.fltUnidadSolicitud = payload;
};
export const setFltTipoObsSolicitud = (state, payload) => {
  state.fltTipoObsSolicitud = payload;
};
export const setFltObsSolicitud = (state, payload) => {
  state.fltObsSolicitud = payload;
};
export const setFltUnidadProceso = (state, payload) => {
  state.fltUnidadProceso = payload;
};
export const setFltTipoObsProceso = (state, payload) => {
  state.fltTipoObsProceso = payload;
};
export const setFltObsProceso = (state, payload) => {
  state.fltObsProceso = payload;
};

// Mutaciones para los componentes del DRAGGABLE ------------------------------
export const setSolicitudes = (state, solicitudes) => {
  state.solicitudes = solicitudes;
};

export const setSolicitudesFiltradas = (state, solicitudes) => {
  state.solicitudesFiltradas = solicitudes;
};

export const setProcesos = (state, payload) => {
  state.procesos = payload;
};
export const setProcesosFiltrados = (state, payload) => {
  state.procesosFiltrados = payload;
};

// Mutaciones para los models de los expansion panels -------------------------
export const setSolicitudesExpansionPanel = (state, payload) => {
  state.solicitudesExpansionPanel = payload;
};
export const setProcesosExpansionPanel = (state, payload) => {
  state.procesosExpansionPanel = payload;
};

// Mutaciones para las clases de error ----------------------------------------
export const setProcesosError = (state, payload) => {
  state.procesosError = payload;
};
export const setSolicitudesError = (state, payload) => {
  state.solicitudesError = payload;
};

// Limpia datos de procesos ---------------------------------------------------
export const limpiarProcesos = (state) => {
  state.procesos = [];
  state.procesosFiltrados = [];
  state.procesosExpansionPanel = [];
}
