
import { http_client, http_client_with_status } from "@/plugins/http_client";


const getListaUsuariosUnidad = async (filters={}) => await http_client(`/api/v1/delegados-ucp/listar-usuarios-unidad`, filters)

const getListaDelegados = () => http_client_with_status('/api/v1/delegados-ucp/listar-delegados', null, "get");

const getListaInstitucionesDistrito = async (filters={}) => await http_client(`/api/v1/delegados-ucp/listar-instituciones-distrito`, filters)

const crearDelegadoUcp = (data) => http_client_with_status('/api/v1/delegados-ucp/guardar-delegado', data, 'post', {
	'Content-Type': 'multipart/form-data',
});

const crearDelegadoUFI = (data) => http_client_with_status('/api/v1/delegados-ucp/guardar-delegado-ufi', data, 'post', {
	'Content-Type': 'multipart/form-data',
});

const crearDelegadoDistrito = (idUsuarioDelegado , data) => 
http_client_with_status(`/api/v1/delegados-ucp/guardar-delegado-distrito/${idUsuarioDelegado}`, data, 'post');

const deleteDelegadoUcp = async(idUsuarioDelegado) => await http_client_with_status(`api/v1/delegados-ucp/eliminar-delegado/${idUsuarioDelegado}`, null, 'DELETE');

const deleteDelegadoDistritoUcp = async(idUsuarioDelegado) => await http_client_with_status(`api/v1/delegados-ucp/eliminar-delegado-distrito/${idUsuarioDelegado}`, null, 'DELETE');

const getListaDelegadosUfi = () => http_client_with_status('/api/v1/delegados-ucp/listar-delegados/ufi', null, "get");

const visualizarAdjuntoDelegado = (idDelegado) => 
http_client_with_status(`/api/v1/delegados-ucp/obtener/archivo-delegado/${idDelegado}`, null, "get", {}, { responseType: 'blob' });

const visualizarAdjuntoDelegadoUfi = (idDelegado) => 
http_client_with_status(`/api/v1/delegados-ucp/obtener/archivo-delegado-ufi/${idDelegado}`, null, "get", {}, { responseType: 'blob' });

const deleteDelegadoUFI = async(idUsuarioDelegado) => await http_client_with_status(`api/v1/delegados-ucp/eliminar-delegado-ufi/${idUsuarioDelegado}`, null, 'DELETE');

export default {
	getListaUsuariosUnidad,
	crearDelegadoUcp,
	getListaDelegados,
	crearDelegadoDistrito,
	getListaInstitucionesDistrito,
	deleteDelegadoUcp,
	deleteDelegadoDistritoUcp,
	getListaDelegadosUfi,
	visualizarAdjuntoDelegado,
	deleteDelegadoUFI,
	visualizarAdjuntoDelegadoUfi,
	crearDelegadoUFI,
};
