import { http_client } from "../plugins/http_client";

/**
 * Retorna el listado de documentos
 *
 * ruta: /api/v1/documents/types
 * método: GET
 *
 * parámetros
 * @param {name: "" } params parámetros por los que se puede filtrar
 */
const getDocumentTypeList = async (params) => {
  return await http_client("/api/v1/documentos/tipos", params);
};

export default {
  getDocumentTypeList,
};
