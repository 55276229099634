const admRegiones = [
    {
      path: "/administracion-regiones",
      name: "administracion-regiones",
      component: () =>
        import(
          /* webpackChunkName: "adm-regiones" */ "./main.vue"
        ),
    },
  ];
  
  export default admRegiones;
  