const aprobacionProveedorRouter = [
  {
    path: "/aprobacion-proveedor",
    name: "aprobacion-proveedor",
    component: () =>
      import(
        /* webpackChunkName: "aprobacionProveedor" */ "./aprobacionProveedorView.vue"
      ),
  },
  {
    path: "/revision-proveedor",
    name: "revision-proveedor",
    component: () =>
      import(
        /* webpackChunkName: "aprobacionProveedor" */ "./revisionSolicitudProveedorView.vue"
      ),
  },
]

export default aprobacionProveedorRouter