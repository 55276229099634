export default {
  setListaFuentes(state, payload) {
    state.listadoFuentes = payload;
  },
  setPaginationData(state, payload) {
    state.filters.page = Number(payload.page);
    state.filters.perPage = Number(payload.per_page);
    state.filters.totalRows = Number(payload.total_rows);
  },
  setCtlFuenteFinanciamiento(state, payload) {
    state.ctl_fuente_financiamiento = payload;
  }
};
