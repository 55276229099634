export const setInstituciones = (state, payload) => {
  state.instituciones = payload;
};

export const setUnidades = (state, payload) => {
  return (state.records = payload);
};

export const setPaginationData = (state, payload) => {
  state.filters.page = Number(payload.page);
  state.filters.per_page = Number(payload.per_page);
  state.filters.total_rows = Number(payload.total_rows);
};

export const setFinanciamientos = (state, payload) => {
  state.financiamientos = payload;
};

export const setLineaTrabajo = (state, payload) => {
  state.id_linea_trabajo = payload;
};

export const setIdUnidad = (state, payload) => {
  state.id_unidad = payload;
};

export const setLineaTrabajoSelct = (state, payload) => {
  state.lineaTrabajoSeleccionada = payload;
};
export const setFiltersCars = (state, payload) => {
  state.filterCards = payload;
};

export const setFilters = (state, payload) => {
  state.filters[payload.property] = payload.body;
} 

export const setReloadInstitutions = (state, payload) => {
  state.reloadInstitutions = payload;
}

/**
 * Mutaciones para los filtros de fuente de financiamiento y obs de la vista de 
 * asignación de techos presupuestarios en el archivo 
 * CardLineaTrabajoComponent.vue
 */

export const clearAsignacionFiltros = (state) => {
  state.filterCards.id_fuente_financiamiento = null;
  state.filterCards.id_obs = null;
}

export const setHistorialMovimientos = (state, payload) => {
  state.historialMovimientos = payload;
}

export const resetHistorialMovimientos = (state) => {
  state.historialMovimientos = [];
}