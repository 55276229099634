import { http_client } from "../plugins/http_client";

const http_client_slim = async (url, parametros = {}, metodo = "get") =>
  http_client(`/api/v1/eliminar/etapas/contratos/${url}`, parametros, metodo);

const obtenerAdjuntoModificativa = async (idContrato) =>
  http_client_slim(`adjunto-modificativa/${idContrato}`);

const obtenerContratoByCorrelativo = async (id_proceso_compra, params) =>
  http_client_slim(`${id_proceso_compra}/listar`, params);

const obtenerEtapasByContratoid = async (id_contrato, params) =>
  http_client_slim(`${id_contrato}/etapas`, params);

const eliminarContratoById = async (id_contrato, id_proceso, params) =>
  http_client_slim(`eliminar-contrato-selected/${id_proceso}/${id_contrato}`, params, 'delete');

const eliminarEtapaContratoById = async (id_proceso_compra, id_etapa, id_contrato, params) =>
  http_client_slim(`eliminar-etapa/${id_proceso_compra}/${id_etapa}/${id_contrato}`, params, 'delete');

const subirModificativaEtapa = async (idProceso, idModificativa, body) =>
  http_client_slim(`${idProceso}/${idModificativa}`, body, "post");

const obtenerProcesoCompraByContrato = async (params) =>
  http_client_slim(`proceso-compra/contrato`, params);

export default {
  obtenerAdjuntoModificativa,
  obtenerContratoByCorrelativo,
  eliminarContratoById,
  obtenerEtapasByContratoid,
  eliminarEtapaContratoById,
  subirModificativaEtapa,
  obtenerProcesoCompraByContrato,
};
