import { http_client } from "@/plugins/http_client";

const postAgreementsMarco = async (params) => {
  return await http_client("/api/v1/convenios/marco", params, "post");
};

const putAgreementMarco = async (id_agreement, params) => {
  return await http_client(
    `/api/v1/convenios/marco/${id_agreement}`,
    params,
    "put"
  );
};

const postAgreementObs = async (id_agreement, params) => {
  return await http_client(
    `/api/v1/convenios/${id_agreement}/obs`,
    params,
    "post"
  );
};

const deleteAgreementObs = async (id_agreement_obs) => {
  return await http_client(
    `/api/v1/convenios/obs/${id_agreement_obs}`,
    {},
    "delete"
  );
};

const getAgreementMarcoById = async (id) => {
  return await http_client(`/api/v1/convenios/${id}`);
};

const getAgreementObs = async (id_agreement) => {
  return await http_client(`/api/v1/convenios/${id_agreement}/obs`);
};

const getAgreementDocuments = async (id_agreement) => {
  return await http_client(`/api/v1/convenios/${id_agreement}/documentos`);
};

const postAgreementDocument = async (id_agreement, params) => {
  return await http_client(
    `/api/v1/convenios/${id_agreement}/documentos`,
    params,
    "post"
  );
};

const deleteAgreementDocument = async (id_agreement_document) => {
  return await http_client(
    `/api/v1/convenios/documentos/${id_agreement_document}`,
    {},
    "delete"
  );
};

const getAgreementPhases = async (id_agreement) => {
  return await http_client(`/api/v1/convenios/${id_agreement}/fases`);
};

const postAgreementPhases = async (id_agreement, id_phase, params) => {
  try {
    return await http_client(
      `/api/v1/convenios/${id_agreement}/fases/${id_phase}`,
      params,
      "post"
    ); 
  } catch (error) {
    return error.response
  }
};

const putAgreementPhases = async (id_convenio_fase, params) => {
  return await http_client(
    `/api/v1/convenios/fases/${id_convenio_fase}`,
    params,
    "put"
  );
};

const putAgreementStatus = async (id_agreement, params) => {
  try {
    return await http_client(
      `/api/v1/convenios/${id_agreement}/estado`,
      params,
      "put"
    );  
  } catch (error) {
    return error.response
  }
};

const getDocumento = async(params) => {
  try {
    return await http_client(
      `/api/v1/documentos/pdf?documento=${params}`, 
      {}, 
      'get',
      {},
      true,
      "blob",
    )
  } catch (error) {
    return error.response
  }
}

const getListaconvenios = async (params = {}) => {
  return await http_client("/api/v1/convenios", params, "get");
};

const getListaConveniosEstados = async (params = {}) => {
  return await http_client("/api/v1/convenios/estados", params, "get");
};

const getConvenioProveedor = async (id, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/convenios/${id}`,
    params,
    "get"
  );
};

const getConvenioProveedorOBSDesc = async (id, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/convenios/${id}/obs/descripcion`,
    params,
    "get"
  );
};
const getConvenioProveedorOBSDocs = async (id, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/convenios/${id}/obs/documentos`,
    params,
    "get"
  );
};

const getConvenioProveedorOBSDoc = async (id_doc, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/convenios/obs/documento/${id_doc}`,
    params,
    "get"
  );
};

const getConvenioProveedorPhases = async (id, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/convenios/${id}/fases`,
    params,
    "get"
  );
};

const getConvenioPresentations = async (id_agreement, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/convenios/${id_agreement}/obs/presentaciones`,
    params,
    "get"
  );
};

const postConvenioOBSPresentations = async (
  id_agreement,
  id_obs_provider,
  params = {}
) => {
  return await http_client(
    `/api/v1/proveedores/convenios/${id_agreement}/presentacion/${id_obs_provider}/detalle`,
    params,
    "post"
  );
};

const updateConvenioOBSPresentation = async (
  id_agreement_provider_presentation,
  params = {}
) => {
  return await http_client(
    `/api/v1/proveedores/convenios/presentacion/${id_agreement_provider_presentation}`,
    params,
    "put"
  );
};

const deleteConvenioOBSPresentation = async (
  id_provider_presentation,
  params = {}
) => {
  return await http_client(
    `/api/v1/proveedores/convenios/presentacion/${id_provider_presentation}`,
    params,
    "delete"
  );
};

const getConvenioOBSPresentationDetail = async (
  id_agreement_provider_presentation,
  params = {}
) => {
  return await http_client(
    `/api/v1/proveedores/convenios/presentacion/${id_agreement_provider_presentation}/detalle`,
    params,
    "get"
  );
};

const getConvenioOBSPresentationConfig = async (
  id_obs_provider,
  params = {}
) => {
  return await http_client(
    `/api/v1/proveedores/convenios/obs/presentacion/${id_obs_provider}`,
    params,
    "get"
  );
};

const getConvenioPhoto = async (id_agreement, params = {}) => {
  try {
    return await http_client(
      `/api/v1/convenios/marco/foto/${id_agreement}`,
      params,
      "get",
      { responseType: "blob" },
      true,
      "blob",
      false
    );
  } catch (error) {}
};

const getConvenioReview = async (id_agreement, params = {}) => {
  return await http_client(
    `/api/v1/convenios/${id_agreement}/revision`,
    params,
    "get"
  );
};

const getConvenioReviewOffers = async (
  id_agreement,
  id_provider,
  params = {}
) => {
  return await http_client(
    `/api/v1/convenios/${id_agreement}/revision/ofertas/${id_provider}`,
    params,
    "get"
  );
};

const changeStatusPresentation = async (
  id_agreement_provider_presentation,
  params = {}
) => {
  return await http_client(
    `/api/v1/proveedores/convenios/obs/presentaciones/${id_agreement_provider_presentation}/estado`,
    params,
    "put"
  );
};
const revertAgreement = async (id_agreement_provider, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/convenios/${id_agreement_provider}/revertir`,
    params,
    "put"
  );
};

const rejectAgreement = async (id_agreement_provider, params = {}) => {
  return await http_client(
    `/api/v1/convenios/proveedor/${id_agreement_provider}/rechazo`,
    params,
    "put"
  );
};

const activeStatusAgreement = async (id_agreement, params = {}) => {
  return await http_client(
    `/api/v1/convenios/${id_agreement}/estado/activo`,
    params,
    "put"
  );
};

const getProviderAgreement = async (id_provider, params = {}) => {
  return await http_client(
    `/api/v1/convenios/proveedor/${id_provider}`,
    params,
    "get"
  );
};
const getImagenesPresentacion = async (id_photo, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/convenios/obs/presentacion/foto/${id_photo}`,
    params,
    "get",
    { responseType: "blob" },
    true,
    "blob",
    false
  );
};

const getDocumentoBases = async(params ={})=>{
  return await  http_client("/api/v1/convenios/documentos/pdf",params,"get", {}, true, "arraybuffer")
}

export default {
  postAgreementsMarco,
  putAgreementMarco,
  postAgreementObs,
  deleteAgreementObs,
  getAgreementMarcoById,
  getAgreementObs,
  getAgreementDocuments,
  postAgreementDocument,
  deleteAgreementDocument,
  getAgreementPhases,
  postAgreementPhases,
  putAgreementPhases,
  putAgreementStatus,
  getListaconvenios,
  getConvenioProveedor,
  getConvenioProveedorOBSDesc,
  getConvenioProveedorOBSDocs,
  getConvenioProveedorOBSDoc,
  getConvenioProveedorPhases,
  getConvenioPresentations,
  postConvenioOBSPresentations,
  updateConvenioOBSPresentation,
  deleteConvenioOBSPresentation,
  getConvenioOBSPresentationDetail,
  getConvenioOBSPresentationConfig,
  getConvenioPhoto,
  getConvenioReview,
  getDocumento,
  getConvenioReviewOffers,
  getListaConveniosEstados,
  changeStatusPresentation,
  revertAgreement,
  rejectAgreement,
  activeStatusAgreement,
  getProviderAgreement,
  getImagenesPresentacion,
  getDocumentoBases
};
