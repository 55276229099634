<template>
  <div>
    <v-btn @click.stop="openCartDrawer" class="open-cart-btn px-0 py-0" icon>
      <v-badge v-if="requestsToModifyCount > 0" :value="requestsToModifyCount" :content="requestsToModifyCount" left
               color="error">
        <v-badge v-if="offerts > 0" :content="badgeConfig.value" :value="badgeConfig.value" :color="badgeConfig.color">
          <v-icon class="app-cart">mdi mdi-cart</v-icon>
        </v-badge>
        <v-icon v-else class="app-cart">mdi mdi-cart</v-icon>
      </v-badge>
      <template v-else>
        <v-badge v-if="offerts > 0" :content="badgeConfig.value" :value="badgeConfig.value" :color="badgeConfig.color">
          <v-icon class="app-cart">mdi mdi-cart</v-icon>
        </v-badge>
        <v-icon v-else class="app-cart">mdi mdi-cart</v-icon>
      </template>
    </v-btn>
  </div>
</template>
<script>
import { PAGINATION_PROPERTY_NAMES } from '@/utils/loadable';
import { mapActions, mapState } from "vuex";

export default {
  name: 'CartComponent',
  data: () => ({
    drawer: false,
  }),
  computed: {
    ...mapState(["userInfo"]),
    ...mapState('catalogoEnLinea', ["inCartRequest", "timer"]),
    ...mapState('cmSolicitudesConObservacion', ["requestsToModify"]),
    offerts() {
      return this.inCartRequest.data?.productosActualizados.length ?? 0;
    },

    badgeConfig() {
      const isExpired = this.timer.status === 'expired';

      return {
        color: isExpired ? 'var(--v-error-lighten2)' : 'green',
        value: isExpired ? '?' : this.offerts,
      };
    },
    requestsToModifyCount() {
      if (this.requestsToModify.isLoading || this.inCartRequest.isLoading) return 0;
      
      const count = this.requestsToModify.pagination[PAGINATION_PROPERTY_NAMES.COUNT];

      if (this.inCartRequest.isDirty && !this.inCartRequest.isLoading && this.inCartRequest.error) {
        return count;
      }

      return !this.inCartRequest.data?.solicitud.id ? count : count - 1;
    },
  },
  methods: {
    ...mapActions("utils", ["setCartDrawer"]),
    openCartDrawer() {
      this.setCartDrawer(true);
    },
  },
}
</script>
