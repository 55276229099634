import { http_client } from "@/plugins/http_client";

const getReporteInstituciones = async (params) => await http_client('/api/v1/indicadores/reporte/instituciones', params);

const getReporteInstitucionEmpleado = async (id_institucion, params) => await http_client(`/api/v1/indicadores/institucion/${id_institucion}`, params);

const getReporteExcel = async () =>
  await http_client(
    `/api/v1/indicadores/excel`,
    {},
    "get",
    { responseType: "blob" },
    true,
    "blob",
    false
  );

const generarReporteIndicadores = async (params = {}) =>
  await http_client(`/api/v1/indicadores/cron-job?now=true`, params, "post");

export default {
  getReporteInstituciones,
  getReporteExcel,
  getReporteInstitucionEmpleado,
  generarReporteIndicadores
}