export default () => ({
  consultas: [],
  salas: [],
  subastas: [],
  obss: [],
  unidades: [],
  presentaciones: [],
  solicitudes: [],
  subastaId: null,
  isShow: false,
  isEdit: false,
  form: {
    fecha_hora_inicio: null,
    fecha_hora_fin: null,
    salto_ofertas: null,
    tiempo_oferta_bk: 0, // guardara el valor real del input tiempo oferta
    tiempo_oferta: null, // guardara el string que se construira en funcion del tiempo seleccionado en segundos, minutos ú hora
    unidad_tiempo: 'minutes',
    monto_inicial: null,
    lote: null,
    id_ctl_subasta: null,
    cantidad: 1,
    porcentaje: null,
  },
  rangoFechasInicio: {
    inicio: null, // Siempre sera el valor devuelto por el servidor
    fin: null, // Inicialmente sera el valor devuelto por el servidor, pero se actualizara en función de la fecha de final seleccionada
  },
  horasValidasInicio: {
    min: 0,
    max: 23,
  },
  rangoFechasFin: {
    inicio: null, // Inicialmente sera el valor devuelto por el servidor, pero se actualizara en función de la fecha de inicio seleccionada
    fin: null, // Siempre sera el valor devuelto por el servidor
  },
  horasValidasFin: {
    min: 0,
    max: 23,
  },
  hayCountdownSubasta: false,
});
