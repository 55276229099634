import { http_client } from "@/plugins/http_client";

const url = "/api/v1/instituciones/financiadores";
const getConvenios = async (params = {}) =>
  await http_client(url, params, "get");
const storeConvenio = async (body) => await http_client(url, body, "post");
const getConvenio = async (id) => await http_client(`${url}/${id}`);
const updateConvenio = async (id, body) =>
  await http_client(`${url}/${id}`, body, "put");
const deleteConvenio = async (id) =>
  await http_client(`${url}/${id}`, {}, "delete");

export default {
  getConvenios,
  storeConvenio,
  getConvenio,
  updateConvenio,
  deleteConvenio,
};
