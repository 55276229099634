const adminCatalogoCifrados = [
  {
    path: "/administracion-catalogo-cifrados",
    name: "administracion-catalogo-cifrados",
    component: () =>
      import(
        /* webpackChunkName: "adm-instituciones" */ "./MainAdminCatalogoView.vue"
      ),
  },
  {
    path: "/version-cifrado-institucion",
    name: "version-cifrado-institucion",
    component: () =>
      import(
        /* webpackChunkName: "cifrado-institucion" */ "./CifradoInstitucionView.vue"
      ),
  },
];

export default adminCatalogoCifrados;
