const sancionProveedor = [
  {
    path: "/listado-proveedores-sancionados",
    name: "listado-proveedores-sancionados",
    component: () =>
      import(
        /* webpackChunkName: "listado-proveedores-sancionados" */ "./listProvSancionView.vue"
      ),
  },
  {
    path: "/detalle-sancion-proveedor/:id",
    name: "detalle-sancion-proveedor",
    component: () =>
      import(
        /* webpackChunkName: "detalle-sancion-proveedor" */ "./detailProvSancionView.vue"
      ),
  },
  {
    path: "/sancionado/",
    name: "proveedor-sancionado",
    component: () =>
      import(
        /* webpackChunkName: "proveedor-sancionado" */ "./sancionadoView.vue"
      ),
  },
];

export default sancionProveedor;
