

const routerTarjetas = [
  {
    path: "/tarjetas",
    name: "tarjetas",
    component: () =>
      import(
        /* webpackChunkName: "tarjetas" */ "./tarjetasView.vue"
      ), 
  }
];

export default routerTarjetas;
