import { http_client_with_status } from "@/plugins/http_client";

const cargarResumenInstituciones = (filtros) => http_client_with_status(
    `/api/v1/reporteria-baja-cuantia/resumen-instituciones`,
    filtros,
    'get',
);

const cargarFondosInstitucionales = (idInstitucion, filtros) => http_client_with_status(
    `/api/v1/reporteria-baja-cuantia/fondos-institucionales/${idInstitucion}`,
    filtros,
    'get',
);

const cargarEstadisticasAnuales = (idAnio, filtros) => http_client_with_status(
    `/api/v1/reporteria-baja-cuantia/estadisticas-anuales/${idAnio}`,
    filtros,
    'get',
);

export default {
    cargarResumenInstituciones,
    cargarFondosInstitucionales,
    cargarEstadisticasAnuales,
}
