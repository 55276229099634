import { createLoadable, createPageable, isResponseSuccesful, setLoadableResponse, setPageableResponse, toggleLoadable, togglePageable } from '@/utils/loadable';
import BajaCuantiaServices from '@/services/BajaCuantia.services';
import ProveedoresServices from '@/services/Proveedores.services';
import ProductService from '@/services/catalogoEnLinea/Product.service';
import cmReporteriaServices from '@/services/catalogoEnLinea/cmReporteria.services';
import CmFamiliasServices from '@/services/CmFamilias.services';
import PacProcesosServices from '@/services/PacProcesos.services';
import { omit } from 'lodash';

const obtenerEstadoInicial = () => ({
    filtros: {
        institucion: null,
        proveedor: null,
        familia: null,
        tipo_convenio: null,
        producto: null,
        estado: null,
        anio: null,
        proceso_compra: null,
    },
    busquedas: {
        institucion: null,
        proveedor: null,
        producto: null,
        proceso_compra: null,
    },
    // Filtros
    instituciones: createPageable([], 30),
    paginacionInstituciones: {
        pagina: 1,
        registrosPorPagina: 30,
    },
    proveedores: createPageable([], 30),
    paginacionProveedores: {
        pagina: 1,
        registrosPorPagina: 30,
    },
    convenios: createPageable([], 30),
    paginacionConvenios: {
        pagina: 1,
        registrosPorPagina: 30,
    },
    tiposProductos: createLoadable([]),
    estadosNoDevolucion: createLoadable([]),
    productos: createLoadable([], 30),
    paginacionProductos: {
        pagina: 1,
        registrosPorPagina: 30,
    },
    familias: createLoadable([]),
    aniosFiscales: createLoadable([]),
    procesosCompra: createPageable([], 30),
    paginacionProcesosCompra: {
        pagina: 1,
        registrosPorPagina: 30,
    },
    // Data principal
    productosSolicitud: createPageable([], 10),
    paginacionProductosSolicitud: {
        pagina: 1,
        registrosPorPagina: 10,
    },
    estadisticas: createLoadable(null),
    // Graficos
    ventasPorAnio: createLoadable([]),
    topProductos: createLoadable([]),
    ventasPorConvenio: createLoadable([]),
});

export default {
    namespaced: true,
    state: obtenerEstadoInicial(),
    mutations: {
        resetearEstado(state) {
            Object.assign(state, obtenerEstadoInicial());
        },
        manejarLimpiezaFiltros(state) {
            const estadoInicial = obtenerEstadoInicial();

            // Limpiar filtros
            state.paginacionProductosSolicitud.pagina = 1;
            state.filtros = estadoInicial.filtros;

            state.busquedas = estadoInicial.busquedas;

            // Limpiar paginaciones
            state.instituciones = createPageable([], 30),
            state.paginacionInstituciones.pagina = 1;

            state.proveedores = createPageable([], 30);
            state.paginacionProveedores.pagina = 1;

            state.convenios = createPageable([], 30);
            state.paginacionConvenios.pagina = 1;

            state.productos = createPageable([], 30);
            state.paginacionProductos.pagina = 1;

            state.procesosCompra = createPageable([], 30);
            state.paginacionProcesosCompra.pagina = 1;
        },

        manejarCambioTipoConvenio(state) {
            // Productos
            state.filtros.producto = null;
            state.busquedas.producto = '';
            state.productos = createPageable([], 30);
            state.paginacionProductos.pagina = 1;

            // Estados
            state.filtros.estado = null;
            state.estadosNoDevolucion = createLoadable([]);
        },
        // Instituciones
        empezarPeticionInstituciones(state) {
            togglePageable(state.instituciones);
        },
        manejarRespuestaPeticionInstituciones(state, response) {
            const { data, headers } = response;
            const copiaInstituciones = state.instituciones.data;
            setPageableResponse(state.instituciones, data, headers, { skipOnSuccess: true, persistDataOnError: true });
            
            if (!isResponseSuccesful(data)) {
                const { pagina } = state.paginacionInstituciones;
                state.paginacionInstituciones.pagina = pagina > 1 ? pagina - 1 : 1; 
                return;
            };

            state.instituciones.data = copiaInstituciones.concat(data.data);
        },
        manejarBusquedaInstitucion(state, termino) {            
            state.busquedas.institucion = termino;
            state.instituciones.data = [];
            state.paginacionInstituciones.pagina = 1;
        },
        manejarCargaMasInstituciones(state) {
            const { page, per_page } = state.instituciones.pagination;

            state.paginacionInstituciones.pagina = page + 1;
            state.paginacionInstituciones.registrosPorPagina = per_page;
        },
        // Proveedores
        empezarPeticionProveedores(state) {
            togglePageable(state.proveedores);
        },
        manejarRespuestaPeticionProveedores(state, response) {
            const { data, headers } = response;
            const copiaProveedores = state.proveedores.data;

            setPageableResponse(state.proveedores, data, headers, { skipOnSuccess: true, persistDataOnError: true });

            if (!isResponseSuccesful(data)) {
                // Hacemos rollback de la pagina en caso de error para volver a cargar la misma al scrollear
                const { pagina } = state.paginacionProveedores;
                state.paginacionProveedores.pagina = pagina > 1 ? pagina - 1 : 1; 
                return;
            };

            state.proveedores.data = copiaProveedores.concat(data.data);
        },
        manejarBusquedaProveedor(state, termino) {            
            state.busquedas.proveedor = termino;
            state.proveedores.data = [];
            state.paginacionProveedores.pagina = 1;
        },
        manejarCargaMasProveedores(state) {
            const { page, per_page } = state.proveedores.pagination;

            state.paginacionProveedores.pagina = page + 1;
            state.paginacionProveedores.registrosPorPagina = per_page;
        },
        // Productos
        empezarPeticionProductos(state) {
            togglePageable(state.productos);
        },
        manejarRespuestaPeticionProductos(state, response) {
            const { data, headers } = response;
            const copiaProductos = state.productos.data;

            setPageableResponse(state.productos, data, headers, { skipOnSuccess: true, persistDataOnError: true });

            if (!isResponseSuccesful(data)) {
                const { pagina } = state.paginacionProductos;
                state.paginacionProductos.pagina = pagina > 1 ? pagina - 1 : 1; 
                return;
            };

            state.productos.data = copiaProductos.concat(data.data);
        },
        manejarBusquedaProducto(state, termino) {            
            state.busquedas.producto = termino;
            state.productos.data = [];
            state.paginacionProductos.pagina = 1;
        },
        manejarCargaMasProductos(state) {
            const { page, per_page } = state.productos.pagination;

            state.paginacionProductos.pagina = page + 1;
            state.paginacionProductos.registrosPorPagina = per_page;
        },
        // Procesos de compra
        empezarPeticionProcesosCompra(state) {
            togglePageable(state.procesosCompra);
        },
        manejarRespuestaPeticionProcesosCompra(state, response) {
            const { data, headers } = response;
            const copiaProcesos = state.procesosCompra.data;

            setPageableResponse(state.procesosCompra, data, headers, { skipOnSuccess: true, persistDataOnError: true });

            if (!isResponseSuccesful(data)) {
                const { pagina } = state.paginacionProcesosCompra;
                state.paginacionProcesosCompra.pagina = pagina > 1 ? pagina - 1 : 1; 
                return;
            };

            state.procesosCompra.data = copiaProcesos.concat(data.data);
        },
        manejarBusquedaProcesoCompra(state, termino) {         
            state.busquedas.proceso_compra = termino;
            state.procesosCompra.data = [];
            state.paginacionProcesosCompra.pagina = 1;   
        },
        manejarCargaMasProcesosCompra(state) {
            const { page, per_page } = state.procesosCompra.pagination;

            state.paginacionProcesosCompra.pagina = page + 1;
            state.paginacionProcesosCompra.registrosPorPagina = per_page;
        },

        // Tipos de producto
        empezarPeticionTipoProductos(state) {
            toggleLoadable(state.tiposProductos);
        },
        manejarRespuestaPeticionTipoProductos(state, response) {
            const { data } = response;
            setLoadableResponse(state.tiposProductos, data);
        },

        // Estados
        empezarPeticionEstadosNoDevolucion(state) {
            toggleLoadable(state.estadosNoDevolucion);
        },
        manejarRespuestaPeticionEstadosNoDevolucion(state, response) {
            const { data } = response;

            setLoadableResponse(state.estadosNoDevolucion, data);    
        },

        // Familias
        empezarPeticionFamilias(state) {
            toggleLoadable(state.familias);
        },
        manejarRespuestaPeticionFamilias(state, response) {
            const { data } = response;
            setLoadableResponse(state.familias, data);
        },

        // Anios fiscales
        empezarPeticionAniosFiscales(state) {
            togglePageable(state.aniosFiscales);
        },
        manejarRespuestaPeticionAniosFiscales(state, data) {
            setLoadableResponse(state.aniosFiscales, { data });
        },
        limpiarAniosFiscales(state) {
            state.aniosFiscales.data = [];
        },
        terminarPeticionAniosFiscales(state) {
            state.aniosFiscales.isLoading = false;
        },

        // Estadisticas
        empezarPeticionEstadisticas(state) {
            toggleLoadable(state.estadisticas);
        },
        manejarRespuestaPeticionEstadisticas(state, response) {
            const { data } = response;
            setLoadableResponse(state.estadisticas, data);
        },

        // Productos solicitud
        empezarPeticionProductosSolicitud(state) {
            togglePageable(state.productosSolicitud);
        },
        manejarRespuestaPeticionProductosSolicitud(state, response) {
            const { data, headers } = response;
            setPageableResponse(state.productosSolicitud, data, headers);
        },

        actualizarPaginacionProductosSolicitud(state, paginacion) {
            state.paginacionProductosSolicitud.pagina = paginacion.page;
            state.paginacionProductosSolicitud.registrosPorPagina = paginacion.per_page;
        },

        // Ventas anuales
        empezarCargaVentasPorAnio(state) {
            toggleLoadable(state.ventasPorAnio);
        },
        manejarRespuestaPeticionCargaVentasPorAnio(state, response) {
            const { data } = response;
            setLoadableResponse(state.ventasPorAnio, data);
        },

        // Top productos
        empezarPeticionTopProductos(state) {
            toggleLoadable(state.topProductos);
        },
        manejarRespuestaPeticionTopProductos(state, response) {
            const { data } = response;

            setLoadableResponse(state.topProductos, data);
        },

        // Ventas por convenio
        empezarPeticionVentasPorConvenio(state) {
            toggleLoadable(state.ventasPorConvenio);
        },
        manejarRespuestaPeticionVentasPorConvenio(state, response) {
            const { data } = response;
            setLoadableResponse(state.ventasPorConvenio, data);
        },
    },
    actions: {
        // Limpieza filtros
        limpiarFiltros({ commit, dispatch }) {
            commit('manejarLimpiezaFiltros');

            // Refrescar data
            dispatch('cargarInstituciones');
            dispatch('cargarProveedores');
            dispatch('cargarFamilias');
            dispatch('cargarProductos');
            dispatch('cargarEstadosNoDevolucion');
            dispatch('cargarEstadisticas');
            dispatch('cargarProductosSolicitud');
            dispatch('cargarProcesosCompra');
            dispatch('cargarVentasPorAnio');
            dispatch('cargarTopProductos');
            dispatch('cargarVentasPorConvenio');
        },
        buscarPorFiltros({ commit, dispatch, state }) {
            dispatch('cargarVentasPorAnio');
            dispatch('cargarTopProductos');
            dispatch('cargarVentasPorConvenio');

            commit('actualizarPaginacionProductosSolicitud', { page: 1, per_page: state.paginacionProductosSolicitud.registrosPorPagina });
            dispatch('cargarProductosSolicitud');
            dispatch('cargarEstadisticas');
        },
        // Casos concretos
        manejarCambioTipoConvenio({ commit, dispatch }) {
            commit('manejarCambioTipoConvenio');
            dispatch('cargarProductos');
            dispatch('cargarEstadosNoDevolucion');
        },
        // Instituciones
        async cargarInstituciones({ commit, state }) {
            const filtros = { 
                pagination: true, 
                per_page: state.paginacionInstituciones.registrosPorPagina,
                page: state.paginacionInstituciones.pagina,
                busqueda: state.busquedas.institucion,
            };

            commit('empezarPeticionInstituciones');
            
            const response = await BajaCuantiaServices.cargarInstituciones(filtros);   
            
            commit('manejarRespuestaPeticionInstituciones', response);
        },
        async buscarInstitucion({ commit, state, dispatch }, termino) {
            if (state.busquedas.institucion === termino) return;

            commit('manejarBusquedaInstitucion', termino);
            dispatch('cargarInstituciones');
        },
        async cargarMasInstituciones({ commit, state, dispatch }) {
            if (state.instituciones.isLoading) return;

            const { page, per_page, total_rows } = state.instituciones.pagination;
            const currentlyLoaded = page * per_page;

            if (!(currentlyLoaded < total_rows)) return;

            commit('manejarCargaMasInstituciones');
            dispatch('cargarInstituciones');
        },

        // Proveedores
        async cargarProveedores({ commit, state }) {
            const filtros = { 
                pagination: true, 
                per_page: state.paginacionProveedores.registrosPorPagina,
                page: state.paginacionProveedores.pagina,
                busqueda: state.busquedas.proveedor,
            };

            commit('empezarPeticionProveedores');
            const response = await ProveedoresServices.cargarProveedores(filtros);
            commit('manejarRespuestaPeticionProveedores', response);     
        },
        async buscarProveedor({ commit, state, dispatch }, termino) {
            if (state.busquedas.proveedor === termino) return;

            commit('manejarBusquedaProveedor', termino);
            dispatch('cargarProveedores');
        },
        async cargarMasProveedores({ commit, state, dispatch }) {
            if (state.proveedores.isLoading) return;

            const { page, per_page, total_rows } = state.proveedores.pagination;
            const currentlyLoaded = page * per_page;
            
            if (!(currentlyLoaded < total_rows)) return;

            commit('manejarCargaMasProveedores');
            dispatch('cargarProveedores');
        },

        // Productos
        async cargarProductos({ commit, state }) {
            const filtros = { 
                pagination: true, 
                per_page: state.paginacionProductos.registrosPorPagina,
                page: state.paginacionProductos.pagina,
                nombre: state.busquedas.producto,
                ...state.filtros.tipo_convenio && ({ id_tipo_producto: state.filtros.tipo_convenio }),
            };

            commit('empezarPeticionProductos');
            const response = await ProductService.getProducts(filtros);
            commit('manejarRespuestaPeticionProductos', response);
        },
        async buscarProducto({ commit, state, dispatch }, termino) {
            if (state.busquedas.producto === termino) return;

            commit('manejarBusquedaProducto', termino);
            dispatch('cargarProductos');
        },
        async cargarMasProductos({ commit, state, dispatch }) {
            if (state.productos.isLoading) return;

            const { page, per_page, total_rows } = state.productos.pagination;
            const currentlyLoaded = page * per_page;

            if (!(currentlyLoaded < total_rows)) return;

            commit('manejarCargaMasProductos');
            dispatch('cargarProductos');
        },

        // Procesos de compra
        async cargarProcesosCompra({ commit, state }) {
            const filtros = { 
                pagination: true, 
                per_page: state.paginacionProcesosCompra.registrosPorPagina,
                page: state.paginacionProcesosCompra.pagina,
                busqueda: state.busquedas.proceso_compra,
            };

            commit('empezarPeticionProcesosCompra');
            const response = await cmReporteriaServices.cargarProcesosCompra(filtros);
            commit('manejarRespuestaPeticionProcesosCompra', response);
        },
        async buscarProcesoCompra({ commit, state, dispatch }, termino) {
            if (state.busquedas.proceso_compra === termino) return;


            commit('manejarBusquedaProcesoCompra', termino);
            dispatch('cargarProcesosCompra');
        },
        async cargarMasProcesosCompra({ commit, state, dispatch }) {
            if (state.procesosCompra.isLoading) return;

            const { page, per_page, total_rows } = state.procesosCompra.pagination;
            const currentlyLoaded = page * per_page;

            if (!(currentlyLoaded < total_rows)) return;

            commit('manejarCargaMasProcesosCompra');
            dispatch('cargarProcesosCompra');
        },

        // Tipos de productos
        async cargarTiposProductos({ commit }) {
            commit('empezarPeticionTipoProductos');
            const response = await ProductService.getProductTypes();
            commit('manejarRespuestaPeticionTipoProductos', response);
        },

        // Estados
        async cargarEstadosNoDevolucion({ commit, state }) {
            const filtros = {
                ...state.filtros.tipo_convenio && ({ id_tipo_producto: state.filtros.tipo_convenio }),
                omitir_flujo_aprobacion: true,
            }

            commit('empezarPeticionEstadosNoDevolucion');
            const response = await cmReporteriaServices.cargarEstadosNoDevolucion(filtros);
            commit('manejarRespuestaPeticionEstadosNoDevolucion', response);
        },

        // Familias
        async cargarFamilias({ commit }) {
            commit('empezarPeticionFamilias');
            const response = await CmFamiliasServices.cargarFamilias();
            commit('manejarRespuestaPeticionFamilias', response);
        },

        // Anios fiscales
        async cargarAniosFiscales({ commit }) {
            try {
                commit('empezarPeticionAniosFiscales');
                const { data } = await PacProcesosServices.cargarAniosFiscales();
                commit('manejarRespuestaPeticionAniosFiscales', data);
            } catch (error) {
                commit('limpiarAniosFiscales');
            } finally {
                commit('terminarPeticionAniosFiscales');
            }
        },

        // Estadisticas
        async cargarEstadisticas({ commit, state }) {
            const anioSeleccionado = state.aniosFiscales.data.find((anio) => anio.id === state.filtros.anio)?.anio;
            
            const filtrosFormulario = {
                ...omit(state.filtros, ['anio']),
                ...anioSeleccionado && ({ anio: anioSeleccionado }),
            } 
            
            commit('empezarPeticionEstadisticas');
            const response = await cmReporteriaServices.obtenerEstadisticasCmReporte(filtrosFormulario);
            commit('manejarRespuestaPeticionEstadisticas', response);
        },

        // Reporteria
        async cargarProductosSolicitud({ commit, state }) {
            const anioSeleccionado = state.aniosFiscales.data.find((anio) => anio.id === state.filtros.anio)?.anio;

            const filtrosFormulario = {
                ...omit(state.filtros, ['anio']),
                ...anioSeleccionado && ({ anio: anioSeleccionado }),
            } 

            const filtros = {
                ...filtrosFormulario,
                pagination: true,
                per_page: state.paginacionProductosSolicitud.registrosPorPagina,
                page: state.paginacionProductosSolicitud.pagina,
            }

            commit('empezarPeticionProductosSolicitud');
            const response = await cmReporteriaServices.obtenerReporteriaProducto(filtros);
            commit('manejarRespuestaPeticionProductosSolicitud', response);
        },

        // Ventas anuales
        async cargarVentasPorAnio({ state, commit }) {
            const anioSeleccionado = state.aniosFiscales.data.find((anio) => anio.id === state.filtros.anio)?.anio;
            
            const filtrosFormulario = {
                ...omit(state.filtros, ['anio']),
                ...anioSeleccionado && ({ anio: anioSeleccionado }),
            } 

            commit('empezarCargaVentasPorAnio');
            const response = await cmReporteriaServices.cargarMontosPorAnio(filtrosFormulario);
            commit('manejarRespuestaPeticionCargaVentasPorAnio', response);
        },

        // Top productos
        async cargarTopProductos({ commit, state }) {
            const anioSeleccionado = state.aniosFiscales.data.find((anio) => anio.id === state.filtros.anio)?.anio;
            
            const filtrosFormulario = {
                ...omit(state.filtros, ['anio']),
                ...anioSeleccionado && ({ anio: anioSeleccionado }),
            } 

            commit('empezarPeticionTopProductos');
            const response = await cmReporteriaServices.cargarTopProductos(filtrosFormulario);
            commit('manejarRespuestaPeticionTopProductos', response);
        },

        // Ventas por convenio
        async cargarVentasPorConvenio({ commit, state }) {
            const anioSeleccionado = state.aniosFiscales.data.find((anio) => anio.id === state.filtros.anio)?.anio;
            
            const filtrosFormulario = {
                ...omit(state.filtros, ['anio']),
                ...anioSeleccionado && ({ anio: anioSeleccionado }),
            } 

            commit('empezarPeticionVentasPorConvenio');
            const response = await cmReporteriaServices.cargarMontosPorConvenio(filtrosFormulario);
            commit('manejarRespuestaPeticionVentasPorConvenio', response);
        },
    },
};
