import Instituciones from "../../../services/Instituciones.services";
import getUnidades from "../../../services/Unidades.services";

export default {
  namespaced: true,

  state: {
    filters: {
      page: 1,
      per_page: 10,
      total_rows: null,
      id_institucion: null,
    },
    filtersAsignacionUnidad: {
      page: 1,
      per_page: 10,
      total_rows: null,
      id_institucion: null,
    },
    instituciones: [],
    filters2: {
      page: 1,
      per_page: 10,
      total_rows: null,
    },
    records: [],

    id_select_unidad_st: null,
  },
  mutations: {
    setPage(state, payload) {
      state.filters.page = payload;
    },
    setPerPage(state, payload) {
      state.filters.per_page = payload;
    },
    setPageASignacionUnidad(state, payload) {
      state.filtersAsignacionUnidad.page = payload;
    },
    setPerPageASignacionUnidad(state, payload) {
      state.filtersAsignacionUnidad.per_page = payload;
    },
    initPaginationCifrado(state, payload) {
      state.filters.page = Number(payload.page)
      state.filters.per_page = Number(payload.per_page)
      state.filters.total_rows = Number(payload.total_rows)
    },
    initPaginationAsignacionUnidad(state, payload) {
      state.filtersAsignacionUnidad.page = Number(payload.page)
      state.filtersAsignacionUnidad.per_page = Number(payload.per_page)
      state.filtersAsignacionUnidad.total_rows = Number(payload.total_rows)
    },
    setInstituciones(state, payload){
      state.instituciones = payload;
    },
    setPaginationDataFilter2(state, payload){
      state.filters2.page = Number(payload.page);
      state.filters2.per_page = Number(payload.per_page);
      state.filters2.total_rows = Number(payload.total_rows);
    },
    setUnidades(state, payload){
      return (state.records = payload);
    },
    setFilters2(state, payload){
      state.filters2[payload.property] = payload.body;
    }, 
    setIdSelectUnidad(state, payload){
      state.id_select_unidad_st = payload;
    }
  },
  actions: {
    async getInstituciones({ commit }, filters) {
      const response = await Instituciones.getInstituciones(filters);
      if (response.status === 200) {
        commit("setInstituciones", response.data);
        commit("setPaginationDataFilter2", response.headers);
      } else {
        commit("setInstituciones", []);
      }
    },
    async setingsRecords({ commit }, filtros = {}) {
      const unidadesRecords = await getUnidades.getUnidades(filtros);
      let correlativo = 0;
      if (unidadesRecords?.status === 200) {
        const datosUnidades = unidadesRecords?.data
        const cabecera = unidadesRecords?.headers;
    
        if (cabecera?.page !== "1") {
          correlativo = Number(cabecera.per_page) * Number(cabecera.page - 1);
        }
        datosUnidades.forEach(record => {
          correlativo += 1;
          record.correlativo = correlativo;
        })
        commit("setUnidades", datosUnidades);
        commit("setPaginationData", cabecera);
      }
    },
    async guardarUnidades({ commit }, data){
      const response = await getUnidades.postUnidadesNewData(data);
      return response;
    },
    async getInstitucion({ commit }, id){
      return await Instituciones.getInstitucion(id);
    },
  }
}