import { http_client } from "@/plugins/http_client";

const url = "/api/v1/instituciones/unidades";

const getUnidades = async (filters = {}) => {
  const l = await http_client(
    "/api/v1/instituciones/unidades/techos",
    filters,
    "get"
  );
  return l;
};

const postUnidadesNewData = async (params = {}) => {
  return await http_client("/api/v1/instituciones/unidades", params, "post");
};

const deleteUnidades = async (id, params = {}) => {
  return await http_client(
    `/api/v1/instituciones/unidades/${id}`,
    params,
    "delete"
  );
  // const response =   http_client(`/api/v1/instituciones/unidades/${this.recordToDelete}`, params, "delete");
};

const getAllUnidades = async (params = {}) =>
  await http_client(url, params, "get");
const getEstructurasUnidad = async (idUnidad, params = {}) =>
  await http_client(`${url}/${idUnidad}/estructuras`, params, "get");
const getLineasUnidad = async (idUnidad, idLinea, params = {}) =>
  await http_client(`${url}/${idUnidad}/lineas/${idLinea}`, params, "get");
const getObsLineaUnidad = async (idUnidad, idLinea, params = {}) =>
  await http_client(`${url}/${idUnidad}/lineas/${idLinea}/obs`, params, "get");
const getFuentesLineaUnidad = async (idUnidad, idLinea, params = {}) =>
  await http_client(
    `${url}/${idUnidad}/lineas/${idLinea}/fuentes`,
    params,
    "get"
  );

const getHistorialLinea = async (id_linea, filters = {}) => await http_client(`${url}/historial/${id_linea}`, filters)
const getUnidNotConsl = async (idInstitucion, filters = {}) => await http_client(`/api/v1/unidades/no-consolidadoras/${idInstitucion}`, filters)
const getTipoUnidad = async (filters = {}) => await http_client('/api/v1/unidades/tipos', filters)
// const postUnidadConsolidada = async (body) => await http_client('/api/v1/unidades', body, 'post')
const putUnidadConsolidada = async (id_unidad, body) => await http_client(`/api/v1/unidades/${id_unidad}`, body, 'put')
const getDetailUnidad = async (id_unidad, body) => await http_client(`/api/v1/unidades/${id_unidad}`, body)
const getUnidadesDependientes = async (id_unidad, body) => await http_client(`/api/v1/unidades/${id_unidad}/dependientes`, body)

const getUnidadesDependientesConsolidadora = async (id_unidad,filters={}) => {
  return await http_client(`/api/v1/unidades/${id_unidad}/dependientes`,filters,"get")
}

const getUnidadesInstitucionUnidadesDenominadas = async (filters={}) => {
  return await http_client(`/api/v1/instituciones/unidades/denominadas-unidades`,filters,"get")
}

export default {
  getUnidades,
  postUnidadesNewData,
  deleteUnidades,
  getAllUnidades,
  getEstructurasUnidad,
  getLineasUnidad,
  getObsLineaUnidad,
  getFuentesLineaUnidad,
  getHistorialLinea,
  getUnidadesDependientesConsolidadora,
  getUnidNotConsl,
  // postUnidadConsolidada,
  getTipoUnidad,
  getDetailUnidad,
  putUnidadConsolidada,
  getUnidadesDependientes,
  getUnidadesInstitucionUnidadesDenominadas
};
