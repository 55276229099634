import { http_client } from "@/plugins/http_client";

const getSolicitudesCompra = async (params = {}) => {
  return await http_client("/api/v1/compras/solicitudes", params, "get");
};

const getTiposSolicitudesCompra = async (params = {}) => {
  return await http_client("/api/v1/compras/solicitudes/tipos", params, "get");
};

const getEstadosSolicitudesCompra = async (params = {}) => {
  return await http_client(
    "/api/v1/compras/solicitudes/estados",
    params,
    "get"
  );
};

const postSolicitudCompra = async (params = {}) => {
  return await http_client("/api/v1/compras/solicitudes", params, "post");
};

const getSolicitudesCompraInsumos = async (id, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/${id}/insumos`,
    params,
    "get"
  );
};

const getSolicitudesObsPresentaciones = async (params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/obs/presentaciones`,
    params,
    "get"
  );
};

const getSolicitudesProveedores = async (params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/obs/presentaciones/proveedores`,
    params,
    "get"
  );
};

const getSolicitudesObs = async (params = {}) => {
  return await http_client(
    `api/v1/compras/solicitudes/obs/presentaciones/convenios`,
    params,
    "get"
  );
};

const getSolicitudesObsPresentacionesDetalle = async (id, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/obs/presentaciones/${id}`,
    params,
    "get"
  );
};

const getSolicitudesProveedoresInsumo = async (id, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/${id}/proveedores`,
    params,
    "get"
  );
};

const deleteSolicitud = async (id, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/${id}`,
    params,
    "delete"
  );
};

const addSolicitudInsumo = async (id_solicitud, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/${id_solicitud}/insumos`,
    params,
    "post"
  );
};

const getSolicitudesCompraInsumo = async (id_insumo, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/insumo/${id_insumo}`,
    params,
    "get"
  );
};

const putArchivoEspecificaciones = async (id_solicitud_compra, body) => {
  return await http_client(
    `/api/v1/compras/solicitudes/insumos/${id_solicitud_compra}/archivo`,
    body,
    "put"
  );
};

const deleteArchivoEspecificaciones = async (id_solicitud_compra, id_archivo, params = {}) => {
  return await http_client(
    // `/api/v1/compras/solicitudes/insumos/${id_solicitud_compra}/archivo`,
    `/api/v1/compras/solicitudes/insumos/${id_solicitud_compra}/archivo/${id_archivo}`,
    params,
    "delete"
  );
};

const deleteSolicitudCompraInsumo = async (id_insumo, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/insumos/${id_insumo}`,
    params,
    "delete"
  );
};

const updateSolicitudCompraInsumo = async (id_insumo, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/insumo/${id_insumo}`,
    params,
    "put"
  );
};

const addSolicitudInsumoDetalle = async (id_insumo, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/insumos/${id_insumo}/detalles`,
    params,
    "post"
  );
};

const deleteSolicitudInsumoDetalle = async (
  id_solicitud_compra_detalle,
  params = {}
) => {
  return await http_client(
    `/api/v1/compras/solicitudes/insumos/detalles/${id_solicitud_compra_detalle}`,
    params,
    "delete"
  );
};

const addSolicitudCotizacionDetalle = async (
  id_solicitud_compra_obs,
  params = {}
) => {
  return await http_client(
    `/api/v1/compras/solicitudes/insumo/${id_solicitud_compra_obs}/cotizacion`,
    params,
    "post"
  );
};

const deleteSolicitudCotizacionDetalle = async (id_cotizacion) => {
  return await http_client(
    `/api/v1/compras/solicitudes/insumo/cotizacion/${id_cotizacion}`,
    {},
    "delete"
  );
};

const getUrlDocumento = async (ruta = {}) =>
  await http_client(
    `/api/v1/documentos/cotizaciones`,
    ruta,
    "get",
    { responseType: "blob" },
    true,
    "blob",
    false
  );

const updateSolicitudInsumoDetalle = async (id_detalle, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/insumos/detalles/${id_detalle}`,
    params,
    "put"
  );
};

const updateSolicitudEstado = async (id_solicitud, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/${id_solicitud}/estado`,
    params,
    "put"
  );
};

const getSolicitudesFondos = async (
  { id_solicitud, id_unidad },
  params = {}
) => {
  return await http_client(
    `/api/v1/compras/solicitudes/${id_solicitud}/unidades/${id_unidad}/fondos`,
    params,
    "get"
  );
};

const getConsolidadosSolicitudCompra = async (params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/consolidados`,
    params,
    "get"
  );
};

const getProveedores = async (filters = {}) => {
  return await http_client(`/api/v1/proveedores/web`, filters, "get");
};

const putSolicitudesInsumosEstado = async (
  id_solicitud,
  id_insumo,
  params = {}
) => {
  return await http_client(
    `/api/v1/compras/solicitudes/${id_solicitud}/insumos/${id_insumo}/estados`,
    params,
    "put"
  );
};

const putSolicitudesInsumosDetalleUFI = async (params = []) => {
  return await http_client(
    `/api/v1/compras/solicitudes/insumos/detalles/`,
    params,
    "put"
  );
};

const putSolicitudesProveedores = async (id_proveedor, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/proveedores/${id_proveedor}/estados`,
    params,
    "put"
  );
};

const getSolicitudCompraGanadores = async (id_solicitud, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/${id_solicitud}/ganadores`,
    params,
    "get"
  );
};

const putSolicitudesProveedoresWeb = async (id_proveedor, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/proveedores/web/${id_proveedor}/estados`,
    params,
    "put"
  );
};

const getSolicitudesInsumosDocs = async (id_insumo, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/insumos/${id_insumo}/documentos`,
    params,
    "get"
  );
};

const postSolicitudesInsumosDocs = async (id_insumo, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/insumos/${id_insumo}/documentos`,
    params,
    "post"
  );
};

const deleteSolicitudesInsumosDocs = async (id_insumo_doc, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/insumos/documentos/${id_insumo_doc}`,
    params,
    "delete"
  );
};

const getSolicitudesInsumosDoc = async (id_insumo_doc, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/insumos/documentos/${id_insumo_doc}`,
    params,
    "get",
    {},
    true,
    "arraybuffer"
  );
};

const postSolicitudesProveedorDocs = async (
  id_proveedor,
  id_solicitud,
  params = {}
) => {
  return await http_client(
    `/api/v1/compras/solicitudes/${id_solicitud}/proveedores/${id_proveedor}/documentos`,
    params,
    "post"
  );
};

const getSolicitudesProveedorDocs = async (
  id_proveedor,
  id_solicitud,
  params = {}
) => {
  return await http_client(
    `/api/v1/compras/solicitudes/${id_solicitud}/proveedores/${id_proveedor}/documentos`,
    params,
    "get"
  );
};

const getSolicitudesProveedorDoc = async (id_proveedor_doc, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/proveedores/documentos/${id_proveedor_doc}`,
    params,
    "get",
    {},
    true,
    "arraybuffer"
  );
};

const getAdministradoresContrato = async (
  id_solicitud_compra,
  filters = {}
) => {
  return await http_client(
    `/api/v1/compras/solicitudes/administradores/${id_solicitud_compra}`,
    filters,
    "get"
  );
};

const verificarEmpleado = async (params) => {
  return await http_client(
    `/api/v1/empleados/verificacion`,
    params,
    "post"
  );
};

const agregarAdministradorContrato = async (id_solicitud_compra, params) => {
  try {
    return await http_client(
      `/api/v1/compras/solicitudes/administradores/${id_solicitud_compra}`,
      params,
      "post"
    );
  } catch (error) {
    return error.response;
  }
};

const deleteAdministradorContrato = async (id_solicitud_compra, params) => {
  return await http_client(
    `/api/v1/compras/solicitudes/administradores/${id_solicitud_compra}`,
    params,
    "delete"
  );
};

const getCatalogoPasos = async (params = {}) => {
  return await http_client(`/api/v1/compras/solicitudes/pasos`, params, "get");
};

const getSolicitud = async (id_solicitud_compra, params) => {
  return await http_client(
    `/api/v1/compras/solicitudes/${id_solicitud_compra}`,
    params,
    "get"
  );
};

const deleteProveedorDoc = async (
  id_solicitud,
  id_proveedor,
  id_documento,
  params
) => {
  return await http_client(
    `/api/v1/compras/solicitudes/${id_solicitud}/proveedores/${id_proveedor}/documentos/${id_documento}`,
    params,
    "delete"
  );
};

const postSolicitudCotizaciones = async (id_solicitud_compra, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/${id_solicitud_compra}/cotizacion`,
    params,
    "post"
  );
};

const getSolicitudCotizaciones = async (id_solicitud_compra, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/${id_solicitud_compra}/cotizacion`,
    params,
    "get"
  );
};

const getBitacoraSolicitud = async (params) => {
  return await http_client(
    `/api/v1/compras/solicitudes/bitacoras`,
    params,
    "get"
  );
};

const getPdfBitacoraSolicitud = async (params) => {
  return await http_client(
    `/api/v1/compras/solicitudes/bitacoras/reporte`,
    params,
    "get",
    {},
    true,
    "arraybuffer"
  );
};

const PostCargarCsv = async (id_solicitud_compra, params = {}) => {
  return await http_client(
    `/api/v1/compras/solicitudes/${id_solicitud_compra}/insumos/subir`,
    params,
    "post"
  );
};

const GetDescargarCsv = async (params = {}) => {
  return await http_client(
    "/api/v1/compras/solicitudes/insumos/descargar",
    params,
    "get"
  );
};

const getSolicitudCompraDinac = async (params = {}) => {
  return await http_client("/api/v1/solicitudes/dinac", params, "get")
}

// Nueva solicitud de compra de proceso tradicional
const getDetailSolicitudCompra = async (id_solicitud_compra, body = {}) => await http_client(`/api/v1/solicitudes/compras/${id_solicitud_compra}`, body)
const getDetailInsumoSolicitudCompra = async (id_solicitud_compra, id_insumo, body = {}) => await http_client(`/api/v1/solicitudes/compras/${id_solicitud_compra}/insumos/${id_insumo}`, body)
const getCtlProcesoCompra = async (filters = {}) => await http_client('/api/v1/pac/procesos/unidades', filters)
const getCtlSegmento = async (id_proceso_compra, filters = {}) => await http_client(`/api/v1/pac/procesos/${id_proceso_compra}/segmentos`, filters)
const getCtlSegmentoObs = async (id_proceso_compra, filters = {}) => await http_client(`/api/v1/compra/proceso/${id_proceso_compra}/segmentos`, filters)
const getCtlObsBySegmento = async (id_proceso_compra, id_segmento, filters = {}) => await http_client(`/api/v1/pac/procesos/${id_proceso_compra}/segmentos/${id_segmento}`, filters)

const postInsumoProcesoCompra = async (id_solicitud_compra, body = {}) => await http_client(`/api/v1/solicitudes/compras/${id_solicitud_compra}/insumos`, body, 'post')
const putInsumoProcesoCompra = async (id_solicitud_compra, id_insumo, id_detalle, body = {}) => { 
  return await http_client(`/api/v1/solicitudes/compras/${id_solicitud_compra}/insumos/${id_insumo}/detalle/${id_detalle}`, body, 'put') 
}
const putSolicitudProcesoCompra = async (id_solicitud_compra, body = {}) => await http_client(`/api/v1/solicitudes/compras/${id_solicitud_compra}`, body, 'put')
const deleteInsumoProcesoCompra = async (id_solicitud_compra, id_insumo, body = {}) => await http_client(`/api/v1/solicitudes/compras/${id_solicitud_compra}/insumos/${id_insumo}`, body, 'delete')

// Documentos de especificación
const getDocsEspecificacion = async (id_solicitud_compra, body = {}) => await http_client(`/api/v1/solicitudes/compras/${id_solicitud_compra}/especificacion`, body)
const downloadDocEspecificacion = async (id_solicitud_compra, id_doc_especificacion) => await http_client(
  `/api/v1/solicitudes/compras/${id_solicitud_compra}/especificacion/${id_doc_especificacion}`, {}, "get", { responseType: "blob" }, true, "blob", false
);
const deleteDocEspecificacion = async (id_solicitud_compra, id_doc_especificacion) => await http_client(
  `/api/v1/solicitudes/compras/${id_solicitud_compra}/especificacion/${id_doc_especificacion}`, {}, 'delete'
);
const addDocEspecificacion = async (id_solicitud_compra, body = {}) => await http_client(
  `/api/v1/solicitudes/compras/${id_solicitud_compra}/especificacion/`, body, 'post'
);

const validacionInsumo = async (id_solicitud_compra, id_insumo, body = {}) => await http_client(`/api/v1/solicitudes/compras/${id_solicitud_compra}/insumos/${id_insumo}`, body, 'put');

export default {
  getSolicitudesCompra,
  getTiposSolicitudesCompra,
  getEstadosSolicitudesCompra,
  postSolicitudCompra,
  getSolicitudesCompraInsumos,
  getSolicitudesObsPresentaciones,
  getSolicitudesProveedores,
  getSolicitudesObs,
  getSolicitudesObsPresentacionesDetalle,
  getSolicitudesProveedoresInsumo,
  deleteSolicitud,
  addSolicitudInsumo,
  getSolicitudesCompraInsumo,
  deleteSolicitudCompraInsumo,
  updateSolicitudCompraInsumo,
  addSolicitudInsumoDetalle,
  deleteSolicitudInsumoDetalle,
  updateSolicitudInsumoDetalle,
  deleteSolicitudCotizacionDetalle,
  addSolicitudCotizacionDetalle,
  getUrlDocumento,
  updateSolicitudEstado,
  getSolicitudesFondos,
  getProveedores,
  getConsolidadosSolicitudCompra,
  putSolicitudesInsumosEstado,
  putSolicitudesInsumosDetalleUFI,
  putSolicitudesProveedores,
  getSolicitudCompraGanadores,
  putSolicitudesProveedoresWeb,
  postSolicitudesInsumosDocs,
  getSolicitudesInsumosDocs,
  deleteSolicitudesInsumosDocs,
  getSolicitudesInsumosDoc,
  postSolicitudesProveedorDocs,
  getSolicitudesProveedorDocs,
  getSolicitudesProveedorDoc,
  getAdministradoresContrato,
  verificarEmpleado,
  agregarAdministradorContrato,
  deleteAdministradorContrato,
  getCatalogoPasos,
  getSolicitud,
  deleteProveedorDoc,
  postSolicitudCotizaciones,
  getSolicitudCotizaciones,
  getBitacoraSolicitud,
  getPdfBitacoraSolicitud,
  PostCargarCsv,
  GetDescargarCsv,
  putArchivoEspecificaciones,
  deleteArchivoEspecificaciones,
  getSolicitudCompraDinac,
  getDetailSolicitudCompra,
  getDetailInsumoSolicitudCompra,
  getCtlProcesoCompra,
  postInsumoProcesoCompra,
  getCtlSegmento,
  getCtlObsBySegmento,
  putInsumoProcesoCompra,
  putSolicitudProcesoCompra,
  deleteInsumoProcesoCompra,
  getDocsEspecificacion,
  downloadDocEspecificacion,
  deleteDocEspecificacion,
  addDocEspecificacion,
  validacionInsumo,
  getCtlSegmentoObs
};
