const routesCompraEnLinea = [
    {
      path: "/administracion-compra-en-linea",
      name: "administracion-compra-en-linea",
      component: () =>
        import(
          /* webpackChunkName: "administracionCompraEnLinea" */ "./administracionCompraEnLinea.vue"
        ),
    },
    { 
      path: "/fondos-compra-en-linea",
      name: "fondos-compra-en-linea",
      component: () =>
        import(
          /* webpackChunkName: "fondosCompraEnLinea" */ "./fondosCompraEnLinea.vue"
        ),
    },
    {
      path: "/revision-fondos-compra-en-linea",
      name: "revision-fondos-compra-en-linea",
      component: () =>
        import(
          /* webpackChunkName: "revisionFondosCompraEnLinea" */ "./revisionFondosCompraEnLinea.vue"
        ),
    },
    {
      path: "/revision-registros-fondo-compra-en-linea/:id_fondo",
      name: "revision-registros-fondo-compra-en-linea",
      component: () =>
        import(
          /* webpackChunkName: "revisionRegistrosCompraEnLinea" */ "./revisionRegistrosFondoCompraEnLinea.vue"
        ),
    },
  ];
  
  export default routesCompraEnLinea;