import paacServices from "../../../../services/Paac.services";
import unidadesServices from "../../../../services/Unidades.services";
import obsServices from "../../../../services/Obs.services";

/**
 * Obtiene las solicitudes disponibles para agregar al proceso de compra
 */
export const obtainSolicitudes = async ({ commit, state, dispatch }) => {
  // Se definen los filtros para hacer la petición a backend
  let filters = {
    id_unidad: state.fltUnidadSolicitud ? state.fltUnidadSolicitud : undefined,
    id_obs_clasificacion: state.fltTipoObsSolicitud
      ? state.fltTipoObsSolicitud
      : undefined,
    id_obs: state.fltObsSolicitud ? state.fltObsSolicitud : undefined,
  };

  // Se realiza la petición a backend
  const response = await paacServices.getSolicitudes(filters);

  if (response) {
    let solicitudes = response?.data?.solicitudes;

    // Eliminamos todos los elementos que estén dentro de la propiedad items en solicitudes
    // que ya se encuentren dentro de la propiedad items de procesos utilizando
    // item.id_solicitud_compra_detalle_obs para comparar siempre y cuando el id del padre (id_obs) sea el mismo
    let solicitudesFiltradas = solicitudes.map((solicitud) => {
      solicitud.items = solicitud.items.filter(
        (item) =>
          !state.procesos.some(
            (proceso) =>
              proceso.id_obs === solicitud.id_obs &&
              proceso.items.some(
                (itemProceso) =>
                  itemProceso.id_solicitud_compra_detalle_obs ===
                  item.id_solicitud_compra_detalle_obs
              )
          )
      );
      return solicitud;
    });

    solicitudesFiltradas = solicitudesFiltradas.filter((solicitud) => {
      return solicitud.items.length > 0;
    });

    // Se actualiza el estado
    commit("setSolicitudes", solicitudes);
    commit("setSolicitudesFiltradas", solicitudesFiltradas);

    if (state.procesos.length == 0) {
      // Hacemos una copia de solicitudes pero con la propiedad items vacía para
      // guardarla en procesos
      let procesos = solicitudes.map((solicitud) => {
        let data = { ...solicitud };
        data.items = [];
        return data;
      });
      commit("setProcesos", procesos);
    }
    dispatch("onParentAdd");
  }
};

export const filterProcesos = ({ commit, state, dispatch }) => {
  let unidad = null;
  let obs = null;
  let tipoObs = null;

  // Hacemos una copia del arreglo de procesos y de sus items para no modificar el arreglo original ni sus items
  let procesosFiltrados = JSON.parse(JSON.stringify(state.procesos));

  if (state.fltUnidadProceso != null) {
    unidad = state.ctlUnidad.find(
      (unidad) => unidad.id === state.fltUnidadProceso
    );
  }

  if (state.fltObsProceso != null) {
    obs = state.ctlObs.find((obs) => obs.id === state.fltObsProceso);
  }

  if (state.fltTipoObsProceso != null) {
    tipoObs = state.ctlTipoObs.find(
      (tipoObs) => tipoObs.id === state.fltTipoObsProceso
    );
  }

  // Filtramos por unidad los elementos de la propiedad items de cada elemento
  // de procesosFiltrados
  if (unidad != null) {
    procesosFiltrados = procesosFiltrados.map((proceso) => {
      proceso.items = proceso.items.filter(
        (item) => item.id_unidad === unidad.id
      );
      return proceso;
    });
  }

  if (tipoObs != null) {
    procesosFiltrados = procesosFiltrados.map((proceso) => {
      proceso.items = proceso.items.filter(
        (item) => item.id_obs_clasificacion === tipoObs.id
      );
      return proceso;
    });
  }

  // Filtramos por obs los elementos de la propiedad items de cada elemento
  // de procesosFiltrados
  if (obs != null) {
    procesosFiltrados = procesosFiltrados.map((proceso) => {
      proceso.items = proceso.items.filter((item) => item.id_obs === obs.id);
      return proceso;
    });
  }

  // Eliminamos los elementos de procesosFiltrados que no tengan elementos en la propiedad items
  procesosFiltrados = procesosFiltrados.filter((proceso) => {
    return proceso.items.length > 0;
  });
  // Actualizamos el estado
  commit("setProcesosFiltrados", procesosFiltrados);

  dispatch("onParentAdd");
};

// Acciones para obtener los catálogos -----------------------------------------

/**
 * Obtiene el listado de unidades para llenar el select
 */
export const fetchUnidades = async ({ commit }) => {
  const response = await unidadesServices.getAllUnidades();
  if (response) {
    commit("setCtlUnidad", response.data);
  }
};

/**
 * Obtiene el listado de tipos de observación para llenar el select
 */
export const fetchTipoObs = async ({ commit }) => {
  const response = await obsServices.getObsCategoriaList();
  if (response) {
    commit("setCtlTipoObs", response.data);
  }
};

/**
 * Obtiene el listado de OBS para llenar el select
 */
export const fetchObs = async ({ commit }) => {
  const response = await obsServices.getObsList();
  if (response) {
    commit("setCtlObs", response.data);
  }
};

// Acciones para el funcionamiento del DRAGGABLE -------------------------------
/**
 * Abre todos los expansion panels al agregar un nuevo elemento
 */
export const onParentAdd = async ({ commit, state }) => {
  const totalSolicitudes = state.solicitudesFiltradas.length;
  let solicitudesExpansionPanel = [];
  for (let i = 0; i < totalSolicitudes; i++) {
    solicitudesExpansionPanel.push(i);
  }

  const totalProcesos = state.procesosFiltrados.length;
  let procesosExpansionPanel = [];
  for (let i = 0; i < totalProcesos; i++) {
    procesosExpansionPanel.push(i);
  }

  commit("setSolicitudesExpansionPanel", solicitudesExpansionPanel);
  commit("setProcesosExpansionPanel", procesosExpansionPanel);
};

export const onSolicitudStart = async ({ commit, state }, payload) => {
  // Hacemos una copia de los elementos de state para no modificar el estado
  let procesosFiltrados = [...state.procesosFiltrados];
  let procesosExpansionPanel = [...state.procesosExpansionPanel];

  const parentId = payload.from.getAttribute("data-parent-id");

  // Parámetros para identificar si los el elemento que se esta moviendo esta
  // dentro de los parámetros de filtrado
  const id_unidad = payload.item.getAttribute("data-id-unidad");
  const id_obs_clasificacion = payload.item.getAttribute("data-id-tipo-obs");
  const id_obs = payload.item.getAttribute("data-id-obs");

  // Verificamos si en procesosFiltrados existe un elemento con el mismo
  // id que el elemento que se esta moviendo
  const existe = state.procesosFiltrados.find(
    (proceso) => proceso.id_obs == parentId
  );

  // Verificar si el elemento que se esta moviendo esta dentro de los parámetros
  // de filtrado, si el calor del filtro es null o undefined no se toma en cuenta
  // el filtro
  const filtroUnidad = state.fltUnidadProceso
    ? state.fltUnidadProceso == id_unidad
    : true;
  const filtroTipoObs = state.fltTipoObsProceso
    ? state.fltTipoObsProceso == id_obs_clasificacion
    : true;
  const filtroObs = state.fltObsProceso ? state.fltObsProceso == id_obs : true;

  // Si no existe, lo creamos y lo agregamos
  if (!existe && filtroUnidad && filtroTipoObs && filtroObs) {
    const solicitud = state.solicitudesFiltradas.find(
      (solicitud) => solicitud.id_obs == parentId
    );

    // Creamos el nuevo objeto padre
    procesosFiltrados.unshift({
      nombre_obs: solicitud.nombre_obs,
      id_obs: solicitud.id_obs,
      codigo_obs: solicitud.codigo_obs,
      items: [],
    });

    // Actualizamos el estado
    commit("setProcesosFiltrados", procesosFiltrados);

    // sumamos 1 a todos los elementos de procesosExpansionPanel y agregamos el
    // valor 0 al inicio del array
    // esto es para que el elemento que se esta moviendo se agregue al inicio de
    // la lista
    procesosExpansionPanel = procesosExpansionPanel.map((item) => item + 1);
    procesosExpansionPanel.unshift(0);
  } else {
    // Si existe obtenemos el indice del elemento y verificamos si esta en
    // procesosExpansionPanel
    const index = procesosFiltrados.findIndex(
      (proceso) => proceso.id_obs == parentId
    );
    const existe = procesosExpansionPanel.find((item) => item == index);
    // Si no existe lo agregamos al inicio del array
    if (!existe) {
      procesosExpansionPanel.unshift(index);
    }
  }
  // Abrir los paneles de procesos
  const totalProcesos = procesosFiltrados.length;
  procesosExpansionPanel = [];
  for (let i = 0; i < totalProcesos; i++) {
    procesosExpansionPanel.push(i);
  }
  commit("setProcesosExpansionPanel", procesosExpansionPanel);

  // Si el elemento no se encontraba dentro de los parámetros de filtrado,
  // agregamos la clase de error a solicitudes
  if (!filtroUnidad || !filtroTipoObs || !filtroObs) {
    commit("setProcesosError", true);
  }
};

export const onProcesoStart = async ({ commit, state }, payload) => {
  // Hacemos una copia de los elementos de state para no modificar el estado
  let solicitudesFiltradas = [...state.solicitudesFiltradas];
  let solicitudesExpansionPanel = [...state.solicitudesExpansionPanel];

  const parentId = payload.from.getAttribute("data-parent-id");

  // Parámetros para identificar si los el elemento que se esta moviendo esta
  // dentro de los parámetros de filtrado
  const id_unidad = payload.item.getAttribute("data-id-unidad");
  const id_obs_clasificacion = payload.item.getAttribute("data-id-tipo-obs");
  const id_obs = payload.item.getAttribute("data-id-obs");

  // Verificamos si en solicitudesFiltradas existe un elemento con el mismo id
  // que el elemento que se esta moviendo
  const existe = solicitudesFiltradas.find(
    (solicitud) => solicitud.id_obs == parentId
  );

  // Verificar si el elemento que se esta moviendo esta dentro de los parámetros
  // de filtrado, si el calor del filtro es null o undefined no se toma en cuenta
  // el filtro
  const filtroUnidad = state.fltUnidadSolicitud
    ? state.fltUnidadSolicitud == id_unidad
    : true;
  const filtroTipoObs = state.fltTipoObsSolicitud
    ? state.fltTipoObsSolicitud == id_obs_clasificacion
    : true;
  const filtroObs = state.fltObsSolicitud
    ? state.fltObsSolicitud == id_obs
    : true;

  // Si no existe, lo creamos y lo agregamos
  if (!existe && filtroUnidad && filtroTipoObs && filtroObs) {
    const proceso = state.procesosFiltrados.find(
      (proceso) => proceso.id_obs == parentId
    );
    solicitudesFiltradas.unshift({
      nombre_obs: proceso.nombre_obs,
      id_obs: proceso.id_obs,
      codigo_obs: proceso.codigo_obs,
      items: [],
    });

    // Actualizamos el estado
    commit("setSolicitudesFiltradas", solicitudesFiltradas);

    // sumamos 1 a todos los elementos de this.procesosModel y agregamos el
    // valor 0 al inicio del array
    // esto es para que el elemento que se esta moviendo se agregue al inicio de
    // la lista
    solicitudesExpansionPanel = solicitudesExpansionPanel.map(
      (item) => item + 1
    );
    solicitudesExpansionPanel.unshift(0);
  } else {
    // Si existe obtenemos el indice del elemento y verificamos si esta en
    // this.procesosModel
    const index = solicitudesFiltradas.findIndex(
      (solicitud) => solicitud.id_obs == parentId
    );
    const existe = solicitudesExpansionPanel.find((item) => item == index);
    // Si no existe lo agregamos al inicio del array
    if (!existe) {
      solicitudesExpansionPanel.unshift(index);
    }
  }
  // Abrir los paneles de solicitudes
  const totalSolicitudes = solicitudesFiltradas.length;
  solicitudesExpansionPanel = [];
  for (let i = 0; i < totalSolicitudes; i++) {
    solicitudesExpansionPanel.push(i);
  }
  commit("setSolicitudesExpansionPanel", solicitudesExpansionPanel);

  // Si el elemento no se encontraba dentro de los parámetros de filtrado,
  // agregamos la clase de error a solicitudes
  if (!filtroUnidad || !filtroTipoObs || !filtroObs) {
    commit("setSolicitudesError", true);
  }
};

export const onEnd = async ({ commit, state }) => {
  // Hacemos una copia de los elementos de state para no modificar el estado
  let solicitudesFiltradas = [...state.solicitudesFiltradas];
  let procesosFiltrados = [...state.procesosFiltrados];

  procesosFiltrados = procesosFiltrados.filter(
    (proceso) => proceso.items.length > 0
  );
  solicitudesFiltradas = solicitudesFiltradas.filter(
    (solicitud) => solicitud.items.length > 0
  );

  // Actualizamos el estado
  commit("setProcesosFiltrados", procesosFiltrados);
  commit("setSolicitudesFiltradas", solicitudesFiltradas);

  // quitamos la clase de error a solicitudes y procesos
  commit("setProcesosError", false);
  commit("setSolicitudesError", false);
};

export const onParentSolicitudesStart = async ({ commit, state }, payload) => {
  const id_unidad = state.fltUnidadProceso;
  const id_obs_clasificacion = state.fltTipoObsProceso;
  const id_obs = state.fltObsProceso;

  const solicitud = payload.item._underlying_vm_;

  // Verificamos que todos los elementos dentro de la propiedad items de solicitud
  // cumplan con los parámetros de filtrado, pero si el campo de filtro es null
  // o undefined no se toma en cuenta el filtro
  const filtroUnidad = id_unidad
    ? solicitud.items.every((item) => item.id_unidad == id_unidad)
    : true;
  const filtroTipoObs = id_obs_clasificacion
    ? solicitud.items.every(
        (item) => item.id_obs_clasificacion == id_obs_clasificacion
      )
    : true;
  const filtroObs = id_obs
    ? solicitud.items.every((item) => item.id_obs == id_obs)
    : true;

  // Si no cumple con los parámetros de filtrado agregamos la clase de error a
  // procesos
  if (!filtroUnidad || !filtroTipoObs || !filtroObs) {
    commit("setProcesosError", true);
  }
};

export const onParentProcesosStart = async ({ commit, state }, payload) => {
  const id_unidad = state.fltUnidadSolicitud;
  const id_obs_clasificacion = state.fltTipoObsSolicitud;
  const id_obs = state.fltObsSolicitud;

  const proceso = payload.item._underlying_vm_;

  // Verificamos que todos los elementos dentro de la propiedad items de proceso
  // cumplan con los parámetros de filtrado, pero si el campo de filtro es null
  // o undefined no se toma en cuenta el filtro
  const filtroUnidad = id_unidad
    ? proceso.items.every((item) => item.id_unidad == id_unidad)
    : true;
  const filtroTipoObs = id_obs_clasificacion
    ? proceso.items.every(
        (item) => item.id_obs_clasificacion == id_obs_clasificacion
      )
    : true;
  const filtroObs = id_obs
    ? proceso.items.every((item) => item.id_obs == id_obs)
    : true;

  // Si no cumple con los parámetros de filtrado agregamos la clase de error a
  // solicitudes
  if (!filtroUnidad || !filtroTipoObs || !filtroObs) {
    commit("setSolicitudesError", true);
  }
};

// -----------------------------------------------------------------------------

/**
 * Función que iguala los movimientos entre solicitudesFiltradas y
 * procesosFiltrados filtrados y los replica en solicitudes y procesos
 */
export const replicarMovimientos = async ({ commit, state }) => {
  // Hacemos una copia de los elementos de state para no modificar el estado
  let solicitudesFiltradas = JSON.parse(
    JSON.stringify(state.solicitudesFiltradas)
  );
  let procesosFiltrados = JSON.parse(JSON.stringify(state.procesosFiltrados));
  let solicitudes = JSON.parse(JSON.stringify(state.solicitudes));
  let procesos = JSON.parse(JSON.stringify(state.procesos));

  solicitudesFiltradas.forEach((solicitudFiltrada) => {
    // Buscamos en solicitudes el elemento con el mismo id_obs
    const solicitud = solicitudes.find(
      (solicitud) => solicitud.id_obs == solicitudFiltrada.id_obs
    );

    // Buscamos en procesos el elemento con el mismo id_obs
    const proceso = procesos.find(
      (proceso) => proceso.id_obs == solicitudFiltrada.id_obs
    );

    // buscamos en solicitudFiltrada los elementos que no estén en solicitud y
    // los agregamos a solicitud y lo eliminamos de proceso, tener en cuenta que
    // items puede ser null o undefined
    if (
      solicitud != null &&
      solicitud != undefined &&
      proceso != null &&
      proceso != undefined
    ) {
      solicitudFiltrada.items.forEach((itemFiltrado) => {
        const item = solicitud.items.find(
          (item) =>
            item.id_solicitud_compra_detalle_obs ==
            itemFiltrado.id_solicitud_compra_detalle_obs
        );
        if (!item) {
          solicitud.items.push(itemFiltrado);
          const index = proceso.items.findIndex(
            (item) =>
              item.id_solicitud_compra_detalle_obs ==
              itemFiltrado.id_solicitud_compra_detalle_obs
          );
          proceso.items.splice(index, 1);
        }
      });
    }
  });

  procesosFiltrados.forEach((procesoFiltrado) => {
    // Buscamos en solicitudes el elemento con el mismo id_obs
    const solicitud = solicitudes.find(
      (solicitud) => solicitud.id_obs == procesoFiltrado.id_obs
    );

    // Buscamos en procesos el elemento con el mismo id_obs
    const proceso = procesos.find(
      (proceso) => proceso.id_obs == procesoFiltrado.id_obs
    );

    // buscamos en procesoFiltrado los elementos que no estén en proceso y
    // los agregamos a proceso y lo eliminamos de solicitud
    if (
      solicitud != null &&
      solicitud != undefined &&
      proceso != null &&
      proceso != undefined
    ) {
      procesoFiltrado.items.forEach((itemFiltrado) => {
        const item = proceso.items.find(
          (item) =>
            item.id_solicitud_compra_detalle_obs ==
            itemFiltrado.id_solicitud_compra_detalle_obs
        );
        if (!item) {
          proceso.items.push(itemFiltrado);
          const index = solicitud.items.findIndex(
            (item) =>
              item.id_solicitud_compra_detalle_obs ==
              itemFiltrado.id_solicitud_compra_detalle_obs
          );
          solicitud.items.splice(index, 1);
        }
      });
    }
  });

  // Actualizamos el estado
  commit("setProcesos", procesos);
  commit("setSolicitudes", solicitudes);
};
