import { http_client } from "@/plugins/http_client";

const getGanadorLicitacion = async (id, params = {}) => {
    return await http_client(`/api/v1/comparar-precios/ganador/${id}`, params, "get");
};

const getAnalisisGanador = async (id_obs, id_sub_proceso_obs, params = {}) => {
    return await http_client(`/api/v1/comparar-precios/analisis/${id_obs}/${id_sub_proceso_obs}`, params, "get");
};
const getComprasGanador = async (id_obs, params = {}) => {
    return await http_client(`/api/v1/comparar-precios/compras/${id_obs}`, params, "get");
};

const getReportes = async (params = {}) => {
    return await http_client(`/api/v1/reportes`, params, "get");
}

const getReporteTipoContratos = async (id_contrato) => {
    return await http_client(
        `/api/v1/catalogos/reportes/tipos`,
        id_contrato,
        "get"
    );
};

const getReporteEstado = async (id_contrato) => {
    return await http_client(
        `/api/v1/catalogos/reportes/estados`,
        id_contrato,
        "get"
    );
};

export default {
    getGanadorLicitacion,
    getAnalisisGanador,
    getComprasGanador,
    getReportes,
    getReporteTipoContratos,
    getReporteEstado
}