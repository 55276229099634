import PaacServices from "../../../../services/Paac.services"

const etapaAperturaOfertaStore = {
  namespaced: true,
  state: {
    lista_proveedores: [],
    lista_obs: [],
    listaAperturaOfertas: [],
    info: {},
  },

  mutations: {
    setListaProveedores(state, payload){
      state.lista_proveedores = payload
    },
    setListaObs(state, payload){
      state.lista_obs = payload
    },
    setListaAperturaOferta (state, payload) {
      state.listaAperturaOfertas = payload
    },
    setInfo(state, payload){
      state.info = payload
    }
  },
  actions: {
    async getProveedoresAperturaOferta({commit}, id_proceso_compra){
      const response = await PaacServices.getProveedoresAperturaOferta(id_proceso_compra)
      if(response.status === 200){
        commit("setListaProveedores", response.data)
      }
    },
    async getObsAperturaOferta({commit}, id_proceso_compra){
      const response = await PaacServices.getObsAperturaOferta(id_proceso_compra)
      if(response.status === 200){
        commit("setListaObs", response.data)
      }
    },
    async getDetalleAperturaOferta({commit}, data){
      const response = await PaacServices.getDetalleAperturaOferta(data.id_proceso_compra, data.id_sub_proceso_obs, data.id_proveedor)
      if(response.status === 200){
        commit("setInfo", response.data)
      }
    },
    async getDocumento({commit}, data){
      try {
        const response = await PaacServices.downloadDocumentoProcesoCompra(data)
        if(response.status === 200){
          return response.data
        }else{
          return false
        }
      } catch (error) {
        return error.response
      }
    }
  }
}

export default etapaAperturaOfertaStore