export default {
  setListaOrganismos(state, payload) {
    state.listadoOrganismos = payload;
  },
  setPaginationData(state, payload) {
    state.filters.page = Number(payload.page);
    state.filters.per_page = Number(payload.per_page);
    state.filters.total_rows = Number(payload.total_rows);
  },
};
