import defaultState from "./state";

export const ResetStateMt = (state) => {
  Object.assign(state, defaultState())
}

export const SetListSolicMt = (state, payload_param) => {
  state.list_solicitudes_st = payload_param;
}

export const SetPagSolicMt = (state, payload_param) => {
  state.pag_solicitudes_st.page = Number(payload_param.page)
  state.pag_solicitudes_st.per_page = Number(payload_param.per_page)
  state.pag_solicitudes_st.total_rows = Number(payload_param.total_rows)
}

export const SetDetailSolicMt = (state, payload_param) => {
  payload_param?.solicitud_compra_obs?.forEach((element, index) => {
    element.correlativo = index + 1;
  });

  state.list_insumos_st = payload_param?.solicitud_compra_obs;
  state.unidad_solicitud_st = payload_param?.detalle_unidad;
}

export const SetListEspecificacionesMt = (state, payload_param) => {
  state.list_especificaciones_st = payload_param;
}

export const SetPagEspecificacionesMt = (state, payload_param) => {
  state.pag_especificaciones_st.page = Number(payload_param.page)
  state.pag_especificaciones_st.per_page = Number(payload_param.per_page)
  state.pag_especificaciones_st.total_rows = Number(payload_param.total_rows)
}

export const SetListPreciosMt = (state, payload_param) => {
  state.list_precios_st = payload_param;
}

export const SetPagPreciosMt = (state, payload_param) => {
  state.pag_precios_st.page = Number(payload_param.page)
  state.pag_precios_st.per_page = Number(payload_param.per_page)
  state.pag_precios_st.total_rows = Number(payload_param.total_rows)
}

export const SetListAdministradoresMt = (state, payload_param) => {
  state.list_administradores_st = payload_param;
}

export const SetPagAdministradoresMt = (state, payload_param) => {
  state.pag_administradores_st.page = Number(payload_param.page)
  state.pag_administradores_st.per_page = Number(payload_param.per_page)
  state.pag_administradores_st.total_rows = Number(payload_param.total_rows)
}

export const SetListFinanciamientosMt = (state, payload_param) => {
  state.list_financiamientos_st = payload_param;
}

export const SetPagFinanciamientosMt = (state, payload_param) => {
  state.pag_financiamientos_st.page = Number(payload_param.page)
  state.pag_financiamientos_st.per_page = Number(payload_param.per_page)
  state.pag_financiamientos_st.total_rows = Number(payload_param.total_rows)
}

export const ResetEspecificacionesMt = (state) => {
  state.list_especificaciones_st = [];
  state.pag_especificaciones_st.page = 1;
  state.pag_especificaciones_st.per_page = 10;
  state.pag_especificaciones_st.total_rows = 0;
}

export const ResetPreciosMt = (state) => {
  state.list_precios_st = [];
  state.pag_precios_st.page = 1;
  state.pag_precios_st.per_page = 10;
  state.pag_precios_st.total_rows = 0;
}

export const ResetAdministradoresMt = (state) => {
  state.list_administradores_st = [];
  state.pag_administradores_st.page = 1;
  state.pag_administradores_st.per_page = 10;
  state.pag_administradores_st.total_rows = 0;
}

export const ResetFinanciamientosMt = (state) => {
  state.list_financiamientos_st = [];
  state.pag_financiamientos_st.page = 1;
  state.pag_financiamientos_st.per_page = 10;
  state.pag_financiamientos_st.total_rows = 0;
}