const conveniosMarcoProveedorRoutes = [
  {
    path: "/listado-convenios-proveedor",
    name: "listado-convenios-proveedor",
    component: () =>
      import(
        /* webpackChunkName: "conveniosProveedor" */ "./conveniosMarcoView.vue"
      ),
  },
  {
    path: "/detalle-convenios-proveedor/:id",
    name: "detalle-convenios-proveedor",
    component: () =>
      import(
        /* webpackChunkName: "detalleConvenio" */ "./informacionConvenioView.vue"
      ),
  },
  {
    path: "/ofertar-producto/:id",
    name: "ofertar-producto",
    component: () =>
      import(/* webpackChunkName: "ofertarProducto" */ "./ofertarProductoView.vue"),
  },
];

export default conveniosMarcoProveedorRoutes;
