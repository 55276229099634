const routesModalidades = [
  {
    path: "/configuracion-proceso-compra",
    name: "configuracion-proceso-compra",
    component: () =>
      import(
        /* webpackChunkName: "ConfiguracionProcesoCompracpp" */ "./listView"
      ),
  },
  {
    path: "/configuracion-proceso-compra/:id_modalidad/detalle",
    name: "detalle-configuracion-proceso-compra",
    component: () =>
      import(
        /* webpackChunkName: "ConfiguracionProcesoCompraDetalle" */ "./detalleModalidadView"
      ),
  },
  {
    path: "/configuracion-hito/:idModalidad/:idHito?",
    name: "configuracion-hito",
    component: () =>
      import(
        /* webpackChunkName: "configuracionHitoView" */ "./configuracionHitoView"
      ),
  },
];

export default routesModalidades;
