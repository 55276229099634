<template>
  <section class="justify-start">
    <v-card height="500px" style="overflow: auto">
      <v-card-text>
        <v-card-title>
          <p class="secondary--text">{{ title }}</p>
        </v-card-title>
        <v-card-text>
          <p class="mb-0">Fecha y hora de inicio</p>
          <p class="text-subtitle-1 font-weight-bold">{{ fecha_inicio }}</p>
          <p class="mb-0">Fecha y hora de fin</p>
          <p class="text-subtitle-1 font-weight-bold">{{ fecha_fin }}</p>
          <p class="mb-0">Cantidad de días</p>
          <p class="text-subtitle-1 font-weight-bold">{{ dias }}</p>
          <p class="mb-0">Observaciones</p>
          <p
            class="text-subtitle-1 font-weight-bold"
            v-if="observaciones === null || observaciones === ''"
          >
            No hay observaciones
          </p>
          <p class="text-subtitle-1 font-weight-bold" v-else>
            {{ observaciones }}
          </p>
          <slot name="content" />
        </v-card-text>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "CardLiquidacionComponent",
  props: {
    title: {
      type: String,
    },
    fecha_inicio: {
      type: String,
    },
    fecha_fin: {
      type: String,
    },
    dias: {
      type: String | Number,
    },
    observaciones: {
      type: String,
      default: "No hay observaciones",
    },
  },
};
</script>

<style></style>
