<template>
    <Fragment>
        <div style="position: fixed !important; right: 0 !important; z-index: 9999;">
            <v-fade-transition v-for="(appMessage, index) in app_messages" :key="index">
                <v-snackbar
                    v-bind="sharedProps"
                    :timeout="appMessage.timeout"
                    :value="true"
                    :color="appMessage.type"
                >
                    <v-icon :color="appMessage.type" left>
                        {{ getErrorIcon(appMessage.type) }}
                    </v-icon>
                    <span class="body-1">
                        <strong> {{ appMessage.message }}</strong>
                    </span>
    
                    <template #action="{ attrs }">
                        <v-btn v-bind="attrs" icon right @click="dismiss(appMessage)">
                            <v-icon small>mdi-close</v-icon>
                        </v-btn
                    >
                  </template>
                </v-snackbar>
            </v-fade-transition>
        </div>
    </Fragment>
</template>
<script>
import { mapState } from 'vuex';
import { Fragment } from 'vue-fragment';
import Vue from "vue";

export default {
    name: 'AppMessagesQueue',
    components: { Fragment },
    computed: {
        ...mapState("utils", ["app_messages"]),
        sharedProps () {
            return {
                top: true,
                style: "position: relative !important; height: auto;",
                "z-index": "11",
                text: true,
                tile: true,
                elevation: "1",
                right: true,
            };
        },
    },
    methods: {
        dismiss(appMessage) {
            Vue.prototype.dismissAppMessage(appMessage);
        },
        getErrorIcon(type) {
            switch(type) {
                case "success":
                    return 'mdi-check-circle';
                case "error":
                    return 'mdi-alert-octagon';
                case "warning":
                    return 'mdi-alert';
                case "info":
                    return 'mdi-information';
            };
        },
    },
}
</script>