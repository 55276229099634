const routesBajaCuantia = [
    {
      path: "/admin-fondos-baja-cuantia",
      name: "admin-fondos-baja-cuantia",
      component: () =>
        import(
          /* webpackChunkName: "adminFondosBajaCuantia" */ "./adminFondosBajaCuantiaView.vue"
        ),
    },
    {
      path: "/list-fondos-baja-cuantia",
      name: "list-fondos-baja-cuantia",
      component: () =>
        import(
          /* webpackChunkName: "conveniosProveedor" */ "./fondosBajaCuantiaView.vue"
        ),
    },
    {
      path: "/revision-fondos-baja-cuantia",
      name: "revision-fondos-baja-cuantia",
      component: () =>
        import(
          /* webpackChunkName: "revisionFondosBajaCuantia" */ "./revisionFondosBajaCuantia.vue"
        ),
    },
    {
      path: "/revision-registros-fondo-baja-cuantia/:id_fondo",
      name: "revision-registros-fondo-baja-cuantia",
      component: () =>
        import(
          /* webpackChunkName: "revisionRegistrosFondoBajaCuantia" */ "./revisionRegistrosFondoBajaCuantia.vue"
        ),
    },
  ];
  
  export default routesBajaCuantia;