const routeSolicitudLinea = [
    {
        path: "/solicitud-compra-linea",
        name: "solicitud-compra-linea",
        component: () =>
            import(/* webpackChunkName: "solicitudList" */ "./solicitudCompraLineaView.vue"),
    },
    {
        path: "/solicitud-compra-linea/:id_compra_linea",
        name: "crear-compra-linea",
        component: () =>
            import(/* webpackChunkName: "solicitudList" */ "./CrearSolicitudCompraLineaView.vue"),
    },
    {
        path: "/solicitud-compra-linea/:id_solicitud/agregar-insumo",
        name: "agregar-insumo-compra-linea",
        component: () =>
            import(/* webpackChunkName: "agregarInsumo" */ "./addInsumoCLView.vue"),
    },
    {
        path: "/solicitud-compra-linea/:id_solicitud_compra_linea/detalle-insumo/:id_detalle",
        name: "editar-insumo-compra-linea",
        component: () =>
            import(/* webpackChunkName: "editarInsumo" */ "./editarInsumoCLView.vue"),
    }
]

export default routeSolicitudLinea;