import pathServices from "@/services/Path.services";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    /** @deprecated usar app_messages */
    alert: {
      show: false,
      type: "info",
      message: "",
      timeout: 2500,
    },
    loader: false,
    sideBar: true,
    menu: [],
    rutas: [],
    drawer: true,
    global_status: null,
    cartDrawer: false,
    // Multiple snackbars messages
    app_messages: [],
    messageId: 0,
  },
  getters: {
    getAlert: (state) => {
      return state.alert;
    },
  },
  mutations: {
    setDrawer(state, payload) {
      state.drawer = payload;
    },
    setAlert: (state, payload) => {
      state.alert = payload;
    },
    setLoader: (state, payload) => {
      state.loader = payload;
    },
    setSideBar: (state, payload) => {
      state.sideBar = payload;
    },
    setMenu: (state, menu) => {
      state.menu = menu;
    },
    setRutas: (state, rutas) => {
      state.rutas = rutas;
    },
    setGlobalStatus: (state, status) => {
      state.global_status = status;
    },
    setCartDrawer(state, payload) {
      state.cartDrawer = payload;
    },
    pushAppMessage(state, message) {
      const baseTimeout = 4000;
      const messageId = message?.id ?? state.messageId++;
      const timeout = message.timeout ?? baseTimeout + state.app_messages.length * 200;

      const timeoutId = setTimeout(() => {
        state.app_messages = state.app_messages.filter((msg) => msg.id !== messageId);
      }, timeout);

      state.app_messages.push({ ...message, id: messageId, timeout, timeoutId });
    },
    dismissAppMessage(state, appMessage) {
      clearTimeout(appMessage.timeoutId);
      state.app_messages = state.app_messages.filter((msg) => msg.id !== appMessage.id);
    },
  },
  actions: {
    setDrawer({ commit }, payload) {
      commit("setDrawer", payload);
    },
    toggleDrawer({ commit }) {
      commit("setDrawer", !this.state.utils.drawer);
    },
    showDrawer({ commit }) {
      commit("setDrawer", true);
    },
    hideDrawer({ commit }) {
      commit("setDrawer", false);
    },
    getMenu: async ({ commit }) => {
      const response = await pathServices.getAuthorizedPaths();
      const rutas = JSON.parse(JSON.stringify(response.data));
      const menu = getNavItem(response?.data);

      commit("setMenu", menu);
      commit("setRutas", rutas);
      Vue.prototype.hideLoader()
    },
    clearMenu: ({ commit }) => {
      commit("setMenu", []);
      commit("setRutas", []);
    },
    setCartDrawer({ commit }, payload) {
      commit('setCartDrawer', payload);
    },
  },
};
const getNavItem = (items) => {
  return items.filter((item) => {
    if (!item.mostrar) return false;
    if (item.childrens?.length > 0) item.childrens = getNavItem(item.childrens);
    return true;
  });
};
