import EstructurasPresupuestariasServices from "../../../../../services/EstructurasPresupuestarias.services";

export default {
  async getListaEstructuras({ commit }, filters = {},) {
    const response = await EstructurasPresupuestariasServices.getEstructuras(filters);
    if (response.status === 200) {
      commit("setListaEstructuras", response?.data);
      commit("setPaginationData", response?.headers);
    } else {
      commit("setListaEstructuras", []);
    }
  },

  async getCtlEstructuraPresupuestaria({ commit }) {
    const response = await EstructurasPresupuestariasServices.getEstructuras({ pagination: false });
    if (response.status === 200) {
      commit("setCtlEstructuraPresupuestaria", response?.data);
    }
  },

  async storeEstructura({ commit }, data) {
    const response = await EstructurasPresupuestariasServices.storeEstructura(
      data
    );
    return response;
  },

  async updateEstructura({ commit }, data) {
    const response = await EstructurasPresupuestariasServices.updateEstructura(
      data
    );
    return response;
  },
  async deleteEstructura({ commit }, id) {
    const response = await EstructurasPresupuestariasServices.deleteEstructura(
      id
    );
    return response;
  },
};
