import { createLoadable, isResponseSuccesful, resetearLoadable, setLoadableResponse, toggleLoadable } from "@/utils/loadable";
import VerificacionDocumentosOdcServices from "@/services/VerificacionDocumentosOdc.services";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        apartados: createLoadable([]),
        informacion: createLoadable(null),
        openKeysArbolApartado: new Map(),
        interaccionesEnCache: new Map(),
        oficialSuspendido: false,
    },
    mutations: {
        empezarPeticionHttpApartados(state) {
            toggleLoadable(state.apartados);
        },
        manejarRespuestaHttpApartados(state, payload) {
            setLoadableResponse(state.apartados, payload);
        },
        empezarPeticionHttpInformacionOdc(state) {
            toggleLoadable(state.informacion);
        },
        manejarRespuestaHttpInformacionOdc(state, payload) {
            setLoadableResponse(state.informacion, payload);

            if (isResponseSuccesful(payload)) {
                const esAdministradorDinac = Vue.prototype.haveRoles(['ROLE_VERIFICACION_NOMBRAMIENTO_ODC_DINAC']);
                state.oficialSuspendido = !esAdministradorDinac && payload.data.usuario.es_oficial_de_cumplimiento_suspendido;
            } else {
                state.oficialSuspendido = false;
            }
        },
        actualizarOpenKeysArbolApartados(state, payload) {
            const { key, value } = payload;

            state.openKeysArbolApartado.set(key, value);
        },
        guardarInteraccionesEnCache(state, payload) {
            const { key, interacciones } = payload;
            state.interaccionesEnCache.set(key, interacciones);
        },
        resetearEstadoOficialCumplimiento(state) {
            resetearLoadable(state.apartados, []);
            resetearLoadable(state.informacion, null);
            state.openKeysArbolApartado = new Map();
            state.interaccionesEnCache = new Map();
            this.oficialSuspendido = false;
        },
    },
    actions: {
        async cargarApartados({ commit }, idOficialCumplimiento) {
            commit("empezarPeticionHttpApartados");
            const { data } = await VerificacionDocumentosOdcServices.cargarDocumentosOficialCumplimiento(idOficialCumplimiento);
            commit('manejarRespuestaHttpApartados', data);
        },
        async cargarInformacionOdc({ commit, state }, idOficialCumplimiento) {
            if (state.informacion.isLoading) return;
            
            commit("empezarPeticionHttpInformacionOdc");
            const { data } = await VerificacionDocumentosOdcServices.cargarInformacionOficialCumplimiento(idOficialCumplimiento);
            commit('manejarRespuestaHttpInformacionOdc', data);
        },
    },
};
