export default [
  {
    path: "/solicitudes-aprobacion-UACI",
    name: "solicitudes-aprobacion-UACI",
    component: () =>
      import(
        /* webpackChunkName: "orden de compra" */ "./listaAprobacionUACIView.vue"
      ),
  },
  {
    path: "/creacion-orden-compra",
    name: "creacion-orden-compra",
    component: () =>
      import(
        /* webpackChunkName: "orden de compra" */ "./creacionOrdenCompraView.vue"
      ),
  },
  {
    path: "/lista-proveedores",
    name: "lista-proveedores",
    component: () =>
      import(
        /* webpackChunkName: "orden de compra" */ "./listaProveedoresView.vue"
      ),
  },
  {
    path: "/detalles-proveedor",
    name: "detalles-proveedor",
    component: () =>
      import(
        /* webpackChunkName: "orden de compra" */ "./components/DetalleProveedorComponent.vue"
      ),
  },
  {
    path: "/detalles-insumo",
    name: "detalles-insumo",
    component: () =>
      import(
        /* webpackChunkName: "orden de compra" */ "./components/DetalleInsumoComponent.vue"
      ),
  },
]