const cdf = [
    {
        path: "/certificacion-fondos",
        name: "listado-certificacion-fondos",
        component: () =>
            import(/* webpackChunkName: "certificacionList" */ "./cdfListadoView.vue")
    },
    {
        path: "/certificacion-fondos/detalle/:idProceso",
        name: "detalle-certificacion-fondos",
        component: () =>
            import(/* webpackChunkName: "certificacionDetalle" */ "./emisionCDFView.vue")
    },
    {
        path: "/certificacion-fondos/detalle/:idProceso/detalle/:idCifrado",
        name: "detalle-cifrado-certificacion-fondos",
        component: () =>
            import(/* webpackChunkName: "certificacionCifradoDetalle" */ "./detalleCertificacionFondoView.vue")
    },
    {
        path: "/certificacion-fondos/catalogo",
        name: "certificacion-fondos-catalogo",
        component: () =>
            import(/* webpackChunkName: "certificacionFondoCatalogo" */ "./certificacionFondoCatalogo.vue")
    },
    {
        path: "/certificar-fondos/:id_proceso",
        name: "certificar-fondos",
        component: () =>
            import(/* webpackChunkName: "CertificarFondos" */ "./certicarFondos.vue")
    },
]

export default cdf