import { http_client } from "@/plugins/http_client";

const postObsProvider = async (params = {}) => {
  return await http_client("/api/v1/obs/proveedores", params, "post");
};

const getObsProvider = async (params = {}, id_proveedor) => {
  let url = id_proveedor
    ? `/api/v1/proveedores/obs/${id_proveedor}`
    : "/api/v1/proveedores/obs";
  return await http_client(url, params, "get");
};

const getProductoProveedor = async (id_obs, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/productos/${id_obs}`,
    params,
    "get"
  );
};

const deleteObsProvider = async (id) => {
  return await http_client(`/api/v1/obs/proveedores/${id}`, {}, "delete");
};

export default {
  postObsProvider,
  getObsProvider,
  deleteObsProvider,
  getProductoProveedor,
};
