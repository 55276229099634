import { http_client } from "@/plugins/http_client";

const url = "/api/v1/instituciones/estructuras";
const url2 = "/api/v1/instituciones/configuraciones";
const urlBase = '/api/v1/';

const getEstructuras = async (params = {}) =>
  await http_client(url, params, "get");
const storeEstructura = async (body) => await http_client(url, body, "post");
const getEstructura = async (id) => await http_client(`${url}/${id}`);
const updateEstructura = async (id, body) =>
  await http_client(`${url}/${id}`, body, "put");
const deleteEstructura = async (id) =>
  await http_client(`${url}/${id}`, {}, "delete");

const getEstructuraFinanciamientos = async (idEstructura, params = {}) =>
  await http_client(`${url}/${idEstructura}/financiamientos`, params, "get");

const storeConfiguracionFinanciamiento = async (body) =>
  await http_client(`${url2}/financiamientos`, body, "post");

const deleteEstructuraFinanciamientos = async (idEstructura, idFuente) =>
  await http_client(
    `${url}/${idEstructura}/financiamientos/${idFuente}`,
    {},
    "delete"
  );

const storeConfiguracionFinanciamientoLinea = async (idUnidad, body) =>
  await http_client(
    `${url2}/financiamientos/unidades/${idUnidad}`,
    body,
    "post"
  );

const getFinanciadores = async (payload) => {
  return await http_client(`${urlBase}instituciones/financiadores`, payload, "get");
}

const deleteConfiguracionFinanciamiento = async (idFntoLineaInstitucion) => {
  return await http_client(`${url2}/${idFntoLineaInstitucion}`, {}, "delete")
}

export default {
  getEstructuras,
  storeEstructura,
  getEstructura,
  updateEstructura,
  deleteEstructura,
  getEstructuraFinanciamientos,
  storeConfiguracionFinanciamiento,
  deleteEstructuraFinanciamientos,
  storeConfiguracionFinanciamientoLinea,
  getFinanciadores,
  deleteConfiguracionFinanciamiento
};
