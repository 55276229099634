import { http_client, http_client_with_status } from "@/plugins/http_client";

const verificarDocumento = async(document_number = {}, params = {}) =>{
    try {
        const response = await http_client(`/api/v1/personas/${document_number}/verificacion`,params,"get")
        return response
    } catch (error) {
        return error.response
    }
}

const buscarPersonaPorDocumento = (numeroDocumento, params) => http_client_with_status(`/api/v1/personas/buscar-persona/${numeroDocumento}`, params, 'get')

const buscarInformacionCompletaPorDocumento = (numeroDocumento, params) => 
    http_client_with_status(`/api/v1/personas/buscar-persona/informacion-completa/${numeroDocumento}`, params, 'get')

export default{
    verificarDocumento,
    buscarPersonaPorDocumento,
    buscarInformacionCompletaPorDocumento,
}
