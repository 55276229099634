import OrganismosServices from "../../../../../services/Organismos.services";

export default {
  async getListaOrganismos({ commit }, filters = {}) {
    filters.id_tipo_financiacion = 1;
    const response = await OrganismosServices.getOrganismos(filters);
    if (response.status === 200) {
      commit("setListaOrganismos", response?.data);
      commit("setPaginationData", response?.headers);
    } else {
      commit("setListaOrganismos", []);
    }
  },

  async storeOrganismo({ commit }, data) {
    const response = await OrganismosServices.storeOrganismo(data);
    return response;
  },

  async updateOrganismo({ commit }, data) {
    const response = await OrganismosServices.updateOrganismo(data);
    return response;
  },
  async deleteOrganismo({ commit }, id) {
    const response = await OrganismosServices.deleteOrganismo(id);
    return response;
  },
};
