<template>
    <v-alert
        v-if="alertMeta"
        dense
        :type="alertMeta.type"
    >
        {{ alertMeta.text }}
        <b @click.stop="setCartDrawer" style="cursor: pointer; text-decoration: underline;">aqui.</b>
    </v-alert>
</template>
<script>
import { mapMutations } from "vuex";
import { mapState } from 'vuex';

const timerStatus = {
    'empty-request': {
        type: 'success',
        text: 'No tienes una solicitud en progreso, crea una',
    },
    'expired': {
        type: 'error',
        text: ' La solicitud actual ha expirado, para cerrar la solicitud haz click ',
    },
}

export default {
    name: 'RequestStatusFeedbackComponent',
    methods: {
        ...mapMutations("utils", ["setCartDrawer"])
    },
    computed: {
        ...mapState("catalogoEnLinea", ["timer"]),
        alertMeta() {
            return timerStatus[this.timer.status];
        },
    },
};
</script>