export default () => ({
  dataSolicitudes: [],
  dataUsuarios: [],
  dataUsuaariosCreados: [],
  rolUsuario: null,
  itemSelected: {},
  totalPaginas: 0,
  buscarSolicitud: "",
  showEditarUsuario: false,
  dataUsuario: null,
  profesiones: [],
  perfiles: [],
  instituciones: [],
  loadingUsers: false,
});
