<template>
  <div class="text-center mt-3">
    <div class="d-flex justify-space-between flex-column flex-sm-row mb-6">
      <div class="mx-sm-0 mx-auto file_inputs_filter">
        <v-select
          :items="selectItem"
          dense
          solo
          @input="someItem()"
          v-model="itemsPerPage"
        />
      </div>
      <p class="text--gray mt-0 mt-sm-5 pagination_inputs">
        {{ showRestElements }} registros de {{ totalElements }}
      </p>
      <v-pagination
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        @input="nextItem()"
        :total-visible="visiblePerPage"
        v-model="page"
        :length="totalPageInfo"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppPaginationComponent",
  props: {
    selectItem: {
      type: Array,
      default: () => [5, 10, 25, 50, 100],
    },
    totalElements: {
      type: Number,
      default: 0,
    },
    reset: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    page: 1,
    itemsPerPage: 10,
  }),
  watch: {
    reset(val) {
      this.nextItem(val);
    },
  },
  computed: {
    visiblePerPage() {
      let value = (this.$vuetify.breakpoint.sm) ? 5 : 10;
      return value;
    },
    totalPageInfo() {
      return Math.ceil(this.totalElements / this.itemsPerPage);
    },
    showRestElements() {
      const restos = this.itemsPerPage * this.page;
      if (restos <= this.totalElements) return restos;
      else return restos - (restos - this.totalElements);
    },
  },
  methods: {
    nextItem(e = false) {
      if (e) this.page = 1;
      this.$emit("cantidadPage", {
        cantidad_por_pagina: this.itemsPerPage,
        pagina: this.page,
      });
    },
    someItem() {
      this.page = 1;
      this.$emit("changePage", {
        cantidad_por_pagina: this.itemsPerPage,
        pagina: this.page,
      });
    },
  },
  mounted() {
    // this.itemsPerPage = this.selectItem[0];
  },
};
</script>

<style scoped>
.file_inputs_filter {
  width: 100px;
}
.pagination_inputs {
  font-size: 14px;
  font-weight: 500 !important;
}
@media only screen and (max-width: 600px) {
  .file_inputs_filter {
    width: 100%;
  }
  .pagination_inputs {
    width: 100%;
  }
}
</style>