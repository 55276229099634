import getUnidades from "../../../services/Unidades.services";
import Instituciones from "../../../services/Instituciones.services";
import Financiamientos from "../../../services/Financiamientos.services";
import LineasTrabajosServices from "../../../services/LineasTrabajos.services";

export const setingsRecords = async ({ commit }, filtros = {}) => {
  const unidadesRecords = await getUnidades.getUnidades(filtros);
  let correlativo = 0;
  if (unidadesRecords?.status === 200) {
    const datosUnidades = unidadesRecords?.data
    const cabecera = unidadesRecords?.headers;

    if (cabecera?.page !== "1") {
      correlativo = Number(cabecera.per_page) * Number(cabecera.page - 1);
    }
    datosUnidades.forEach(record => {
      correlativo += 1;
      record.correlativo = correlativo;
    })
    commit("setUnidades", datosUnidades);
    commit("setPaginationData", cabecera);
  }
};

export const getInstituciones = async ({ commit }, filters) => {
  const response = await Instituciones.getInstituciones(filters);
  if (response.status === 200) {
    commit("setInstituciones", response.data);
    commit("setPaginationData", response.headers);
  } else {
    commit("setInstituciones", []);
  }
};

export const getInstitucion = async ({ commit }, id) => {
  return await Instituciones.getInstitucion(id);
};

export const getFinanciamientos = async ({ commit }, filters = {}) => {
  const response = await Financiamientos.getFinanciamientos(filters);
  if (response.status === 200) {
    commit("setFinanciamientos", response?.data);
    commit("setPaginationData", response?.headers);
  } else {
    commit("setFinanciamientos", []);
  }
};

export const guardarUnidades = async ({ commit }, data) => {
  const response = await getUnidades.postUnidadesNewData(data);
  return response;
};

export const getLineaTrabajo = async ({ commit, state }, payload) => {
  const response = await getUnidades.getLineasUnidad(
    payload.id_unidad,
    payload.id_linea_trabajo,
    state.filterCards
  );
  if(response?.data[0]?.unidad){
    state.dataUnidad.institucion = response?.data[0]?.unidad?.instituciones?.nombre
    state.dataUnidad.unidad = response?.data[0]?.unidad?.nombre
  }
  commit("setLineaTrabajoSelct", response?.data);
};

export const agregarObsLineaTrabajoUnidad = async ({ commit }, datos) => {
  const response = await LineasTrabajosServices.agregarObsLineaTrabajoUnidad(
    datos
  );
  return response;
};

export const editarLineaUnidad = async ({ commit }, datos) => {
  const response = await LineasTrabajosServices.editarLineaTrabajoUnidad(
    datos.id_linea_unidad,
    datos.payload
  );
  return response;
};

export const eliminarLineaTrabajoUnidad = async ({ commit }, idLineaUnidad) => {
  const response = await LineasTrabajosServices.eliminarLineaTrabajoUnidad(
    idLineaUnidad
  );
  return response;
};

export const editarObsLineaTrabajo = async ({ commit }, datos) => {
  const response = await LineasTrabajosServices.editarObsLineaTrabajo(
    datos.id_linea_obs,
    datos.payload
  );
  return response;
};

export const eliminarObsLineaTrabajo = async ({ commit }, idObs) => {
  const response = await LineasTrabajosServices.eliminarObsLineaTrabajo(idObs);
  return response;
};

export const getHistorialMovimientos = async ({ commit }, id_linea) => {
  const RESP_SC_CONST = await getUnidades.getHistorialLinea(id_linea);
  if (RESP_SC_CONST) {
      const { data } = RESP_SC_CONST;
      commit("setHistorialMovimientos", data);
  }
}
