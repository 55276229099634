const asignacionCDP = [
    {
        path: "/cm-creacion-convenio",
        name: "cm-creacion-convenio",
        component: () =>
            import(
                /* webpackChunkName: "creacionConvenio" */ "./main.vue"
                ),
    },
    {
        path: "/cm-list-convenio",
        name: "cm-list-convenio",
        component: () =>
            import(
                /* webpackChunkName: "listConveio" */ "./list.vue"
                ),
    }
];

  export default asignacionCDP;
