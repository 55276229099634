export const setConsultas = (state, payload) => {
  state.consultas = payload;
};

export const setIdProcesoCompra = (state, payload) => {
  state.id_proceso_compra = payload;
}

export const setIsLoadingAgregar = (state, payload) => {
  state.isLoadingAgregar = payload;
}

export const setIsLoadingRechazar = (state, payload) => {
  state.isLoadingRechazar = payload;
}

