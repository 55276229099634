const routesCompraPorEmergencia = [
    {
      path: "/administracion-compra-por-emergencia",
      name: "administracion-compra-por-emergencia",
      component: () =>
        import(
          /* webpackChunkName: "administracionCompraPorEmergencia" */ "./administracionCompraPorEmergencia.vue"
        ),
    },
    { 
      path: "/fondos-compra-por-emergencia",
      name: "fondos-compra-por-emergencia",
      component: () =>
        import(
          /* webpackChunkName: "fondosCompraPorEmergencia" */ "./fondosCompraPorEmergencia.vue"
        ),
    },
    // {
    //   path: "/revision-fondos-compra-por-emergencia",
    //   name: "revision-fondos-compra-por-emergencia",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "revisionFondosCompraPorEmergencia" */ "./revisionFondosCompraPorEmergencia.vue"
    //     ),
    // },
    // {
    //   path: "/revision-registros-fondo-compra-por-emergencia/:id_fondo",
    //   name: "revision-registros-fondo-compra-por-emergencia",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "revisionRegistrosCompraPorEmergencia" */ "./revisionRegistrosFondoCompraPorEmergencia.vue"
    //     ),
    // },
  ];
  
  export default routesCompraPorEmergencia;