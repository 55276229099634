<template>
    <Fragment>
      <v-data-table 
        v-if="!amounts.isLoading" 
        hide-default-footer 
        :headers="headers" 
        :items="data" light 
        >

        <template v-slot:[`item.monto_maximo_compra`]="{ item }">
          $ {{ item.monto_maximo_compra }}
        </template>
        <template v-slot:[`item.monto_maximo_emergencia`]="{ item }">
          $ {{ item.monto_maximo_emergencia }}
        </template>
        <template v-slot:[`item.monto_minimo_compra`]="{ item }">
          $ {{ item.monto_minimo_compra }}
        </template>
        <template v-slot:[`item.monto_minimo_compra_eme`]="{ item }">
          $ {{ item.monto_minimo_compra_eme }}
        </template>
        <template v-slot:[`item.monto_minimo_envio`]="{ item }">
          $ {{ item.monto_minimo_envio }}
        </template>
        <template v-slot:[`item.monto_minimo_envio_eme`]="{ item }">
          $ {{ item.monto_minimo_envio_eme }}
        </template>
      </v-data-table>
    </Fragment>
</template>
<script>
import { createLoadable, toggleLoadable, setLoadableResponse }  from '@/utils/loadable';
import { Fragment } from 'vue-fragment';

export default {
    name: 'RequestAmountsFeed',
    components: { Fragment },
    props: {
        isEmergencyRequest: { type: Boolean, },
    },
    data: () => ({
        amounts: createLoadable(null),
        fields: [
            { text: 'Monto máximo emergencia', value: 'monto_maximo_emergencia', emergency: true },
            { text: 'Monto mínimo de compra en emergencia', value: 'monto_minimo_compra_eme', emergency: true },
            { text: 'Monto mínimo de envio en emergencia', value: 'monto_minimo_envio_eme', emergency: true },
            { text: 'Monto mínimo de compra', value: 'monto_minimo_compra', emergency: false, },
            { text: 'Monto máximo de compra', value: 'monto_maximo_compra', emergency: false, },
            { text: 'Monto mínimo de envio', value: 'monto_minimo_envio',  emergency: false },
        ],
    }),
    computed: {
        headers() {
            const activeHeaders = this.fields.filter((field) => field.emergency === this.$props.isEmergencyRequest);

            return activeHeaders.map((field) => ({ ...field, align: 'center', sortable: false,  }));
        },
        data() {
            return this.amounts.data ? [this.amounts.data] : [];
        },
    },
    methods: {
        async fetchAmounts() {
            toggleLoadable(this.amounts);
            const { data } = await this.services.PurchaseRequest.getRequestAmounts();
            setLoadableResponse(this.amounts, data);
        },
    },
    created() {
        this.fetchAmounts();
    },
};
</script>
