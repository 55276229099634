import {http_client} from "@/plugins/http_client";

const getTour = async(params ={})=>{
    return await  http_client("/api/v1/tour/",params,"get")
}



const postTourGuiado = async(body ={})=>{
  return await  http_client("/api/v1/tour/", body, "post")
}



export default{
    getTour,
    postTourGuiado,
}