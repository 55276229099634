import employeeServices from "../../../../services/Empleados.services";
import agreementMarcoServices from "../../../../services/ConvenioMarco.services";
import ObsServices from "../../../../services/Obs.services";
import agreementDocumentTypeService from "../../../../services/ConvenioTipoDocumento.services";
import AgreementMarco from "../../../../services/ConvenioMarco.services";
import phase from "../../../../services/Fases.services";

export const saveIdAgreementMarco = ({ commit }, id) => {
  commit("setIdAgreementMarco", id);
};

export const getAgreementMarco = async ({ commit }, id) => {
  const response = await agreementMarcoServices.getAgreementMarcoById(id);
  if (response.status === 200) {
    commit("setAgreement", response.data);
  }
};

export const postAgreementsMarco = async ({ commit, state }) => {
  const response = await agreementMarcoServices.postAgreementsMarco(
    state.general_data
  );
  if (response.status === 201 || response.state === 200) {
    commit("setIdAgreementMarco", response.data.id);
  }
  return response;
};

export const putAgreementMarco = async ({ commit }, params) => {
  const response = await agreementMarcoServices.putAgreementMarco(
    params.id,
    params.agreement
  );
  commit("setIdAgreementMarco", response.data.id);
  return response;
};

export const postAgreementObs = async ({ commit }, params) => {
  const response = await agreementMarcoServices.postAgreementObs(
    params.id,
    params.data
  );
  return response;
};

export const deleteAgreementObs = async ({ commit }, id) => {
  const response = await agreementMarcoServices.deleteAgreementObs(id);
  return response;
};

export const postAgreementDocument = async ({ commit }, params) => {
  const data = new FormData();
  data.append("id_tipo", params.data.id_tipo);
  data.append("document", params.data.documento);
  const response = await agreementMarcoServices.postAgreementDocument(
    params.id,
    data
  );
  return response;
};

export const getDocumento = async({commit}, params) => {
  try {
    const response = await agreementMarcoServices.getDocumento(params)
    if(response.status === 200){
      return response.data
    }else{
      return false
    }
  } catch (error) {
    return error.response
  }
}

export const deleteAgreementDocument = async ({ commit }, id) => {
  const response = await agreementMarcoServices.deleteAgreementDocument(id);
  return response;
};

export const getEmpleados = async ({ commit }, filters = {}) => {
  const response = await employeeServices.getEmployees(filters);
  if (response.status === 200) {
    commit("setListaEmpleados", response.data);
  }
};

export const getDocumentType = async ({ commit }, filters = {}) => {
  const response = await agreementDocumentTypeService.getAgreementDocumentType(
    filters
  );
  if (response.status === 200) {
    commit("setListaDocumentType", response.data);
  }
};

export const getObsList = async ({ commit }, filters = {}) => {
  const response = await ObsServices.getObsList(filters);
  if (response.status === 200) {
    commit("setObsList", response.data);
  }
};

export const getObsAgreementList = async ({ commit }, id_agreement) => {
  const response = await agreementMarcoServices.getAgreementObs(id_agreement);
  if (response.status === 200) {
    response.data.forEach((c, i) => {
      return (c.correlativo = i + 1);
    });
    commit("setObsAgreementList", response.data);
  }
};
export const getDocumentAgreementList = async ({ commit }, id_agreement) => {
  const response = await agreementMarcoServices.getAgreementDocuments(
    id_agreement
  );
  if (response.status === 200) {
    commit("setDocumentAgreementList", response.data);
  }
};

export const getPhasesAgreementList = async ({ commit }, id_agreement) => {
  const response = await agreementMarcoServices.getAgreementPhases(
    id_agreement
  );
  if (response.status === 200) {
    commit("setPhasesAgreementList", response.data);
  }
};

export const postPhaseAgreement = async ({ commit }, params) => {
  const response = await agreementMarcoServices.postAgreementPhases(
    params.id_agreement,
    params.id_phase,
    params.data
  );
  return response;
};

export const putPhaseAgreement = async ({ commit }, params) => {
  try {
    const response = await agreementMarcoServices.putAgreementPhases(
      params.id_convenio_fase,
      params.data
    );
    return response; 
  } catch (error) {
    return error.response
  }
};

export const putStatusAgreement = async ({ commit }, params) => {
  const response = await agreementMarcoServices.putAgreementStatus(
    params.id_agreement,
    params.data
  );
  return response;
};

export const getConveniosList = async ({ commit, state }) => {
  const response = await AgreementMarco.getListaconvenios(state.filters);
  if (response.status === 200) {
    commit("setListConvenios", response?.data);
    commit("setPaginationData", response?.headers);
  } else {
    commit("setListConvenios", []);
  }
};

export const getImageAgreement = async ({ commit }, id_agreement) => {
  const response = await agreementMarcoServices.getConvenioPhoto(id_agreement);
  if (response !== undefined && response.status === 200) {
    const urlCreator = window.URL || window.webkitURL;
    let image = urlCreator.createObjectURL(response.data);
    return image;
  }
};

export const getFasesList = async ({ commit }) => {
  const response = await phase.getListPhases();
  if (response.status === 200) {
    commit("setListadoFases", response?.data);
  } else {
    commit("setListadoFases", []);
  }
};

export const getEstadosList = async ({ commit }) => {
  const response = await agreementMarcoServices.getListaConveniosEstados();
  if (response.status === 200) {
    commit("setListadoEstados", response?.data);
  } else {
    commit("setListadoEstados", []);
  }
};

export const loadGeneralData = ({ commit, state }, data) => {
  commit("setGeneralData", data);
};

export const clearGeneralData = ({ commit }, data) => {
  commit("clearGeneralData", data);
};

export const loadFaseData = ({ commit }, data) => {
  commit("setPhaseData", data);
};

export const getReviewOffers = async ({ commit }, data) => {
  const { id_agreement, id_provider } = data;
  const response = await AgreementMarco.getConvenioReviewOffers(
    id_agreement,
    id_provider
  );
  if (response.status === 200) {
    commit("setReviewOffers", response?.data);
  } else {
    commit("setReviewOffers", []);
  }
};
export const nextTab = ({ commit, state }) => {
  commit("setTab", state.tab + 1);
};

export const prevTab = ({ commit, state }) => {
  commit("setTab", state.tab - 1);
};
