export default () => ({
  // Contiene los elementos de solicitud de compra
  solicitudes: [],
  // Contiene los elementos de solicitud de compra agregados al proceso de
  // compra
  procesos: [],
  // Arreglo con los datos filtrados, se crea una copia de solicitudes para no
  // modificar el arreglo original
  solicitudesFiltradas: [],
  // Arreglo con los datos filtrados, se crea una copia de proceso para no
  // modificar el arreglo original
  procesosFiltrados: [],
  // Models para abrir y cerrar los expansion panels
  solicitudesExpansionPanel: [],
  procesosExpansionPanel: [],
  // Filtros ---------------------------------------------------------------
  // -> Solicitudes
  fltUnidadSolicitud: null,
  fltTipoObsSolicitud: null, // En backend se llama id_clasificacin_obs
  fltObsSolicitud: null,
  // -> Proceso
  fltUnidadProceso: null,
  fltTipoObsProceso: null, // En backend se llama id_clasificacin_obs
  fltObsProceso: null,
  // Catálogos -------------------------------------------------------------
  ctlUnidad: [],
  ctlTipoObs: [],
  ctlObs: [],
  // Clases para la animación de error
  procesosError: false,
  solicitudesError: false,
});
