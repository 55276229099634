import { http_client } from "@/plugins/http_client"

const getRequestProviders = async (filters = {}) =>
  await http_client("/api/v1/solicitudes/proveedores", filters, "get")

const getRequestProvidersById = async (id) =>
  await http_client(`/api/v1/solicitudes/proveedores/${id}`)

const getDeclarationRequestProvider = async (id) =>
  await http_client(`/api/v1/solicitudes/proveedores/${id}/declaracion`)

const getObservationHistoryRequestProvider = async (id) =>
  await http_client(`/api/v1/solicitudes/proveedores/${id}/observacion/historial`)

const updateStatusRequestProvider = async(id, params) =>
  await http_client(`/api/v1/solicitudes/proveedores/${id}/estado`, params, 'put')

const resendEmailProvider = async(id_solicitud, params) =>
  await http_client(`/api/v1/solicitudes/proveedores/${id_solicitud}/reenviar/observaciones`, params, 'put')

export default {
  getRequestProviders,
  getRequestProvidersById,
  getDeclarationRequestProvider,
  getObservationHistoryRequestProvider,
  updateStatusRequestProvider,
  resendEmailProvider
}
