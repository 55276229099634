import { http_client } from "../plugins/http_client"

const verificaCorreo = async (idproveedor,parametos)=>{
    try {
        return await http_client(`api/v1/solicitudes/proveedores/${idproveedor}/codigo`,parametos,"post")
    } catch (error) {
        return error.response;
    }
    
}

const verificarCodigo = async(idproveedor, parametos)=>{
    try {
        return await http_client(`/api/v1/solicitudes/proveedores/${idproveedor}/verificacion`,parametos,"post")
    } catch (error) {
        return error.response;
    }
}

///VerificarCorreo


export default{
    verificaCorreo,
    verificarCodigo
}