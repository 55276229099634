const convenioMarco = [
  {
    path: "/convenio-marco-list",
    name: "convenio-marco",
    component: () =>
      import(
        /* webpackChunkName: "conveniosProveedor" */ "./convenioMarcoView.vue"
      ),
  },
  {
    path: "/evaluacion-ofertas",
    name: "evaluacion-ofertas",
    component: () =>
      import(
        /* webpackChunkName: "evauacion_ofertas" */ "../ConvenioMarco/evaluacionOfertasView.vue"
      ),
  },
  {
    path:"/evaluacion-proveedor",
    name:"evaluacion-proveedor",
    component:()=>
    import(/* webpackChunkName: "evaluacion_proveedor" */ "../ConvenioMarco/evaluacionProveedorView.vue")
  },
  {
    path: "/crear-convenio-marco",
    name: "crear-convenio-marco",
    component: () =>
      import(
        /* webpackChunkName: "conveniosProveedor" */ "./convenioMarcoCreateView.vue"
      ),
  },
  {
    path:"/recepcion-ofertas",
    name:"recepcion-ofertas",
    component:()=>
      import(/* webpackChunkName: "RecepcionOfertas" */ "./recepcionOfertasView.vue")
  }
];

export default convenioMarco;