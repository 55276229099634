import { http_client, http_client_with_status } from "@/plugins/http_client";

// Fondos compra por emergencia

const getEmpleadoByEmail = async (filter) => await http_client(`/api/v1/compra/emergencia/administradores`, filter);

const postAddAdminFondo = (id_fondo, id_admin, body = {}) =>
  http_client(
    `/api/v1/compra/emergencia/${id_fondo}/administradores/${id_admin}`,
    body,
    "post"
  );

const deleteAdminFondo = (id_admin) =>
  http_client(`/api/v1/compra/emergencia/administradores/${id_admin}`, {}, "delete");

const postAddFondo = (body = {}) =>
  http_client("/api/v1/compra/emergencia/fondos/compra-emergencia", body, "post");

const putEditFondo = (id_fondo, body = {}) =>
  http_client(`/api/v1/compra/emergencia/update/fondo/${id_fondo}`, body, "put", { 'Content-Type': 'multipart/form-data' });

const getFondoById = (id_fondo, filter) =>
  http_client(`/api/v1/compra/emergencia/show/fondo/${id_fondo}`, filter);

const getListFondos = (filters) =>
  http_client("/api/v1/compra/emergencia/fondos", filters);

// Administracion fondos compra en emergencia

const postAddRegFondo = (id_fondo, body = {}) =>
  http_client(`/api/v1/compra/emergencia/crear-registro/${id_fondo}`, body, "post");

const postAddInformeFondo = (id_fondo, body = {}) =>
  http_client(`/api/v1/compra/emergencia/crear-registro-informe-fondo/${id_fondo}`, body, "post");

const getListRegsFondos = (id_fondo, filters) =>
  http_client(`/api/v1/compra/emergencia/${id_fondo}/registros`, filters);

const getReporteGeneralFondos = () =>
  http_client(
    "/api/v1/compra/emergencia/reporte/general",
    {},
    "get",
    { responseType: "blob" },
    true,
    "blob",
    true
  );

const getReporteFondo = (id_fondo_compra_emergencia) =>
  http_client(
    `/api/v1/compra/emergencia/${id_fondo_compra_emergencia}/reporte`,
    {},
    "get",
    { responseType: "blob" },
    true,
    "blob",
    true
  );

const enviarNotificacionUCP = (params = {}) =>
  http_client(`/api/v1/compra/emergencia/notificar`, params, "post");

const deleteGastoFondo = (id_gasto) =>
  http_client(
    `/api/v1/compra/emergencia/eliminar-registro/${id_gasto}`,
    {},
    "delete"
  );

const uploadExcel = (id_fondo, body = {}) =>
  http_client(
    `/api/v1/compra/emergencia/crear-registro/reporte/${id_fondo}`,
    body,
    "post"
  );

const postAsueto = (body = {}) =>
  http_client("/api/v1/asuetos/fecha-fin", body, "post");

const editarGastoFondo = (idRegistro, data) =>
  http_client(
    `/api/v1/compra/emergencia/editar-registro/${idRegistro}`,
    data,
    "put"
  );

const cargarCatalogoEstados = (params) =>
  http_client_with_status(
    "/api/v1/compra/emergencia/catalogo/estado-registro",
    params,
    "get"
  );

// // Revision fondos
// const listarFondos = (filtros) =>
//   http_client_with_status(
//     "/api/v1/compra/emergencia/revision/listar/fondos",
//     filtros,
//     "get"
//   );

// const cargarInstituciones = (filtros) =>
//   http_client_with_status(
//     "/api/v1/compra/emergencia/catalogo/instituciones",
//     filtros,
//     "get"
//   );

// const getListRegistrosFondo = async (id_fondo, filters) =>
//   await http_client_with_status(
//     `/api/v1/compra/emergencia/revision/listar/registros/fondos/${id_fondo}`,
//     filters,
//     "get"
//   );

// const marcarRegistroComoRevisado = (id_gasto) =>
//   http_client_with_status(
//     `/api/v1/compra/emergencia/marcar-como-revisado/${id_gasto}`,
//     null,
//     "patch"
//   );

// const marcarRegistroComoObservado = (id_fondo, data) =>
//   http_client_with_status(
//     `/api/v1/compra/emergencia/marcar-como-observado/${id_fondo}`,
//     data,
//     "patch"
//   );

// const cargarConteoRegistros = (idFondo) =>
//   http_client_with_status(
//     `/api/v1/compra/emergencia/conteo/${idFondo}`,
//     null,
//     "get"
//   );

// const aprobacionMasivaRegistros = async (id_fondo, body = {}) =>
//   await http_client(
//     `/api/v1/compra/emergencia/registros-revisados/${id_fondo}`,
//     body,
//     "post"
//   );

// const cargarProcesosCompraEmergencia = (id_fondo) =>
//   http_client(
//     `/api/v1/compra/emergencia/procesos/compra/emergencia/${id_fondo}`,
//     {},
//     "get"
//   );

const cargarTiposDocumentosCompraEmergencia = (params) =>
  http_client(`/api/v1/compra/emergencia/catalogo/tipos/documentos`, params, "get");

const cargarDocumentos = async (id, params = {}) =>
  http_client(`/api/v1/compra/emergencia/documentos/compra/${id}`, params);

const visualizarAdjuntoRegCompraEmergencia = (id) => 
  http_client_with_status(`/api/v1/compra/emergencia/reg-documentos/compra/${id}`, null, "get", {}, { responseType: 'blob' });

const visualizarAdjuntoFondoCompraEmergencia = (id) => 
  http_client_with_status(`/api/v1/compra/emergencia/obtener/archivo-fondo-compra/${id}`, null, "get", {}, { responseType: 'blob' });

const crearAdjuntoExcelRegCompra = (data) => http_client_with_status('/api/v1/compra/emergencia/registro-documentos/compra/excel', data, 'post', {
	'Content-Type': 'multipart/form-data',
});

const deleteAdjuntoFondoCompraEmergencia = (id_fondo_compra) =>
  http_client(
    `/api/v1/compra/emergencia/eliminar-archivo-fondo-compra/${id_fondo_compra}`,
    {},
    "delete"
  );

const visualizarAdjuntoInformeFondo = (id) => 
  http_client_with_status(`/api/v1/compra/emergencia/visualizar-adjunto-informe-fondo/compra/${id}`, null, "get", {}, { responseType: 'blob' });

const getListRegistrosInforme = (id_informe_registro, filters) =>
  http_client(`/api/v1/compra/emergencia/${id_informe_registro}/registros-informes`, filters);

export default {
  // Fondos compra por emergencia
  getEmpleadoByEmail,
  postAddAdminFondo,
  deleteAdminFondo,
  postAddFondo,
  putEditFondo,
  getFondoById,
  getListFondos,

  // Administracion fondos compra por emergencia
  postAddRegFondo,
  getListRegsFondos,
  getReporteGeneralFondos,
  getReporteFondo,
  enviarNotificacionUCP,
  deleteGastoFondo,
  uploadExcel,
  postAsueto,
  editarGastoFondo,
  cargarCatalogoEstados,

  // Revision fondos
  // listarFondos,
  // cargarInstituciones,
  // getListRegistrosFondo,
  // marcarRegistroComoRevisado,
  // marcarRegistroComoObservado,
  // cargarConteoRegistros,
  // aprobacionMasivaRegistros,

  //
  // cargarProcesosCompraEmergencia,
  cargarTiposDocumentosCompraEmergencia,

  cargarDocumentos,
  visualizarAdjuntoRegCompraEmergencia,
  crearAdjuntoExcelRegCompra,
  visualizarAdjuntoFondoCompraEmergencia,
  deleteAdjuntoFondoCompraEmergencia,
  postAddInformeFondo,
  visualizarAdjuntoInformeFondo,
  getListRegistrosInforme,
};
