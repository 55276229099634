export const setIsEdit = (state, payload) => {
  state.isEdit = payload
};

export const setIdAgreementMarco = (state, payload) => {
  state.id_agreement_marco = payload;
};

export const setIdEstadoConvnio = (state, payload) => {
  state.id_estado_convenio = payload
};

export const setListaEmpleados = (state, payload) => {
  state.lista_empleados = payload;
};

export const setListaDocumentType = (state, payload) => {
  state.lista_document_type = payload;
};

export const setObsList = (state, payload) => {
  state.obs_list = payload;
};

export const setObsAgreementList = (state, payload) => {
  state.lista_obs_convenio = payload;
};

export const setDocumentAgreementList = (state, payload) => {
  state.lista_document_convenio = payload;
};

export const setPhasesAgreementList = (state, payload) => {
  state.lista_fases_convenio = payload;
};

export const setAgreement = (state, payload) => {
  state.agreement = payload;
};

export const setGeneralData = (state, payload) => {
  if(state.general_data.get(payload.property) !== undefined){
    state.general_data.set(payload.property, payload.data)
  }else{
    state.general_data.append(payload.property, payload.data)
  }
  }

export const setPhaseData = (state, payload) => {
  state.fases_data.append(payload.property, payload.data);
};

export const clearGeneralData = (state, payload) => {
  state.general_data = new FormData()
};

export const setAgreementObs = (state, payload) => {
  state.agreement = payload;
};

export const setListConvenios = (state, payload) => {
  state.Listado_convenios = payload;
};

export const setStartDate = (state, payload) => {
  state.start_date = payload;
};

export const setImageAgreement = (state, payload) => {
  state.image_agreement = payload;
};

export const setListadoFases = (state, payload) => {
  state.phase_list = payload
}

export const setListadoEstados = (state, payload) => {
  state.estados_list = payload
}

export const setAgreementState = (state, payload) => {
  state.agreement_state = payload
}

export const setTab = (state, payload) => {
  state.tab = payload
}

export const setAgreementDetail = (state, payload) => {
  state.agreement_detail = payload;
};

export const setPaginationData = (state, payload) => {
  state.filters.page = Number(payload.page);
  state.filters.per_page = Number(payload.per_page);
  state.filters.total_rows = Number(payload.total_rows);
};

export const setReviewOffers = (state, payload) => {
  state.reviewOffers = payload;
};

export const setTempProviderId = (state, payload) => {
  state.tempProviderId = payload;
}

export const setReadOnlyOffers = (state, payload) => {
  state.readOnlyOffers = payload;
}

export const setBadCatalogoObs = (state, payload) => {
  state.bad_catalogo_obs = payload
}

export const setAlertCatalogoObs = (state, payload) => {
  state.alert_obs = payload
}

export const setBadDocumentos = (state, payload) => {
  state.bad_documentos = payload
}

export const setAlertDocumentos = (state, payload) => {
  state.alert_documentos = payload
}

export const setBadEtapas = (state, payload) => {
  state.bad_etapas = payload
}

export const setAlertEtapas = (state, payload) => {
  state.alert_etapas = payload
}

export const setVigenciaState = (state, payload) => {
  state.vigencia_saved = payload;
}