<template>
    <span :style="`color: ${colorTextoTiempoRestante}`">{{ textoTiempoRestante }}</span>
</template>
<script>
import { obtenerDuracionEnDias } from '@/utils/countdown';
import { mapState } from 'vuex';
import { isNil } from 'lodash';
import moment from 'moment';

export default {
    name: 'FechaEntrega',
    props: {
        fecha: { type: String },
        fallback: { type: String },
    },
    computed: {
        ...mapState(["serverTime"]),
        tiempoRestante() {
            const fechaActual = this.serverTime.clone();

            if (isNil(this.fecha)) return null;

            const fechaEntrega = moment(this.fecha, 'DD/MM/YYYY HH:mm:ss');
            const diferencia = fechaEntrega.diff(fechaActual, 'seconds');

            return moment.duration(diferencia, 'seconds');
        },
        textoTiempoRestante() {
            return isNil(this.tiempoRestante) ? this.fallback : obtenerDuracionEnDias(this.tiempoRestante);
        },
        colorTextoTiempoRestante() {
            if (isNil(this.tiempoRestante)) return '#313945';

            return this.tiempoRestante < 0 ? '#E76A4F' : '#313945';
        },
    },
}
</script>