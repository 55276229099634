const cmReporteriaRoutes = [
    {
        path: "/convenio-marco-reporteria",
        name: "convenio-marco-reporteria",
        component: () =>
            import(
                /* webpackChunkName: "creacionConvenio" */ "./reporteComprasView.vue"
            ),
    },
    {
        path: "/procesos-pendientes-contratacion",
        name: "procesos-pendientes-contratacion",
        component: () =>
            import(/* webpackChunkName: "ProcesosPublicosReporteria" */ "./reporteProcesosLibres.vue"),
    },
    {
        path: "/procesos-pendientes-contratacion-copia",
        name: "procesos-pendientes-contratacion-copia",
        component: () =>
            import(/* webpackChunkName: "ProcesosPublicosReporteria" */ "./reporteProcesosLibresCopia.vue"),
    },
    {
        path: "/procesos-en-reserva",
        name: "procesos-en-reserva",
        component: () =>
            import(/* webpackChunkName: "ProcesosEnReserva" */ "./reporteProcesoReserva.vue"),
    },
    {
        path: '/reporteria-baja-cuantia',
        name: 'reporteria-baja-cuantia',
        component: () => 
            import(
                /* webpackChunkName: "reporteBajaCuantia" */ "./reporteBajaCuantia.vue"
            ),
    },
    {
        path: '/reporteria-auditoria-interna',
        name: 'reporteria-auditoria-interna',
        component: () => 
            import(
                /* webpackChunkName: "reporteAuditoriaInterna" */ "./reporteAuditoriaInterna.vue"
            ),
    },
];

export default cmReporteriaRoutes;
