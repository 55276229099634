// importacion de services
import SancionService from "@/services/Sanciones.services";

//  accion que obtiene la lista de proveedores sancionados a través del servicio SancionService
export const FetchListProvSancionAc = async ({ commit }, filters_param = {}) => {
    const RESP_SC_CONST = await SancionService.reqGetBlackList(filters_param);
    if (RESP_SC_CONST) {
        const { data, headers } = RESP_SC_CONST;
        commit("SetListProvMt", data);
        commit("SetListProvPagMt", headers);
    }
}

//  accion que obtiene el detalle de un proveedor sancionado a través del servicio SancionService
export const FetchDetailProvSancionAc = async ({ commit }, id_proveedor_param) => {
    const RESP_SC_CONST = await SancionService.reqGetDetailProv(id_proveedor_param);
    if (RESP_SC_CONST) {
        const { data } = RESP_SC_CONST;
        commit("SetDetailProvMt", data);
    }
}

//  accion que obtiene el detalle de una sancion a través del servicio SancionService
export const FetchProvListSancionAc = async ({ commit }, {id_proveedor_param, filters_param}) => {
    const RESP_SC_CONST = await SancionService.reqGetListSancProv(id_proveedor_param, filters_param);
    if (RESP_SC_CONST) {
        const { data, headers } = RESP_SC_CONST;
        commit("SetListSancionProvMt", data);
        commit("SetListSancionProvPagMt", headers);
    }
}

//  accion que obtiene la lista de sanciones de un proveedor a través del servicio SancionService
export const FetchDetailSancionAc = async ({ commit, state }) => {
    const RESP_SC_CONST = await SancionService.reqGetDetailSanc(state.id_sancion_st);
    if (RESP_SC_CONST) {
        const { data } = RESP_SC_CONST;
        commit("SetDetailSancionMt", data);
    }
}

