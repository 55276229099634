import defaultState from "./state";
import * as mutations from "./mutations";
import * as actions from "./actions";

const ProcCompReservaStore = {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
}

export default ProcCompReservaStore;