import { http_client_with_status } from "@/plugins/http_client";

const obtenerInformacionAuditoria = (filtros) => http_client_with_status(
    `/api/v1/reporteria-auditoria-interna/informacion`,
    filtros,
    'get',
);

const catalogoUsuario = (filtros) => http_client_with_status(
    `/api/v1/reporteria-auditoria-interna/usuarios`,
    filtros,
    'get',
);
const catalogoTitulo = (filtros) => http_client_with_status(
    `/api/v1/reporteria-auditoria-interna/obtener-titulos`,
    filtros,
    'get',
);


export default {
    obtenerInformacionAuditoria,
    catalogoUsuario,
    catalogoTitulo
}
