const solicitudCompras = [
    {
        path: "/solicitudes-compra",
        name: "solicitudes-compra",
        component: () =>
            import(
                /* webpackChunkName: "solicitudesCompra" */ "./solicitudesCompraView.vue"
            )
    },
    {
        path: "/solicitudes-compra/:idSolicitud/detalle/:tipo",
        name: "solicitudes-compra-detalle",
        component: () =>
            import(
                /* webpackChunkName: "solicitudesCompraDetalle" */ "./detalleSolicitudView.vue"
            )
    },
]

export default solicitudCompras;