export default {
  setListaLineas(state, payload) {
    state.listadoLineas = payload;
  },
  setPaginationData(state, payload) {
    state.filters.page = Number(payload.page);
    state.filters.perPage = Number(payload.per_page);
    state.filters.totalRows = Number(payload.total_rows);
  },
};
