<template>
  <Fragment>
    <v-btn
      style="width: 100%"
      color="primary"
      large
      :loading="isCreating"
      @click.stop="dialog = true"
      >
        Presentar solicitud
      </v-btn>
    <!-- Confirmar envio de solicitud -->
    <ConfirmationModalComponent
      :isOpen="dialog" 
      title="Confirmación de compra"
      :description="sendToApprovalConfirmMessage"
      @confirm="handleSendingConfirmation" 
      @cancel="dialog = false"    
      :isLoading="isCreating"  
    />
    <!-- Confirmar envio si el monto de envio no es mayor al minimo -->
    <ConfirmationModalComponent
      :isOpen="deliveryWarnModal.isOpen" 
      :description="deliveryWarnModal.message"
      @confirm="confirmDeliveryWarn" 
      @cancel="closeDeliveryWarn"  
      :isLoading="approval.isLoading"
    />
    <!-- Modal para mostrar cambios de precio -->
    <FeedbackModalComponent
      :isOpen="feedback"
      title="Cambios en los precios"
      type="info"
      description="Hay cambios de precio en algunos de los articulos agregados a tu carrito, puedes verificar los precios actualizados al cerrar esta ventana."
      buttonText="Cerrar"
      @on-close="handleFeedbackModalClose"
    >
      <v-simple-table class="my-6 price-changes-table">
        <template v-slot:default>
          <thead color="primary">
            <tr>
              <th class="text-left">Producto</th>
              <th class="text-left">Precio antiguo</th>
              <th class="text-left">Precio nuevo</th>
              <th class="text-left">Cantidad</th>
              <th class="text-left">Sub total</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in changes"
              :key="item.id"
            >
              <td>{{ item.name }}</td>
              <td>${{ item.old_price }}</td>
              <td>${{ item.new_price }}</td>
              <td>{{ item.quantity }}</td>
              <td>${{ item.subtotal }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </FeedbackModalComponent>
  </Fragment>
</template>
<script>
import {
  createLoadable,
  toggleLoadable,
  isResponseSuccesful,
  setLoadableResponse
} from '@/utils/loadable.js';
import { FeedbackModalComponent, ConfirmationModalComponent } from '@/components/utils';
import { mapActions, mapMutations, mapState } from 'vuex';
import { Observable } from '@/utils/observable.js';
import { Fragment } from 'vue-fragment';
import { convertToFormData } from '@/utils/data';

export default {
  name: 'PurchaseConfirmationComponent',
  components: { Fragment, FeedbackModalComponent, ConfirmationModalComponent },
  props: {
    request: { type: Object, required: false },
    isEditAction: { type: Boolean, default: false,}
  },
  data: () => (({
    dialog: false,
    feedback: false,
    agendamientoList:[],
    priceValidation: createLoadable(null),
    agendamientoOffer: createLoadable([]),
    approval: createLoadable(null),
    changes: [],
    deliveryWarnModal: {
      isOpen: false,
      message: '',
    },
  })),
  computed: {
    ...mapState("catalogoEnLinea", ["priceChanges",'agendamientos']),
    isCreating() {
      return this.priceValidation.isLoading || this.approval.isLoading;
    },
    sendToApprovalConfirmMessage() {
      return `¿Está seguro de continuar con el proceso de compra? Una vez confirmado, 
      la solicitud será enviada a aprobación.`
    },
  },
  methods: {
    ...mapMutations("catalogoEnLinea", ["clearInCartRequest", "setTimerStatus", "setFeedbackModalConfig"]),
    ...mapMutations("utils", ["setCartDrawer"]),
    ...mapActions("catalogoEnLinea", ["updateItemsWithNewPrices"]),
    
    async sendToApproval() {
      toggleLoadable(this.approval);
      const { data }  = await this.services.PurchaseRequest.sendRequestToApproval(this.request.id);
      setLoadableResponse(this.approval, data, { skipOnSuccess: true });

      if(!isResponseSuccesful(data)) return;

      this.clearInCartRequest();
      this.setTimerStatus('empty-request');
      this.setCartDrawer(false);
      const ruta = this.isEditAction ? "/cm-solicitudes-con-observacion/" : "/catalogo-electronico/"
      Observable.emit('refresh-requests-to-modify');
      this.setFeedbackModalConfig({
            isOpen: true,
            description: "Solicitud enviada a aprobación.",
            type:  "success",
            onClose: () => this.$router.push({ path: ruta, replace: true }),
        });
    },
    async validatePriceChanges(){
      toggleLoadable(this.priceValidation);

      const { data } = await this.services.PurchaseRequest.checkPriceChanges(this.request.id);

      setLoadableResponse(this.priceValidation, data, { skipOnSuccess: true });
      if (!isResponseSuccesful(data)) return;

      if (data.data.existenCambios) {
        const changes = await this.updateItemsWithNewPrices(data.data.cambios);
        Observable.emit('offerts-change');
        this.changes = changes;
        this.feedback = true;
        return;
      }

      if (data.data.minimo_envio.alerta && data.data.id_tipo_solicitud != 2) {
        this.deliveryWarnModal = {
          isOpen: true,
          message: `${data.data.minimo_envio.mensaje}. ¿Desea continuar?`,
        };
        
        return;
      }

      // Todo esta validado para enviar  la solicitud a aprobacion
      await this.sendToApproval();
    },
    async handleSendingConfirmation() {
      await this.validatePriceChanges();
      this.dialog = false;
    },
    handleFeedbackModalClose() {
      this.feedback = false;
    },
    async confirmDeliveryWarn() {
      await this.sendToApproval();
      this.closeDeliveryWarn();
    },
    closeDeliveryWarn() {
      this.deliveryWarnModal = {
        isOpen: false,
        message: '',
      };
    }
  },
};
</script>
