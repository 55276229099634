const routesDelegadosUCP = [
    {
      path: "/delegados-ucp",
      name: "delegados-ucp",
      component: () =>
        import(
          /* webpackChunkName: "delegados-ucp" */ "./indexDelegadosUcp.vue"
        ),
    },
    {
      path: "/delegados-ufi",
      name: "delegados-ufi",
      component: () =>
        import(
          /* webpackChunkName: "delegados-ufi" */ "./indexDelegadosUfi.vue"
        ),
    },
  ];
  
  export default routesDelegadosUCP;