import {http_client} from "@/plugins/http_client";

const getTipoContribuyente = async(parametos={})=>{
    return await http_client("/api/v1/contribuyentes/tipos",{},"get")
}

export default{
    getTipoContribuyente
}


//Contribuyentes