<template>
    <v-text-field
        v-bind="{...$props, ...$attrs}"
        v-on="$listeners"
        prepend-inner-icon="mdi mdi-magnify"
        @input="debouncedSearch"
    />
</template>
<script>
import { debounce } from 'lodash';

export default {
    name: 'DebouncedSearchComponent',
    emits: ['search'],
    props: {
        delay: { type: Number, default: 600 },
        inputProps: { type: Object },
    },
    methods: {
        search(term) {
            this.$emit('search', term);
        },
    },
    watch: {
        delay() {
            this.debouncedSearch = debounce(this.search, this.$props.delay);
        },
    },
    created() {
        this.debouncedSearch = debounce(this.search, this.$props.delay);
    },
}
</script>