export const setProcesoCompraUACI = (state, payload) => {
  state.etapasProceso = payload;

};
export const setIdProcesoCompraUACI = (state, payload) => {
  state.id_proceso_compra = payload;
}

export const setItems = (state, payload) => {
  state.itemsGenerales = payload;
}