<template>
        <div
            v-if="showRetry" 
            class="retry-action py-4 px-4 text-center"
            style="background-color: #FAFAFA; border-radius: 4px; border: dashed 2px #424242;"
        >
            <div class="d-flex align-center" style="gap: 16px">
                <slot name="icon">
                    <v-icon size="40">mdi-alert</v-icon>
                </slot>
                <p class="my-0 mx-0 body-1 text-left">{{ technicalError }}</p>
            </div>
            <v-btn color="primary" class="mt-4" @click="retryHandler">{{ retryBtnText }}</v-btn>
            <slot name="suffix"></slot>
        </div>
        <div v-else>
            <slot></slot>
        </div>
</template>
<script>

export default {
    name: 'RetryDataLoading',
    props: {
        retryHandler: { type: Function, required: true },
        loadable: { type: Object, required: true },
        retryBtnText: { type: String, default: 'Intentar de nuevo' },
        skip: { type: Boolean, default: false },
    },
    computed: {
        hasErrorState() {
            return this.loadable.error && this.loadable.isDirty && !this.loadable.isLoading;
        },
        technicalError() {
            return this.loadable.error.message;
        },
        showRetry() {
            return !this.skip && this.hasErrorState;
        },
    },
}
</script>
