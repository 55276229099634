import { http_client } from "@/plugins/http_client";

const getGruposList = async (params = {}) => {
  return await http_client(`/api/v1/cm-grupo`, params, "get");
};

const upsertGroup = async(group, groupId) => {
  let url = '/api/v1/cm-grupo/';
  const method = groupId ? 'PUT' : 'POST';

  if (groupId) url = url + `/${groupId}`;

  return await http_client(url, group, method);
}

const deleteGrupos = async (id_grupo, params = {}) => {
  return await http_client(`/api/v1/cm-grupo/${id_grupo}`, params, "delete");
};

const getFamiliasList = async (params = {}) => {
  return await http_client(`/api/v1/cm-familia`, params, "get");
};

const putGruposEstado = async (id_grupo, params = {}) => {
  return await http_client(`/api/v1/cm-grupo/estado/${id_grupo}`, params, "put");
};

export default {
  getGruposList,
  upsertGroup,
  deleteGrupos,
  getFamiliasList,
  putGruposEstado
};
