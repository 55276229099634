const cmAprobacionSolicitudCompra = [
    {
        path: "/cm-aprobacion-solicitud-compra",
        name: "cm-aprobacion-solicitud-compra",
        component: () =>
            import(
                /* webpackChunkName: "cmAprobacionSolicitudCompra" */ "./main.vue"
                ),
    },
    {
        path: "/cm-aprobacion-solicitud-compra/:id",
        name: "cm-aprobacion-solicitud-compra-detalle",
        component: () =>
            import(
                /* webpackChunkName: "cmAprobacionSolicitudCompraDetalle" */ "./detalle.vue"
                ),
    }
];

export default cmAprobacionSolicitudCompra;
