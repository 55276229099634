<template>
  <v-row justify="center">
    <v-dialog
      v-model="value"
      content-class="elevation-0"
      :max-width="maxWidth"
      persistent
    >
      <section>
        <v-card height="auto">
          <v-card-title
            v-if="showClose"
            class="text-h5 primary--text text-center"
          >
            <section class="container">
              <v-icon
                color="secondary"
                @click="cerrarModal"
                class="btn-close-custom"
              >
                mdi-close
              </v-icon>
              <div class="d-flex justify-start mt-5">
                <span class="secondary--text">{{ title }}</span>
              </div>
            </section>
          </v-card-title>
          <v-card-title v-else class="text-h5 primary--text text-center">
            <section class="container">
              <div class="d-flex justify-center mt-5">
                <span class="secondary--text">{{ title }}</span>
              </div>
            </section>
          </v-card-title>
          <section class="container px-8">
            <slot name="content" />
          </section>

          <div class="actions-buttons pl-5 pb-5" :class="classButtons">
            <slot name="actions" />
          </div>
        </v-card>
      </section>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "AppDialog",
  props: {
    title: String,
    showClose: {
      type: Boolean,
      default: true,
    },
    value: Boolean,
    maxWidth: {
      type: Number,
      default: 600,
    },
    classButtons: {
      type: String,
      default: "d-flex justify-start",
    },
  },
  methods: {
    cerrarModal(){
      this.$emit('input', false)
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.description {
  padding: 0 50px 0 50px;
  font-size: 18px;
}

.actions-buttons button {
  margin-right: 30px;
}
.btn-close-custom {
  position: absolute;
  top: 10px;
  left: 15px;
}
</style>
