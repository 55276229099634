export default () => ({
    // Componente de listado de solicitudes de necesidades pendientes
    list_solic_pendt_st: [],
    list_solic_pendt_pag_st: {
        page: 1,
        per_page: 10,
        total_rows: 0,
    },

    // Vista de listado de sanciones de necesidades aprobadas
    list_solic_aprob_st: [],
    list_solic_aprob_pag_st: {
        page: 1,
        per_page: 10,
        total_rows: 0,
    },
});
