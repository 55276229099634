const paacProveedorRouter = [
  {
    path: "/proveedor-paac",
    name: "proveedor-paac",
    component: () =>
      import(
            /* webpackChunkName: "insumo-agregado/" */ "../PaacProveedor/paacProveedor.vue"
      ),
  },
  {
    path: "/proceso-compra-paac/:id_proceso_compra",
    name: "proceso-compra-paac",
    component: () =>
      import(
            /* webpackChunkName: "proceso-compra-paac/" */ "./procesoCompraPaac.vue"
      ),
  },
  {
    path: "/ver-obs-paac/:id_proceso_compra/:id_obs",
    name: "ver-obs-paac",
    component: () =>
      import(
            /* webpackChunkName: "ver-articulo-paac/" */ "./verObsPaac.vue"
      ),
  },
  {
    path: "/ver-oferta-paac/:id_proceso_compra/:id_subproceso/:id_proveedor_participante?",
    name: "ofertar-paac",
    component: () =>
      import(
            /* webpackChunkName: "ver-articulo-paac/" */ "./ofertarPaac.vue"
      ),
  },
]

export default paacProveedorRouter