<template>
    
        <div v-if="empty" class="empty-indicator-wrapper" :class="{ colored }">
            <slot name="empty-content">
                <slot name="icon" className="empty-indicator-icon">
                    <v-icon class="empty-indicator-icon" color="primary">mdi mdi-emoticon-sad</v-icon>
                </slot>
                <h3 v-if="title" class="empty-indicator-title mt-3">{{ title }}</h3>
                <p v-if="!hideText" class="empty-indicator-text mt-3">{{ text }}</p>
                <slot name="extra"></slot>
            </slot>
        </div>
        <Fragment v-else>
            <slot></slot>
        </Fragment>
</template> 
<script>
import { Fragment } from 'vue-fragment';

export default {
    name: 'EmptyComponent',
    components: { Fragment },
    props: {
        empty: { type: Boolean, required: true },
        text: {  type: String, required: false, default: 'Recurso no encontrado' },
        title: { type: String, required: false },
        colored: { type: Boolean, required: false },
        hideText: { type: Boolean, default: false },
    },
}
</script>
<style>
    .empty-indicator-wrapper {
        text-align: center;
        padding: 16px;
    }

    .empty-indicator-wrapper.colored {
        border: dashed 2px #313945;
        border-radius: 4px;
    }

    .empty-indicator-icon {
        font-size: 80px !important;
        display: block;
    }

    .empty-indicator-title {
        font-size: 24px;
    }

    .empty-indicator-text {
        font-size: 18px;
        margin: 0 !important;
    }
</style>