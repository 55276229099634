import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import LegacyValidations from "@/utils/legacy-validations";
import {
  email,
  helpers,
  minLength,
  numeric,
  required,
  requiredIf
} from 'vuelidate/lib/validators'

const txtField = helpers.regex('txtField', /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 -]*$/)
const txtArea = helpers.regex('txtArea', /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 ()/.,\n:;-]*$/)

function containNumber (value) {
  if (value) {
    if (
      value.charAt(0) == '2' ||
      value.charAt(0) == '6' ||
      value.charAt(0) == '7'
    ) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}

function fileNameValid (value) {
  if (!value) {
    return true
  }
  let extension = /(\.pdf)$/i
  let file = value
  return extension.test(file.name)
}

const getDefaultState = () => {
  return {
    estadoSolicitud: null,
    idUsuario: null,
    formInfoGeneral: {
      tipoSolicitud: null,
      nacionalidad: null,
      numeroDocumento: null,
      primerNombre: null,
      segundoNombre: null,
      primerApellido: null,
      segundoApellido: null,
      estadoUsuario: null
    },
    formMainCuenta: {
      cargoUsuario: null,
      divisionUsuario: null,
      stckUnidades: [],
      emailUsuario: null,
      fechaCaducidadUsuario: null,
      infoAdicionalUsuario: null,
      profesionUsuario: null,
      rolPerfilUsuario: null,
      telefonoUsuario: null,
      tituloUsuario: null,
      detalleModificacionUsuario: null,
      urlDocumentoInstitucional: null
    }
  }
}

const validator = new Vue({
  mixins: [validationMixin],
  computed: {
    emailUsuario () {
      return state.formMainCuenta.emailUsuario
    },
    telefonoUsuario () {
      return state.formMainCuenta.telefonoUsuario
    },
    profesionUsuario () {
      return state.formMainCuenta.profesionUsuario
    },
    tituloUsuario () {
      return state.formMainCuenta.tituloUsuario
    },
    rolPerfilUsuario () {
      return state.formMainCuenta.rolPerfilUsuario
    },
    fechaCaducidadUsuario () {
      return state.formMainCuenta.fechaCaducidadUsuario
    },
    cargoUsuario () {
      return state.formMainCuenta.cargoUsuario
    },
    infoAdicionalUsuario () {
      return state.formMainCuenta.infoAdicionalUsuario
    },
    divisionUsuario () {
      return state.formMainCuenta.divisionUsuario
    },
    stckUnidades () {
      return state.formMainCuenta.stckUnidades
    },
    urlDocumentoInstitucional () {
      return state.formMainCuenta.urlDocumentoInstitucional
    }
  },
  validations: {
    emailUsuario: {
      required,
      email
    },
    telefonoUsuario: {
      required,
      numeric,
      containNumber,
      minLength: minLength(8)
    },
    profesionUsuario: {
      required
    },
    tituloUsuario: {
      required: requiredIf(function (value) {
        return value.urlDocumentoInstitucional == null
      }),
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
    rolPerfilUsuario: {
      required
    },
    fechaCaducidadUsuario: {
      required
    },
    cargoUsuario: {
      required,
      txtField
    },
    infoAdicionalUsuario: {
      txtArea
    },
    divisionUsuario: {
      required: requiredIf(function (value) {
        if (value?.stckUnidades?.length > 0) {
          return false
        } else {
          if (value?.divisionUsuario == null) {
            return true
          } else {
            return false
          }
        }
      })
    }
  }
})

// initial state
const state = getDefaultState()

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
    validator.$v.$reset()
  },
  setFormInfoGeneral (state, payload = {}) {
    Object.keys(payload).forEach(key => {
      state.formInfoGeneral[key] = payload[key]
    })
  },
  setFormMainCuenta (state, payload = {}) {
    Object.keys(payload).forEach(key => {
      state.formMainCuenta[key] = payload[key]
    })
  },
  addUnidadUsuario (state, payload) {
    state.formMainCuenta.stckUnidades.push(payload)
  },
  removeUnidadUsuario (state, payload) {
    state.formMainCuenta.stckUnidades = state.formMainCuenta.stckUnidades.filter(
      item => item.id !== payload
    )
  },
  cleanUnidadesUsuario (state) {
    state.formMainCuenta.stckUnidades = []
  },
  setEstadoSolicitud (state, payload) {
    state.estadoSolicitud = payload
  },
  setIdUsuario (state, payload) {
    state.idUsuario = payload
  },
  // actualizar informacion del formulario
  updateCargoUsuario (state, payload) {
    state.formMainCuenta.cargoUsuario = payload
  },
  updateDivisionUsuario (state, payload) {
    state.formMainCuenta.divisionUsuario = payload
  },
  updateEmailUsuario (state, payload) {
    state.formMainCuenta.emailUsuario = payload
  },
  updateFechaCaducidadUsuario (state, payload) {
    state.formMainCuenta.fechaCaducidadUsuario = payload
  },
  updateInfoAdicionalUsuario (state, payload) {
    state.formMainCuenta.infoAdicionalUsuario = payload
  },
  updateProfesionUsuario (state, payload) {
    state.formMainCuenta.profesionUsuario = payload
  },
  updateRolPerfilUsuario (state, payload) {
    state.formMainCuenta.rolPerfilUsuario = payload
  },
  updateTelefonoUsuario (state, payload) {
    state.formMainCuenta.telefonoUsuario = payload
  },
  updateTituloUsuario (state, payload) {
    state.formMainCuenta.tituloUsuario = payload
  },
  updateDetalleModificacionUsuario (state, payload) {
    state.formMainCuenta.detalleModificacionUsuario = payload
  },
  updateestadoUsuario (state, payload) {
    state.formInfoGeneral.estadoUsuario = payload
  }
}

const getters = {
  getFullName (state) {
    return `
    ${
      state.formInfoGeneral.primerNombre
        ? state.formInfoGeneral.primerNombre
        : ''
    } 
    ${
      state.formInfoGeneral.segundoNombre
        ? state.formInfoGeneral.segundoNombre
        : ''
    } 
    ${
      state.formInfoGeneral.primerApellido
        ? state.formInfoGeneral.primerApellido
        : ''
    } 
    ${
      state.formInfoGeneral.segundoApellido
        ? state.formInfoGeneral.segundoApellido
        : ''
    } 
    ${
      state.formInfoGeneral.apellidoCasada
        ? state.formInfoGeneral.apellidoCasada
        : ''
    }`
  },
  $v (state) {
    return Object.assign({}, validator.$v)
  }
}

const actions = {
  $touch () {
    validator.$v.$touch()
  },
  $touchValue (state, value) {
    validator.$v[value].$touch()
  }
}

const solicitudCuenta = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default solicitudCuenta
