const panelAdministracionOdcRoutes = [
    {
        path: "/panel-administracion-odc",
        name: "panel-administracion-odc",
        component: () =>
          import(/* webpackChunkName: "panel-administracion-odc" */ "./index.vue"),
    },
    {
        path: '/documentos-oficial-cumplimiento/:id_oficial',
        name: "documentos-oficial-cumplimiento",
        component: () =>
            import(/* webpackChunkName: "documentos-oficial-cumplimiento" */ "./documentosOdcView.vue"),
    }
];

export default panelAdministracionOdcRoutes;