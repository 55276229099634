<template>
    <v-dialog
      max-width="600px"
      v-model="internalOpen"
      :persistent="isLoading"
    >
        <div class="feedback-modal">
            <slot name="body">
                <v-icon color="#404A7A !important" class="mb-4 feedback-modal-icon">mdi mdi-information</v-icon>
                <h2 v-if="title" class="feedback-modal-title mb-2">{{ title }}</h2>
                <p class="feedback-modal-description">{{ description }}</p>
            </slot>
            <slot></slot>
            <div v-if="showActions">
                <v-btn 
                    color="secondary" 
                    class="feedback-modal-button mt-3" 
                    @click="internalOpen=false"
                    :disabled="isLoading"
                >
                    {{ cancelText }}
                </v-btn>
                <v-btn 
                    color="primary" 
                    class="feedback-modal-button mt-3 ml-3" 
                    @click="handleConfirm"
                    :loading="isLoading"
                >
                    {{ okText }}
                </v-btn>
            </div>
        </div>
    </v-dialog>
</template>
<script>

export default {
    name: 'ConfirmationModalComponent',
    props: {
        isOpen: { type: Boolean, required: false },
        title: { type: String, required: false, },
        description: { type: String, required: true },
        okText: {  type: String, required: false, default: 'Confirmar' },
        cancelText: { type: String, required: false, default: 'Cancelar' },
        isLoading: { type: Boolean, required: false },
        showActions: { type: Boolean, default: true },
    },
    data: () => ({
        triggeredByConfirm: false,
    }),
    methods: {
        handleConfirm() {
            this.$emit('confirm');
            this.triggeredByConfirm = true;
            this.internalOpen = false;
        },
    },
    emits: ['cancel', 'confirm'],
    computed: {
        internalOpen: {
            get () {
                return this.$props.isOpen;
            },
            set (value) {
                if (!value && !this.triggeredByConfirm) this.$emit('cancel');
                this.triggeredByConfirm = false;
            }
        },
    },
}
</script>
<style>
    .feedback-modal {
        background-color: white;
        padding: 16px;
        text-align: center;
    }

    .feedback-modal .feedback-modal-icon {
        font-size: 72px;
    }

    .feedback-modal-title {
        font-size: 24px;
        text-align: center;
    }

    .feedback-modal-button {
        margin: auto;
    }
</style>