import { isEmpty, pick } from 'lodash';
import Vue from "vue";

export const createLoadable = (initialData) => ({
    isLoading: false,
    data: initialData,
    isDirty: false,
    error: null,
    initialValueCache: initialData,
});

export const resetearLoadable = (loadable, initialData) => {
    loadable.isLoading = false;
    loadable.data = initialData;
    loadable.isDirty = false;
    loadable.error = null;
    loadable.initialValueCache = initialData;
}

export const toggleLoadable = (loadable) => {
    loadable.isLoading = !loadable.isLoading;
    loadable.isDirty = true;
}

export const setLoadableResponse = (loadable, response, config = { }) => {
    loadable.isLoading = false;
    loadable.error = null;
    
    // Manejo de error response
    if (response.error) {
        loadable.error = response.error;

        if (!config.persistDataOnError) loadable.data = config.initialValue || loadable.initialValueCache;

        if (
            !config.dontShowError &&
            !(config.dontShowErrorIfCode && config.dontShowErrorIfCode.includes(response.error.code))
        ) {
            Vue.prototype.pushAppMessage({ type: "error", message: response.error.message });
        }

        return;
    }

    // Manejo de successful response
    if (config.showAlertOnSuccess) {
        Vue.prototype.pushAppMessage({
            type: "success",
            message: response.message,
        });
    }

    if (config.skipOnSuccess) return;
    
    if (config.isFile) {
        loadable.data = response;
    } else {
        loadable.data = response.data;
    }
}


export const shouldFetch = (loadable) => {
    return !loadable.isDirty && !loadable.isLoading && isEmpty(loadable.data);
}

export const shouldRetry = (loadable) => {
    return loadable.isDirty && !loadable.isLoading && loadable.error;
};

// Pageables
export const PAGINATION_PROPERTY_NAMES = {
    'PAGE_SIZE': 'per_page',
    'COUNT': 'total_rows',
    'CURRENT_PAGE': 'page',
}

const paginationKeys = Object.values(PAGINATION_PROPERTY_NAMES);

export const pickPageableProperties = (headers) => {
    let pagination = pick(headers, paginationKeys);

    for (let key in pagination) {
        pagination[key] = Number(pagination[key]);
    }

    return pagination;
};


export const createPageable = (initialData, perPage) => ({
    ...createLoadable(initialData),
    pagination: {
        [PAGINATION_PROPERTY_NAMES.PAGE_SIZE]: perPage,
        [PAGINATION_PROPERTY_NAMES.COUNT]:  0, 
        [PAGINATION_PROPERTY_NAMES.CURRENT_PAGE]: 1,
    }
});

export const resetearPageable = (pageable, initialData, perPage) => {
    resetearLoadable(pageable, initialData);
    pageable.pagination = {
        [PAGINATION_PROPERTY_NAMES.PAGE_SIZE]: perPage,
        [PAGINATION_PROPERTY_NAMES.COUNT]:  0, 
        [PAGINATION_PROPERTY_NAMES.CURRENT_PAGE]: 1,
    };
}

export const togglePageable = (pageable, data, headers) => {
    toggleLoadable(pageable, data);
    if (headers) pageable.pagination = pickPageableProperties(headers);
}

export const setPageableResponse = (pageable, response, headers, config) => {
    setLoadableResponse(pageable, response, config);

    if (headers) pageable.pagination = pickPageableProperties(headers);
};

export const isResponseSuccesful = (response) => {
    return 'data' in response;
}

export const isApiErrorResponse = (response) => {
    return 'error' in response && 'message' in response.error;
}

export const isBlobApiErrorResponse = async (blob) => {
    const fileError = await blob.text();

    if (!fileError) return [false];

    const json = JSON.parse(fileError);
    return [isApiErrorResponse(json), json];
}