import { http_client_with_status } from "@/plugins/http_client";

const cargarListaOrdenesCompra = async (params = {}) => {
  return await http_client_with_status(`/api/v1/ordenes/compras/obtener/ordenes-compra`, params, "get");
};
// const actualizarEstadoOrdenCompra = async (params = {}) => {
//     return await http_client_with_status(`/api/v1/ordenes/compras/actualizar/estado`, params, "get");
//   };
const visualizarAdjuntoOrden = (idProductoSolicitud, data) => http_client_with_status(`/api/v1/ordenes/compras/obtener-archivo/orden/${idProductoSolicitud}`, data, "get", {}, { responseType: 'blob' });

const cargarListaOrdenesCompraCumplimiento = async (params = {}) => {
  return await http_client_with_status(`/api/v1/ordenes/compras/obtener/ordenes-compra/cumplimiento`, params, "get");
};

// const actualizarEstadoOrdenCompraCumplimiento = async (params = {}) => {
//   return await http_client_with_status(`/api/v1/ordenes/compras/actualizar/estado/cumplimiento`, params, "get");
// };

const obtenerBotonesOrdenCompra = async (id_orden, params = {}) => {
  return await http_client_with_status(`/api/v1/ordenes/compras/${id_orden}/obtener-botones`, params, "get");
};
const alterarEstadoCompra = async (id_secuencia, params = {}) => {
  return await http_client_with_status(`/api/v1/ordenes/compras/alterar-estado/global/${id_secuencia}`, params, "post", {
    'Content-Type': 'multipart/form-data'
  });
};
const obtenerEstadoOrden = async (params = {}) => {
  return await http_client_with_status(`/api/v1/ordenes/compras/obtener-estado`, params, "get");
};

export default {
  cargarListaOrdenesCompra,
  // actualizarEstadoOrdenCompra,
  visualizarAdjuntoOrden,
  cargarListaOrdenesCompraCumplimiento,
  // actualizarEstadoOrdenCompraCumplimiento,
  obtenerBotonesOrdenCompra,
  alterarEstadoCompra,
  obtenerEstadoOrden,
}
