import moment from 'moment';

const formatTimeString = (time, unit) => time > 1 ? `${time.toFixed(0)} ${unit}s` : `${time.toFixed(0)} ${unit}`;

export const transformHoursToString = (hours) =>  {
    const days = hours / 24;

    return days < 1 ? formatTimeString(hours, 'hora') : convertDaysToPreciseString(days);
}

export const convertDaysToPreciseString = (days) => {
    const floorDays = Math.floor(days);
    const remainingHours = Math.ceil(days - floorDays);

    return remainingHours > 0
        ?  `${formatTimeString(floorDays, 'dia')} y ${formatTimeString(remainingHours, 'hora')}`
        : `${formatTimeString(Number(days), 'dia')}`
};

export const formatDate = (date, format = 'DD-MM-YYYY HH:mm:ss') => {
    const momentObject = moment(date);

    return momentObject.isValid() ? momentObject.format(format) : '-';
};

export const obtenerNombreDeMes = (mes) => moment().month(mes - 1).format('MMMM');
