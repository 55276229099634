const routerGestionUsuarios = [
  {
    path: "/usuario/list-usuarios-solicitudes",
    name: "listado-usuarios-solicitudes",
    component: () =>
      import(/* webpackChunkName: "mainUsuarios" */ "./mainUsuariosView.vue"),
  },
  {
    path: "/usuario/crear-usuario",
    name: "crear-usuario",
    component: () =>
      import(
        /* webpackChunkName: "agregarUsuario" */ "./creacionUsuarioView.vue"
      ),
  },
  {
    path: '/usuario/solicitar-usuario',
    name: 'solicitar-usuario',
    component: () =>
      import(
        /* webpackChunkName: "solicitudUsuario" */ "./solicitudUsuarioView.vue"
      ),
  },
  {
    path: '/usuario/ver-solicitud-usuario/:id',
    name: 'visualizar-solicitud-usuario',
    component: () =>
      import(
        /* webpackChunkName: "visualizarSolicitudUsuario" */ "./visualizarSolicitudUsuarioView.vue"
      ),
  },
];

export default routerGestionUsuarios;
