import Vue from "vue";
import "./plugins/axios";
import "./plugins/http_client";
import "./plugins/utils";
import "./plugins/security";
import "./plugins/tiptap";
import App from "./App.vue";
import router from "./router";
import "./services";
import store from "./store";
import vuetify from "./plugins/vuetify";
import vMask from "v-mask";
import "./assets/scss/variables.scss";
import ProductZoomer from "vue-product-zoomer";
import VCalendar from "v-calendar";
import Vue2MultipleVModels from "vue2-multiple-vmodels";
import { Plugin } from 'vue-fragment';

import Shepherd from "shepherd.js";
import "shepherd.js/dist/css/shepherd.css";
Vue.prototype.$shepherd = new Shepherd.Tour({
  useModalOverlay: true,
  keyboardNavigation: true,
  defaultStepOptions: {
    canClickTarget:false,
    classes: "stepsClass",
    scrollTo: true,
  },
});

const moment = require("moment");
require("moment/locale/es");
Vue.prototype.moment = moment;
import momentTime from "moment-timezone";

Vue.use(vMask);
Vue.use(require("vue-moment"), {
  moment,
  momentTime,
});
Vue.use(Vue2MultipleVModels);

import loadComponentes from "@/plugins/loadComponents";
import { mapState } from "vuex";
loadComponentes(Vue);

Vue.config.productionTip = false;

import Vuelidate from "vuelidate";

Vue.use(Vuelidate);
Vue.use(ProductZoomer);
Vue.use(VCalendar, {
  componentPrefix: "vc",
});

new Vue({
  router,
  store,
  vuetify,
  beforeCreate() {
    const token = localStorage.getItem("token");
    if (token) store.commit("setToken", token);
  },
  computed: {
    ...mapState(["token"]),
  },
  render: (h) => h(App),
}).$mount("#app");

// Fragments support
Vue.use(Plugin);
