import { http_client_with_status } from "@/plugins/http_client";

const cargarProveedoresFamilia = (idFamilia, params) => 
    http_client_with_status(`api/v1/cm-contactos-proveedor/proveedores/${idFamilia}`, params, "get");

const cargarContactosProveedor = (idFamilia, idProveedor, params) => 
    http_client_with_status(`api/v1/cm-contactos-proveedor/contactos/${idFamilia}/${idProveedor}`, params, "get");

export default {
    cargarProveedoresFamilia,
    cargarContactosProveedor,
};
