const defaultState = () => ({
    list_solicitudes_st: [],
    pag_solicitudes_st: {
        page: 1,
        per_page: 10,
        total_rows: 0,
    },
    unidad_solicitud_st: null,
    list_insumos_st: [],
    list_especificaciones_st: [],
    pag_especificaciones_st: {
        page: 1,
        per_page: 10,
        total_rows: 0,
    },
    list_precios_st: [],
    pag_precios_st: {
        page: 1,
        per_page: 10,
        total_rows: 0,
    },
    list_administradores_st: [],
    pag_administradores_st: {
        page: 1,
        per_page: 10,
        total_rows: 0,
    },
    list_financiamientos_st: [],
    pag_financiamientos_st: {
        page: 1,
        per_page: 10,
        total_rows: 0,
    },
})

export default defaultState