<template>
  <Fragment v-if="nuevo_tiempo">
    <VueCountdown v-show="mostrarCountdown" :time="nextDay" v-slot="{ days, hours, minutes, seconds }" @end="endCount"
      @progress="handleProgress">
      <div class="d-flex align-center justify-center text-h6" :class="color">
        <span :class="`${color} mr-3`" v-if="titulo != ''">{{ titulo }}</span>
        {{ days ? days + " días" : "" }}
        {{ hours < 10 ? "0" + hours : hours }}:{{ minutes < 10 ? "0" + minutes : minutes }}:{{ seconds < 10 ? "0" + seconds : seconds }} 
      </div>
      <div class="d-flex align-center justify-center" style="gap: 8px;">
        <p v-if="alertaSincronizacionVisible" class="text-caption text-uppercase mb-0" :class="color">
          <v-icon :class="color" style="transform: translateY(-1px);" small>mdi-clock</v-icon>
          Sincronizando...
        </p>
      </div>
    </VueCountdown>
    <v-alert type="info" v-show="!mostrarCountdown" color="#39414c" class="pulsating-chip white--text">
      Cerrando Subasta
    </v-alert>
  </Fragment>

</template>
<script>
import VueCountdown from "@chenfengyuan/vue-countdown";
import { Fragment } from "vue-fragment";
import { Listener, Observable } from "@/utils/observable";
import { mapMutations } from "vuex";

export default {
  props: {
    nextDay: {
      type: Number,
      default: 0,
    },
    titulo: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "redSubasta--text",
    },
    nuevo_tiempo: {
      type: Boolean,
      default: false,
    }
  },
  components: { VueCountdown, Fragment },
  name: "AppCountdown",
  data: () => ({
    fecha: null,
    mostrarCountdown: true,
    // Indicador sincronizacion
    alertaSincronizacionVisible: false,
    timeoutCleaner: null,
    unsubscriber: null,
  }),
  methods: {
    ...mapMutations("etapaSubastaInversaStore", ["setEstadoCountdownSubasta"]),
    handleProgress(data) {
      if (data.totalDays === 0 && data.totalHours === 0 && data.totalMinutes === 0 && data.totalSeconds <= 10) {
        this.$emit("cierreSala");
        this.mostrarCountdown = false;
        this.setEstadoCountdownSubasta(false);
      } else {
        this.mostrarCountdown = true;
        this.$emit("cierreSala", true);
        this.setEstadoCountdownSubasta(true);
      }
    },
    endCount() {
      this.$emit("status", true);
    },
    mostrarIndicadorSincronizacion() {
      if (this.timeoutCleaner) {
        clearTimeout(this.timeoutCleaner);
        this.timeoutCleaner = null;
      }

      this.alertaSincronizacionVisible = true;

      this.timeoutCleaner = setTimeout(() => {
        this.alertaSincronizacionVisible = false;
      }, 1000);
    },
  },
  watch: {
    mostrarCountdown: {
      handler(value) {
        Observable.emit('CAMBIO-VISIBILIDAD-COUNTDOWN-SUBASTA', value);
      },
      immediate: true,
    },
  },
  created() {
    const listener = new Listener('AppCountdownHoraServidor').setHandler(() => this.mostrarIndicadorSincronizacion());
    const unsubscriber = Observable.getInstance().subscribe('REFRESCAR-HORA-SERVIDOR', listener);

    this.unsubscriber = unsubscriber;
    this.setEstadoCountdownSubasta(true);
  },
  beforeDestroy() {
    Observable.emit('CAMBIO-VISIBILIDAD-COUNTDOWN-SUBASTA', false);
    this.unsubscriber?.();
    this.timeoutCleaner = null;
    this.setEstadoCountdownSubasta(false);
  },
};
</script>

<style scoped>
.pulsating-chip {
  animation: pulsate 1s infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}
</style>
