const administracionSanciones = [
  {
    path: "/administracion-sanciones",
    name: "administracion-sanciones",
    component: () =>
      import(
        /* webpackChunkName: "administracion-sanciones" */ "./AdministracionSancionesView.vue"
      ),
  },
];

export default administracionSanciones;
