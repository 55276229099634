<template>
  <Fragment>
    <template>
      <h3>CREAR SOLICITUD</h3>
      <v-form ref="form" class="mt-4">
        <v-select 
          v-model="form.id_institucion"
          label="Institución"
          placeholder="Institución"
          :items="institucionesUsuario"
          item-text="nombre"
          item-value="id"
          outlined
          light
          :error-messages="id_institucionErrors"
          @change="$v.form.id_institucion.$touch()"
          @blur="$v.form.id_institucion.$touch()"
        />
        <v-autocomplete
            class="mt-2"
            :items="filteredProcesses"
            :loading="availableProcesses.isLoading"
            item-text="formattedText"
            item-value="id"
            label="Proceso de Compra"
            v-model="form.id_proceso_compra"
            outlined
            :error-messages="id_proceso_compraErrors"
            @change="$v.form.id_proceso_compra.$touch()"
            @blur="$v.form.id_proceso_compra.$touch()"
            light
            @input="handleAutocompleteInput"
        >
          <template v-slot:selection="{ item }">
            <v-list-item class="px-0">
                <v-list-item-content class="py-0">
                    <v-list-item-title>
                        {{ item.codigo_proceso }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{ item.nombre_proceso }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-content>
                  <v-list-item-title>
                      {{ item.codigo_proceso }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                      {{ item.nombre_proceso }}
                  </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
        <v-select
            :items="requestTypes.data"
            :loading="requestTypes.isLoading"
            item-text="nombre"
            item-value="id"
            label="Tipo de solicitud"
            v-model="form.id_tipo_solicitud"
            outlined
            :error-messages="id_tipo_solicitudErrors"
            @change="$v.form.id_tipo_solicitud.$touch()"
            @blur="$v.form.id_tipo_solicitud.$touch()"
            light
        />
        <v-text-field
            label="Teléfono"
            placeholder="Teléfono"
            outlined
            v-model="form.telefono"
            class="mt-2"
            :error-messages="telefonoErrors"
            @change="$v.form.telefono.$touch()"
            @blur="$v.form.telefono.$touch()"
            v-mask="'####-####'"
            light
        />

        <v-autocomplete
            class="mt-2"
            :items="usuariosContrato.data"
            :loading="usuariosContrato.isLoading"
            :item-text="administradorContrato"
            item-value="empleado.usuario.id"
            v-model="form.id_usuario_contrato"
            label="Administrador de contrato"
            outlined
            light
            :error-messages="unidadesUsuariosErrors"
            @change="$v.form.id_usuario_contrato.$touch()"
            @blur="$v.form.id_usuario_contrato.$touch()"
        >
          <template v-slot:selection="{ item }">
              <v-list-item class="px-0">
                  <v-list-item-content class="py-0">
                      <v-list-item-title>
                          {{ item.empleado.correo }}
                      </v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
            </template>
        </v-autocomplete>

        <v-textarea
            v-model="form.descripcion"
            label="Descripción"
            placeholder="Descripción"
            outlined
            :error-messages="descripcionErrors"
            @change="$v.form.descripcion.$touch()"
            @blur="$v.form.descripcion.$touch()"
            light
            class="mt-2"
        />
        <template v-if="isProductKind">
          <v-select
              :items="regions.data"
              :loading="regions.isLoading"
              item-text="nombre"
              item-value="id"
              label="Región"
              placeholder="Región"
              v-model="form.id_region"
              class="mt-2"
              outlined
              :error-messages="id_regionErrors"
              @change="$v.form.id_region.$touch()"
              @blur="$v.form.id_region.$touch()"
              light
          />
          <v-textarea
              v-model="form.direccion"
              label="Dirección de entrega"
              placeholder="Dirección"
              outlined
              :error-messages="direccionErrors"
              @change="$v.form.direccion.$touch()"
              @blur="$v.form.direccion.$touch()"
              light
              class="mt-2"
          />
        </template>
        <v-checkbox 
          class="mt-2" 
          v-model="form.solicitud_de_emergencia" 
          label="Solicitud de emergencia"
          light
        />
        <RequestAmountsFeed :isEmergencyRequest="form.solicitud_de_emergencia"/>
        <div class="mt-10 d-flex flex-column flex-md-row" style="gap: 16px;">
          <div class="flex-shrink-1 flex-grow-1 order-2 order-md-1" style="flex-basis: 50%;">
            <v-btn
              color="secondary"
              @click="clearForm"
              :loading="requestCreation.isLoading"
              style="width: 100%;"
              large
            >
              Limpiar
            </v-btn>
          </div>
          <div class="flex-shrink-1 flex-grow-1 order-1 order-md-2" style="flex-basis: 50%;">
            <v-btn
              style="width: 100%;"
              :loading="requestCreation.isLoading"
              color="primary"
              large
              @click.stop="submit"
            >
              Empezar solicitud
            </v-btn>  
          </div>
        </div>
      </v-form>
    </template>
    <ConfirmationModalComponent
        :isOpen="isConfirmationOpen"
        description="¿Desea continuar con la creación de la solicitud?"
        @confirm="createRequest"
        @cancel="isConfirmationOpen = false"
        :isLoading="requestCreation.isLoading"
    />
  </Fragment>
</template>
<script>
import { ConfirmationModalComponent } from '@/components/utils';
import { validationMixin } from 'vuelidate'
import { Observable } from '@/utils/observable.js';
import { Fragment } from 'vue-fragment';
import {
  createLoadable,
  toggleLoadable,
  setLoadableResponse,
  isResponseSuccesful
} from '@/utils/loadable.js';
import { required, maxLength } from 'vuelidate/lib/validators'
import RequestAmountsFeed from './RequestAmountsFeed.vue';
import { mapState, mapMutations } from "vuex";
import { Validator } from '@/utils/form-validation.js';
import { tel } from '@/utils/custom-validation-rules.js';

export default {
  name: 'CreateRequestComponent',
  mixins: [validationMixin],
  components: { ConfirmationModalComponent, RequestAmountsFeed, Fragment },
  validations() {
    return {
      form: {
        id_institucion: { required },
        id_proceso_compra: { required },
        ...(this.isProductKind && {id_region: {required}}),
        ...(this.isProductKind && {direccion: {required, maxLength: maxLength(300)}}),
        descripcion: {required, maxLength: maxLength(350)},
        id_tipo_solicitud: {required,},
        id_usuario_contrato: {required,},
        telefono: {required, tel,},
      },
    };
  },
  data: () => ({
    // Data
    regions: createLoadable([]),
    requestTypes: createLoadable([]),
    requestCreation: createLoadable(null),
    availableProcesses: createLoadable([]),
    usuariosContrato: createLoadable([]),
    mappedUnidadesUsuarios: [],
    // Form
    form: {
      id_institucion: null,
      id_proceso_compra: null,
      id_region: null,
      descripcion: '',
      direccion: '',
      solicitud_de_emergencia: false,
      id_tipo_solicitud: null,
      telefono: null,
      id_usuario_contrato: null,
    },
    // UI
    isConfirmationOpen: false,
  }),
  computed: {
    ...mapState('catalogoEnLinea', ["inCartRequest", "timer"]),
    ...mapState(['institucionesUsuario']),
    id_institucionErrors() {
      return new Validator(this.$v.form.id_institucion).detect().getResult();
    },
    id_proceso_compraErrors() {
      return new Validator(this.$v.form.id_proceso_compra).detect().getResult();
    },
    direccionErrors() {
      return new Validator(this.$v.form.direccion).detect().getResult();
    },
    unidadesUsuariosErrors() {
      return new Validator(this.$v.form.id_usuario_contrato).detect().getResult();
    },
    descripcionErrors() {
      return new Validator(this.$v.form.descripcion).detect().getResult();
    },
    id_regionErrors() {
      return new Validator(this.$v.form.id_region).detect().getResult();
    },
    id_tipo_solicitudErrors() {
      return new Validator(this.$v.form.id_tipo_solicitud).detect().getResult();
    },
    telefonoErrors() {
      return new Validator(this.$v.form.telefono).detect().getResult();
    },
    isProductKind() {
      return this.form.id_tipo_solicitud === 1;
    },
    filteredProcesses() {
      return this.availableProcesses.data.map(process => ({
        ...process,
        formattedText: `${process.codigo_proceso} - ${process.nombre_proceso}`,
      })).filter((process) => {
        const codigoProceso = process.codigo_proceso.toLowerCase();
        const nombreProceso = process.nombre_proceso.toLowerCase();
        return codigoProceso || nombreProceso;
      });
    },
    administradorContrato() {
      return usuariosContrato => usuariosContrato.empleado.usuario.email;
    }

  },
  methods: {
    ...mapMutations("catalogoEnLinea", ["injectRequestData", "setFeedbackModalConfig"]),
    ...mapMutations("utils", ["setCartDrawer"]),
    async fetchRegions() {
      toggleLoadable(this.regions);
      const {data} = await this.services.Region.getRegions();
      setLoadableResponse(this.regions, data);
    },
    async fetchAvailableProcesses() {
      toggleLoadable(this.availableProcesses);
      const { data } = await this.services.PacProcesos.procesosDisponibles();
      setLoadableResponse(this.availableProcesses, data);
    },
    async fetchRequestTypes() {
      toggleLoadable(this.requestTypes);
      const {data} = await this.services.PurchaseRequest.getRequestTypes();
      setLoadableResponse(this.requestTypes, data);
    },
    async fetchUnidadesUsuarios() {
      toggleLoadable(this.usuariosContrato)
      const { data } = await this.services.Usuarios.obtenerUnidadesUsuarios();
      setLoadableResponse(this.usuariosContrato, data);
    },

    async createRequest() {
      const request = { ...this.form };

      if (!this.isProductKind) {
        delete request.direccion;
        delete request.id_region;
      }

      toggleLoadable(this.requestCreation);
      const {data} = await this.services.PurchaseRequest.createRequest(request);

      setLoadableResponse(this.requestCreation, data, {skipOnSuccess: true});
      this.isConfirmationOpen = false;
      if (!isResponseSuccesful(data)) return;

      this.setCartDrawer(false);
      this.injectRequestData(data.data);
      this.clearForm();
      Observable.emit('refresh-requests-to-modify');
      this.setFeedbackModalConfig({
        isOpen: true,
        type: "success",
        description: "Solicitud creada con éxito",
        buttonText: "Aceptar",
        onClose: () => this.$router.push({path: "/catalogo-electronico/", replace: true}),
      });
    },
    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.isConfirmationOpen = true;
    },
    clearForm() {
      this.$v.$reset();
      this.$refs.form.reset();
    },
    handleAutocompleteInput() {
      this.fetchAvailableProcesses();
  },

  },
  async created() {
    await this.fetchRegions();
    await this.fetchRequestTypes();
    await this.fetchAvailableProcesses();
    await this.fetchUnidadesUsuarios();
  },
};
</script>
<style>
</style>