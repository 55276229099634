import { http_client } from "../plugins/http_client";

const http_client_slim = async (url, parametros = {}, metodo = "get") =>
  http_client(`/api/v1/proceso-compra-libre/${url}`, parametros, metodo);

const changeEstadoProcesoLibre = async (id_proceso_compra, params) =>
  http_client_slim(`cambiar-estado/proceso-libre/${id_proceso_compra}`, params, "put");

const listProcesoLibre = async (id_proceso_compra, params) =>
  http_client_slim(`listar/proceso-libre/${id_proceso_compra}`, params);

export default {
	changeEstadoProcesoLibre,
	listProcesoLibre,
};
