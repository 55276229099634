import requesObs from "../../../../services/Obs.services";
import ObsServices from "../../../../services/Obs.services";

export default {
  namespaced: true,

  state: {
    listado_obs: [],
    listado_categorias_obs: [],
    filters: {
      page: 1,
      per_page: 10,
      total_rows: null,
      id_obs: null,
      eliminado: true,
      sort_by: null,
      order: null,
    },
    listado_obs_select: [],
  },

  mutations: {
    setListaObs(state, payload) {
      state.listado_obs = payload;
    },
    setCategoriasObs(state, payload) {
      state.listado_categorias_obs = payload;
    },
    setPaginationData(state, payload) {
      state.filters.page = Number(payload.page);
      state.filters.per_page = Number(payload.per_page);
      state.filters.total_rows = Number(payload.total_rows);
    },
    setListaObsSelect(state, payload) {
      state.listado_obs_select = payload;
    },
    setObsFilter(state, payload) {
      state.filters.id_obs = payload;
    },
    setSortColumn(state, payload) {
      state.filters.sort_by = payload;
    },
    setSortDesc(state, payload) {
      state.filters.order = payload;
    },
    setPage(state, payload) {
      state.filters.page = payload;
    },
    setPerPage(state, payload) {
      state.filters.per_page = payload;
    }
  },

  actions: {
    async getListaobs({ commit, state }) {
      const response = await ObsServices.getObsList(state.filters);
      if (response.status === 200) {
        commit("setListaObs", response?.data);
        commit("setPaginationData", response?.headers);
      } else {
        commit("setListaObs", []);
      }
    },
    async getListadoCategoriasObs({ commit }) {
      const response = await ObsServices.getObsCategoriaList();
      if (response.status === 200) {
        commit("setCategoriasObs", response?.data);
      } else {
        commit("setCategoriasObs", []);
      }
    },
    async getListaObsSelect({ commit }) {
      const response = await ObsServices.getObsList({
        pagination: false,
        eliminado: true,
        productos: true,
      });
      if (response.status === 200) {
        commit("setListaObsSelect", response?.data);
      } else {
        commit("setListaObsSelect", []);
      }
    },

    async PostAgregarObs({ commit }, data) {
      const response = await ObsServices.postObs(data);
      return response;
    },

    async putActualizarObs({ commit }, datos) {
      const response = await ObsServices.updateObs(datos);
      return response;
    },
  },
};
