import {http_client} from "@/plugins/http_client";

const getCifradosPresupuestarios = async(idInstitucion, params ={})=>{
    return await  http_client(`/api/v1/cifrados/fondos/${idInstitucion}`,params,"get")
}

const getConfiguracionCifrado = async(params ={})=>{
  return await  http_client("/api/v1/cifrados/listado", params, "get")
}

const postConfiguracionCifrado = async(body ={})=>{
  return await  http_client("/api/v1/cifrados/", body, "post")
}

const postAsignacionFondoUnidad = async(body ={})=>{
  return await  http_client("/api/v1/cifrados/finaciamientos/", body, "post")
}

const putUpdateCifrado = async(id_fondo, body ={})=>{
  return await  http_client(`/api/v1/cifrados/fondos/${id_fondo}`, body, "put")
}

const getFinanciamientoUnidades = async(params ={})=>{
  return await  http_client("/api/v1/cifrados/unidades", params, "get")
}

const deleteCifradoPresupuestario = async (id_cifrado_presupuestario, params ={}) => {
  return await  http_client(`/api/v1/cifrados/${id_cifrado_presupuestario}`, params, "delete")
}

const cambiarMontoDisponible = async (id_cifrado_presupuestario, params ={}) => {
  return await  http_client(`/api/v1/cifrados/${id_cifrado_presupuestario}/disponible`, params, "put")
}

const putUpdateUnidad = async (id_financiamiento_unidad, params ={}) => {
  return await  http_client(`/api/v1/cifrados/unidad/financiamiento/${id_financiamiento_unidad}/reasignacion`, params, "put")
}

const deleteFondosAsignadosUnidad = async (id_financiamiento_unidad, params ={}) => {
  return await  http_client(`/api/v1/cifrados/unidad/financiamiento/${id_financiamiento_unidad}`, params, "delete")
}

export default{
  getCifradosPresupuestarios,
  getConfiguracionCifrado,
  postConfiguracionCifrado,
  postAsignacionFondoUnidad,
  getFinanciamientoUnidades,
  putUpdateCifrado,
  deleteCifradoPresupuestario,
  cambiarMontoDisponible,
  putUpdateUnidad,
  deleteFondosAsignadosUnidad,
}