const routerSubastaInversa = [
  {
    path: "/proceso-compra/subasta-inversa",
    name: "subasta-inversa",
    component: () =>
      import(
        /* webpackChunkName: "subastaInversaView" */ "./subastaInversaView.vue"
      ),
  },
];

export default routerSubastaInversa;
