const routesInteligenciaMercado = [
    {
      path: "/informacion-inteligencia-mercado",
      name: "informacion-inteligencia-mercado",
      component: () =>
        import(
          /* webpackChunkName: "informacionInteligenciaMercado" */ "./index.vue"
        )
    }
];

export default routesInteligenciaMercado;
