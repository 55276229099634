import moment from "moment-timezone";

export const setSalas = (state, payload) => {
    state.salas = payload.filter((sala) => {
        return !!sala?.subastas_activas || !!sala?.proxima_subasta?.length > 0
    });
};

export const setSubastas = (state, payload) => {
    state.subastas = payload;
};

export const setObss = (state, payload) => {
    state.obss = payload;
};

export const setPresentaciones = (state, payload) => {
    state.presentaciones = payload;
};

export const setUnidades = (state, payload) => {
    state.unidades = payload;
};

export const setSolicitudes = (state, payload) => {
    state.solicitudes = payload;
};

export const setForm = (state, payload) => {
    state.form = payload;
};

export const setIsShow = (state, payload) => {
    state.isShow = payload;
};

export const setIsEdit = (state, payload) => {
    state.isEdit = payload;
};

export const setSubastaId = (state, payload) => {
    state.subastaId = payload;
};

export const setRangoFechas = (state, payload) => {
    const inicio = moment(payload.fecha_hora_inicio).format("YYYY-MM-DD HH:mm");
    const fin = moment(payload.fecha_hora_fin).format("YYYY-MM-DD HH:mm");

    const fechasSubasta = {
        inicio,
        fin,
    };

    state.rangoFechasInicio = fechasSubasta;
    state.rangoFechasFin = fechasSubasta;
};

export const setFechaMaximaInicio = (state, payload) => {
    const fin =
        payload != ""
            ? moment(payload).format("YYYY-MM-DD HH:mm")
            : state.rangoFechasFin.fin;
    const fechasSubasta = {
        inicio: state.rangoFechasInicio.inicio,
        fin: fin,
    };

    state.rangoFechasInicio = fechasSubasta;
};

export const setFechaMinimaFin = (state, payload) => {
    const inicio =
        payload != ""
            ? moment(payload).format("YYYY-MM-DD HH:mm")
            : state.rangoFechasInicio.inicio;
    const fechasSubasta = {
        inicio: inicio,
        fin: state.rangoFechasFin.fin,
    };

    state.rangoFechasFin = fechasSubasta;
};

export const setHorasValidasInicio = (state, payload) => {
    state.horasValidasInicio = payload;
};

export const setHorasValidasFin = (state, payload) => {
    state.horasValidasFin = payload;
};

export const setEstadoCountdownSubasta = (state, payload) => {
    state.hayCountdownSubasta = payload;
}
