import LineasTrabajosServices from "../../../../../services/LineasTrabajos.services";

export default {
  async getListaLineas({ commit }, filters = {}) {
    const response = await LineasTrabajosServices.getLineas(filters);
    if (response.status === 200) {
      commit("setListaLineas", response?.data);
      commit("setPaginationData", response?.headers);
    } else {
      commit("setListaLineas", []);
    }
  },

  async storeLinea({ commit }, data) {
    const response = await LineasTrabajosServices.storeLinea(data);
    return response;
  },

  async updateLinea({ commit }, data) {
    const response = await LineasTrabajosServices.updateLinea(data);
    return response;
  },
  async deleteLinea({ commit }, id) {
    const response = await LineasTrabajosServices.deleteLinea(id);
    return response;
  },
};
