const routerPeo = [
  {
    path: "/peo",
    name: "evaluacion-ofertas-peo",
    component: () =>
      import(
        /* webpackChunkName: "detallesInsumo" */ "./evaluacionesPeoView.vue"
      ),
  },
  {
    path: "peo-ganadores/:id",
    name: "peo-ganadores",
    component: () =>
      import(/* webpackChunkName: "peo-ganadores" */ "./peoGanadoresView.vue"),
  },
  {
    path: "peo-nuevo-ganador/:id_proceso/:id_oferta?",
    name: "peo-nuevo-ganador",
    component: () =>
      import(
        /* webpackChunkName: "peo-nuevo-ganador" */ "./peoNuevoGanadorView.vue"
      ),
  },
];

export default routerPeo;
