const reporteProvider = [
    {
        path: "/reporte-proveedor",
        name: "reporte-proveedor",
        component: () =>
            import(/* webpackChunkName: "reporteProveedor" */ "./ReporteProveedorView.vue")
    },
    {
        path: "/reporte-proveedor/seguimiento/:id_reporte_sancion",
        name: "seguimiento-proveedor",
        component: () =>
            import(/* webpackChunkName: "seguimientoProveedor" */ "./SeguimientoProveedorView.vue")
    }
]

export default reporteProvider