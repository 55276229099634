import CryptoJS from 'crypto-js';
import AuthServices from '../services/Auth.services';

export default async () => {
  const response = await AuthServices.getServerTime();
  const data = { origin: process.env.VUE_APP_ORIGIN, timestamp: response.data.time };
  const privateKey = process.env.VUE_APP_PRIVATE_KEY;
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), privateKey).toString();
  
  return `${encryptedData}`;
}