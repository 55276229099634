import { http_client } from "@/plugins/http_client";

const listSelectCategoriaAtributo = async (params = {pagination:false}) => {
  return await http_client(`api/v1/cm-categoria-atributo`, params, "get");
};


export default {
  listSelectCategoriaAtributo,
};
