import { http_client_slim } from "../plugins/http_client";

const client = http_client_slim("/api/v1/cambio/estado/proceso");

const obtenerEstados = async () => client("");

const obtenerEstadoProceso = async (id) => client(`/${id}`);

const modificarEstadoProceso = async (id, id_seguimiento, data) =>
  client(`/${id}/${id_seguimiento}`, { method: "patch", data });

export default {
  obtenerEstados,
  obtenerEstadoProceso,
  modificarEstadoProceso,
};
