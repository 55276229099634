export default {
    namespaced:true,
    state:{
        fondo:{}
    },
    mutations:{
        setFondo(state, payload){
            state.fondo = payload
        }
    }
}