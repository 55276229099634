import { http_client_with_status } from "@/plugins/http_client";

const cargarListaConvocatoriasAdministrador = (params) => http_client_with_status(`/api/v1/cm-convocatoria/listado/tecnicos`, params);

const cargarListaConvocatoriasProveedor = (params) => http_client_with_status(`/api/v1/cm-convocatoria/listado/proveedores`, params);

const guardarConvocatoria = (data) => http_client_with_status(`/api/v1/cm-convocatoria/crear/convocatoria`, data, 'post', {
    'Content-Type': 'multipart/form-data',
});

const editarConvocatoria = (idConvocatoria, data) => 
    http_client_with_status(`/api/v1/cm-convocatoria/actualizar/convocatoria/${idConvocatoria}`, data, 'put');

const invitarProveedor = (idConvocatoria, data) => 
    http_client_with_status(`/api/v1/cm-convocatoria/invitar/proveedor/${idConvocatoria}`, data, 'post');

const editarAdjuntoConvocatoria = (idConvocatoria, data) => 
http_client_with_status(`/api/v1/cm-convocatoria/actualizar/archivo/${idConvocatoria}`, data, 'put', { 'Content-Type': 'multipart/form-data' });

const visualizarAdjuntoConvocatoria = (idConvocatoria) => 
    http_client_with_status(`/api/v1/cm-convocatoria/obtener/archivo/${idConvocatoria}`, null, "get", {}, { responseType: 'blob' });

const cambiarVisibilidadConvocatoria = (idConvocatoria, data) => 
    http_client_with_status(`/api/v1/cm-convocatoria/modificar-visibilidad/convocatoria/${idConvocatoria}`, data, 'patch');
    
// Participacion
const participarEnConvocatoria = (idConvocatoria) => http_client_with_status(`/api/v1/cm-convocatoria/aceptar/invitacion/${idConvocatoria}`, null, 'post');

const editarAdjuntoParticipacion = (idParticipacion, data) => 
http_client_with_status(`/api/v1/cm-convocatoria/actualizar/archivo/proveedor/${idParticipacion}`, data, 'put', { 'Content-Type': 'multipart/form-data' });

const visualizarAdjuntoParticipacion = (idParticipacion) => 
    http_client_with_status(`/api/v1/cm-convocatoria/obtener/archivo/proveedor/${idParticipacion}`, null, "get", {}, { responseType: 'blob' });

// Ofertas convocatorias 
const cargarOfertasConvocatoria = (idConvocatoria, filtros) => http_client_with_status(`/api/v1/cm-convocatoria/convocatoria/ofertas/${idConvocatoria}`, filtros, 'get');

const aprobarOferta = (idOferta) => http_client_with_status(`/api/v1/cm-convocatoria/aprobar/oferta/${idOferta}`, null, 'post');

const observarOferta = (idOferta, data) => http_client_with_status(`/api/v1/cm-convocatoria/observar/oferta/${idOferta}`, data, 'post');

// Documentos convocatoria
const cargarDocumentosConvocatoria = (idConvocatoria, filtros) => http_client_with_status(`/api/v1/cm-convocatoria/convocatoria/documentos/${idConvocatoria}`, filtros, 'get');

const guardarDocumentoConvocatoria = (idConvocatoria, data) => http_client_with_status(`/api/v1/cm-convocatoria/convocatoria/documentos/crear/${idConvocatoria}`, data, 'post', {
    'Content-Type': 'multipart/form-data',
});

const eliminarDocumentoConvocatoria = (idDocumento) => http_client_with_status(`/api/v1/cm-convocatoria/convocatoria/documentos/eliminar/${idDocumento}`, null, 'delete');

const visualizarDocumentoConvocatoria = (idDocumento) => http_client_with_status(`/api/v1/cm-convocatoria/convocatoria/documentos/visualizar/${idDocumento}`, null, 'get', {}, { responseType: 'blob' });

// Busqueda por participacion
const cargarConvocatoriasPorParticipacion = (params) => http_client_with_status(`/api/v1/cm-convocatoria/convocatoria/busqueda/participacion-proveedor`, params, 'get');

export default {
    cargarListaConvocatoriasAdministrador,
    cargarListaConvocatoriasProveedor,
    guardarConvocatoria,
    editarConvocatoria,
    invitarProveedor,
    editarAdjuntoConvocatoria,
    visualizarAdjuntoConvocatoria,
    participarEnConvocatoria,
    editarAdjuntoParticipacion,
    visualizarAdjuntoParticipacion,
    cambiarVisibilidadConvocatoria,
    cargarOfertasConvocatoria,
    aprobarOferta,
    observarOferta,
    // Documentos de convocatoria
    cargarDocumentosConvocatoria,
    guardarDocumentoConvocatoria,
    eliminarDocumentoConvocatoria,
    visualizarDocumentoConvocatoria,
    // Busqueda por participacion
    cargarConvocatoriasPorParticipacion,
};
