const solicitudOBS = [
  {
    path: "/proceso-solicitud-compra/list-solicitudes",
    name: "list-solicitudes-compra",
    component: () =>
      import(/* webpackChunkName: "solicitudList" */ "./solicitudesCompraView.vue"),
  },
  {
    path: "/proceso-solicitud-compra/crear-solicitud",
    name: "crear-solicitud-compra",
    component: () =>
      import(
        /* webpackChunkName: "solicitudCreate" */ "./solicitudCreateView.vue"
      ),
  },
  {
    path: "/proceso-solicitud-compra/:id_solicitud/compras/:tipo",
    name: "compras",
    component: () =>
      import(
        /* webpackChunkName: "SolicitudesOBS" */ "./insumoListView.vue"
      ),
  },
  {
    path: "/proceso-solicitud-compra/:id_solicitud/agregar-insumo/:tipo",
    name: "agregar-insumo-solicitud-compra",
    component: () =>
      import(
        /* webpackChunkName: "agregarInsumoCompra" */ "./agregarInsumoView.vue"
      ),
  },
  {
    path: "/agregar-insumo/:tipo?",
    name: "agregar-insumo",
    component: () =>
      import(
        /* webpackChunkName: "SolicitudesOBS" */ "./components/AgregarInsumosComponent.vue"
      ),
  },
  {
    path: "/agregar-insumo/:idSolicitud/detalles/:tipo/:idInsumo?",
    name: "agregar-insumo-detalles",
    component: () =>
      import(
        /* webpackChunkName: "SolicitudesOBS" */ "./agregarInsumoDetalleView.vue"
      ),
  },

  {
    path: "/proceso-solicitud-compra/catalogo-de-compras",
    name: "catalogo-de-compras",
    component: () =>
      import(
        /* webpackChunkName: "catalogo_de_compras" */ "../../components/CatalogoComprasComponent.vue"
      ),
  },
  {
    path: "/catalogo-de-compras-producto/:id",
    name: "catalogo-de-compras-producto",
    component: () =>
      import(
        /* webpackChunkName: "catalogo_de_compras_producto" */ "../../components/CatalogoCompraProductosComponent.vue"
      ),
  },
  {
    path: "/editar-insumo-solicitud/:id",
    name: "editar-insumo-solicitud",
    component: () =>
      import(
        /* webpackChunkName: "editar_insumo_solicitud" */ "../../components/EditarInsumoComponent.vue"
      ),
  },
  {
    path: "/solicitud-obs-tecnico",
    name: "solicitud-obs-tecnico",
    component: () =>
      import(
        /* webpackChunkName: "solicitud-obs-tecnico" */ "./solicitudOBSTecnico.vue"
      ),
  },
  {
    path: "/proceso-solicitud-compras/verificar-proveedor/:tipo",
    name: "verificar-proveedor",
    component: () =>
      import(
        /* webpackChunkName: "verificar-proveedor" */ "./verificarProveedorView.vue"
      ),
  },
  {
    path: "/proceso-solicitud-compra/lista-ganadores/:tipo",
    name: "lista-ganadores",
    props:true,
    component: () =>
      import(
        /* webpackChunkName: "lista-ganadores" */ "./listaGanadoresView.vue"
      ),
  },
  {
    path: "/proceso-solicitud-compra/proveedor-detalle/:tipo",
    name: "proveedor-detalle",
    component: () =>
      import(
        /* webpackChunkName: "proveedor-detalle" */ "./detalleInsumosProveedorView.vue"
      ),
  },
  {
    path: "/proceso-solicitud-compra/proveedor-insumo-detalle/:tipo",
    name: "proveedor-insumo-detalle",
    component: () =>
      import(
        /* webpackChunkName: "proveedor-insumo-detalle" */ "./verInsumoDetalleView.vue"
      ),
  },
];

export default solicitudOBS;
