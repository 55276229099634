import { http_client } from "../plugins/http_client"


const getDepartamentosList = async (parmas = {})=>{
    return await http_client("/api/v1/departamentos", parmas,"get")
}

export default{
    getDepartamentosList
}





//Departamentos