import { http_client_slim } from "@/plugins/http_client";

const client = http_client_slim("/api/v1/usuarios/v2");

const obtenerUsuarios = async (data) => client("", { data });

const obtenerHistorial = async (id) => client(`/${id}`);

const eliminarHistorial = async (id) => client(`/${id}`, { method: "delete" });

export default {
  obtenerUsuarios,
  obtenerHistorial,
  eliminarHistorial
};
