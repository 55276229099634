export default () => ({
    id_proceso: null,
    proveedor_options: [],
    especificacion: null,
    id_proveedor: null,
    resultados: [],
    cantidad_por_pagina: 5,
    pagina_actual: 1,
    total_registros: 0,
    participante: {}
});