// importacion de services
import ProcesoReserva from "../../../services/ProcesoReserva.services";
import SolicitudCompra from "../../../services/SolicitudCompra.services";
import root from "../../../store"; // store global

export const FetchListSolicAc = async ({ commit }, payload_params) => {
    root.commit("utils/setLoader", true);
    const { data, status, headers } = await ProcesoReserva.getListSolicitud(
        payload_params.id_proceso,
        payload_params.paginate
    );
    if (status == 200) {
        data.forEach((element, index) => {
            element.correlativo = index + 1;
        });

        commit("SetListSolicMt", data);
        commit("SetPagSolicMt", headers);
    }
    root.commit("utils/setLoader", false);
};

export const FetchDetailSolicAc = async ({ commit }, id_solicitud) => {
    root.commit("utils/setLoader", true);
    const { data, status } = await ProcesoReserva.getDetailSolicitud(
        id_solicitud
    );
    if (status == 200) {
        commit("SetDetailSolicMt", data);
    }
    root.commit("utils/setLoader", false);
};

export const FetchListEspecAc = async ({ commit }, payload_params) => {
    root.commit("utils/setLoader", true);
    const { data, status, headers } = await SolicitudCompra.getDocsEspecificacion(
        payload_params.id_solicitud,
        payload_params.paginate,
    );
    if (status == 200) {
        commit("SetListEspecificacionesMt", data);
        commit("SetPagEspecificacionesMt", headers);
    }
    root.commit("utils/setLoader", false);
};

export const FetchListPreciosAc = async ({ commit }, payload_params) => {
    root.commit("utils/setLoader", true);
    const { data, status, headers } =
        await SolicitudCompra.getSolicitudCotizaciones(
            payload_params.id_solicitud,
            payload_params.paginate,
        );
    if (status == 200) {
        commit("SetListPreciosMt", data);
        commit("SetPagPreciosMt", headers);
    }
    root.commit("utils/setLoader", false);
};

export const FetchListAdminAc = async ({ commit }, payload_params) => {
    root.commit("utils/setLoader", true);
    const { data, status, headers } =
        await SolicitudCompra.getAdministradoresContrato(
            payload_params.id_solicitud,
            payload_params.paginate,
        );
    if (status == 200) {
        data.forEach((element, index) => {
            element.correlativo = index + 1;
        });
        commit("SetListAdministradoresMt", data);
        commit("SetPagAdministradoresMt", headers);
    }
    root.commit("utils/setLoader", false);
};

export const FetchListFinanAc = async ({ commit }, payload_params) => {
    root.commit("utils/setLoader", true);
    const { data, status, headers } =
        await ProcesoReserva.getListFinanciamientos(
            payload_params.id_proceso,
            payload_params.paginate,
        );
    if (status == 200) {
        commit("SetListFinanciamientosMt", data);
        commit("SetPagFinanciamientosMt", headers);
    }
    root.commit("utils/setLoader", false);
};

export const ResetAllListAc = async ({ commit }) => {
    commit("SetDetailSolicMt", { solicitud_compra_obs: [], detalle_unidad: null });
    commit("ResetEspecificacionesMt");
    commit("ResetPreciosMt");
    commit("ResetAdministradoresMt");
    commit("ResetFinanciamientosMt");
}
