<template>
    <div class="pagination">
        <v-pagination :length="pages" v-model="currentPage" />
        <v-select v-model="currentSize" class="pagination-sizer" :items="sizes" label="Por página"  />
    </div>
</template>
<script>
export default {
    name: 'PaginationComponent',
    props: {
        total: { type: Number, required: true },   
        sizes: { type: Array, required: false, default: () => [5, 10, 15, 25, 50, 100]  },
        pagination: { type: Object },
    },
    data() {
        return {
            currentSize: 10,
            currentPage: 1,
        };
    },
    computed: {
        pages() {
            return Math.ceil(this.total / this.currentSize);
        },
    },
    emits: ['onPaginationChange'],
    watch: {
        currentSize(value) {
            const total = this.$props.total;
            const newSizeCeilPage = Math.ceil(total / value);

            if (this.currentPage > newSizeCeilPage) {
                this.currentPage = 1;
                return; // Hacemos un retorno, ya que al setear un nuevo valor a currentPage, se disparara el otro watch, emitiendo el evento dos veces
            }

            this.pagination.currentPage = this.currentPage;
            this.pagination.size = value;
        },
        currentPage(value) {
            this.pagination.currentPage = value;
            this.pagination.size = this.currentSize;
        },
        pagination: {
            handler(value) {
                this.currentPage = value.currentPage;
                this.currentSize = value.size;
            },
            deep: true,
            immediate: true,
        },
    },
}
</script>
<style>
    .pagination {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: baseline;
        gap: 16px;
    }

    .pagination-sizer {
        flex: 0 1 100px;
        display: inline-block;
    }
</style>