export default () => ({
  loading: false,
  cargar_producto: false,
  proveedorSelecionado: 0,
  proveedores: [],
  productos: [], 
  id_producto_seleccionado: 0,
  productoSeleccionado: {},
  productosFilters:{
    page:1,
    per_page: 10,
    total_rows: null,
    id_obs: null,
    id_proveedor:[],
    precio_minimo: null,
    precio_maximo: null,
  },
  // Listado de productos temporal para simular peticion a la api
  productosTemp: [
    {
      id: 1,
      id_proveedor: 1,
      titulo: "Papel bond multipropósito",
      proveedor: "Amazon",
      obs: "11121607 - Papel bond",
      id_unidad: 1,
      unidad: "Unidad",
      id_presentacion: 1,
      presentacion: "Resma",
      precio: 3.0,
      presentaciones: [
        { id: 1, name: "Unidad" },
        { id: 2, name: "Caja" },
      ],
      imagenes: [
        {
          id: 1,
          url: "https://m.media-amazon.com/images/I/71mwf6-21xL._AC_SS450_.jpg",
        },
        {
          id: 2,
          url: "https://m.media-amazon.com/images/I/71YriwrbQeL._AC_SL1500_.jpg",
        },
        {
          id: 3,
          url: "https://pariscorp.com/wp-content/uploads/2019/Product_Images/Printworks/PrintWorks_100_Recycled_Printer_Paper.png",
        },
        {
          id: 4,
          url: "https://hppaper.com/na/content/uploads/2021/12/HP_HO20_Rm_8.5x11_500_Left_200510-2021-385x500.png",
        },
      ],

      detalle:
        "Resma Blanco brillante 96, 85 x 11 pulgadas. 5 libras 500 hojas papel para copia",
    },
    {
      id: 2,
      id_proveedor: 2,
      titulo: "Papel bond multipropósito",
      proveedor: "Hammermill S.A. de C.V.",
      obs: "11121607 - Papel bond",
      id_unidad: 1,
      unidad: "Unidad",
      id_presentacion: 1,
      presentacion: "Resma",
      precio: 2.5,
      presentaciones: [
        { id: 1, name: "Unidad" },
        { id: 2, name: "Caja" },
      ],
      imagenes: [
        {
          id: 2,
          url: "https://m.media-amazon.com/images/I/71YriwrbQeL._AC_SL1500_.jpg",
        },
      ],
      detalle:
        "Resma Blanco brillante 96, 85 x 11 pulgadas. 5 libras 500 hojas papel para copia",
    },
    {
      id: 3,
      id_proveedor: 1,
      titulo: "Papel bond multipropósito",
      proveedor: "Amazon",
      obs: "11121607 - Papel bond",
      id_unidad: 1,
      unidad: "Unidad",
      id_presentacion: 1,
      presentacion: "Resma",
      precio: 2.5,
      presentaciones: [
        { id: 1, name: "Unidad" },
        { id: 2, name: "Caja" },
      ],
      imagenes: [
        {
          id: 1,
          url: "https://pariscorp.com/wp-content/uploads/2019/Product_Images/Printworks/PrintWorks_100_Recycled_Printer_Paper.png",
        },
      ],
    },
    {
      id: 4,
      id_proveedor: 2,
      titulo: "Papel bond multipropósito",
      proveedor: "Hammermill S.A. de C.V.",
      obs: "11121607 - Papel bond",
      id_unidad: 1,
      unidad: "Unidad",
      id_presentacion: 1,
      presentacion: "Resma",
      presentaciones: [
        { id: 1, name: "Unidad" },
        { id: 2, name: "Caja" },
      ],
      precio: 2.5,
      imagenes: [
        {
          id: 1,
          url: "https://hppaper.com/na/content/uploads/2021/12/HP_HO20_Rm_8.5x11_500_Left_200510-2021-385x500.png",
        },
      ],
      detalle:
        "Resma Blanco brillante 96, 85 x 11 pulgadas. 5 libras 500 hojas papel para copia",
    },
    {
      id: 5,
      id_proveedor: 1,
      proveedor: "Amazon",
      obs: "11121607 - Papel bond",
      id_unidad: 1,
      unidad: "Unidad",
      id_presentacion: 1,
      presentacion: "Resma",
      precio: 2.5,
      presentaciones: [
        { id: 1, name: "Unidad" },
        { id: 2, name: "Caja" },
      ],
      imagenes: [
        {
          id: 1,
          url: "https://m.media-amazon.com/images/I/91Ym-Qx604L._AC_SS450_.jpg",
        },
      ],
      detalle:
        "Resma Blanco brillante 96, 85 x 11 pulgadas. 5 libras 500 hojas papel para copia",
    },
  ],
});
