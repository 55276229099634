const routesComprasCentrosEscolares = [
    {
      path: "/administracion-compras-centros-escolares",
      name: "administracion-compras-centros-escolares",
      component: () =>
        import(
          /* webpackChunkName: "adminFondosCentrosEscolares" */ "./adminFondosCentrosEscolares.vue"
        ),
    },
    {
      path: "/listar-fondos-compras-centros-escolares",
      name: "listar-fondos-compras-centros-escolares",
      component: () =>
        import(
          /* webpackChunkName: "fondosComprasCentrosEscolares" */ "./fondosComprasCentrosEscolares.vue"
        ),
    },
    {
      path: "/revision-fondos-centros-escolares",
      name: "revision-fondos-centros-escolares",
      component: () =>
        import(
          /* webpackChunkName: "revisionFondosCentrosEscolares" */ "./revisionFondosCentrosEscolares.vue"
        ),
    },
    {
      path: "/revision-registros-fondos-centros-escolares/:id_fondo",
      name: "revision-registros-fondos-centros-escolares",
      component: () =>
        import(
          /* webpackChunkName: "revisionRegistrosFondosCentrosEscolares" */ "./revisionRegistrosFondosCentrosEscolares.vue"
        ),
    },
  ];
  
  export default routesComprasCentrosEscolares;