import { http_client } from "@/plugins/http_client";

// const postInsumoProcReserva = async (id_proceso_compra, body) => await http_client(`/api/v1/procesos-con-reserva/${id_proceso_compra}/solicitudes`, body, 'post');
// const putInsumoProcReserva = async (id_proceso_compra, body) => await http_client(`/api/v1/procesos-con-reserva/${id_proceso_compra}/solicitudes`, body, 'put');
const postInsumoSolicitud = async (id_solicitud, body) =>
  await http_client(
    `/api/v1/procesos-con-reserva/solicitudes/compra/${id_solicitud}/insumos`,
    body,
    "post"
  );
const putInsumoSolicitud = async (id_solicitud, id_detalle_obs, body) =>
  await http_client(
    `/api/v1/procesos-con-reserva/solicitudes/obs/${id_solicitud}/detalle/${id_detalle_obs}`,
    body,
    "put"
  );

const getDetailSolicitud = async (id_solicitud) =>
  await http_client(
    `/api/v1/procesos-con-reserva//solicitudes/${id_solicitud}`,
    {}
  );
const getListSolicitud = async (id_proceso_compra, body) =>
  await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/solicitudes/compra`,
    body
  );
const postSolicitudProcReserva = async (id_proceso_compra, body) =>
  await http_client(
    `/api/v1/procesos-con-reserva/${id_proceso_compra}/solicitudes/necesidad`,
    body,
    "post"
  );
const deleteSolicitudProcReserva = async (id_solicitud, body) =>
  await http_client(
    `/api/v1/procesos-con-reserva/solicitudes/${id_solicitud}`,
    body,
    "delete"
  );

const getListFinanciamientos = async (id_proceso, body) =>
  await http_client(
    `/api/v1/procesos-con-reserva/${id_proceso}/solicitudes/financiamiento`,
    body
  );
const deleteFinanciamiento = async (id_solicitud, id_financiamiento, body) =>
  await http_client(
    `api/v1/procesos-con-reserva/solicitudes/necesidad/${id_solicitud}/financiamiento/${id_financiamiento}`,
    body,
    "delete"
  );

const activarProceso = async (id_proceso, body = {}) => {
  return await http_client(
    `/api/v1/procesos-con-reserva/${id_proceso}/activar`,
    body,
    "put"
  );
};

export default {
  // postInsumoProcReserva,
  // putInsumoProcReserva,
  postInsumoSolicitud,
  putInsumoSolicitud,
  postSolicitudProcReserva,
  getListSolicitud,
  deleteSolicitudProcReserva,
  getDetailSolicitud,
  getListFinanciamientos,
  deleteFinanciamiento,
  activarProceso
};
