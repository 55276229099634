<template>
    <v-autocomplete
        outlined
        v-bind="mergedProps"
        v-on="$listeners"
        v-model="model"
        :loading="pageable.isLoading"
        :items="elementosTransformados"
        no-filter
        @click:clear="handleClear"
        @keydown="handleChangeDebounced"
    >
        <template v-slot:no-data>
            <div class="text-center py-2">
                <p v-if="!pageable.isLoading" class="mx-0 my-0">No hay datos disponibles</p>
                <v-progress-circular 
                    v-else
                    indeterminate 
                    color="primary" 
                    :size="25" 
                    :width="3" 
                />
            </div>
        </template>
        <template v-slot:append-item>
            <div v-intersect="debouncedIntersect" class="text-center">
                <v-progress-circular 
                    v-if="pageable.data.length > 0 && pageable.isLoading" 
                    indeterminate 
                    class="mt-2"
                    color="primary" 
                    :size="25" 
                    :width="3" 
                />
            </div>
        </template>
    </v-autocomplete>
</template>
<script>
import { debounce, omit } from 'lodash';

export default {
    name: 'ProgresiveLoadAutocomplete',
    emits: ['search', 'load-more'],
    props: {
        pageable: {
            type: Object,
            required: true,
        },
        label: { type: String },
        itemText: { type: String },
        itemValue: { type: String },
        transformItem: { type: Function },
    },
    data: () => ({
        term: '',
        model: null,
    }),
    methods: {
        handleClear() {
            this.$emit('search', '');
        },
        handleChange(evt) {
            const value = evt.target.value;
            this.$emit('search', value);
        },
        handleIntersect(entries, observer, isIntersecting) {
            if (!isIntersecting) return;
            this.$emit('load-more');
        },
    },
    watch: {
        value(val) {
            this.model = val;
        },
    },
    computed: {
        mergedProps() {
            return {...this.$props, ...omit(this.$attrs, 'value')}
        },
        value() {
            return this.$attrs.value;
        },
        items() {
            return this.pageable.data;
        },
        search: {
            get() {
                this.term;
            },
            set(value) {
                if (value === null || this.term === value) return;
                this.term = value;
                this.debouncedSearch(value);
            },
        },
        elementosTransformados() {
            return this.pageable.data.map((item) => {
                return this.transformItem ? this.transformItem(item) : item;
            });
        },
    },
    created() {
        this.handleChangeDebounced = debounce(this.handleChange, 600);
        this.debouncedIntersect = debounce(this.handleIntersect, 500);
    },
}
</script>