import { http_client, http_client_with_status } from "@/plugins/http_client";

const login = (body) => http_client_with_status("/api/v1/login", body, "post");
const verify2fa = (body) =>
  http_client_with_status("api/v1/login/validate-2fa", body, "post");
const resetPasswordAndReport = (token) =>
  http_client_with_status(`api/v1/password/report/${token}`, null, "post");
const changeEmail = async (body) =>
  await http_client(`/api/v1/users/update/email`, body, "put");
const changePassword = async (body) =>
  await http_client(`/api/v1/users/update/password`, body, "put");
const verifyAccount = async (token) =>
  await http_client(`/api/v1/verification/account/${token}`);
const twoFactor = async (body) =>
  await http_client("/api/v1/2fa/code", body, "post");
const verifyCode = async (body) =>
  await http_client("/api/v1/2fa/verify", body, "post");
const get2FAMethods = async () => await http_client("/api/v1/users/2fa/method");
const store2FAMethods = async (body) =>
  await http_client("/api/v1/users/2fa/method", body, "post");
const authMethodVerification = async (body) =>
  await http_client("/api/v1/users/2fa/method/verify", body, "post");
const changePrimaryMethod = async (idMethod) =>
  await http_client(`/api/v1/users/2fa/method/${idMethod}`, {}, "put");
// const logout = async () => await http_client('/api/v1/logout', {}, 'post')
const logout = async (tokenID) =>
  await http_client("/api/v1/logout", { tokenID }, "post");
const resetPassword = async (body) =>
  await http_client("/api/v1/password/reset", body, "post");
const newPassword = async (body, token) =>
  await http_client("/api/v1/password/change", body, "put", {
    Authorization: `Bearer ${token}`,
  });

const changePasswordUser = async (body = {}) => {
  return await http_client(`/api/v1/password/change`, body, "put");
};

const obtenerAniosFiscales = (params) =>
  http_client("/api/v1/anios", params, "get");

const GetTokenDigitalIdentity = async (authCode) =>
  await http_client(
    "/api/v1/identidad-digital",
    { code: authCode },
    "post",
    {}
  );

const getServerTime = async () =>
  await http_client(
    "/api/time",
    {},
    "get",
    {},
    true,
    "",
    false,
    null,
    true,
    false
  );

const cargarInstitucionesUsuario = () =>
  http_client("/api/v1/usuario/instituciones", null, "get");

const almacenarErrorConexion = async (
  params = { error: "Error al reconectar 5 veces" }
) =>
  http_client(
    "/api/socket",
    params,
    "post",
    {},
    true,
    "",
    false,
    null,
    false,
    false
  );
const cerrarSesiones = async () =>
  http_client(
    "/api/v1/desconexion",
    {},
    "post",
    {},
    true,
    "",
    false,
    null,
    false,
    false
  );

export default {
  login,
  changeEmail,
  changePassword,
  verifyAccount,
  verifyCode,
  twoFactor,
  get2FAMethods,
  store2FAMethods,
  authMethodVerification,
  changePrimaryMethod,
  logout,
  resetPassword,
  newPassword,
  GetTokenDigitalIdentity,
  changePasswordUser,
  getServerTime,
  verify2fa,
  resetPasswordAndReport,
  obtenerAniosFiscales,
  cargarInstitucionesUsuario,
  almacenarErrorConexion,
  cerrarSesiones,
};
