const routesCompraEnExterior = [
    {
        path: "/compra_exterior_reg_informe",
        name: "compra_exterior_reg_informe",
        component: () =>
            import(
                /* webpackChunkName: "mainCompraExterior" */ "../views/main.vue"
                ),
    }
];

export default routesCompraEnExterior;
