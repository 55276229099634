<template>
    <Fragment>
        <v-row class="mb-0">
            <v-col 
                style="position: relative;"
                :class="`mb-${isProductRequest ? '2' : '0'}`" 
                cols="12" 
                :sm="isProductRequest ? 12 : 8"
            >
                <h2 style="position: relative; top: 50%; transform: translateY(-50%);">Lista de proveedores</h2>
            </v-col>
            <v-col class="py-0 mt-2 mt-sm-0" v-if="!isProductRequest" cols="12" sm="4">
                <v-text-field
                    label="Buscar"
                    placeholder="Buscar dirección"
                    :loading="productOffers.isLoading"
                    outlined
                    clearable
                    prepend-inner-icon="mdi mdi-magnify"
                    @input="debouncedSearch"
                />
            </v-col>
        </v-row>
        <v-data-table
            v-if="productKind"
            :headers="headers"
            :items="productOffers.data"
            :loading="productOffers.isLoading"
            class="product-offers-table"
            :total_registros="total_registro"
            @paginar="manejarPaginacionRegistros"
        >
            <template v-slot:item.uni="{ item }">
                <v-chip v-if="item.uni?.nombre" small class="ma-2">{{ item.uni?.nombre }}</v-chip>
                <span v-else>-</span>
            </template>
            <template v-slot:item.precio="{ item }">${{ item.precio }}</template>
            <template v-slot:item.precio_sin_iva="{ item }">${{ precioSinIva(item.precio) }}</template>
            <template v-slot:item.disponibilidad="{ item }">
                {{ `${item.CMProducto.CmSubGrupo.manejo_existencias ? item.disponibilidad : 'Sin límite'}` }}
            </template>
            <template v-slot:item.direcciones="{ item }">
                <div style="min-width: 150px;" class="d-flex flex-wrap justify-center">
                    <v-chip v-for="{ id, direccion } in item.cm_do" :key="id" class="ma-2" style="flex: 0 1 auto;">
                        {{ direccion }}
                    </v-chip>
                </div>
            </template>
            <template v-slot:item.marca="{ item }">
                <div style="min-width: 150px;" class="d-flex flex-wrap justify-center">
                    <v-chip v-if="item.marca" small class="ma-2">{{ item.marca }}</v-chip>
                    <span v-else>-</span>
                </div>
            </template>
            <template v-slot:item.regiones="{ item }">
                <div style="min-width: 150px;" class="d-flex flex-wrap justify-center">
                    <v-chip v-for="{ id, CMRegion, tiempo_respuesta } in item.conMar_regCon" :key="id" small class="ma-2" style="flex: 0 1 auto;">
                        {{ `${CMRegion.nombre} - ${tiempoEntrega(tiempo_respuesta)}` }} 
                    </v-chip>
                </div>
            </template>
            <template v-slot:item.ofeProCon_atrOfe="{ item }">
                <div style="min-width: 150px;" class="d-flex flex-wrap justify-center">
                    <v-chip 
                        v-for="{ id, CMAtributoSubgrupo } in item.CMProducto.cmsgp" 
                        v-if="CMAtributoSubgrupo.CMAtributo?.nombre" 
                        :key="id" 
                        class="ma-2"
                        small
                        style="flex: 0 1 auto;"
                    >
                        {{ CMAtributoSubgrupo.CMAtributo.nombre }}
                    </v-chip>
                </div>
            </template>
            <template v-slot:item.actions="{ item }">
                <div class="product-offers-table-actions d-flex justify-center" style="min-width: 70px;"> 
                    <v-btn text @click.stop="handleOfferSelection(item)">
                        <v-icon color="primary">mdi mdi-pencil</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <!-- <PaginationComponent 
            class="mt-10"
            :total="productOffers.pagination.total_rows" 
            :pagination="pagination"
        /> -->
        <UpsertCartItemComponent
            :isOpen="isModalOpen" 
            :isEditing="false" 
            :offerMeta="offerMeta" 
            :offer="activeOffer"
            :fundsLoadable="productFunds"
            @open-change="toggleModal"  
            @save="collectUpsertData"
            :retryFundsLoading="retryProductsFundsFetch"
            :arbol-obs="arbolObs"
        />
        <ConfirmationModalComponent
            :isOpen="confirmationOpen" 
            description="¿Desea continuar con la acción?" 
            @confirm="performUpsertOffer" 
            @cancel="confirmationOpenCancel"  
            :isLoading="offerUpsertion.isLoading"
        />
    </Fragment>
</template>
<script>
import UpsertCartItemComponent from './UpsertCartItemComponent.vue';
import { ConfirmationModalComponent, PaginationComponent, NavButton } from '@/components/utils';
import { debounce } from 'lodash';
import { CMConstants } from '@/utils/cm-constants.js';
import { Observable, Listener } from '@/utils/observable.js';
import { Fragment } from 'vue-fragment';
import { PaginationHandler } from '@/utils/pagination-handler.js';
import { shouldFetch, shouldRetry } from '@/utils/loadable';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
    name: 'OfferListComponent',
    components: {
        UpsertCartItemComponent,
        ConfirmationModalComponent,
        PaginationComponent,
        Fragment,
        NavButton,
    },
    props: {
        productId : { 
            type: Number, 
            required: true,
        },
        productKind : { 
            type: Number,
        },
        requestId : { 
            type: String,
        },
        isEditAction : { 
            type: Boolean,
        },
        timer: {
            type: Object,
            required: true,
        },
        arbolObs: {
            type: Array,
        },
    },
    data: () => ({
        activeOffer: null,
        isModalOpen: false,
        confirmationOpen: false,
        upsertData: null,
        unsubscribe: null,
        location: '',
        pagination: new PaginationHandler(),
        paginaActual: 1,
        registrosPorPagina: 10,
    }),
    computed: {
        ...mapState("catalogoEnLinea", ["productOffers", "offerUpsertion", "productFunds","agendamientos",'configuraciones']),
        isEditing() {
            return this.activeOffer?.reservado > 0 && this.activeOffer?.CMProducto.id_tipo_producto !== 2;
        },
        isProductRequest() {
            return this.productKind === CMConstants.TIPO_PRODUCTO;
        },
        isOpenRequest() {
            return this.timer.status === 'ticking';
        },
        isExpiredRequest() {
            return this.timer.status === 'expired';
        },
        headers() {
            const tableHeaders = [
                this.createtableColumn('Proveedor', 'Proveedor.nombre'),
                this.createtableColumn('Cantidad disponible', 'disponibilidad'),
                this.isProductRequest && this.createtableColumn('Marca', 'marca'),
                this.isProductRequest && (this.isOpenRequest || this.isExpiredRequest) && this.createtableColumn('Cantidad reservada', 'reservado'),
                !this.validacionMonto && this.createtableColumn('Precio (IVA)', 'precio'),
                !this.validacionMonto && this.createtableColumn('Precio (SIN IVA)', 'precio_sin_iva'),
                this.createtableColumn('Unidad medida', 'uni'),
                this.isProductRequest && this.createtableColumn('Regiones - Tiempos de entrega', 'regiones'),
                !this.isProductRequest && this.createtableColumn('Direcciones', 'direcciones'),
                // this.createtableColumn('Atributos', 'ofeProCon_atrOfe'),
                this.isOpenRequest && this.createtableColumn('Acciones', 'actions'),
            ];

            return tableHeaders.filter((header) => header);
        },
        offerMeta() {
            if (!this.activeOffer) return {};
            const direcciones = this.activeOffer.cm_do?.map((d) => ({
                id: d.id,
                direccion: d.direccion,
            })) || [];
            
            return {
                disponibilidad: this.activeOffer.disponibilidad,
                id_oferta: this.activeOffer.id,
                tipo_oferta: this.activeOffer.CMProducto.id_tipo_producto,
                id_producto: this.activeOffer.CMProducto.id,
                id_fondo_solicitud_necesidad: this.activeOffer.id_fondo_solicitud_necesidad,
                precio: this.activeOffer.precio,
                idProductoSolicitud: this.activeOffer.id_producto_solicitud,
                cantidad_decimal: this.activeOffer.CMProducto.CmSubGrupo.cantidad_decimal,
                manejo_altura: this.activeOffer.CMProducto.CmSubGrupo.altura,
                manejo_base: this.activeOffer.CMProducto.CmSubGrupo.base,
                manejo_cantidad: this.activeOffer.CMProducto.CmSubGrupo.cantidad,
                manejo_altura_decimal: this.activeOffer.CMProducto.CmSubGrupo.altura_decimal,
                manejo_existencias: this.activeOffer.CMProducto.CmSubGrupo.manejo_existencias,
                manejo_base_decimal: this.activeOffer.CMProducto.CmSubGrupo.base_decimal,
                manejo_direccion: this.activeOffer.CMProducto.CmSubGrupo.direccion,
                manejo_fecha: this.activeOffer.CMProducto.CmSubGrupo.fecha,
                manejo_hora: this.activeOffer.CMProducto.CmSubGrupo.hora,
                manejo_pdf: this.activeOffer.CMProducto.CmSubGrupo.pdf,
                limite_altura: this.activeOffer.CMProducto.CmSubGrupo.limite_altura,
                limite_base: this.activeOffer.CMProducto.CmSubGrupo.limite_base,
                manejo_monto: this.activeOffer.CMProducto.CmSubGrupo.monto,
                manejo_lineas: this.activeOffer.CMProducto.CmSubGrupo.lineas,
                manejo_vigencia: this.activeOffer.CMProducto.CmSubGrupo.vigencia,
                manejo_cifra: this.activeOffer.CMProducto.CmSubGrupo.cifra,
                reservado: 0,
                // Se utilizaba para almacenar antes los valores en la oferta.
                ...this.activeOffer.CMProducto.id_tipo_producto !== 2 && ({
                    // // monto: this.activeOffer.cmsc?.monto,
                    // direcciones,
                    // // direccion: this.activeOffer.direccion,
                    // // altura: this.activeOffer.cmsc?.altura,
                    // // base: this.activeOffer.cmsc?.base,
                    // comentario: this.activeOffer.cmsc?.comentario,
                    // fecha_agendamiento: this.activeOffer?.CmAgendamiento?.fecha_agendamiento,
                    // hora_agendamiento: this.activeOffer?.CmAgendamiento?.hora_agendamiento,
                }),
            }
        },
        canFetchFunds() {
            return this.productId && this.isOpenRequest;
        },
        validacionMonto(){
            return this.productOffers.data[0]?.CMProducto.CmSubGrupo.monto
        },
        total_registro() {
            return this.productOffers.pagination.total_rows;
        },
        configuracionDecimal(){
            return this.configuraciones.data.find(c => c.operacion === 'precios_mas_decimales');
        }
    },
    methods: {
        // Data
        ...mapActions("catalogoEnLinea", ["fetchProductOffers", "upsertOffer", "fetchProductFunds","obtenerListaConfiguracionesFamilia"]),
        ...mapMutations("catalogoEnLinea", ["setFeedbackModalConfig", "resetProductFundsState","eliminarAgendamiento"]),
        createtableColumn(text, value) {
            return { sortable: false, align: 'center', value, text };
        },
        handleOfferUpdate() {
            Observable.emit('actualizar-carrito');
        },
        async loadOffers() {
            const filters = {  
                direccion: this.location, 
                pagination: true,
                per_page: this.registrosPorPagina,
                page: this.paginaActual,
            };
            const offers = await this.fetchProductOffers({ productId: this.productId, filters });

            if (!offers) return;
        },
        async performUpsertOffer() {
            const kind = this.isProductRequest ? 'product' : 'service';
            const successfulResponse = await this.upsertOffer({ kind, data: this.upsertData });
            this.confirmationOpen = false;
            
            if (!successfulResponse) return; 

            this.handleOfferUpdate();
            this.setFeedbackModalConfig({
                isOpen: true,
                type: "success", 
                description: successfulResponse.message,
                buttonText: "Aceptar",
                onClose: this.handleFeedbackModalClose,
            });
        },
        confirmationOpenCancel(){
            this.confirmationOpen = false;
            this.agendamientos.forEach(agendamiento => {
                this.eliminarAgendamiento({
                    idProducto: this.activeOffer.CMProducto.id,
                    agendamiento: { ...agendamiento },
                });
            });
        },
        // UI
        toggleModal() {
            this.isModalOpen = !this.isModalOpen;
        },
        handleOfferSelection(item) {
            this.activeOffer = item;
            this.toggleModal();
        },
        collectUpsertData(data) {
            this.isModalOpen = false;
            this.upsertData = data;
            this.confirmationOpen = true;
        },
        handleFeedbackModalClose() {
            this.activeOffer = null;
            this.loadOffers();
        },
        searchByLocation(location) {
            this.location = location;
        },
        retryProductsFundsFetch() {
            if (!shouldRetry(this.productFunds)) return;
            this.fetchProductFunds(this.productId);
        },
        tiempoEntrega(tiempo) {
            const days = Math.floor(tiempo / 24);
            const hours = tiempo % 24;

            let result = `${days} días`;
            if (hours > 0) result += `, ${hours} horas`;
            
            return result.trim();
        },
        manejarPaginacionRegistros(paginacion) {
            const { pagina, cantidad_por_pagina } = paginacion;
            this.paginaActual = pagina;
            this.registrosPorPagina = cantidad_por_pagina;
            this.loadOffers();
        },
        precioSinIva(precio){
            if(!precio) return 0;
            const precioSinIva = precio / 1.13
            const decimal = !!this.configuracionDecimal ? this.configuracionDecimal.valor : 2;
            return Number(precioSinIva).toFixed(decimal)
        }
    },
    watch: {
        location() {
            this.loadOffers();
        },
        pagination: {
            handler() { this.loadOffers() },
            deep: true,
        },
        canFetchFunds: {
            handler(canFetch) {
                if (canFetch && shouldFetch(this.productFunds)) this.fetchProductFunds(this.productId);
            },
            immediate: true,
        },
    },
    created() {
        this.loadOffers();
        this.obtenerListaConfiguracionesFamilia(this.productId);
        this.debouncedSearch = debounce(this.searchByLocation, 600);
    },
    mounted() {
        const listener = new Listener('ProductOffers').setHandler(() => this.loadOffers());
        this.unsubscribe = Observable.getInstance().subscribe('offerts-change', listener);
    },
    beforeDestroy() {
        this.unsubscribe?.();
        this.resetProductFundsState();
    },
}
</script>
<style>
    .product-offers-table tbody tr {
        background-color: #FFFFFF;
    }
    .product-offers-table .v-data-footer {
        background-color: #FFFFFF;
    }
    
    .product-offers-table thead tr th:last-child,
    .product-offers-table tbody tr td:last-child {
        position: sticky;
        background-color: inherit;
        right: 0;
    }

    .product-offers-table tbody tr td:last-child {
        background-color: inherit !important;
    }

    .product-offers-table.v-data-table .v-icon {
        font-size: 24px !important;
    }

    .product-offers-table .v-card__actions {
        padding: 0;
    }

    .product-offers-table .v-card__actions .v-btn {
        min-width: unset !important;
        padding: 8px !important;
    }
</style>