import { http_client } from "@/plugins/http_client";

const getTarjetas = async (params = {}) => {
  return await http_client("/api/v1/tarjetas", params, "get");
};

const getTarjeta = async (id, params = {}) => {
  return await http_client(`/api/v1/tarjetas/${id}`, params, "get");
};

const postTarjetas = async (params = {}) => {
  return await http_client("/api/v1/tarjetas", params, "post");
};

const putTarjetas = async (id, params = {}) => {
  return await http_client(`/api/v1/tarjetas/${id}`, params, "put");
};

const borrarTarjeta = async (id, params = {}) => {
  return await http_client(`/api/v1/tarjetas/${id}`, params, "delete");
};

const getTiposTarjetas = async (params = {}) => {
  return await http_client("/api/v1/tarjetas/tipos", params, "get");
};

const getTiposMarcasTarjetas = async (params = {}) => {
  return await http_client(
    "/api/v1/tarjetas/marca-internacional",
    params,
    "get"
  );
};

const getInstitucionesFinancierasTarjetas = async (params = {}) => {
  return await http_client("/api/v1/instituciones/financieras", params, "get");
};

export default {
  getTarjetas,
  getTarjeta,
  postTarjetas,
  putTarjetas,
  getTiposTarjetas,
  getTiposMarcasTarjetas,
  getInstitucionesFinancierasTarjetas,
  borrarTarjeta,
};
