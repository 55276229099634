const indicadoresRoute = [
    {
        path: "/indicadores-institucionales",
        name: "indicadores-institucionales",
        component: () =>
            import(
                /* webpackChunkName: "IndicadoresView" */ "./IndicadoresInstitucionView.vue"
            )
    },
]

export default indicadoresRoute;