import { http_client } from "../plugins/http_client"


const getMunicipiosList = async(parametros = {})=>{
    return await http_client("/api/v1/municipios", parametros,"get")
}

export default{
    getMunicipiosList
}


//Municipios