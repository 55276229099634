export default () => ({
    infoObs: {},
    listaInstituciones: [],
    listaInstitucionesConjuntas: [],
    tipoEntidades: [],
    tipoContratos: [],
    obsList: [],
    infoGeneral: {},
    listaDocumentos: [],
    listaEtapas: [],
    id_proceso_compra: null,
    total_rows_pac: 0,
    total_rows_pac_conjuntas: 0,
})
