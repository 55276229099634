<template>
    <v-overlay
        absolute
        color="#000000"
    >
        <div class="d-flex justify-center align-center" style="gap: 16px;">
            <v-progress-circular indeterminate color="white" :size="25" :width="3"></v-progress-circular>
            <p class="white--text mb-0">Cargando... </p>
        </div>
    </v-overlay>
</template>
<script>
export default {
    name: 'DarkOverlayLoader',
}
</script>