import { http_client, http_client_with_status } from "@/plugins/http_client";

const getUsuarios = async (filters = {}) =>
  await http_client("/api/v1/usuarios", filters);
const getProfesiones = async () => await http_client(`/api/v1/profesiones`);
const getHistoricoCambios = async (id) =>
  await http_client(`/api/v1/usuarios/logs`, { id_usuario: id });
const getPerfilRol = async (filters = {}) =>
  await http_client(`/api/v1/perfiles`, filters);
const getPerfilRolDependencia = async (filters = {}) =>
  await http_client(`/api/v1/perfiles/dependencias`, filters);
const getTiposSolicitud = async () =>
  await http_client(`/api/v1/solicitudes/usuario/tipos`);
const getSolicitudById = async (id) =>
  await http_client(`/api/v1/solicitudes/usuario/${id}`);
const getDUDA = async (filters = {}) =>
  await http_client(`/api/v1/instituciones/unidades`, filters);
const getDUDAEmpleado = async () =>
  await http_client(`/api/v1/instituciones/unidades/empleado`);
const getInstituciones = async (filters = {}) =>
  await http_client(`/api/v1/instituciones`, filters);
const getUrlDocumento = async (ruta = {}) =>
  await http_client(
    `/api/v1/usuarios/archivo`,
    ruta,
    "get",
    { responseType: "blob" },
    true,
    "blob",
    false
  );
// const getUrlDocumento = async (ruta={}) => await http_client(`/api/v1/usuarios/archivo`, ruta);

const postUsuario = async (body) =>
  await http_client("/api/v1/usuarios", body, "post");
const postArchivo = async (body) =>
  await http_client(`/api/v1/usuarios/archivo`, body, "post");
const postSolicitudUsuario = async (body) =>
  await http_client("/api/v1/solicitudes/usuario", body, "post");
const updateSolicitudUsuario = async (id, body) =>
  await http_client(`/api/v1/solicitudes/usuario/editar/${id}`, body, "put");
const postDocumento = async (body) =>
  await http_client(`/api/v1/solicitudes/usuario/documento`, body, "post");

const putEstadoSolicitud = async (id, body) =>
  await http_client(`/api/v1/solicitudes/usuario/${id}`, body, "put");
const postCreateUser = async (id, body) =>
  await http_client(`/api/v1/solicitudes/usuario/crear/${id}`, body, "post");
const putModifyUser = async (id, body) =>
  await http_client(`/api/v1/solicitudes/usuario/modificar/${id}`, body, "put");

const getUnidadesUsuario = async (filters = {}) =>
  await http_client(`/api/v1/usuarios/unidades`, filters);
const obtenerUnidadesUsuarios = async (filters = {}) =>
  await http_client_with_status(`/api/v1/usuarios/unidades/usuarios`, filters , 'get');

const observarSolicitud = async (params = {}) => {
  await http_client(
    `/api/v1/solicitudes/usuario/observacion/${params.idSolicitud}`,
    { observacion: params.observacion },
    "post"
  );
};

const getSolicitudesTitulares = async (params = {}) =>
  await http_client(`/api/v1/registro/publico`, params);
const getSolicitudTitular = async (id, params = {}) =>
  await http_client(`/api/v1/registro/publico/${id}`, params);
const putSolicitudTitular = async (id, params = {}) =>
  await http_client(`/api/v1/registro/publico/${id}`, params, "put");
const descargarTitulo = async (params = {}) =>
  await http_client(
    `/api/v1/documentos/descargar`,
    params,
    "get",
    { responseType: "blob" },
    true,
    "blob",
    false
  );

const deleteUserForever = async (id, params = {}) =>
  await http_client(`/api/v1/usuarios/delete/${id}`, params, "put");

const getSupervisores = async (params = {}) =>
  await http_client(`/api/v1/usuarios/supervisores/`, params);

const buscarUsuarios = async (params = {}) =>
  await http_client(`/api/v1/usuarios/search`, params);

const postUsuarioRol = async (params = {}) =>
  await http_client(`/api/v1/users/rol`, params, "post");
const deletetUsuarioRol = async (params = {}) =>
  await http_client(`/api/v1/users/rol`, params, "delete");
const getRolesUsuario = async (idUsuario, params = {}) =>
  await http_client(`/api/v1/users/${idUsuario}/roles`, params);
const cambiarEstadoUsuario = async (id, params = {}) => {
  return await http_client(`/api/v1/users/${id}`, params, "delete");
};
const cambiarEstadoProveedor = async (id, params = {}) => {
  return await http_client(`/api/v1/users/update/${id}`, params, "put");
};

const getAllUsersRequest = async (params = {}) =>
  await http_client(`/api/v1/solicitudes/usuario/institucion`, params);
const deleteUserRequest = async (id_solicitud_usuario) =>
  await http_client(
    `/api/v1/solicitudes/usuario/${id_solicitud_usuario}`,
    {},
    "delete"
  );

const generarReporteUsuario = async (params = {}) =>
  await http_client(
    `/api/v1/usuarios/cron-job/reporte/usuario?now=true`,
    params,
    "post"
  );

const removerObservacionUsuario = async (id) =>
  http_client(`/api/v1/usuarios/remover/observacion/${id}`, {}, "patch");

export default {
  getUsuarios,
  getTiposSolicitud,
  getProfesiones,
  getPerfilRol,
  getPerfilRolDependencia,
  getDUDA,
  getDUDAEmpleado,
  getSolicitudById,
  getInstituciones,
  getUrlDocumento,
  getHistoricoCambios,
  postUsuario,
  postArchivo,
  postSolicitudUsuario,
  postDocumento,
  putEstadoSolicitud,
  postCreateUser,
  putModifyUser,
  getUnidadesUsuario,
  observarSolicitud,
  updateSolicitudUsuario,
  getSolicitudesTitulares,
  getSolicitudTitular,
  putSolicitudTitular,
  descargarTitulo,
  deleteUserForever,
  getSupervisores,
  buscarUsuarios,
  postUsuarioRol,
  deletetUsuarioRol,
  getRolesUsuario,
  cambiarEstadoUsuario,
  cambiarEstadoProveedor,
  getAllUsersRequest,
  deleteUserRequest,
  obtenerUnidadesUsuarios,
  generarReporteUsuario,
  removerObservacionUsuario,
};
