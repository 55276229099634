export default {
  setListaConvenios(state, payload) {
    state.listadoConvenios = payload;
  },
  setPaginationData(state, payload) {
    state.filters.page = Number(payload.page);
    state.filters.perPage = Number(payload.per_page);
    state.filters.totalRows = Number(payload.total_rows);
  },
};
