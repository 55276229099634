const cmOrdenCompra = [
  {
    path:"/orden-compra",
    name:"orden-compra",
    component:()=>
      import(/* webpackChunkName: "OrdenCompra" */ "./cmOrdenCompra.vue")
  },
  {
    path:"/detalle-orden-compra/:id",
    name:"detalle-orden-compra",
    component:()=>
      import(/* webpackChunkName: "DetalleOrdenCompra" */ "./components/DetalleOrdenCompra.vue")
  }
  
];

export default cmOrdenCompra;
