import { http_client } from "@/plugins/http_client";

const http_client_slim = (url, params = {}, method = "get") =>
  http_client(`/api/v1/reasignacion/contractual${url}`, params, method);

const listarContratosInstitucion = async (params = {}) =>
  http_client_slim("/list/administradores/contrato", params);

const obtenerListadoAdministradores = async (id_contrato, params = {}) =>
  http_client_slim(`/list/administradores/contrato/${id_contrato}`, params);

const eliminarSeguidorContractual = async (id_empleado_solicitud) =>
  http_client_slim(`/eliminar/seguidor/${id_empleado_solicitud}`, {}, "delete");

const listarEmpleadosDisponibles = async (params = {}) =>
  http_client_slim("/list/empleados/disponibles", params);

const listarSolicitudesDisponibles = async (id_contrato, params = {}) =>
  http_client_slim(`/list/solicitudes/disponibles/${id_contrato}`, params);

const agregarAdministradorContrato = async (body = {}) =>
  http_client_slim("/agregar/administrador", body, "post");

export default {
  listarContratosInstitucion,
  obtenerListadoAdministradores,
  eliminarSeguidorContractual,
  listarEmpleadosDisponibles,
  listarSolicitudesDisponibles,
  agregarAdministradorContrato,
};
