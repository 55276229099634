import { http_client, http_client_with_status } from "@/plugins/http_client";

// Fondos compra en linea

const getEmpleadoByEmail = async (filter) => await http_client(`/api/v1/compra/linea/administradores`, filter);

const postAddAdminFondo = (id_fondo, id_admin, body = {}) =>
  http_client(
    `/api/v1/compra/linea/${id_fondo}/administradores/${id_admin}`,
    body,
    "post"
  );

const deleteAdminFondo = (id_admin) =>
  http_client(`/api/v1/compra/linea/administradores/${id_admin}`, {}, "delete");

const postAddFondo = (body = {}) =>
  http_client("/api/v1/compra/linea/fondos/compra-linea", body, "post");

const putEditFondo = (id_fondo, body = {}) =>
  http_client(`/api/v1/compra/linea/update/fondo/${id_fondo}`, body, "put");

const getFondoById = (id_fondo, filter) =>
  http_client(`/api/v1/compra/linea/show/fondo/${id_fondo}`, filter);

const getListFondos = (filters) =>
  http_client("/api/v1/compra/linea/fondos", filters);

// Administracion fondos compra en linea

const postAddRegFondo = (id_fondo, body = {}) =>
  http_client(`/api/v1/compra/linea/crear-registro/${id_fondo}`, body, "post");

const getListRegsFondos = (id_fondo, filters) =>
  http_client(`/api/v1/compra/linea/${id_fondo}/registros`, filters);

const getReporteGeneralFondos = () =>
  http_client(
    "/api/v1/compra/linea/reporte/general",
    {},
    "get",
    { responseType: "blob" },
    true,
    "blob",
    false
  );

const getReporteFondo = (id_fondo_baja_cuantia) =>
  http_client(
    `/api/v1/compra/linea/${id_fondo_baja_cuantia}/reporte`,
    {},
    "get",
    { responseType: "blob" },
    true,
    "blob",
    false
  );

const enviarNotificacionUCP = (params = {}) =>
  http_client(`/api/v1/compra/linea/notificar`, params, "post");

const deleteGastoFondo = (id_gasto) =>
  http_client(
    `/api/v1/compra/linea/eliminar-registro/${id_gasto}`,
    {},
    "delete"
  );

const uploadExcel = (id_fondo, body = {}) =>
  http_client(
    `/api/v1/compra/linea/crear-registro/reporte/${id_fondo}`,
    body,
    "post"
  );

const postAsueto = (body = {}) =>
  http_client("/api/v1/asuetos/fecha-fin", body, "post");

const editarGastoFondo = (idRegistro, data) =>
  http_client(
    `/api/v1/compra/linea/editar-registro/${idRegistro}`,
    data,
    "put"
  );

const cargarCatalogoEstados = (params) =>
  http_client_with_status(
    "/api/v1/compra/linea/catalogo/estado-registro",
    params,
    "get"
  );

// Revision fondos
const listarFondos = (filtros) =>
  http_client_with_status(
    "/api/v1/compra/linea/revision/listar/fondos",
    filtros,
    "get"
  );

const cargarInstituciones = (filtros) =>
  http_client_with_status(
    "/api/v1/compra/linea/catalogo/instituciones",
    filtros,
    "get"
  );

const getListRegistrosFondo = async (id_fondo, filters) =>
  await http_client_with_status(
    `/api/v1/compra/linea/revision/listar/registros/fondos/${id_fondo}`,
    filters,
    "get"
  );

const marcarRegistroComoRevisado = (id_gasto) =>
  http_client_with_status(
    `/api/v1/compra/linea/marcar-como-revisado/${id_gasto}`,
    null,
    "patch"
  );

const marcarRegistroComoObservado = (id_fondo, data) =>
  http_client_with_status(
    `/api/v1/compra/linea/marcar-como-observado/${id_fondo}`,
    data,
    "patch"
  );

const cargarConteoRegistros = (idFondo) =>
  http_client_with_status(
    `/api/v1/compra/linea/conteo/${idFondo}`,
    null,
    "get"
  );

const aprobacionMasivaRegistros = async (id_fondo, body = {}) =>
  await http_client(
    `/api/v1/compra/linea/registros-revisados/${id_fondo}`,
    body,
    "post"
  );

const cargarProceosCompraLinea = (id_fondo) =>
  http_client(
    `/api/v1/compra/linea/procesos/compra/linea/${id_fondo}`,
    {},
    "get"
  );

const cargarTiposDocumentosCompraLinea = (params) =>
  http_client(`/api/v1/compra/linea/catalogo/tipos/documentos`, params, "get");

const cargarDocumentos = async (id, params = {}) =>
  http_client(`/api/v1/compra/linea/documentos/compra/${id}`, params);

export default {
  // Fondos compra en linea
  getEmpleadoByEmail,
  postAddAdminFondo,
  deleteAdminFondo,
  postAddFondo,
  putEditFondo,
  getFondoById,
  getListFondos,

  // Administracion fondos compra en linea
  postAddRegFondo,
  getListRegsFondos,
  getReporteGeneralFondos,
  getReporteFondo,
  enviarNotificacionUCP,
  deleteGastoFondo,
  uploadExcel,
  postAsueto,
  editarGastoFondo,
  cargarCatalogoEstados,

  // Revision fondos
  listarFondos,
  cargarInstituciones,
  getListRegistrosFondo,
  marcarRegistroComoRevisado,
  marcarRegistroComoObservado,
  cargarConteoRegistros,
  aprobacionMasivaRegistros,

  //
  cargarProceosCompraLinea,
  cargarTiposDocumentosCompraLinea,

  cargarDocumentos,
};
