import Vue from "vue";
import CompraLinea from "../../../services/SolicitudCompraLinea.services";

export default {
  namespaced: true,
  state: {
    solicitudes: [],
    filters: {
      page: 1,
      per_page: 10,
      total_rows: null,
      pagination: "true",
      fecha_desde: null,
      fecha_hasta: null,
      id_estado: null,
      id_unidad: null,
    },
    solicitudDetalle: [],
    infoPago: null,
    filters2: {
      page: 1,
      per_page: 10,
      total_rows: null,
      pagination: "true",
    },
    compra: {},
    totales: []
  },
  mutations: {
    setSolicitudes(state, payload) {
      state.solicitudes = payload;
    },
    setPagination(state, payload) {
      state.filters.page = Number(payload.page);
      state.filters.per_page = Number(payload.per_page);
      state.filters.total_rows = Number(payload.total_rows);
    },
    setPaginationDetalle(state, payload) {
      state.filters2.page = Number(payload.page);
      state.filters2.per_page = Number(payload.per_page);
      state.filters2.total_rows = Number(payload.total_rows);
    },
    setFiltroFecha(state, payload) {
      state.filters.fecha_desde = payload.fecha_desde;
      state.filters.fecha_hasta = payload.fecha_hasta;
      state.filters.page = 1;
    },
    setFiltroEstado(state, payload) {
      state.filters.id_estado = payload;
      state.filters.page = 1;
    },
    setSolicitud(state, payload) {
      state.solicitudDetalle = payload;
    },
    setInfoPago(state, payload) {
      state.infoPago = payload;
    },
    setNuevoInsumoEdit(state, payload) {
      state.nuevoInsumo[payload.atributo] = payload.valor;
    },
    setFiltroUnidad(state, payload) {
      state.filters.id_unidad = payload;
      state.filters.page = 1;
    },
    setCompra(state, payload) {
      state.compra = payload;
    },
    setTotales(state, payload) {
      state.totales = payload;
    },
  },
  actions: {
    async getSolicitud({ commit, state }, id_compra_linea) {
      Vue.prototype.showLoader();
      const response = await CompraLinea.getCompraEnLinea(
        id_compra_linea
      ).catch(() => {
        Vue.prototype.hideLoader();
      });

      commit("setCompra", response.data);
    },
    async getSolicitudes({ commit, state }) {
      Vue.prototype.showLoader();
      const response = await CompraLinea.getSolicitudesCompraLinea(
        state.filters
      ).catch(() => {
        Vue.prototype.hideLoader();
      });
      if (response.status === 200) {
        commit("setSolicitudes", response.data.solicitudes);
        commit("setPagination", response.headers);
        commit("setTotales", response.data.totales);
      }
      Vue.prototype.hideLoader();
    },
    async getDetalleCompraLinea({ commit, state }, id_compra_linea) {
      Vue.prototype.showLoader();
      state.infoPago = null;
      const response = await CompraLinea.getDetalleSolicitudCompra(
        id_compra_linea,
        state.filters2
      ).catch(() => {
        Vue.prototype.hideLoader();
      });
      if (response.status === 200) {
        commit("setSolicitud", response.data.detalles);
        commit("setInfoPago",response.data.pago)
        commit("setPaginationDetalle", response.headers);
      }
      Vue.prototype.hideLoader();
    },
  },
};
