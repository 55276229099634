export default class LegacyValidations {
    static maxFileSize = 1024000000;
    static gbComparer = 1024000000;

    static maxFileSizeRule(size = this.maxFileSize) {
        return function validate(file) {
            if (!file) return true;
    
            const exceedsFileSize = file.size > size;

            return !exceedsFileSize;
        }
    }

    static maxSizeRuleSimple(size = this.maxFileSize, file = null) {
            if (!file) return true;
            const exceedsFileSize = file.size > size;
            return !exceedsFileSize;
    }

    static pushToErrorsArray = (errors, size = this.maxFileSize, unity = 'MB') => {
        const maxSizeString = this.getMaxSizeString(size, unity);
        errors.push(`El archivo no debe sobrepasar los ${maxSizeString}`);
    }

    static getMaxSizeString(size = this.maxFileSize, unity = 'MB') {
        const maxSizeInUnity = unity === 'MB' ? size / 1000000 : size;
        const unityComparer = unity === 'MB' ? this.gbComparer / 1000000 : this.gbComparer;

        let maxSizeString = `${maxSizeInUnity}${unity}`;
        if (!(maxSizeInUnity / unityComparer < 1)) {
            maxSizeString = `${(maxSizeInUnity / unityComparer).toFixed(2)}GB`;
        }

        return maxSizeString;
    }
}
