import requestProviders from "../../../../services/AprobacionProveedores.services";
import taxpayingTypes from "../../../../services/Contribuyentes.services";
import classificationCompanies from "../../../../services/ClasificacionEmpresa.services";
import SolicitudProveedor from "../../../../services/SolicitudProveedor.services";
export default {
  namespaced: true,
  state: {
    id_request: null,
    declaration: null,
    proveedor: {},
    lista_proveedores: [],
    lista_tipo_controbuyente: [],
    lista_clasificacion_empresarial: [],
    lista_historial_observaciones: [],
    lista_estados_aprobacion: [],
    filters: {
      page: 1,
      per_page: 10,
      total_rows: null,
      nombre: null,
      id_clasificacion_empresa: null,
      id_identificacion_tributaria: null,
      fecha_desde: "",
      fecha_hasta: "",
      id_solicitud_proveedor_estado: null,
      ordenar_proveedor: null,
    },
  },
  mutations: {
    setIdRequest(state, payload) {
      state.id_request = payload;
    },
    setDeclaration(state, payload) {
      state.declaration = payload;
    },
    setProveedor(state, payload) {
      state.proveedor = payload;
    },
    setListaProveedores(state, payload) {
      state.lista_proveedores = payload;
    },
    setListaTipoContribuyente(state, payload) {
      state.lista_tipo_controbuyente = payload;
    },
    setListaClasificacionEmpresarial(state, payload) {
      state.lista_clasificacion_empresarial = payload;
    },
    setListaHistorialObservacion(state, payload) {
      state.lista_historial_observaciones = payload;
    },
    setPaginationData(state, payload) {
      state.filters.page = Number(payload.page);
      state.filters.per_page = Number(payload.per_page);
      state.filters.total_rows = Number(payload.total_rows);
    },
    setFiltroNombre(state, payload) {
      state.filters.nombre = payload;
      state.filters.page = 1;
    },
    setFiltroTipoContribuyente(state, payload) {
      state.filters.id_identificacion_tributaria = payload;
      state.filters.page = 1;
    },
    setFiltroClasificacion(state, payload) {
      state.filters.id_clasificacion_empresa = payload;
      state.filters.page = 1;
    },
    setFiltroFechaDesde(state, payload) {
      state.filters.fecha_desde = payload;
      state.filters.page = 1;
    },
    setFiltroFechaHasta(state, payload) {
      state.filters.fecha_hasta = payload;
      state.filters.page = 1;
    },
    setFiltroEstado(state, payload) {
      state.filters.id_solicitud_proveedor_estado = payload;
      state.filters.page = 1;
    },
    setListaEstadosAprobacion(state, payload) {
      state.lista_estados_aprobacion = payload;
    },
    setOrdenarProveedor(state, payload) {
      state.filters.ordenar_proveedor = payload;
      state.filters.page = 1;
    },
    // Limpiar Filtros y paginación
    setLimpiarFiltros(state) {
      state.filters.nombre = null;
      state.filters.id_clasificacion_empresa = null;
      state.filters.id_identificacion_tributaria = null;
      state.filters.fecha_desde = "";
      state.filters.fecha_hasta = "";
      state.filters.id_solicitud_proveedor_estado = null;
      state.filters.ordenar_proveedor = null;
      state.filters.page = 1;
      state.filters.per_page = 10;
    },
  },
  actions: {
    limpiarFiltros({ commit }) {
      commit("setLimpiarFiltros");
    },
    saveIdRequest({ commit }, id) {
      commit("setIdRequest", id);
    },
    async getDeclaration({ commit }, id) {
      const response = await requestProviders.getDeclarationRequestProvider(id);
      if (response.status === 200) {
        commit("setDeclaration", response?.data);
      } else {
        commit("setDeclaration", null);
      }
    },
    async getProveedor({ commit }, id) {
      const response = await requestProviders.getRequestProvidersById(id);
      if (response.status === 200) {
        commit("setProveedor", response?.data);
      } else {
        commit("setProveedor", {});
        return response;
      }
    },
    async getListaProveedores({ commit, state }) {
      // Verificamos si las fechas no estén vacías, si es así, verificamos que la fecha desde sea menor a la fecha hasta
      // Si no es asi las intercambiamos
      if (
        state.filters.fecha_desde !== "" &&
        state.filters.fecha_hasta !== ""
      ) {
        if (state.filters.fecha_desde > state.filters.fecha_hasta) {
          let aux = state.filters.fecha_desde;
          state.filters.fecha_desde = state.filters.fecha_hasta;
          state.filters.fecha_hasta = aux;
        }
      }

      const response = await requestProviders.getRequestProviders(
        state.filters
      );
      if (response.status === 200) {
        response.data.forEach((element, index) => {
          element.correlativo = index + 1;
        });
        commit("setListaProveedores", response?.data);
        commit("setPaginationData", response?.headers);
      } else {
        commit("setListaProveedores", []);
      }
    },
    async getListaTipoContribuyente({ commit }) {
      const response = await taxpayingTypes.getTipoContribuyente();
      if (response.status === 200) {
        commit("setListaTipoContribuyente", response?.data);
      }
    },
    async getListaClasificacionEmpresarial({ commit }) {
      const response = await classificationCompanies.getClasificacionEmpres();
      if (response.status === 200) {
        commit("setListaClasificacionEmpresarial", response?.data);
      }
    },
    async getListaHistorialObservaciones({ commit }, id) {
      const response =
        await requestProviders.getObservationHistoryRequestProvider(id);
      if (response.status === 200) {
        commit("setListaHistorialObservacion", response.data);
      } else {
        commit("setListaHistorialObservacion", []);
      }
    },
    async updateEstadoSolicitudProveedor({ commit }, data) {
      const response = await requestProviders.updateStatusRequestProvider(
        data.id,
        data.body
      );
      return response;
    },
    async getListaEstadosAprobacion({ commit }) {
      const response = await SolicitudProveedor.getSolicitudesEstados();
      if (response.status === 200) {
        commit("setListaEstadosAprobacion", response?.data);
      }
    },
    async ordenarPorProveedor({ commit, dispatch }, data) {
      commit("setOrdenarProveedor", data);
      dispatch("getListaProveedores");
    },
  },
};
