import {http_client} from "@/plugins/http_client";


const getActividadEmpresarial = async(params ={}) =>{
    return await http_client("/api/v1/giros",params,"get")
}


export default{
    getActividadEmpresarial
}