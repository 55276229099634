import ProveedoresServices from "../../../../services/Proveedores.services";
import agreementMarcoServices from "../../../../services/ConvenioMarco.services";
export default {
  namespaced: true,
  state: {
    lista_convenios: [],
    filters: {
      page: 1,
      per_page: 3,
      total_rows: null,
      nombre_obs: null,
    },
    obs_tag_list: [],
    obs_parent_list: [],
    currentAgreement: {},
    currentAgreementDesc: [],
    currentAgreementDocs: [],
    currentAgreementPhases: [],
    agreementPresentations: [],
    breadcrumbsItems: [
      {
        text: "Convenios Marco",
        disable: false,
        href: "/listado-convenios-proveedor",
      },
    ],
    presentationsFilters: {
      page: 1,
      per_page: 10,
      total_rows: null,
    },
  },
  mutations: {
    setPaginationData(state, payload) {
      state.filters.page = Number(payload.page);
      state.filters.per_page = Number(payload.per_page);
      state.filters.total_rows = Number(payload.total_rows);
    },
    setPresentationPagination(state, payload) {
      state.presentationsFilters.page = Number(payload.page);
      state.presentationsFilters.per_page = Number(payload.per_page);
      state.presentationsFilters.total_rows = Number(payload.total_rows);
    },
    setConveniosListData(state, payload) {
      state.lista_convenios = payload;
    },
    setObsTagList(state, payload) {
      state.obs_tag_list = payload;
    },
    setObsParentList(state, payload) {
      state.obs_parent_list = payload;
    },
    setCurrentAgreement(state, payload) {
      state.currentAgreement = payload;
    },
    setCurrentAgreementDesc(state, payload) {
      state.currentAgreementDesc = payload;
    },
    setCurrentAgreementDocs(state, payload) {
      state.currentAgreementDocs = payload;
    },
    setCurrentAgreementPhases(state, payload) {
      state.currentAgreementPhases = payload;
    },
    setAgreementPresentations(state, payload) {
      state.agreementPresentations = payload;
    },
  },
  actions: {
    async getListaConvenios({ state, commit }) {
      const response = await ProveedoresServices.getProviderAgreement(
        state.filters
      );
      if (response.status === 200) {
        commit("setConveniosListData", response?.data);
        commit("setPaginationData", response?.headers);
      } else {
        commit("setConveniosListData", []);
      }
    },
    async getObsTagList({ commit }) {
      const response = await ProveedoresServices.getProviderAgreementTags();
      if (response.status === 200) {
        commit("setObsTagList", response?.data);
      } else {
        commit("setObsTagList", []);
      }
    },
    async getObsParentList({ commit }) {
      const response =
        await ProveedoresServices.getProviderAgreementObsFilter();
      if (response.status === 200) {
        commit("setObsParentList", response?.data);
      } else {
        commit("setObsParentList", []);
      }
    },
    async getCurrentAgreement({ commit }, id) {
      const response = await agreementMarcoServices.getConvenioProveedor(id);
      if (response.status === 200) {
        commit("setCurrentAgreement", response?.data);
      } else {
        commit("setCurrentAgreement", {});
      }
    },
    async getCurrentAgreementDesc({ commit }, id) {
      const response = await agreementMarcoServices.getConvenioProveedorOBSDesc(
        id
      );
      if (response.status === 200) {
        commit("setCurrentAgreementDesc", response?.data);
      } else {
        commit("setCurrentAgreementDesc", {});
      }
    },
    async getCurrentAgreementDocs({ commit }, id) {
      const response = await agreementMarcoServices.getConvenioProveedorOBSDocs(
        id
      );
      if (response.status === 200) {
        commit("setCurrentAgreementDocs", response?.data);
      } else {
        commit("setCurrentAgreementDocs", {});
      }
    },
    async getCurrentAgreementPhases({ commit }, id) {
      const response = await agreementMarcoServices.getConvenioProveedorPhases(
        id
      );
      if (response.status === 200) {
        commit("setCurrentAgreementPhases", response?.data);
      } else {
        commit("setCurrentAgreementPhases", {});
      }
    },
    async getAgreementPresentations({ commit, state }, id_agreement) {
      const response = await agreementMarcoServices.getConvenioPresentations(
        id_agreement,
        state.presentationsFilters
      );
      if (response.status === 200) {
        commit("setAgreementPresentations", response?.data);
        commit("setPresentationPagination", response?.headers);
      } else {
        commit("setAgreementPresentations", {});
      }
    },
  },
};
