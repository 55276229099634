import { http_client } from "@/plugins/http_client";

const url = "/api/v1/instituciones/techos";

const getInstituciones = async (params = {}) => {
  return await http_client(url, params, "get");
};

const getInstitucion = async (id) => {
  return await http_client(`/api/v1/instituciones/${id}`, {}, "get");
};

const getCatalogoInstituciones = async (params = {}) => {
  return await http_client(`/api/v1/catalogo/instituciones`, params, "get");
};
const getCatalogoInstitucionesComprasConjuntas = async (params = {}) => {
  return await http_client(`/api/v1/catalogo/instituciones/compras/conjuntas`, params, "get");
};
const getCatalogoInstitucionesComprasConjuntasNoAdscritas = async (params = {}) => {
  return await http_client(`/api/v1/catalogo/instituciones/compras/conjuntas/no/adscritas`, params, "get");
};
const getCatalogoInstitucionesComprasConjuntasAdscritas = async (params = {}) => {
  return await http_client(`/api/v1/catalogo/instituciones/compras/conjuntas/adscritas`, params, "get");
};

const adscribirInstitucion= async (id)=>{
  return await http_client(`/api/v1/catalogo/instituciones/adscribir/institucion/${id}`, {}, "post");
}
const removerInstitucionCompraConjunta = async (id) => {
  return await http_client(`/api/v1/catalogo/instituciones/compras/conjuntas/${id}`, {}, "delete");
};

const postCatalogoInstituciones = async (params = {}) => {
  return await http_client(`/api/v1/catalogo/instituciones`, params, "post");
};

const putCatalogoInstituciones = async (id_institucion, params = {}) => {
  return await http_client(
    `/api/v1/catalogo/instituciones/${id_institucion}`,
    params,
    "put"
  );
};

const obtenerCatalogoInstituciones = async (params = {}) => {
  return await http_client(`/api/v1/catalogo/instituciones/catalogo-instituciones`, params, "get");
};

export default {
  getInstituciones,
  getInstitucion,
  getCatalogoInstituciones,
  postCatalogoInstituciones,
  putCatalogoInstituciones,
  getCatalogoInstitucionesComprasConjuntas,
  removerInstitucionCompraConjunta,
  getCatalogoInstitucionesComprasConjuntasNoAdscritas,
  adscribirInstitucion,
  getCatalogoInstitucionesComprasConjuntasAdscritas,
  obtenerCatalogoInstituciones
};
