const aprobacionUFI = [
    {
      path: "/aprobacionUFI-solicitudes",
      name: "aprobacionUFI-soliciudes",
      component: () =>
        import(
          /* webpackChunkName: "conveniosProveedor" */ "./solicitudesView.vue"
        ),
    },
    {
        path: "/insumos-listado",
        name: "insumos-listado",
        component: () =>
          import(
            /* webpackChunkName: "conveniosProveedor" */ "./insumosListaView.vue"
          ),
      },
  ];
  
  export default aprobacionUFI;