import { http_client, http_client_with_status } from "@/plugins/http_client";

const getEvaluadorPeoProcesoCompra = async (id_proceso_compra, params = {}) =>
    http_client(
    `/api/v1/evaluar-permisos-url/${id_proceso_compra}`,
    params,
"get"
);

export default {
    getEvaluadorPeoProcesoCompra,
};
