import { http_client_with_status } from "@/plugins/http_client"

const getPendingItems = async (filters) => await http_client_with_status("/api/v1/cm-solicitudes-productos/pendientes", filters)

const obtenerProductoOrden = async (filters) => await http_client_with_status("/api/v1/cm-solicitudes-productos/lista-producto", filters)

const getSeenItems = async (filters) => await http_client_with_status("/api/v1/cm-solicitudes-productos/vistas", filters)

const approve = async (itemId, data) => await http_client_with_status(`/api/v1/cm-solicitudes-productos/aceptar/${itemId}`, data, 'POST', {
    'Content-Type': 'multipart/form-data'
})

const getButtons = async (itemId, data) => await http_client_with_status(`/api/v1/cm-solicitudes-productos/botones/${itemId}`, data, 'GET')

const getItemHistory = async(itemId) => await http_client_with_status(`/api/v1/cm-solicitudes-productos/historial/${itemId}`)

const getItemDetails = async(itemId) => await http_client_with_status(`/api/v1/cm-solicitudes-productos/detalle/${itemId}`)

const getStatusList = async () => await http_client_with_status('api/v1/cm-solicitudes-productos/listaEstados');

export default {
    getPendingItems,
    getSeenItems,
    approve,
    getButtons,
    getItemDetails,
    getItemHistory,
    getStatusList,
    obtenerProductoOrden,
}
