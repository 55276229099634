const etapaBaseProceso = {
    namespaced: true,
    state: {
       listDocumentosBase: []
    },

    mutations: {
        setListDocumentosBase (state, payload) {
            state.listDocumentosBase = payload
        },
    },

    actions: {}
}

export default etapaBaseProceso