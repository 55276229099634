import Vue from "vue";
import store from "../store";
import publicToken from "../plugins/encrypt";
import { isApiErrorResponse, isBlobApiErrorResponse } from "@/utils/loadable";

const validStatus = [200, 201, 204];

export const http_client_with_status_slim = function (path) {
  return function (
    url,
    { data = {}, method = "get", headers = {}, requestConfig = {} }
  ) {
    return http_client_with_status(
      `${path}${url}`,
      data,
      method,
      headers,
      requestConfig
    );
  };
};

export const http_client_with_status = async (
  url,
  data = {},
  method = "get",
  headers = {},
  requestConfig = {}
) => {
  const token = store.state.token ?? localStorage.getItem("token");

  if (token) headers.Authorization = "Bearer " + token;
  if (!token) headers["x-csrf-token"] = await publicToken();

  let config = {
    ...requestConfig,
    method,
    url,
    headers,
    validateStatus: (status) => validStatus.includes(status),
  };

  const propName = method === "get" ? "params" : "data";
  config[propName] = data;

  try {
    if (url.includes("undefined") || url.includes("null")) {
      throw new Error("Parámetros inválidos en la url");
    }
    const res = await Vue.prototype.axios(config);
    return res;
  } catch (error) {
    // Manejo de errores de tipo archivo
    if (error.response.data instanceof Blob) {
      const [isApiError, decoded] = await isBlobApiErrorResponse(
        error.response.data
      );

      if (isApiError)
        return {
          data: generateApiFormatError(
            decoded.error.message,
            decoded.error.code
          ),
        };
    }

    // Manejo de errores por defecto
    const fallback =
      "Su solicitud no ha podido ser procesada, vuelva a intentar. Si el error persiste, intente más tarde.";
    const errorMessage = isApiErrorResponse(error.response.data)
      ? error.response.data.error.message
      : error.response.data?.message ?? fallback;
    const errorCode = isApiErrorResponse(error.response.data)
      ? error.response.data.error.code
      : undefined;

    return { data: generateApiFormatError(errorMessage, errorCode) };
  }
};

const generateApiFormatError = (message, code) => ({
  error: { message, ...(code && { code }) },
});

const formatError = async ({
  e,
  defaultMessage = "Su solicitud no ha podido ser procesada, vuelva a intentar. Si el error persiste, intente más tarde",
}) => {
  // Manejo de errores de tipo archivo
  if (e.response.data instanceof Blob) {
    const [, decoded] = await isBlobApiErrorResponse(e.response.data);
    return decoded?.description || decoded?.message;
  }

  return (
    e?.response?.data?.message ||
    e?.response?.data?.description ||
    e?.response?.data?.err?.description ||
    e?.response?.data?.err?.message ||
    e?.response?.data?.error?.description ||
    e?.response?.data?.error?.message ||
    (e?.response?.data?.length > 0
      ? e?.response?.data?.map(({ message }) => message).join(". ")
      : null) ||
    defaultMessage
  );
};

export const http_client_slim = function (path) {
  return function (
    url,
    {
      data = {},
      method = "get",
      headers = {},
      sendToken = true,
      responseType = "",
      showErrorAlert = true,
      onUploadProgress = null,
      disableCsrfHeader = false,
      enableLoader = true,
    } = {}
  ) {
    return http_client(
      `${path}${url}`,
      data,
      method,
      headers,
      sendToken,
      responseType,
      showErrorAlert,
      onUploadProgress,
      disableCsrfHeader,
      enableLoader
    );
  };
};

/**
 * @deprecated usar http_client_slim
 */
export const http_client = async (
  url,
  data = {},
  method = "get",
  headers = {},
  sendToken = true,
  responseType = "",
  showErrorAlert = true, // Indica si se muestran los mensajes de error al ocurrir una exepcion
  onUploadProgress = null,
  disableCsrfHeader = false,
  enableLoader = true
) => {
  let ocultarErrorUndefinedParam = false;
  const token = store.state.token
    ? store.state.token
    : localStorage.getItem("token");

  if (token && sendToken) headers.Authorization = "Bearer " + token;
  if (!token && !disableCsrfHeader)
    headers["x-csrf-token"] = await publicToken();

  let config = {
    method,
    url,
    headers,
    responseType,
    onUploadProgress,
  };
  if (method === "get") {
    config.params = data;
  } else {
    config.data = data;
  }
  try {
    if (url.includes("undefined") || url.includes("null")) {
      ocultarErrorUndefinedParam = true;
      throw new Error("Parámetros inválidos en la url");
    }
    if (enableLoader) Vue.prototype.showLoader();
    const res = await Vue.prototype.axios(config);
    const { data, status } = res;
    if (status === 201 && typeof data === "string") {
      Vue.prototype.pushAppMessage({
        show: true,
        message: data,
        type: "success",
      });
    }
    if (enableLoader) Vue.prototype.hideLoader();
    return res;
  } catch (e) {
    Vue.prototype.hideLoader();
    if (showErrorAlert && !ocultarErrorUndefinedParam) {
      const errores = [
        {
          error: 500,
          message: await formatError({
            e,
            defaultMessage:
              "Su solicitud no ha podido ser procesada, vuelva a intentar. Si el error persiste, intente más tarde.",
          }),
          type: "warning",
          show: true,
        },
        {
          error: 422,
          message: await formatError({
            e,
            defaultMessage: "No se pudo procesar la entidad",
          }),
          type: "warning",
          show: true,
        },
        {
          error: 404,
          message: await formatError({
            e,
            defaultMessage: "No se encontró el recurso",
          }),
          type: "error",
          show: true,
        },
        {
          error: 403,
          message: await formatError({
            e,
            defaultMessage: "Petición rechazada",
          }),
          type: "error",
          show: true,
        },
        {
          error: 400,
          message: await formatError({
            e,
            defaultMessage: "Petición erronea",
          }),
          type: "warning",
          show: true,
        },
        {
          error: 401,
          message: await formatError({
            e,
            defaultMessage: "Acceso no autorizado",
          }),
          type: "error",
          show: true,
        },
      ];

      const notificacion = errores?.find(
        (error) => error?.error === e?.response?.status
      ) ?? {
        show: true,
        message: await formatError({
          e,
          defaultMessage: "Error al realizar petición",
        }),
        type: "error",
      };
      Vue?.prototype.pushAppMessage(notificacion);
    }
    throw e;
  }
};

Vue.prototype.http_client = http_client;
