import proveedoresServices, {getProcesosProveedoresConjuntas} from "../../../services/Proveedores.services"
import obsServices from "../../../services/Obs.services"
import PaacServices from "../../../services/Paac.services"


export const getObsList = async ({commit}) => {
    const obs = await obsServices.getObsList()
    commit('setObsList', obs.data)
}


export const getTipoEntidades = async ({commit}) => {
    const tipoEntidades = await proveedoresServices.getTipoEntidades()
    commit('setTipoEntidades', tipoEntidades.data)
}

export const getTipoContrato = async ({commit}) => {
    const tipoContrato = await proveedoresServices.getTipoContrato()
    commit('setTipoContrato', tipoContrato.data)
}

export const getPaacProveedor = async ({commit}, filtros = {}) => {
    const {data, headers: {total_rows}} = await proveedoresServices.getProcesosProveedores(filtros)
    commit('setListaInstituciones', data)
    commit('setTotalRowsPaac', total_rows);
}
export const getPaacProveedorConjuntos = async ({commit}, filtros = {}) => {
    const {data, headers: {total_rows}} = await proveedoresServices.getProcesosProveedoresConjuntos(filtros)
    commit('setListaInstitucionesConjuntas', data)
    commit('setTotalRowsPaacConjuntas', total_rows);
}

export const getInfoGeneral = async ({commit}, id_proceso_compra) => {
    const response = await PaacServices.getInfoGeneralProcesoCompra(id_proceso_compra)
    if (response.status === 200) {
        commit("setInfoGeneral", response.data)
    }
}

export const getDocumentos = async ({commit}, id_proceso_compra) => {
    const response = await PaacServices.getDocumentosProcesoCompra(id_proceso_compra)
    if (response.status === 200) {
        commit("setListaDocumentos", response.data)
    }
}

export const getEtapas = async ({commit}, id_proceso_compra) => {
    const response = await PaacServices.getEtapasProcesoCompra(id_proceso_compra)
    if (response.status === 200) {
        commit("setListaEtapas", response.data)
    }
}

export const participar = async ({commit}, id_proceso_compra) => {
    const response = await PaacServices.participar(id_proceso_compra)
    return response
}

export const verificarParticipacion = async ({commit}, id_proceso_compra) => {
    const response = await proveedoresServices.validarParticipacionProveedor(id_proceso_compra)
    return response
}
