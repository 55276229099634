import { http_client } from "@/plugins/http_client";

const publicarProceso = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/procesos/compra/${id_proceso_compra}/publicar/simplificadov1`,
    params,
    "put"
  );
};

const saveGanadorProceso = async (id_proceso, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso}/ganadoresv1`,
    params,
    "post"
  );
};

const getGanadoresProcesos = async (id_proceso, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso}/ganadores`,
    params
  );
};

export default {
  publicarProceso,
  saveGanadorProceso,
  getGanadoresProcesos,
}