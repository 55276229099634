import FuentesFinanciamientosServices from "../../../../../services/FuentesFinanciamientos.services";

export default {
  async getListaFuentes({ commit }, filters = {}) {
    const response = await FuentesFinanciamientosServices.getFuentes(filters);
    if (response.status === 200) {
      commit("setListaFuentes", response?.data);
      commit("setPaginationData", response?.headers);
    } else {
      commit("setListaFuentes", []);
    }
  },

  async getCtlFuenteFinanciamiento({ commit }) {
    const response = await FuentesFinanciamientosServices.getFuentes({ pagination: false });
    if (response.status === 200) {
      commit("setCtlFuenteFinanciamiento", response?.data);
    }
  },

  async storeFuente({ commit }, data) {
    const response = await FuentesFinanciamientosServices.storeFuente(data);
    return response;
  },

  async updateFuente({ commit }, data) {
    const response = await FuentesFinanciamientosServices.updateFuente(data);
    return response;
  },
  async deleteFuente({ commit }, id) {
    const response = await FuentesFinanciamientosServices.deleteFuente(id);
    return response;
  },
};
