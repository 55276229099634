const cmConvocatoriasRoutes = [
    {
        path: "/convocatoria-convenio-marco",
        name: "convocatoria-convenio-marco",
        component: () =>
            import(/* webpackChunkName: "CmConvocatorias" */ "./CmConvocatoriasView.vue")
    },
    {
        path: "/cm-convocatorias/aprobacion/:id_convocatoria",
        name: "cm-convocatorias-aprobacion",
        component: () =>
            import(/* webpackChunkName: "CmAprobacionConvocatorias" */ "./CmAprobacionConvocatoriasView.vue"),
    },
    {
        path: "/cm-documentos-convocatoria/:id_convocatoria",
        name: "cm-documentos-convocatoria",
        component: () =>
            import(/* webpackChunkName: "CmDocumentosConvocatoria" */ "./CmDocumentosConvocatoriaView.vue"),
    }
]

export default cmConvocatoriasRoutes;
