const procesoCompraDoc = [
  {
    path: "/pac-procesos",
    name: "pac-procesos",
    component: () =>
      import(/* webpackChunkName: "ProcesosCompra" */ "./PACListView.vue"),
  },
  {
    path: "/pac-procesos/etapas/:idProceso/:supervisor?",
    name: "pac-procesos-etapas",
    component: () =>
      import(
        /* webpackChunkName: "EtapasProcesoCompra" */ "./etapasProcesoView.vue"
      ),
  },
  {
    path: "/pac-procesos/configuracion/:idProceso",
    name: "pac-procesos-configuracion",
    component: () =>
      import(
        /* webpackChunkName: "ConfiguracionProcesoCompra" */ "./ConfiguracionProcesoView.vue"
      ),
    meta: { version: 2 },
  },
  {
    path: "/pac-procesos/:idProceso/cdp",
    name: "proceso-asignar-cdp",
    component: () =>
      import(
        /* webpackChunkName: "asignacionCdpProceso" */ "./AsignacionCdpProcesoView.vue"
      ),
  },
  {
    path: "/pac-procesos/detalle/:idProceso",
    name: "pac-procesos-detalle",
    component: () =>
      import(
        /* webpackChunkName: "DetalleProcesoCompra" */ "./DetailInterPresiView.vue"
      ),
  },
  {
    path: "/pac-procesos/:idProceso/ofertas/:idTipoOferta/:idOferta?/:idParticipacionProveedor?",
    name: "pac-procesos-ofertas",
    component: () =>
      import(
        /* webpackChunkName: "ProveedorOfertaView" */ "./ProveedorOfertaView.vue"
      ),
  },
  {
    path: "/subasta-inversa-agregar-sala/:idProceso/:idSala",
    name: "subasta-inversa-agregar-sala",
    component: () =>
      import(
        /* webpackChunkName: "configuracion-subasta-inversa-sala" */ "./LotesSalaView.vue"
      ),
  },
  {
    path: "/subasta-inversa-configuracion-sala/:idProceso/:idSala/:idSubasta?",
    name: "subasta-inversa-configuracion-sala",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "configuracion-subasta-inversa-sala" */ "./ConfiguracionSalaView.vue"
      ),
  },
];

export default procesoCompraDoc;
