const admRegiones = [
    {
      path: "/administracion-atributos",
      name: "administracion-atributos",
      component: () =>
        import(
          /* webpackChunkName: "adm-atributos" */ "./main.vue"
        ),
    },
  ];
  
  export default admRegiones;
  