
export const loadingEstado = (state, val) => {
  state.loading = val;
};

export const obtenerProductos = (state, val) => {
  state.productos = val;
};

export const cargarProductoEstado = (state, val) => {
  state.cargar_producto = val;
};

export const cambiarProductoSeleccionado = (state, val) => {
  state.productoSeleccionado = val;
};
export const idProductoSeleccionado = (state, val) => {
  state.id_producto_seleccionado = val;
};

export const setPaginationData = (state, val) => {
  state.productosFilters.page = Number(val.page);
  state.productosFilters.per_page = Number(val.per_page);
  state.productosFilters.total_rows = Number(val.total_rows);
};

export const setObsFilter = (state, val) => {
  state.productosFilters.id_obs = val;
};

export const setProveedorFilter = (state, val) => {
  state.productosFilters.id_proveedor = val;
};

export const setPrecioMinFilter = (state, val) => {
  state.productosFilters.precio_minimo = val;
};
export const setPrecioMaxFilter = (state, val) => {
  state.productosFilters.precio_maximo = val;
};

export const setProvidersList = (state, val) => {
  state.proveedores = val;
};
