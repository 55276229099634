const routesContralores = [
  {
    path: "contralores",
    name: "contralores",
    component: () =>
      import(/* webpackChunkName: "ContraloresView" */ "./ContraloresView.vue"),
  },
];

export default routesContralores;
