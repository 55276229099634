import paacServices from "../../../../services/Paac.services";

export const setConsultas = ({ commit }, payload) => {
  commit("setConsultas", payload);
};

export const setIdProcesoCompra = ({ commit }, payload) => {
  commit("setIdProcesoCompra", payload);
};

// Función que permite responder a una consulta hecha por un proveedor
// y publicarla para que sea vista por todos los proveedores
// Tener en cuenta que el payload es un FormData
export const responderConsulta = async (
  { commit, state, dispatch },
  payload
) => {
  // El payload es un FormData
  const formData = payload;

  if (state.id_estado === 2) {
    commit("setIsLoadingRechazar", true);
  } else {
    commit("setIsLoadingAgregar", true);
  }

  // En un principio no se mandaba la info como formData
  // let params = {
  //   consulta: payload.consulta,
  // };

  if (payload.id_consulta !== null && payload.id_consulta !== undefined) {
    // params.id_consulta = payload.id_consulta;
    formData.append("id_consulta", payload.id_consulta);
  }

  if (payload.id_estado !== null && payload.id_estado !== undefined) {
    // params.id_estado = payload.id_estado;
    formData.append("id_estado", payload.id_estado);
  }

  const response = await paacServices.postConsultas(
    formData,
    state.id_proceso_compra
  );

  if (response.status === 201) {
    // Actualizamos la lista de consultas
    dispatch("obtenerConsultas");
  }

  commit("setIsLoadingAgregar", false);
  commit("setIsLoadingRechazar", false);

  return response;
};

export const editarConsulta = async ({ commit, dispatch }, payload) => {
  const idConsulta = payload.id_consulta;
  commit("setIsLoadingAgregar", true);

  const response = await paacServices.putConsultas(
    { consulta: payload.consulta },
    idConsulta
  );

  if (response.status === 200) {
    // Actualizamos la lista de consultas
    dispatch("obtenerConsultas");
  }

  commit("setIsLoadingAgregar", false);

  return response;
};

// Actualiza las consultas luego de que se haya agregado una nueva
export const obtenerConsultas = async ({ commit, state }) => {
  const response = await paacServices.getEtapaActual(state.id_proceso_compra);

  if (response.status === 200 && response.data.id_etapa === 2) {
    commit("setConsultas", response.data.consultas);
  }
};
