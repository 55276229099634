import { http_client } from "@/plugins/http_client";

const getCifrados = async (params = {}) => {
  return await http_client(`/api/v1/cifrados`, params, "get");
};

const getCifradosProceso = async (id_proceso, filters = {}) => {
  return await http_client(`/api/v1/cdf/cifrado/${id_proceso}`, filters, "get")
}

const getElementsCifrados = async (params = {}) => {
  return await http_client(`/api/v1/cifrados/elementos`, params, "get");
};

const getCatalogoCifrados = async (params = {}) => {
  return await http_client(`/api/v1/cifrados/catalogo`, params, "get");
};

const addElementoCifrado = async (params = {}) => {
  return await http_client(`/api/v1/cifrados/catalogo`, params, "post");
};

const updateElementoCifrado = async (params = {}) => {
  return await http_client(`/api/v1/cifrados/catalogo`, params, "put");
};

const getVersionesCifrados = async (params = {}) => {
  return await http_client(`/api/v1/cifrados/versiones`, params, "get");
};

const postVersionCifrado = async (params = {}) => {
  return await http_client(`/api/v1/cifrados/versiones`, params, "post");
};

const putVersionInstitucion = async (id_institucion, params = {}) => {
  return await http_client(`/api/v1/cifrados/institucion/${id_institucion}/asignar`, params, "put");
};

const deleteElementoCifrado = async (id_catalogo, params = {}) => {
  return await http_client(`/api/v1/cifrados/catalogo/${id_catalogo}`, params, "delete");
};

const getRelacionObsOeg = async (params = {}) => {
  return await http_client(`/api/v1/relacion-obs-oeg`, params, "get");
};

const getSegmentos = async (filter) => {
  return await http_client("/api/v1/obs/segmentos", filter, "get")
}

const guardarRelacionObsOeg = async (params = {}) => {
  return await http_client(`/api/v1/relacion-obs-oeg`, params, "put");
};

const deleteRelacionObsOeg = async (id_relacion, params = {}) => {
  return await http_client(`/api/v1/relacion-obs-oeg/${id_relacion}`, params, "delete");
};


const getCifradoInfo = async (id_fondo, params = {}) => {
  return await http_client(`/api/v1/cifrados/${id_fondo}`, params, "get");
};

const updateCifradoInfo = async (id_fondo, params = {}) => {
  return await http_client(`/api/v1/cifrados/${id_fondo}`, params, "put");
}

// new view
const getDetailProceso = async (id_proceso, params = {}) => {
  return await http_client(`/api/v1/cdf/proceso/${id_proceso}/detalle`, params);
};

const getFondosCifrado = async (id_proceso, params = {}) => {
  return await http_client(`/api/v1/cdf/proceso/${id_proceso}`, params);
};

const getFinanciamientoCifrado = async (id_proceso, params = {}) => {
  return await http_client(`/api/v1/cdf/proceso/${id_proceso}/cifrado/financiamiento`, params);
};

const getUnidadFinanciamiento = async (id_proceso, id_fondo, params = {}) => {
  return await http_client(`/api/v1/cdf/proceso/${id_proceso}/fondo/${id_fondo}`, params);
}

const postCifrado = async (params = {}) => {
  return await http_client(`/api/v1/cdf/proceso/financiamiento`, params, "post");
};
const postFinanciamientoCifrado = async (params = {}) => {
  return await http_client(`/api/v1/cdf/financiamiento/unidad`, params, "post");
};

const putFinanciamientoCifrado = async (id_financiamiento, params = {}) => {
  return await http_client(`/api/v1/cdf/financiamiento-unidad-proceso/${id_financiamiento}`, params, "put");
};

const deleteFinanciamientoCifrado = async (id_financiamiento, params = {}) => {
  return await http_client(`/api/v1/cdf/financiamiento-unidad-proceso/${id_financiamiento}`, params, "delete");
};

const uploadCertificadoFondo = async (id_fondo, params = {}) => {
  return await http_client(`/api/v1/cdf/certificacion/${id_fondo}`, params, "post");
};

export default {
  getCifrados,
  getCifradosProceso,
  getElementsCifrados,
  getCatalogoCifrados,
  addElementoCifrado,
  getVersionesCifrados,
  postVersionCifrado,
  putVersionInstitucion,
  deleteElementoCifrado,
  getRelacionObsOeg,
  getSegmentos,
  guardarRelacionObsOeg,
  deleteRelacionObsOeg,
  updateElementoCifrado,
  getCifradoInfo,
  updateCifradoInfo,
  getFondosCifrado,
  getFinanciamientoCifrado,
  postCifrado,
  postFinanciamientoCifrado,
  getDetailProceso,
  deleteFinanciamientoCifrado,
  getUnidadFinanciamiento,
  putFinanciamientoCifrado,
  uploadCertificadoFondo,
};
