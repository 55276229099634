import { http_client_with_status } from "@/plugins/http_client";

const cargarOfertasExcel = (data) =>
    http_client_with_status(`/api/v1/cm-oferta/cargar-ofertas`, data, 'post', {
        'Content-Type': 'multipart/form-data',
    });


export default {
    cargarOfertasExcel,
}