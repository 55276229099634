import { 
    createPageable, 
    togglePageable, 
    setPageableResponse, 
} from '@/utils/loadable';
import CMSolicitudesConObservacion from '@/services/CMSolicitudesConObservacion.services'

export default {
    namespaced: true,
    state: {
        requestsToModify: createPageable([]),
    },
    mutations: {
        setRequestsLoading(state) {
            togglePageable(state.requestsToModify);
        },
        setRequestsResponse(state, response) {
            const { data, headers } = response;
            setPageableResponse(state.requestsToModify, data, headers);
        },
        clearRequestsToModify(state) {
            state.requestsToModify = createPageable([]);
        },
    },
    actions: {
        loadRequestsToModify: async({ commit }, pagination) => {
            commit('setRequestsLoading');
            const response = await CMSolicitudesConObservacion.getRequestsWithObservation(pagination);
            commit('setRequestsResponse', response);
        },
    },
};
