<template>
    <v-dialog v-model="internalOpen" max-width="600px">
    <v-form 
        ref="form"
        class="py-6 px-6" 
        style="background-color: #FFFFFF;"
        @submit.prevent="handleSubmit()" 
    >
        <v-textarea 
            v-model="form.justificacion" 
            label="Justificación" 
            placeholder="Justificación" 
            outlined 
            :error-messages="justificacionErrors"
            @change="$v.form.justificacion.$touch()"
            @blur="$v.form.justificacion.$touch()"
        />
        <div class="mt-4 d-flex" style="gap: 16px;">
            <v-btn class="flex-shrink-1 flex-grow-1" color="primary" type="submit">{{ ctaText }}</v-btn>
            <v-btn class="flex-shrink-1 flex-grow-1"  color="secondary" @click.stop="handleClose">{{ cancelText }}</v-btn>
        </div>
    </v-form>
</v-dialog>
</template>
<script>
import { isNil } from 'lodash';
import { validationMixin } from 'vuelidate';
import { Validator } from '@/utils/form-validation.js';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
name: 'DenyModalComponent',
mixins: [validationMixin],
emits: ['on-cancel', 'on-save', ], 
validations: {
    form: {
        justificacion: { required, maxLength: maxLength(300), },
    },
},
props: {
    isOpen: { type: Boolean, },
    ctaText: { type: String, default: 'Rechazar', },
    cancelText: { type: String, default: 'Volver', },
},
data: () => ({
    justificacion: null,
    internalOpen: false,
    form: {
        justificacion: '',
    },
}),
computed: {
    justificacionErrors() {
        return new Validator(this.$v.form.justificacion).detect().getResult();
    },
},
methods: {
    clearForm() {
        this.$v.$reset();
        this.$refs.form.reset();
    },
    handleSubmit() {
        this.$v.$touch();

        if (this.$v.$invalid) return;

        this.$emit('on-save', {...this.form});
        this.clearForm();
        this.internalOpen = false;
    },
    handleClose() {
        this.clearForm();
        this.internalOpen = false;
    },
},
watch: {
    internalOpen(value) {
        if (value) return;

        this.$emit('on-cancel');
    },
    isOpen(value) {
        if (isNil(value)) return;

        this.internalOpen = value;
    },
},
};
</script>