import SolicitudCompra from "../../../../services/SolicitudCompra.services";
import EstructurasPresupuestarias from "../../../../services/EstructurasPresupuestarias.services";
import FuentesFinanciamientos from "../../../../services/FuentesFinanciamientos.services";
import LineasTrabajos from "../../../../services/LineasTrabajos.services";
import Presentaciones from "../../../../services/Presentaciones.services";

export default {
  namespaced: true,
  state: {
    insumoSeleccionado: [],
    insumosList: [],
    id_solicitud: null,
    nuevoInsumo: {
      id: null,
      id_financiamiento_unidad: null,
      id_obs: null,
      id_unidad: null,
      id_solicitud_compra: null,
      id_unidad_medida: null,
      id_presentacion_producto_proveedor: null,
      unidad_medida: null,
      precio_unitario: null,
      nombre_origen_compra_en_linea: null,
      url_origen_compra_en_linea: null,
      observacion: null,
      proveedor_web_id: null,
      proveedor_web_nombre: null,
      solicitud_compra_detalle_obs: [],
      cotizaciones: [],
      monto_disponible: null,
    },
    unidades_medida: null,
    estructuras_presupuestarias: [],
    organismos_financiador: [],
    lineas_trabajo: [],
    objetos_presupuestarios: [],
    ctl_unidades_medida: [],
    proveedores_web: [],
    insumoEditable: false,
    insumoFilters: {
      page: 1,
      per_page: 10,
      total_rows: null,
      id_obs: null,
      id_fnto_linea_unidad: null,
      id_ganador_obs: null,
    },
    id_estructura_presupuestaria: null,
    id_fuente_financiamiento: null,
    id_linea_unidad: null,
    objetoPresupuestario: {},
    fondos: [],
    estadoInsumo: null,
    proveedoresList: [],
    selectedProvider: null,
    proveedorInfo: {},
    idEstadoInsumo: null,
    getTour: null,
  },
  mutations: {
    setGetTour(state, payload) {
      state.getTour = payload;
      },
    setInsumo(state, payload) {
      state.insumoSeleccionado = payload;
    },
    setOBSInsumoFilter(state, payload) {
      state.insumoFilters.id_obs = payload;
    },
    setIdSolicitud(state, payload) {
      state.id_solicitud = payload;
    },
    setInsumosList(state, payload) {
      state.insumosList = payload;
    },
    setEstructurasPresupuestarias(state, payload) {
      state.estructuras_presupuestarias = payload;
    },
    setOrganismoFinanciador(state, payload) {
      state.organismos_financiador = payload;
    },
    setLineasTrabajo(state, payload) {
      state.lineas_trabajo = payload;
    },
    // catalogo
    setUnidadesMedida(state, payload) {
      state.ctl_unidades_medida = payload;
    },
    setProveedoresWeb(state, payload) {
      state.proveedores_web = [...payload];
    },
    setProveedoresWeb(state, payload) {
      state.proveedores_web = [...payload];
    },
    setInsumoHeaders(state, payload) {
      state.insumoFilters.page = Number(payload.page);
      state.insumoFilters.per_page = Number(payload.per_page);
      state.insumoFilters.total_rows = Number(payload.total_rows);
    },
    setPage(state, payload) {
      state.insumoFilters.page = payload;
    },
    setPerPage(state, payload) {
      state.insumoFilters.per_page = payload;
    },
    setObjetosPresupuestarios(state, payload) {
      state.objetos_presupuestarios = payload;
    },
    setInsumoEditable(state, payload) {
      state.insumoEditable = payload;
    },
    setInsumoData(state, payload) {
      Object.keys(state.nuevoInsumo).forEach((key) => {
        state.nuevoInsumo[key] = payload[key];
      });
    },
    setNuevoInsumoStep1(state, payload) {
      state.nuevoInsumo.id_financiamiento_unidad =
        payload.id_financiamiento_unidad;
      state.nuevoInsumo.id_obs = payload.id_obs;
      state.nuevoInsumo.monto_disponible = payload.monto_disponible;
    },
    setNuevoInsumoStep2(state, payload) {
      state.nuevoInsumo.id_solicitud_compra = payload.id_solicitud_compra;
      state.nuevoInsumo.nombre_origen_compra_en_linea =
        payload.nombre_origen_compra_en_linea;
      state.nuevoInsumo.url_origen_compra_en_linea =
        payload.url_origen_compra_en_linea;
      state.nuevoInsumo.proveedor_web_id = payload.proveedor_web_id;
      state.nuevoInsumo.proveedor_web_nombre = payload.proveedor_web_nombre;
      // id
      state.nuevoInsumo.id_unidad_medida = payload.unidades_medida;
      state.nuevoInsumo.precio_unitario = payload.precio_unitario;
      // id
      state.unidades_medida = payload.unidades_medida;
      state.precio_unitario = payload.precio_unitario;
    },
    setNuevoInsumoStep3(state, payload) {
      state.nuevoInsumo.observacion = payload;
    },
    setCantidadStep3(state, payload) {
      if (state.nuevoInsumo.solicitud_compra_detalle_obs.length == 1) {
        state.nuevoInsumo.solicitud_compra_detalle_obs[0].cantidad = parseInt(payload);
      }
    },
    setNuevoInsumoEdit(state, payload) {
      state.nuevoInsumo[payload.atributo] = payload.valor;
    },
    clearFiltrosEstructuras(state) {
      state.id_estructura_presupuestaria = null;
      state.id_fuente_financiamiento = null;
    },
    setIdEstructuraPresupuestaria(state, payload) {
      state.id_estructura_presupuestaria = payload;
    },
    setIdFuenteFinanciamiento(state, payload) {
      state.id_fuente_financiamiento = payload;
    },
    setIdLineaUnidad(state, payload) {
      state.id_linea_unidad = payload;
    },
    setObjetoPresupuestario(state, payload) {
      state.objetoPresupuestario = payload;
    },
    setFondosAsignados(state, payload) {
      state.fondos = payload;
    },
    setFondoFilters(state, payload) {
      state.insumoFilters.id_fnto_linea_unidad = payload;
    },
    // cotizaciones component
    addPrecioMercado(state, payload) {
      state.nuevoInsumo.cotizaciones.push(payload);
    },
    deletePrecioMercado(state, payload) {
      state.nuevoInsumo.cotizaciones = state.nuevoInsumo.cotizaciones.filter(
        (item) => item.id !== payload
      );
    },
    setIdUnidadMedida(state, payload) {
      state.nuevoInsumo.id_unidad_medida = payload.id;
      state.nuevoInsumo.unidad_medida = payload.nombre;
    },
    cleanUnidadMedida(state) {
      state.nuevoInsumo.id_unidad_medida = null;
      state.nuevoInsumo.unidad_medida = null;
    },
    cleanCotizaciones(state) {
      state.nuevoInsumo.cotizaciones = [];
    },
    setPrecioUnitarioGlobal(state, payload) {
      state.nuevoInsumo.precio_unitario = payload;
    },
    setEstadoInsumo(state, payload) {
      state.estadoInsumo = payload;
    },
    setIdEstadoInsumo(state, payload) {
      state.idEstadoInsumo = payload;
    },
    setProveedoresList(state, payload) {
      state.proveedoresList = payload;
    },
    setCurrentProvider(state, payload) {
      state.selectedProvider = payload;
    },
    setProviderInfo(state, payload) {
      state.proveedorInfo = payload;
    },
    setGanadorFilter(state, payload) {
      state.insumoFilters.id_ganador_obs = payload;
    },
  },
  actions: {
    async guardarInsumo({ commit, state }, id_solicitud) {
      const nuevoInsumo = {};
      //Se verifica las propiedades que no son null para mandar en el body
      //solo la data necesaria según el insumo
      Object.keys(state.nuevoInsumo).forEach((key) => {
        if (state.nuevoInsumo[key] || key === "proveedor_web_id")
          nuevoInsumo[key] = state.nuevoInsumo[key];
      });
      const { status } = await SolicitudCompra.addSolicitudInsumo(
        id_solicitud,
        nuevoInsumo
      );
      return status;
    },
    async getInsumosList({ commit, state }, props) {
      let params = {
        page: state.insumoFilters.page,
        per_page: state.insumoFilters.per_page,
        total_rows: state.insumoFilters.total_rows,
        id_obs: state.insumoFilters.id_obs,
        id_proveedor_web: props.proveedor,
        id_fnto_linea_unidad: state.insumoFilters.id_fnto_linea_unidad,
        id_ganador_obs: state.insumoFilters.id_ganador_obs,
      };
      if (typeof props === "object") {
        const response = await SolicitudCompra.getSolicitudesCompraInsumos(
          props.id_solicitud,
          params
        );
        if (response.status === 200) {
          commit("setInsumosList", response.data);
          commit("setInsumoHeaders", response.headers);
        }
      } else {
        const response = await SolicitudCompra.getSolicitudesCompraInsumos(
          Number(props),
          params
        );
        if (response.status === 200) {
          commit("setInsumosList", response.data);
          commit("setInsumoHeaders", response.headers);
        }
      }
    },
    async getEstructurasPresupuestarias({ commit }) {
      const response = await EstructurasPresupuestarias.getEstructuras();
      if (response.status === 200) {
        commit("setEstructurasPresupuestarias", response.data);
      }
    },
    async getOrganismosFinanciador({ commit }) {
      const response = await FuentesFinanciamientos.getFuentes();
      if (response.status === 200) {
        commit("setOrganismoFinanciador", response.data);
      }
    },
    async getLineasTrabajo({ commit }, params) {
      const idUnidad = params.idUnidad;
      const filters = params.filters;
      const response = await LineasTrabajos.getInstitucionesLineasUnidades(
        idUnidad,
        filters
      );
      if (response.status === 200) {
        commit("setLineasTrabajo", response.data);
      }
    },
    async getObjetosPresupuestarios({ commit }, params) {
      const response = await LineasTrabajos.getObjetoPresupuestario(
        params.id_linea_unidad,
        params.filters
      );
      if (response.status === 200) {
        commit("setObjetosPresupuestarios", response.data);
      }
    },
    async getUnidadesMedida({ commit }) {
      const response = await Presentaciones.getListPresentations();
      if (response.status === 200) {
        commit("setUnidadesMedida", response.data);
      }
    },
    async getProveedoresWeb({ commit }) {
      const response = await SolicitudCompra.getProveedores();
      if (response.status === 200) {
        commit("setProveedoresWeb", response.data.proveedores);
      }
    },
    async getProveedoresWeb({ commit }, filters = {}) {
      const response = await SolicitudCompra.getProveedores(filters);
      if (response.status === 200) {
        commit("setProveedoresWeb", response.data.proveedores);
      }
      return response;
    },
    async getFondosAsignados({ commit }, id_solicitud) {
      const response = await SolicitudCompra.getSolicitudesFondos(id_solicitud);
      if (response.status === 200) {
        commit("setFondosAsignados", response.data);
      }
    },
  },
};
