import evaluarPermisosUrlServices from "@/services/evaluarPermisosUrl.services.mjs";

const evaluarPermisosUrl = async (to, from, next) => {
switch (to.name) {
case "peo-ganadores":
    const { data } =
    await evaluarPermisosUrlServices.getEvaluadorPeoProcesoCompra(
        to.params.id
    );
    if (data) return next();
    next({ name: "evaluacion-ofertas-peo" });
    break;

default:
    break;
}
};

export default evaluarPermisosUrl;
