const etapaBaseProcesoUaciStore = {
    namespaced: true,
    state: {
       listDocumentosBase: [],
    },

    mutations: {
        setListDocumentosBase (state, payload) {
            state.listDocumentosBase = payload
        }
    },

    actions: {}
}

export default etapaBaseProcesoUaciStore