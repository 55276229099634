import PurchaseRequest from '@/services/catalogoEnLinea/PurchaseRequest.services'
import ProductService from '../../../services/catalogoEnLinea/Product.service';
import CMProducto from '../../../services/cmProducto.services';
import { 
    createLoadable, 
    toggleLoadable, 
    setLoadableResponse, 
    createPageable, 
    togglePageable,
    setPageableResponse,
    isResponseSuccesful,
    isApiErrorResponse,
} from '@/utils/loadable';

const validTimerStatus = ['ticking', 'expired', 'empty-request'];
const initialFeedbackModalState = {
    isOpen: false,
    type: 'success',
    title: null,
    description: '',
    buttonText: 'Continuar',
    onClose: null,
};

// const loadAgendamientosFromLocalStorage = (productId) => {
//     const storedAgendamientos = localStorage.getItem(`agendamientos_${productId}`);
//     return storedAgendamientos ? new Map(JSON.parse(storedAgendamientos)) : new Map();
// };

export default {
    namespaced: true,
    state: {
        agendamientos: new Map(),
        inCartRequest: createLoadable(null),
        timer: {
            // TODO: validar si initial no afecta en otros lugares, si lo hace usar 'empty-request'
            status: 'initial',
            tick: null,
        },
        feedbackModal: initialFeedbackModalState,
        // Productos y ofertas
        product: createLoadable(null),
        productOffers: createPageable([], 10),
        productFunds: createLoadable([]),
        cartProductFunds: createLoadable([]),
        cartProductFundsEdit: createLoadable([]),
        offerUpsertion: createLoadable(null),
        configuraciones: createLoadable([]),
    },
    mutations: {
        setInCartRequest(state, data) {
            if (!data) {
                return toggleLoadable(state.inCartRequest);
            } 
                
            setLoadableResponse(state.inCartRequest, data);
            if (!isResponseSuccesful(data)) {
                state.timer.status = 'empty-request';
            }

            if (isResponseSuccesful(data) && data.code === 'SIN_SOLICITUD_ACTIVA') {
                state.timer.status = 'empty-request';
            }
        },
        injectRequestData(state, data) {
            state.inCartRequest.isDirty = true;
            state.inCartRequest.isLoading = false;
            state.inCartRequest.error = null;
            state.inCartRequest.data = data;
        },
        clearInCartRequest(state) {
            state.inCartRequest = createLoadable(null);
        },
        updateCartItems(state, items) {
            state.inCartRequest.data.productosActualizados = items;
        },
        removeItemFromCart(state, offerToRemove) {
            const offers = state.inCartRequest.data?.productosActualizados ?? [];
            const index = offers.findIndex((offer) => offer.id === offerToRemove.id);

            if (index === -1) return;

            offers.splice(index, 1);
        },
        setTimerStatus(state, status) {
            if (!validTimerStatus.includes(status)) return;

            state.timer.status = status;
        },
        setTimerTick(state, tick) {
            state.timer.tick = tick;
        },
        setFeedbackModalConfig(state, config) {
            state.feedbackModal = config;
        },
        closeFeedbackModal(state) {
            state.feedbackModal = initialFeedbackModalState;
        },
        // Productos y ofertas
        startProductRequest(state) {
            toggleLoadable(state.product);
        },
        handleProductResponse(state, response) {
            setLoadableResponse(state.product, response.data);
        },
        resetProductData(state) {
            state.product = createLoadable(null);
        },
        startProductOffersRequest(state) {
            togglePageable(state.productOffers);
        },
        handleProductOffersResponse(state, response) {
            const { data, headers } = response;
            setPageableResponse(state.productOffers, data, headers, { initialValue: [] });
        },
        resetProductFundsState(state) {
            state.productFunds = createLoadable([]);
        },
        startProductFundsRequest(state) {
            toggleLoadable(state.productFunds);
        },
        handleProductFundsResponse(state, response) {
            setLoadableResponse(state.productFunds, response.data);
        },
        startUpsertOfferRequest(state) {
            toggleLoadable(state.offerUpsertion);
        },
        handleUpsertOfferResponse(state, response) {
            setLoadableResponse(state.offerUpsertion, response.data, { skipOnSuccess: true });
        },
        resetCartProductFundsState(state) {
            state.cartProductFunds = createLoadable([]);
        },
        startCartProductFundsRequest(state) {
            toggleLoadable(state.cartProductFunds);
        },
        handleCartProductFundsResponse(state, response) {
            setLoadableResponse(state.cartProductFunds, response.data);
        },
        // Edicion de solicitud
        startProductFundsRequestEdit(state) {
            toggleLoadable(state.cartProductFundsEdit);
        },
        handleProductFundsResponseEdit(state, response) {
            setLoadableResponse(state.cartProductFundsEdit, response.data);
        },
        resetCartProductFundsStateEdit(state) {
            state.cartProductFundsEdit = createLoadable([]);
        },
        // Agendamientos
        agregarAgendamiento(state, payload) {
            const { idProducto, agendamiento } = payload;
            state.agendamientos.set(idProducto, agendamiento);
            localStorage.setItem('agendamientos', JSON.stringify(Array.from(state.agendamientos)));
        },
        eliminarAgendamiento(state, producto) {
            const idProducto = producto.idProducto;
            state.agendamientos.delete(idProducto);
        },
        setConfiguracionesFamilia(state){
            toggleLoadable(state.configuraciones);
        },
        cargandoConfiguracionesFamilia(state, response) {
            setLoadableResponse(state.configuraciones, response);
        },
    },
    actions: {
        getOpenRequest: async({ commit }) => {
            commit('setInCartRequest');
            const { data } = await PurchaseRequest.getOpenRequest();
            commit('setInCartRequest', data);
        },
        updateItemWithNewQuantity: ({ commit, state }, offer) => {
            const { update, insertion, disponibilidad } = offer;
            const cartItems = state.inCartRequest.data?.productosActualizados ?? [];

            const itemToUpdateIndex = cartItems.findIndex((item) => item.id === update.id);

            if (itemToUpdateIndex === -1) {
                cartItems.push(insertion);
            } else {
                cartItems[itemToUpdateIndex].cmopc.cmsc.altura = update.altura;
                cartItems[itemToUpdateIndex].cmopc.cmsc.base = update.base;
                cartItems[itemToUpdateIndex].cmopc.cmsc.comentario = update.comentario;
                cartItems[itemToUpdateIndex].cmopc.cmsc.monto = update.monto;
                cartItems[itemToUpdateIndex].cmopc.cmsc.cifra = update.cifra;
                cartItems[itemToUpdateIndex].cmopc.cmsc.vigencia = update.vigencia;
                cartItems[itemToUpdateIndex].cantidad = update.cantidad;
                cartItems[itemToUpdateIndex].subtotal = update.subtotal;
                cartItems[itemToUpdateIndex].cmopc.disponibilidad = disponibilidad;

                if (update.id_tipo_prod === 2) {
                    cartItems[itemToUpdateIndex].direccion = update.direccion;
                }
            }

            commit('updateCartItems', cartItems.slice());
        },
        updateItemsWithNewPrices: ({ commit, state }, offers) => {
            const cartItems = state.inCartRequest.data?.productosActualizados ?? [];
            const changes = [];

            offers.forEach((offer) => {
                const index = cartItems.findIndex((item) => item.id === offer.id_producto);

                if (index === -1) return;

                const itemInCart = cartItems[index];
                const subtotal = (itemInCart.cantidad * Number(offer.precio_nuevo)).toFixed(2);
                const updatedItem = { ...itemInCart, precio_de_oferta: offer.precio_nuevo, subtotal };
                cartItems.splice(index, 1, updatedItem);

                changes.push({
                    id: itemInCart.id,
                    name: itemInCart.cmopc.CMProducto.nombre,
                    old_price: offer.precio_antiguo,
                    new_price: offer.precio_nuevo,
                    quantity: itemInCart.cantidad,
                    subtotal,
                });
            });

            commit('updateCartItems', cartItems);
            return changes;
        },
        // Productos y ofertas
        fetchProduct: async({ commit }, productId) => {
            commit('startProductRequest')
            const response = await ProductService.getProductById(productId);
            commit('handleProductResponse', response);
        },
        fetchProductOffers: async({ commit }, { productId, filters }) => {
            commit('startProductOffersRequest')
            const response = await ProductService.getOffersByProductId(productId, filters);
            commit('handleProductOffersResponse', response);
        
            return isResponseSuccesful(response.data) 
                ? response.data.data
                : null;
        },
        fetchProductFunds: async({ commit }, productId) => {
            commit('startProductFundsRequest')
            const response = await ProductService.getProductFunds(productId);
            commit('handleProductFundsResponse', response);
        },
        fetchCartProductFunds: async({ commit }, productId) => {
            commit('startCartProductFundsRequest')
            const response = await ProductService.getProductFunds(productId);
            commit('handleCartProductFundsResponse', response);
        },
        upsertOffer: async({ commit, dispatch, state}, { data, kind }) => {
            commit('startUpsertOfferRequest')
            const action = kind === 'product'
                ?  () => PurchaseRequest.upsertProductToRequest(data)
                : () => PurchaseRequest.upsertServiceToRequest(data);

            const response = await action();
            commit('handleUpsertOfferResponse', response);
            if(isApiErrorResponse(response.data)){
                dispatch('fetchProductOffers', {productId: state.product.data.id})
            }
            return isResponseSuccesful(response.data) ? response.data : null;
        },
        // Edicion de solicitud
        fetchCartProductFundsEdit: async({ commit }, payload) => {
            const {
                productId,
                requestId,
            } = payload
            
            commit('startProductFundsRequestEdit')
            const response = await ProductService.getProductFundsEdit(productId, requestId);
            commit('handleProductFundsResponseEdit', response);
        },
        obtenerListaConfiguracionesFamilia: async ({ commit }, productId) => {
            commit('setConfiguracionesFamilia');
            const { data } = await CMProducto.listarConfiguracionFamilia(productId)
            commit('cargandoConfiguracionesFamilia', data);
        },
    },
};
