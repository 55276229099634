import { http_client } from "@/plugins/http_client";

const listRegion = async (params = {}) => {
  return await http_client(`/api/v1/cm-region`, params, "get");
};

const getRegion = async (id) => {
  return await http_client(`/api/v1/cm-region/${id}`, "get");
};

const storeRegion = async (form) => {
  return await http_client(`/api/v1/cm-region`, form, "post");
};

const updateRegion = async (id, form = {}) => {
  return await http_client(`/api/v1/cm-region/${id}`, form, "put");
};  

const deleteRegion = async (id, params={}) => {
  return await http_client(`/api/v1/cm-region/${id}`,params, "delete");
};

export default {
  listRegion,
  getRegion,
  deleteRegion,
  storeRegion,
  updateRegion,
};
