<template>
  <v-overlay
      absolute
      :value="drawerInput"
      @click="handleOverlayClick"
      class="cart-overlay"
      z-index="300"
      right
  >
    <div class="custom-cart-wrapper scrollable" @click.stop no-gutters>
      <div class="cart-header">
        <div class="cart-close-trigger py-2 px-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                class="px-4 py-4"
                @click.stop="setCartDrawer(false)"
                icon
                color="primary"
              >
                <v-icon color="black">mdi-window-close</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </div>
        <v-divider color="gray" />
      </div>
      <div class="cart-body-content px-4 py-4">
        <ManejoSolicitud 
          :timer="timer"
          :hasActiveRequest="hasActiveRequest" 
          :requestLoadable="inCartRequest"
          :requestsToModifyCount="requestsToModifyCount" 
          />
      </div>
    </div>
  </v-overlay>
</template>
<script>
import { shouldFetch, PAGINATION_PROPERTY_NAMES } from '@/utils/loadable';
import CreateRequestComponent from './CreateRequestComponent.vue';
import { Countdown } from '@/utils/countdown';
import { Observable, Listener } from '@/utils/observable.js';
import { mapState, mapActions, mapMutations } from "vuex";
import ActiveCartRequest from "./ActiveCartRequest.vue";
import ManejoSolicitud from "./ManejoSolicitud.vue";
import moment from "moment-timezone";

export default {
  name: 'CartDrawerComponent',
  components: {
    CreateRequestComponent,
    ActiveCartRequest,
    ManejoSolicitud
  },
  data: () => ({
    unsubscribe: null,
    cartUnsubscriber: null,
  }),
  methods: {
    ...mapMutations("catalogoEnLinea", ["setPriceChangesModalConfig"]),
    ...mapActions("utils", ["setCartDrawer"]),
    handleOverlayClick() {
      this.setCartDrawer(false);
    },
  },
  computed: {
    ...mapState("cmSolicitudesConObservacion", ["requestsToModify"]),
    ...mapState('catalogoEnLinea', ["inCartRequest", "timer"]),
    ...mapState("utils", ["cartDrawer"]),
    ...mapState(["serverTime"]),
    drawerInput: {
      get() {
        return this.cartDrawer;
      },
      set(value) {
        this.setCartDrawer(value);
      },
    },
    hasActiveRequest() {
      return Boolean(this.inCartRequest.data);
    },
    request() {
      return this.inCartRequest.data?.solicitud;
    },
    cartOffers() {
      return this.inCartRequest.data?.productosActualizados ?? [];
    },
    requestsToModifyCount() {
      const count = this.requestsToModify.pagination[PAGINATION_PROPERTY_NAMES.COUNT];

      if (this.inCartRequest.isDirty && !this.inCartRequest.isLoading && this.inCartRequest.error) {
        return count;
      }

      return !this.inCartRequest.data?.solicitud.id ? count : count - 1;
    },
    remainingTime() {
      if (!this.request || !this.serverTime) return null;
      const expiracion = moment(this.request.expiracion_carrito);
      const diff = expiracion.diff(this.serverTime);
      const duracion = moment.duration(diff);
      return duracion;
    },
  },
  methods: {
    ...mapMutations("catalogoEnLinea", ["setPriceChangesModalConfig", "setTimerStatus", "setTimerTick"]),
    ...mapActions("cmSolicitudesConObservacion", ["loadRequestsToModify"]),
    ...mapActions("utils", ["setCartDrawer"]),
    ...mapActions("catalogoEnLinea", ["getOpenRequest"]),
    handleOverlayClick() {
      this.setCartDrawer(false);
    },
    tick(duration) {
      if (this.timer.status !== 'ticking') this.setTimerStatus('ticking');
      this.setTimerTick(duration);
    },
    handleExpired() {
      this.setTimerTick(null);
      this.setTimerStatus('expired');
    },
    fetchRequestsToModify() {
      const defaultPagination = {
        [PAGINATION_PROPERTY_NAMES.PAGE_SIZE]: 10,
        [PAGINATION_PROPERTY_NAMES.CURRENT_PAGE]: 1,
      };

      this.loadRequestsToModify(defaultPagination);
    },
    fetchOpenRequest() {
      this.getOpenRequest();
    }, 
  },
  watch: {
      remainingTime(value) {
        // No hay carrito activo
        if (value === null) {
          if (this.timer.status !== 'empty-request') this.setTimerStatus('empty-request');

          return;
        }

        // El tiempo del carrito aun esta vigente
        if (value > 0) return this.tick(value);

        // El tiempo del carrito ha expirado
        this.handleExpired();
      },
  },
  mounted() {
    const listener = new Listener('CartComponent').setHandler(() => this.fetchRequestsToModify());
    this.unsubscribe = Observable.getInstance().subscribe('refresh-requests-to-modify', listener);

    const listenerCarrito = new Listener('CartComponent2').setHandler(() => this.fetchOpenRequest());
    this.cartUnsubscriber = Observable.getInstance().subscribe('actualizar-carrito', listenerCarrito);

    if (shouldFetch(this.requestsToModify)) this.fetchRequestsToModify();
    if (shouldFetch(this.inCartRequest)) this.fetchOpenRequest();
  },
  beforeDestroy() {
    Countdown.getInstance().clear();
    this.unsubscribe?.();
    this.cartUnsubscriber?.();
  },
}
</script>
<style>
/** Mobile first */
.cart-overlay {
  position: fixed;
}

.cart-overlay .v-overlay__content {
  position: absolute;
  top: 0;
  right: 0;
  width: 700px;
  max-width: 95vw !important;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

/** Estilos customizados */
.cart-overlay .custom-cart-wrapper {
  background-color: #FFF;
  color: #000;
  max-width: 100%;
  max-height: 100%;
  height: inherit;
  overflow: auto;
}

@media (max-width: 600px) {
  /** boton para cerrar drawer */
  .cart-header {
    background-color: #FFF;
    position: sticky;
    top: 0;
    z-index: 20;
  }
}
</style>
