import solicitudProveedorServices from "../../../../services/SolicitudProveedor.services";
import verificarDocumentoServices from "../../../../services/VerificarDocumento.services";
import contribuyentesServices from "../../../../services/Contribuyentes.services";
import identificadorFiscalServices from "../../../../services/IdentificadorFiscal.services";
import paisesServices from "../../../../services/Paises.services";
import departamentosServices from "../../../../services/Departamentos.services";
import municipiosServices from "../../../../services/Municipios.services";
import generosServices from "../../../../services/Generos.services";
import clasificacionEmpresaServices from "../../../../services/ClasificacionEmpresa.services";
import verificarCorreoService from "../../../../services/VerificarCorreo.services";
import jwtDecode from "jwt-decode";

export const definir_estado_inicial = ({ commit }) => {
  commit("setEstadoInicial");
};

/**
 * Verifica si el proceso es una nueva solicitud con observaciones
 * o una nueva solicitud de registro, y en base a eso, carga los datos
 * y se define el comportamiento del formulario.
 *
 * Si la ruta por la que se accede es revisar-observaciones se obtendrá
 * la información de la solicitud con observaciones y se cargará en el
 * formulario.
 */
export const verificar_proceso = async (
  { commit, dispatch, state },
  { ruta, token }
) => {
  // Verificamos que el token sea válido
  const datos = jwtDecode(token);
  const id_proveedor = datos.id_solicitud;

  /**
   * Verificamos que en el token se encuentre el id de la solicitud
   * y que la ruta sea revisar-observaciones
   */
  if (!id_proveedor && ruta !== "revisar-observaciones") return;

  commit("setSolicitudConObservaciones", {
    value: true,
    token,
    id_proveedor,
  });

  // Obtenemos la información de la solicitud con observaciones
  const response = await solicitudProveedorServices.getObservaciones(
    id_proveedor,
    token
  );

  if (response.data.status === 401) {
    return response.data;
  }

  if (response.status === 200) {
    commit("setObservaciones", response.data.observaciones);

    // Recuperamos la información del primer formulario
    const {
      id_tipo_contribuyente,
      id_identificacion_tributaria,
      numero_documento,
      tipo_contribuyente,
      nombre,
    } = response.data;

    await dispatch("obtener_tipos_identificaciones", id_tipo_contribuyente);

    await dispatch(
      "seleccionar_tipo_identificacion",
      id_identificacion_tributaria
    );

    const tipo_identificacion = state.tipo_identificaciones.find(
      (tipo) => tipo.id === id_identificacion_tributaria
    );

    commit("setFormularioUno", {
      id_tipo_contribuyente,
      tipo_contribuyente,
      id_tipo_identificacion: id_identificacion_tributaria,
      tipo_identificacion,
      numero_documento,
      nombre_proveedor: nombre,
    });

    // Si el tipo de identificación tributaria corresponde al DUI ejecutamos la accion de verificar el dui
    if (id_identificacion_tributaria == 1) {
      dispatch("verificar_dui", numero_documento);
    }

    // Recuperamos la información del segundo formulario
    const {
      nombre_comercial,
      sitio_web,
      id_genero,
      id_clasificacion_empresa,
      id_pais,
      id_departamento,
      id_municipio,
      detalle_direccion,
      codigo_area,
      numero_telefono,
    } = response.data;

    if (state.es_salvadoreno) {
      dispatch("obtener_municipios", id_departamento);
    }

    commit("setFormularioDos", {
      nombre_comercial,
      sitio_web,
      genero: id_genero,
      id_clasificacion_empresarial: id_clasificacion_empresa,
      id_pais,
      id_departamento,
      id_municipio,
      direccion: detalle_direccion,
      area: codigo_area,
      telefono: numero_telefono,
    });

    // Recuperamos la información del tercer formulario
    const { correo_electronico } = response.data;

    commit("setFormularioTres", {
      correo_electronico,
      codigo_verificacion: "000000",
      terminos: null,
      declaracion: null,
      archivo_declaracion: null,
    });

    // Indicamos que el correo es válido
    commit("setCorreoValido", true);
  }

  const responseObservacion =
    await solicitudProveedorServices.getDeclaracionJuradaObservacion(
      id_proveedor,
      token
    );

  if (responseObservacion.status === 200) {
    // Convertir a archivo el response.data y descargarlo

    const blob = new Blob([new Uint8Array(responseObservacion.data.data)], {
      type: "application/pdf",
    });

    // Convertimos el blob a un objeto de tipo File
    const file = new File([blob], "declaracion_jurada.pdf", {
      type: "application/pdf",
    });

    commit("setDeclaracionJuradaObservacion", file);
  }

  return response;
};

export const descargar_archivo = async ({ commit, state }) => {
  const response =
    await solicitudProveedorServices.getDeclaracionJuradaObservacion(
      state.id_proveedor,
      state.token
    );

  if (response.status === 200) {
    const blob = new Blob([new Uint8Array(response.data.data)], {
      type: "application/pdf",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "declaracion_jurada.pdf";
    link.click();
  }
};

/**
 * Permite avanzar al siguiente paso del registro de proveedor
 * @param {*} context
 */
export const siguientePaso = ({ commit, state }) => {
  if (state.paso_actual < 3) {
    commit("setPasoActual", state.paso_actual + 1);
  }
};

/**
 * Permite regresar al paso anterior del registro de proveedor
 * @param {*} context
 */
export const pasoPrevio = ({ commit, state }) => {
  if (state.paso_actual > 1) {
    commit("setPasoActual", state.paso_actual - 1);
  }
};

/**
 * Obtiene el listado de tipos de contribuyentes
 * @param {*} context
 */
export const obtener_tipos_contribuyentes = async ({ commit }) => {
  const response = await contribuyentesServices.getTipoContribuyente();
  if (response.status === 200) {
    commit("setTipoContribuyentes", response.data);
  }
};

/**
 * Obtiene el listado de tipos de identificaciones
 * @param {*} context
 * @param {*} id_tipo_contribuyente
 */
export const obtener_tipos_identificaciones = async (
  { commit, state },
  id_tipo_contribuyente
) => {
  // Almacenamos el nombre del tipo de contribuyente seleccionado
  const tipo_contribuyente = state.tipo_contribuyentes.find(
    (tipo) => tipo.id === id_tipo_contribuyente
  );
  commit("setTipoContribuyenteSeleccionado", tipo_contribuyente.nombre);

  // Obtenemos el listado de tipos de identificaciones segun el tipo de contribuyente
  const filtros = { id_tipo_contribuyente };
  const response = await identificadorFiscalServices.getIdentificadorFiscal(
    filtros
  );
  if (response.status === 200) {
    commit("setTipoIdentificaciones", response.data);
    commit("setMascaraDocumento", "");
    commit("setLngDoc", 0);
    commit("setDuiVerificado", false);
    commit("setNombreProveedor", "");
    commit("serNumeroDocumento", "");
    commit("setNullTipoIdentificacion");
  }
};

/**
 * Seleccionar el tipo de identificación
 * @param {*} context
 * @param {*} id_tipo_identificacion
 */
export const seleccionar_tipo_identificacion = (
  { commit, state },
  id_tipo_identificacion
) => {
  const tipo_identificacion = state.tipo_identificaciones.find(
    (tipo_identificacion) => tipo_identificacion.id === id_tipo_identificacion
  );

  const { nombre } = tipo_identificacion;

  if (
    nombre.toLowerCase().includes("salvadoreño") ||
    nombre.toLowerCase().includes("residencia")
  ) {
    commit("setEsSalvadoreno", true);
  } else {
    commit("setEsSalvadoreno", false);
  }
  commit("setDepartamentoId", null);
  commit("setMunicipioId", null);

  let mascara_documento = "";
  let lng_doc = 0;

  switch (tipo_identificacion.id_tipo_documento) {
    case 1: // DUI
      mascara_documento = tipo_identificacion.formato;
      lng_doc = 10;
      break;
    case 2: // NIT
      mascara_documento = tipo_identificacion.formato;
      lng_doc = 17;
      break;
    case 3: // Pasaporte
      mascara_documento = tipo_identificacion.formato;
      lng_doc = 9;
      break;
    default:
      mascara_documento = tipo_identificacion.formato;
      lng_doc = 20;
      break;
  }

  commit("setNombreProveedor", null);
  commit("setTipoIdentificacionSeleccionada", {
    id_tipo_identificacion: tipo_identificacion.id,
    mascara_documento: mascara_documento,
    lng_doc: lng_doc,
    tipo_identificacion: tipo_identificacion,
  });
};

/**
 * Verifica si el numero de DUI es válido y retorna el nombre del propietario
 * @param {*} context
 */
export const verificar_dui = async ({ commit, state }) => {
  commit("setVerificandoDui", true);
  const { numero_documento, captcha } = state.formulario_uno;
  const {
    data: {
      id,
      primer_nombre,
      segundo_nombre,
      primer_apellido,
      segundo_apellido,
      apellido_casada,
    },
  } = await verificarDocumentoServices.verificarDocumento(numero_documento, {
    captcha,
  });

  commit("setIdPersona", id);
  commit("setDuiVerificado", true);
  let nombre_proveedor = "";

  if (primer_nombre) {
    nombre_proveedor += primer_nombre;
  }
  if (segundo_nombre) {
    nombre_proveedor += ` ${segundo_nombre}`;
  }
  if (primer_apellido) {
    nombre_proveedor += ` ${primer_apellido}`;
  }
  if (segundo_apellido) {
    nombre_proveedor += ` ${segundo_apellido}`;
  }
  if (apellido_casada) {
    nombre_proveedor += ` ${apellido_casada}`;
  }

  commit("setNombreProveedor", nombre_proveedor);

  commit("setVerificandoDui", false);
};

/**
 * Obtener listado de generos
 * @param {*} context
 */
export const obtener_generos = async ({ commit }) => {
  const response = await generosServices.getGenerosList();
  if (response.status === 200) {
    commit("setGeneros", response.data);
  }
};

/**
 * Obtener listdo de clasiificaciones empres
 * @param {*} context
 */
export const obtener_clasificaciones_empresariales = async ({ commit }) => {
  const response = await clasificacionEmpresaServices.getClasificacionEmpres();
  if (response.status === 200) {
    commit("setClasificacionEmpresarial", response.data);
  }
};

/**
 * Obtener listado de paises
 * @param {*} context
 */
export const obtener_paises = async ({ commit, state, dispatch }) => {
  if (state.es_salvadoreno) {
    const response = await paisesServices.getListaPaises({
      nombre: "El Salvador",
    });

    if (response.status === 200) {
      commit("setIdPais", response.data[0].id);
      commit("setPaises", response.data);
      commit("setPaisDisable", true);
      commit("setMascaraTelefono", "####-####");
      commit("setLngTel", 9);
      commit("setCodigoArea", response.data[0].codigo_area);
      // Obtenemos el listado de departamentos
      dispatch("obtener_departamentos", response.data[0].id);
    }
  } else {
    const response = await paisesServices.getListaPaises();
    if (response.status === 200) {
      const paises = response.data;
      commit("setLngTel", 12);
      commit("setPaises", paises);
      commit("setPaisDisable", false);
    }
  }
};

/**
 * Cambiar codigo de area al cambiar de pais
 * @param {*} context
 */
export const cambiar_codigo_area = ({ commit, state }, id_pais) => {
  if (id_pais) {
    const pais = state.paises.find((pais) => pais.id === id_pais);
    commit("setCodigoArea", pais.codigo_area);
    // Cambiamos la mascara del telefono

    if (pais.codigo_area === "+503") {
      commit("setMascaraTelefono", "####-####");
      commit("setLngTel", 9);
    } else {
      commit("setMascaraTelefono", "");
      commit("setLngTel", 12);
    }
  }
};

/**
 * Obtener listado de departamentos
 * @param {*} context
 * @param {*} id_pais
 */
export const obtener_departamentos = async ({ commit }, id_pais) => {
  const response = await departamentosServices.getDepartamentosList({
    id_pais,
  });

  if (response.status === 200) {
    commit("setDepartamentos", response.data);
  }
};

/**
 * Obtener listado de municipios
 * @param {*} context
 * @param {*} id_departamento
 */
export const obtener_municipios = async ({ commit }, id_departamento) => {
  const response = await municipiosServices.getMunicipiosList({
    id_departamento,
  });

  if (response.status === 200) {
    commit("setMunicipios", response.data);
  }
};

/**
 * Guardar informacion inicial del proveedor
 * @param {*} context
 */
export const guardar_informacion_inicial = async ({ commit, state }) => {
  if (state.solicitud_con_observaciones) {
    commit("setPasoActual", 3);
    return;
  }

  commit("setLoadingPasoDos", true);

  let data = {
    codigo_area: state.formulario_dos.area,
    direccion: state.formulario_dos.direccion,
    id_clasificacion_empresa: state.formulario_dos.id_clasificacion_empresarial,
    id_genero: state.formulario_dos.genero,
    id_identificacion_tributaria: state.formulario_uno.id_tipo_identificacion,
    id_pais: state.formulario_dos.id_pais,
    nombre: state.formulario_uno.nombre_proveedor,
    nombre_comercial: state.formulario_dos.nombre_comercial,
    numero_documento: state.formulario_uno.numero_documento,
    numero_telefono: state.formulario_dos.telefono,
  };
  if (state.formulario_dos.id_departamento) {
    data.id_departamento = state.formulario_dos.id_departamento;
  }

  if (state.formulario_dos.id_municipio) {
    data.id_municipio = state.formulario_dos.id_municipio;
  }

  if (state.formulario_dos.sitio_web) {
    data.sitio_web = state.formulario_dos.sitio_web;
  }

  const response = await solicitudProveedorServices.postsolicitudProveedores(
    data
  );

  if (response.status === 201) {
    commit("setIdProveedor", response.data.id);
    commit("setPasoActual", 3);
  } else {
    commit("setPasoActual", 1);
    commit("setDuiVerificado", false);
    commit("setNombreProveedor", null);
    commit("serNumeroDocumento", null);
  }
  commit("setLoadingPasoDos", false);
};

export const enviar_codigo = async ({ commit, state, dispatch }) => {
  commit("setEnviarCodigoDisable", true);
  const response = await verificarCorreoService.verificaCorreo(
    state.id_proveedor,
    { correo_electronico: state.formulario_tres.correo_electronico }
  );

  if (response.status === 200) {
    dispatch("iniciar_tiempo_espera_correo");
  }
  commit("setEnviarCodigoDisable", false);
  return response;
};

export const verificar_codigo = async ({ commit, state }) => {
  if (
    state.formulario_tres.codigo_verificacion == null ||
    state.formulario_tres.codigo_verificacion.length < 6
  )
    return;

  commit("setVerificandoCorreo", true);

  const response = await verificarCorreoService.verificarCodigo(
    state.id_proveedor,
    { codigo_verificacion: state.formulario_tres.codigo_verificacion }
  );

  if (response.status === 200) {
    commit("setCorreoValido", true);
  }

  commit("setVerificandoCorreo", false);
  return response;
};

export const iniciar_tiempo_espera_correo = ({ commit, state }) => {
  commit("setTiempoEsperaCorreo", 60);
  const interval = setInterval(() => {
    commit("setTiempoEsperaCorreo", state.tiempoEsperaCorreo - 1);
    if (state.tiempoEsperaCorreo === 0) {
      clearInterval(interval);
    }
  }, 1000);
};

export const completar_registro = async ({ commit, state }) => {
  commit("setLoadingGuardarRegistro", true);
  const datos = new FormData();

  if (state.solicitud_con_observaciones) {
    datos.append("nombre_comercial", state.formulario_dos.nombre_comercial);
    datos.append("numero_documento", state.formulario_uno.numero_documento);
    datos.append("codigo_area", state.formulario_dos.area);
    datos.append("numero_telefono", state.formulario_dos.telefono);
    datos.append("id_genero", state.formulario_dos.genero);
    datos.append(
      "id_clasificacion_empresa",
      state.formulario_dos.id_clasificacion_empresarial
    );
    datos.append(
      "id_identificacion_tributaria",
      state.formulario_uno.id_tipo_identificacion
    );
    datos.append("nombre", state.formulario_uno.nombre_proveedor);
    datos.append("id_pais", state.formulario_dos.id_pais);
    datos.append("detalle_direccion", state.formulario_dos.direccion);
    datos.append(
      "correo_electronico",
      state.formulario_tres.correo_electronico
    );
    datos.append(
      "acepto_declaracion_jurada",
      state.formulario_tres.declaracion
    );
    datos.append("acepto_terminos_condiciones", state.formulario_tres.terminos);
    // Se verifican los campos que pueden ser nulos
    if (state.formulario_dos.sitio_web)
      datos.append("sitio_web", state.formulario_dos.sitio_web);
    if (state.formulario_dos.id_municipio)
      datos.append("id_municipio", state.formulario_dos.id_municipio);
    if (state.formulario_dos.id_departamento)
      datos.append("id_departamento", state.formulario_dos.id_departamento);

    // Se agrega el documento de la declaracion jurada
    datos.append(
      "documento_declaracion_jurada",
      state.formulario_tres.archivo_declaracion
    );

    const response = await solicitudProveedorServices.putObservaciones(
      state.id_proveedor,
      state.token,
      datos
    );

    commit("setLoadingGuardarRegistro", false);
    return response;
  } else {
    datos.append(
      "correo_electronico",
      state.formulario_tres.correo_electronico
    );
    datos.append(
      "acepto_declaracion_jurada",
      state.formulario_tres.declaracion
    );
    datos.append("acepto_terminos_condiciones", state.formulario_tres.terminos);
    datos.append(
      "documento_declaracion_jurada",
      state.formulario_tres.archivo_declaracion
    );

    const response = await solicitudProveedorServices.PutGuardarProveedor(
      state.id_proveedor,
      datos
    );

    commit("setLoadingGuardarRegistro", false);
    return response;
  }
};

export const guardarProveedor = async ({ commit }, data) => {
  const response = await SolicitudProveedor.postsolicitudProveedores(data);
  if (response.status === 201) {
    commit("setidproveedor", response.data.id);
    commit("setVerificacionDos", true);
  } else {
    commit("setVerificacionDos", false);
  }
};

export const CompletarRegistro = async ({ commit, state }, data) => {
  const response = await solicitudProveedorServices.PutGuardarProveedor(
    state.id_proveedor,
    data
  );
  if (response.status === 200 || response.status === 201) {
    commit("setidproveedor", response.data.id);
    return response;
  }
};

export const obtenerDeclaracionJurada = async () => {
  const response = await solicitudProveedorServices.getDeclaracionJurada();
  if (response.status === 200) {
    return response.data;
  } else {
    return false;
  }
};
export const SegundoPaso = ({ commit }, segunaparte) => {
  commit("setsegundopaso", segunaparte);
};

export const primerPaso = ({ commit }, proveedor) => {
  commit("setRegistroProveedor", proveedor);
};
export const ultimoPaso = ({ commit }, tercerpaso) => {
  commit("setUltimoPaso", tercerpaso);
};

export const Verificar = ({ commit }, verifica) => {
  commit("setVerificacion", verifica);
};
export const verificarpaso2 = ({ commit }, verifica2) => {
  commit("setVerificacionDos", verifica2);
};
