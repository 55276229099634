<template>
    <Fragment>
        <h2 class="text-center mt-8 mb-4">CONDICIONES DE LA ORDEN DE COMPRA</h2>
        <p class="text-justify text-bold mb-0" style="font-size: 12px; line-height: 20px;">
            El Contratista acepta todas y cada una de las cláusulas detalladas en la presente Orden de Compra y demas documentos contractuales,
            por el acto de suscribir el presente documento. Las cláusulas que le son aplicables dependerán del bien, obra y/o servicio que suministre.

            <br />
            <br />

            1. CLAUSULA GENERAL: En el contexto de la presente Orden de Compra, los términos siguientes serán interpretados de la manera que se indica 
            a continuación: a) Suministro: Es el bien o servicio que proporcionará el contratista de acuerdo a las 
            especificaciones contenidas en su oferta / cotización y el presente documento y sus anexos: b) Contratante: es 
            <span v-if="modoVistaPrevia" class="text-uppercase">{{ aliasContratante }}</span>
            <v-text-field 
                v-else
                v-bind="generarCommonProps(aliasContratanteErrors)"
                :value="aliasContratante"
                @input="emitirValorAliasContratante"
                @change="validadorAliasContratante()"
                @blur="validadorAliasContratante()"
                placeholder="Nombre de institución"
            />
            , en adelante 
            <span v-if="modoVistaPrevia" class="text-uppercase">"{{ abreviacionContratante }}"</span>
            <v-text-field 
                v-else
                v-bind="generarCommonProps(abreviacionContratanteErrors)"
                :value="abreviacionContratante"
                @input="emitirValorAbrevacionContratante"
                @change="validadorAbreviacionContratante()"
                @blur="validadorAbreviacionContratante()"
                placeholder="Abreviación"
            />
            , quien esta solicitando el suministro del bien o servicio; c) 
            Contratista o Proveedor: Es la personal natural o jurifica mencionada en la presente Orden de Compra, quien suministrará el bien 
            o servicio requerido; La presente Orden de Compra se sujeta a todo lo establecido a la Ley de Compras Públicas (LCP) y las Directrices, 
            lineamientos o requirimientos de 
            <span v-if="modoVistaPrevia">{{ aliasContratante }}</span>
            <v-text-field
                v-else
                v-bind="generarCommonProps(aliasContratanteErrors)"
                :value="aliasContratante"
                @input="emitirValorAliasContratante"
                @change="validadorAliasContratante()"
                @blur="validadorAliasContratante()"
                placeholder="Nombre de institución"
            />
            .

            <br />
            <br />

            2. MODIFICACIÓN, AMPLIACIÓN DEL DOCUMENTO Y/O PRÓRROGA DE PLAZO Y FORMA DE EJECUCIÓN: De común acuerdo la presente Orden de Compra podrá 
            ser modificada en cualquiera de sus partes y/o prorrogada en su plazo de entrega de conformidad a las disposiciones legales aplicables. 
            La modificación es parte integrante de esta Orden de Compra.

            <br />
            <br />

            3. {{ `${!esCMCombustible ? 'FACTURACIÓN' : ''} PARA PAGO: De parte de` }}
            <span v-if="modoVistaPrevia">{{ aliasContratante }}</span>
            <v-text-field 
                v-else
                v-bind="generarCommonProps(aliasContratanteErrors)"
                :value="aliasContratante"
                @input="emitirValorAliasContratante"
                @change="validadorAliasContratante()"
                @blur="validadorAliasContratante()"
                placeholder="Nombre de institución"
            />
            se levantará un acta de recepción de los bienes/servicios recibidos. El contratista 
            posteriormente emitirá {{ !esCMCombustible ? 'Factura' : 'Documentos fiscales correspondientes o su equivalente' }} de <span v-if="modoVistaPrevia">{{ factura }}</span>
            <v-text-field  
            v-else
                v-bind="generarCommonProps(facturaErrors)"
                :value="factura"
                @input="emitirValorFactura"
                @change="validadorFactura()"
                @blur="validadorFactura()"
                placeholder="Tipo factura"

            />  a nombre de
            <span v-if="modoVistaPrevia" class="text-underlined">{{ aliasContratante }}</span>
            <v-text-field 
                v-else
                v-bind="generarCommonProps(aliasContratanteErrors)"
                :value="aliasContratante"
                @input="emitirValorAliasContratante"
                @change="validadorAliasContratante()"
                @blur="validadorAliasContratante()"
                placeholder="Nombre de institución"
            />
            .

            <br />

            El proveedor presentará la siguiente documentación para pagos en las oficinas de 
            <span v-if="modoVistaPrevia">{{ aliasContratante }}</span>
            <v-text-field 
                v-else
                v-bind="generarCommonProps(aliasContratanteErrors)"
                :value="aliasContratante"
                @input="emitirValorAliasContratante"
                @change="validadorAliasContratante()"
                @blur="validadorAliasContratante()"
                placeholder="Nombre de institución"
            />
            :

            <br />

            a) Copia de la Orden de Compra o Contrato
            <br />
            b) Acta de Recepción de los bienes/servicios proveídos
            <br />
            c)
            <span v-if="modoVistaPrevia">{{ factura }}</span>
            <v-text-field  
            v-else
                v-bind="generarCommonProps(facturaErrors)"
                :value="factura"
                @input="emitirValorFactura"
                @change="validadorFactura()"
                @blur="validadorFactura()"
                placeholder="Tipo factura"
            />
            <br />
            La gestión de: presentación, recepción y pago de las facturas será convenida con el Area Financiera de 
            <span v-if="modoVistaPrevia">{{ aliasContratante }}</span>
            <v-text-field 
                v-else
                v-bind="generarCommonProps(aliasContratanteErrors)"
                :value="aliasContratante"
                @input="emitirValorAliasContratante"
                @change="validadorAliasContratante()"
                @blur="validadorAliasContratante()"
                placeholder="Nombre de institución"
            />
            .
            
            <br />
            <br />

            4. IMPUESTOS: El precio deberá incluir todos los tributos, impuestos y/o cargos, comisiones, etc. y cualquier gravamen que pueda 
            recaer sobre el bien a proveer o la actividad del PROVEEDOR, incluido el trece por ciento del Impuesto a la Transferencia de Bienes Muebles y 
            a la Prestación de Servicios (IVA); En consecuencia, el PROVEEDOR será el único responsable de los mismos.

            <br />
            <br />

            5. CONDICIONES PARA EL PAGO: 
            <span v-if="modoVistaPrevia" class="text-capitalize-first-letter d-inline-block">{{ aliasContratante }}</span>
            <v-text-field 
                v-else
                v-bind="generarCommonProps(aliasContratanteErrors)"
                :value="aliasContratante"
                @input="emitirValorAliasContratante"
                @change="validadorAliasContratante()"
                @blur="validadorAliasContratante()"
                placeholder="Nombre de institución"

            />
            pagará el precio de los bienes, obras y/o servicios prestados o suministrados en dólares de los Estados 
            Unidos de America, siempre y cuando estén recibidos a su entera satisfacción y dentro de las condiciones establecidas en la presente Orden de Compra.

            <br />
            <br />

            6. GARANTIA: Cuando aplique, las garantías serán a demanda y se presentarán en una forma aceptable para
            <span v-if="modoVistaPrevia">{{ aliasContratante }}</span>
            <v-text-field 
                v-else
                v-bind="generarCommonProps(aliasContratanteErrors)"
                :value="aliasContratante"
                @input="emitirValorAliasContratante"
                @change="validadorAliasContratante()"
                @blur="validadorAliasContratante()"
                placeholder="Nombre de institución"

            />
            , por un importe del DIEZ POR CIENTO 
            (10%) del precio contractual aceptado, pagadero en dólares de los Estados Unidos de América y provenir de una Institución aceptada por 
            <span v-if="modoVistaPrevia">{{ aliasContratante }}</span>
            <v-text-field 
                v-else
                v-bind="generarCommonProps(aliasContratanteErrors)"
                :value="aliasContratante"
                @input="emitirValorAliasContratante"
                @change="validadorAliasContratante()"
                @blur="validadorAliasContratante()"
                placeholder="Nombre de institución"
            />
            .
            
            <br />
            <br />

            7. SUSPENSIÓN Y TERMINACIÓN: La ejecución de la presente Orden de Compra podrá suspenderse o terminarse unilateralmente por 
            <span v-if="modoVistaPrevia">{{ aliasContratante }}</span>
            <v-text-field 
                v-else
                v-bind="generarCommonProps(aliasContratanteErrors)"
                :value="aliasContratante"
                @input="emitirValorAliasContratante"
                @change="validadorAliasContratante()"
                @blur="validadorAliasContratante()"
                placeholder="Nombre de institución"
            />
            , por incumplimiento
            del Contratista en los casos siguientes:

            <br />
            a) Incumplimiento con cualquiera de las condiciones de su oferta / cotización;
            <br />
            b) Por inclumplimiento de alguna de las condiciones estipuladas en esta Orden de Compra;
            <br />
            c) Cuando por cualquier motivo se altere el objetivo principal de la adquisición de los bienes o servicios descritos al reverso de este documento, 
            o no se permita su consecución en los términos pactados;
            <br />
            d) Por incumplimiento por parte del contratista en las fechas de entrega, calidad, cantidad, de los bienes a entregar; o cuando éste entre en mora al 
            entregarlas. Al ocurrir cualquiera de las causales anteriores 
            <span v-if="modoVistaPrevia">{{ aliasContratante }}</span>
            <v-text-field 
                v-else
                v-bind="generarCommonProps(aliasContratanteErrors)"
                :value="aliasContratante"
                @input="emitirValorAliasContratante"
                @change="validadorAliasContratante()"
                @blur="validadorAliasContratante()"
                placeholder="Nombre de institución"
            />
            procederá a notificar al Contratista sobre la suspensión o terminación de la 
            Orden de Compra, invocando la causal especifica que genera tal notificación. La suspensión o terminación producirá efectos a partir de la notificación 
            al Contratista.

            <br />
            <br />

            8. CASO FORTUITO Y FUERZA MAYOR: Por motivos de casos fortuito o fuerza mayor el Contratista podrá solicitar, modificar y/o ampliar este documento y/o
            solicitar prórroga del plazo a fin de cumplir las obligaciones objeto de la Orden de Compra en ejecución, debiendo justificar y documentar su solicitud, 
            la cual, para que sea efectiva, deberá ser aprobada por 
            <span v-if="modoVistaPrevia">{{ aliasContratante }}</span>
            <v-text-field 
                v-else
                v-bind="generarCommonProps(aliasContratanteErrors)"
                :value="aliasContratante"
                @input="emitirValorAliasContratante"
                @change="validadorAliasContratante()"
                @blur="validadorAliasContratante()"
                placeholder="Nombre de institución"
            />
            ; pero el mero retraso no dará derecho al CONTRATISTA a reclamar una compensación económica 
            adicional. La solicitud de prórroga deberá hacerse dentro de los plazos establecidos en esta orden de compra. Si procediere la aprobación, el Contratista 
            deberá entregar la ampliación de la respectiva garantía, si fuera procedente. 
        
            <br />
            <br />

            9. NOTIFICACIONES: Todas las notificaciones referente a la ejecución de la presente Orden de Compra, serán válidas  solamente  cuando  sean  hechas  por  
            escrito  a las  direcciones de las partes contratantes, para cuyos  efectos  las  partes  señalan  como  lugar  para  recibir  notificaciones la siguiente: 
            {{ direccionFacturacion }}, 
            y la dirección del Contratista será la que este señale en su oferta/cotización. Lo anterior no será obstáculo para que las partes contratantes puedan 
            notificarse por cualquier otro medio que permita tener constancia fehaciente de la recepción.
        
            <br />
            <br />

            10. DOCUMENTOS CONTRACTUALES: Los documentos contracturales que rigen esta contratación son: a) Ley de Compras Públicas (LCP), b) Directrices, lineamientos 
            o requirimientos de 
            <span v-if="modoVistaPrevia">{{ aliasContratante }}</span>
            <v-text-field 
                v-else
                v-bind="generarCommonProps(aliasContratanteErrors)"
                :value="aliasContratante"
                @input="emitirValorAliasContratante"
                @change="validadorAliasContratante()"
                @blur="validadorAliasContratante()"
                placeholder="Nombre de institución"
            />
            , c) Invitaciones, Documentos de Solicitud y adendas o modificaciones a estas, si las hubiere emitidos por 
            <span v-if="modoVistaPrevia">{{ aliasContratante }}</span>
            <v-text-field 
                v-else
                v-bind="generarCommonProps(aliasContratanteErrors)"
                :value="aliasContratante"
                @input="emitirValorAliasContratante"
                @change="validadorAliasContratante()"
                @blur="validadorAliasContratante()"
                placeholder="Nombre de institución"
            />
            ; d) La 
            Oferta / Cotización presentada por el oferente ganador; e) Acuerdo de adjudicación; f) Orden de Compra; g) Garantías y h) Otros documentos 
            que emanaren en el marco de la ejecución del proceso de compra. Todos los documentos mencionados son complementarios entre sí, y se interpretarán de forma
            conjunta.

            <br />
            <br />

            11. CESIÓN Y SUBCONTRATACIÓN: Queda expresamente prohibido al Contratista traspasar o ceder a cualquier titulo, los derechos y obligaciones que emanan de la 
            presente Orden de Compra, asismismo subcontratar a personas naturales o juridicar para realizar las obligaciones emanadas de esta Orden de Compra. 
            La transgresión de esta disposición dará lugar a la caducidad de este documento.
        
            <br />
            <br />

            12. JURISDICCIÓN, LEGISLACIÓN APLICABLE Y SOLUCIÓN DE CONFLICTOS: Para los efectos juridiccionales de esta contratación las partes contratantes nos 
            sometemos en todo a la legislación vigente de la República de El Salvador, renunciando a efectuar reclamaciones por vias que no sean las establecidas 
            por las Leyes de este País. Asimismo señalamos como domicilio especial el de esta ciudad a la competencia de cuyos tribunales nos sometemos expresamente.
            El Contratista renuncia en caso de acción judicial en su contra, a derecho de apelar del decreto de embargo, sentencia de remate y de cualquier otra providencia
            alzable en el juicio que se le promoviere; será depositaria de los bienes que se le embargaren a la persona que 
            <span v-if="modoVistaPrevia">{{ aliasContratante }}</span>
            <v-text-field 
                v-else
                v-bind="generarCommonProps(aliasContratanteErrors)"
                :value="aliasContratante"
                @input="emitirValorAliasContratante"
                @change="validadorAliasContratante()"
                @blur="validadorAliasContratante()"
                placeholder="Nombre de institución"
            />
            designe a quien releva de la 
            obligación de rendir fianza y cuentas, comprometiendose a pagar los gastos ocasionados, inclusive los personales, aunque no hubiere condenación en costas.
            <br />
            Para efectos del Contrato/Orden de Compra, toda controversia que sugiere entre las Partes, sera sometida a: a) Arreglo Directo, en el cual las partes 
            contratantes procurarán solucionar las diferencias a traves de sus representantes y delegados especialmente acreditados, dejando constancia escrita de 
            los puntos controvertidos y de las soluciones en su caso y b) Después de haberse intentado el Arreglo Directo, sin solucion a algunas diferencias, 
            se recurrirá a la vía judicial.

            <br />
            <br />

            13. CUMPLIMIENTO POR PARTE DE LA CONTRATISTA CON LA NORMATIVA SOBRE TRABAJO INFANTIL Y PROTECCIÓN DE LA PERSONA ADOLESCENTE TRABAJADORA. El Contratista 
            declara que no emplea a niñas, niños y adolescentes por debajo de la edad mínima de admisión al empleo y se cumple con la normativa que prohíbe el trabajo 
            infantil y de protección de la persona adolescente trabajadora. Y si durante la ejecución de la contratación se comprobare por la Dirección General de 
            Inspección de Trabajo del Ministerio de Trabajo y Previsión Social, incumplimiento por parte "de "LA CONTRATISTA" a la normativa que prohíbe el trabajo 
            infantil y de protección a la persona adolescente trabajadora, se deberá tramitar el procedimiento sancionatorio de conformidad a lo regulado en el artículo 
            ciento ochenta y siete de la LCP para determinar el cometimiento o no durante la ejecución del contrato de la conducta tipificada como causal de 
            inhabilitación en el artículo ciento ochenta y uno, romano V, literal a), de la LCP relativa a la invocación de hechos falsos para obtener la adjudicación 
            de la contratación. Se entenderá por comprobado el incumplimiento a la normativa por parte de la Dirección General de Inspección de Trabajo si durante el 
            trámite de la inspección se determina que hubo subsanación por haber cometido una infracción, o por el contrario si se remitiere a procedimiento 
            sancionatorio y en este último caso deberá finalizar el procedimiento para conocer la resolución final.

            <br />
            <br />

            14. INCUMPLIMIENTO: En caso de mora de la contratista en el cumplimiento de las obligaciones emanadas del presente contrato se le aplicarán las multas e 
            inhabilidades establecidas en el capítulo II del Titulo X de la LCP.

            <br />
            <br />

            15. CADUCIDAD: Además de las causas de caducidad establecidas en el artículo ciento sesenta y siete de la LCP y en otras leyes vigentes, serán causales 
            de caducidad y 
            <span v-if="modoVistaPrevia">{{ aliasContratante }}</span>
            <v-text-field 
                v-else
                v-bind="generarCommonProps(aliasContratanteErrors)"
                :value="aliasContratante"
                @input="emitirValorAliasContratante"
                @change="validadorAliasContratante()"
                @blur="validadorAliasContratante()"
                placeholder="Nombre de institución"
            />
            podrá dar por terminada la presente contratación, sin responsabilidad alguna de su parte cuando el contratista: a) Suministre 
            bienes de una inferior calidad o en diferentes condiciones de lo ofertado; y b) Traspase o ceda a cualquier título los derechos y obligaciones que 
            emanan de la presente contratación.

            <br />
            <br />

            16. TERMINACIÓN BILATERAL. Las partes contratantes podrán de conformidad con el artículo ciento sesenta y ocho de la LCP, dar por terminada bilateralmente
            la relación jurídica que emana de la presente contratación, debiendo en tal caso emitirse la resolución correspondiente y otorgarse el instrumento de 
            resciliación en un plazo no mayor de ocho días hábiles de notificada la resolución.

            <br />
            <br />

            17. DECLARACIÓN JURADA: Bajo juramento hago las siguientes declaraciones: no ser empleado de la Unidad Ejecutora - 
            <span v-if="modoVistaPrevia" class="text-uppercase">{{ abreviacionContratante }}</span>
            <v-text-field
                v-else
                v-bind="generarCommonProps(abreviacionContratanteErrors)"
                :value="abreviacionContratante"
                @input="emitirValorAbrevacionContratante"
                @change="validadorAbreviacionContratante()"
                @blur="validadorAbreviacionContratante()"
                placeholder="Abreviación"
            />
            , ni el declarante, ni su 
            representante legal, socio, directivo integrante de la sociedad licitante, administrador o gerente. Declaramos no tener ningún tipo de conflicto de 
            intereses, Declaro haber leído, comprendido y aceptado los conceptos vertidos en la presente Orden de Compra.
       
            <br />
            <br />

            18. ADMINISTRACIÓN Y SEGUIMIENTO: 
            <span v-if="modoVistaPrevia">{{ aliasContratante }}</span>
            <v-text-field 
                v-else
                v-bind="generarCommonProps(aliasContratanteErrors)"
                :value="aliasContratante"
                @input="emitirValorAliasContratante"
                @change="validadorAliasContratante()"
                @blur="validadorAliasContratante()"
                placeholder="Nombre de institución"
            />
            ha delegado a: 
            <span v-if="modoVistaPrevia">{{ nombreDelegado || '________________________________________________' }}</span>
            <v-text-field 
                v-else
                v-bind="generarCommonProps([])"
                :value="nombreDelegado"
                @input="emitirValorNombreDelegado"
            />
            con Número de documento:
            <span v-if="modoVistaPrevia">{{ duiDelegado || '_______________________' }}</span>
            <v-text-field 
                v-else
                v-bind="generarCommonProps(duiDelegadoErrors)"
                :value="duiDelegado"
                @input="emitirValorDuiDelegado"
                @change="validadorDuiDelegado()"
                @blur="validadorDuiDelegado()"
            />
           y Teléfono {{ telefonoAdministrador }}; o a quien lo sustituya como responsable de la Administración de la Orden de Compra, de conformidad a los Art. 161 y 162 LCP.
            
           <br/>
           <br/>

           19. Convenio marco suscrito por la DINAC
        </p>
        <!-- Condicion personalizada -->
         <template v-if="modoVistaPrevia && condicionPersonalizada">
            <br />
            <p style="font-size: 12px; line-height: 20px;" class="mb-0">
                <span>20. Condiciones especiales:</span>
                <pre v-if="condicionPersonalizada"  style="white-space: pre-line; display: inline;" class="ml-1">{{ condicionPersonalizada }}</pre>
            </p>
        </template>
        <template v-if="!modoVistaPrevia">
            <br />
            <span style="font-size: 12px; line-height: 20px;">20. Condiciones especiales:</span>
            <v-textarea
                v-bind="generarCommonProps([])"
                style="width: 100%"
                class="mt-2"
                :value="condicionPersonalizada"
                no-resize
                rows="3"
                @input="emitirValorCondicionPersonalizada"
            />
        </template>
    </Fragment>
</template>
<script>
import { Fragment } from 'vue-fragment';

export default {
    components: { Fragment },
    name: 'Condiciones',
    props: {
        modoVistaPrevia: { type: Boolean },
        esCMCombustible: { type : Boolean},
        // Contratante
        // contratante: { type: String },
        // contratanteErrors: { type: Array },
        // validadorContratante: { type: Function },
        // Factura
        factura: { type: String },
        facturaErrors: { type: Array },
        validadorFactura: { type: Function },
        //Factura Emitida
        // facturaEmitida: { type: String },
        // facturaEmitidaErrors: { type: Array },
        // validadorFacturaEmitida: { type: Function },

        direccionFacturacion: {type: String},
        // Alias contratante
        aliasContratante: { type: String },
        aliasContratanteErrors: { type: Array },
        validadorAliasContratante: { type: Function },
        // Abreviacion contratante
        abreviacionContratante: { type: String },
        abreviacionContratanteErrors: { type: Array },
        validadorAbreviacionContratante: { type: Function },
        // Nombre delegado
        nombreDelegado: { type: String },
        // Dui delegado
        duiDelegado: { type: String },
        validadorDuiDelegado: { type: Function },
        duiDelegadoErrors: { type: Array },
        // Condicion personalizada
        condicionPersonalizada: { type: String },
        // Telefono administrador de contrato
        telefonoAdministrador: { type: String },
    },
    computed: {
        inputProps() {
            return ({
                dense: true,
                outlined: true,
                class: 'd-inline-block my-1',
                'hide-details': true,
            });
        },
    },
    methods: {
        generarCommonProps(errores) {
            return ({ outlined: true, dense: true, 'error-messages': errores, 'hide-details': errores.length === 0, class: 'd-inline-block my-1' });
        },
        // Contratante
        // emitirValorContratante(valor) {
        //     this.$emit('update:contratante', valor);
        // },
        // Factura
        emitirValorFactura(valor) {
            this.$emit('update:factura', valor);
        },
        // Factura Emitida
        // emitirValorFacturaEmitida(valor) {
        //     this.$emit('update:factura-emitida', valor);
        // },
        emitirEdicionContratante(valor) {
            this.$emit('update:editando-contratante', valor);
        },
        // Alias contratante
        emitirValorAliasContratante(valor) {
            this.$emit('update:alias-contratante', valor);
        },
        emitirEdicionAliasContratante(valor) {
            this.$emit('update:editando-alias-contratante', valor);
        },
        // Abrevacion contratante
        emitirValorAbrevacionContratante(valor) {
            this.$emit('update:abreviacion-contratante', valor);
        },
        emitirEdicionAbrevacionContratante(valor) {
            this.$emit('update:editando-abreviacion-contratante', valor);
        },
        // Nombre delegado
        emitirValorNombreDelegado(valor) {
            this.$emit('update:nombre-delegado', valor);
        },
        emitirEdicionNombreDelegado(valor) {
            this.$emit('update:editando-nombre-delegado', valor);
        },
        // DUI delegado
        emitirValorDuiDelegado(valor) {
            this.$emit('update:dui-delegado', valor);
        },
        emitirEdicionDuiDelegado(valor) {
            this.$emit('update:editando-dui-delegado', valor);
        },
        // Condicion personalizada
        emitirValorCondicionPersonalizada(valor) {
            this.$emit('update:condicion-personalizada', valor);
        },
        emitirEdicionCondicionPersonalizada(valor) {
            this.$emit('update:editando-condicion-personalizada', valor);
        },
    },
}
</script>