const routesProcesoTradicional = [
    {
        path: '/list-obs-proceso-tradicional/:id_solicitud',
        name: 'list-obs-proceso-tradicional',
        component: () => import(/* webpackChunkName: "proceso-tradicional" */ './mainPTListadoOBSView.vue'),
    },
    {
        path: '/agregar-insumo-proceso-tradicional/:id_solicitud',
        name: 'agregar-insumo-proceso-tradicional',
        component: () => import(/* webpackChunkName: "proceso-tradicional" */ './addInsumoPTView.vue'),
        meta: { id_tipo_accion: 1 },
        props: (route) => ({ id_proceso_compra: route.query.id_proceso_compra }),
    },
    {
        path: '/editar-insumo-proceso-tradicional/:id_solicitud',
        name: 'editar-insumo-proceso-tradicional',
        component: () => import(/* webpackChunkName: "proceso-tradicional" */ './addInsumoPTView.vue'),
        meta: { id_tipo_accion: 2 },
        props: (route) => ({ id_insumo: route.query.id_insumo }),
    },
]

export default routesProcesoTradicional;