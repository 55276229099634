import moment from "moment";
import Vue from "vue";
import PacProcesosServices from "@/services/PacProcesos.services";
import PaacProcesosServices from "@/services/Paac.services";

export default {
  namespaced: true,
  state: {
    cargando: false,
    salas: [],
    default_salas: [],
    sala_seleccionada: null,
    nombre_sala: "",
    showModalRoomDelete: false,
    showModalSubastaDelete: false,
    showModalEditCreateSubasta: false,
    procesoCompra: null,
    subastas: [],
    headers: [
      {
        text: "Lote",
        value: "nombre_lote",
      },
      {
        text: "Tipo de subasta",
        value: "tipo_subasta",
      },
      {
        text: "Fecha y hora de inicio",
        value: "fecha_hora_inicio",
      },
      {
        text: "Fecha y hora de fin",
        value: "fecha_hora_final",
      },
      {
        text: "Monto inicial($)",
        value: "monto_inicial",
      },
      { text: "Acciones", align: "center", value: "actions" },
    ],
    subasta_seleccionada: null,
    headersLoteSubasta: [
      {
        text: "Nombre",
        value: "nombre_agrupacion",
      },
      {
        text: "Detalle",
        value: "unidad",
      },
      {
        text: "Cantidad",
        value: "total_sub_proceso",
      },
      {
        text: "Monto($)",
        value: "monto_sub_proceso_obs",
      },
    ],
    proceso: null,
    form_enviar: {
      id_lote: null,
      id_tipo_subasta: null,
      fechaInicio: null,
      fechaFin: null,
      tiempo_maximo_desierto: null,
      tiempo_maximo_oferta: null,
      saltoOfertas: null,
      monto_inicial: null,
      ctlSubasta: null,
      cantidadElementos: null,
      porcentaje: null,
      monto_digitado: false,
    },
    default_form: {
      id_lote: null,
      id_tipo_subasta: null,
      fechaInicio: null,
      fechaFin: null,
      tiempo_maximo_desierto: null,
      tiempo_maximo_oferta: null,
      saltoOfertas: null,
      monto_inicial: null,
      ctlSubasta: null,
      cantidadElementos: null,
      porcentaje: null,
      monto_digitado: false,
    },
    ctlLotes: [],
    montoTotalLote: null,
    montoInicial: null,
    loteDetails: [],
    loadingLote: false,
    ctlSubastas: [],
    solicitudes_lote: [],
    subasta_unitaria: {
      rehacer_lotes: false,
    },
    subasta_unitaria_default: {
      rehacer_lotes: false,
    },
    headers_lotes_rehacer: [
      {
        text: "Insumo",
        value: "solicitud_compra_obs.observacion",
        align: "center",
        width: "10%",
      },
      {
        text: "Precio unitario",
        value: "precio_unitario",
        align: "center",
      },
      {
        text: "Cantidad",
        value: "cantidad_aprobar",
        align: "center",
      },
      {
        text: "Fecha inicio",
        value: "fecha_inicio",
        align: "center",
      },
      {
        text: "Fecha finalización",
        align: "center",
        value: "fecha_fin",
      },
      {
        text: "Tiempo máximo de oferta",
        value: "tiempo_maximo_oferta",
        align: "center",
      },
      {
        text: "Tiempo máximo desierto",
        value: "tiempo_maximo_desierto",
        align: "center",
      },
      {
        text: "Precio unitario",
        value: "monto_inicial",
        align: "center",
      },
      {
        text: "Saltos de oferta",
        value: "saltos_oferta",
        align: "center",
      },
    ],
    config_calendar: {
      visibility: "click",
    },
    errores: [],
    valores_minimos: {
      oferta: 1,
      desierto: 3,
    },
    lote_seleccionado: {},
  },
  mutations: {
    setSalas(state, salas) {
      state.salas = salas;
    },
    setSalaSeleccionada(state, sala) {
      state.sala_seleccionada = sala;
    },
    setNombreSala(state, nombre) {
      state.nombre_sala = nombre;
    },
    setCargando(state) {
      state.cargando = !state.cargando;
    },
    setShowModalRoomDelete(state, value) {
      state.showModalRoomDelete = value;
    },
    setProcesoCompra(state, procesoCompra) {
      state.procesoCompra = procesoCompra;
    },
    setSubastas(state, subastas) {
      state.subastas = subastas;
    },
    setShowModalEditCreateSubasta(state, value) {
      state.showModalEditCreateSubasta = value;
    },
    setShowModalSubastaDelete(state, value) {
      state.showModalSubastaDelete = value;
    },
    setSubastaSeleccionada(state, subasta) {
      state.subasta_seleccionada = subasta;
    },
    setProceso(state, proceso) {
      state.proceso = proceso;
    },
    resetForm(state) {
      state.form_enviar = { ...state.default_form };
      state.showModalEditCreateSubasta = false;
      state.subasta_seleccionada = null;
      state.solicitudes_lote = [];
      state.errores = [];
      state.subasta_unitaria = { ...state.subasta_unitaria_default };
    },
    setCtlLotes(state, ctlLotes) {
      state.ctlLotes = ctlLotes;
    },
    setMontoLote(state, monto) {
      state.montoTotalLote = monto;
      state.form_enviar.monto_inicial = monto;
      state.montoInicial = monto;
    },
    setLoteDetails(state, loteDetails) {
      state.loteDetails = loteDetails;
    },
    setLoteSeleccionado(state, lote) {
      state.lote_seleccionado = lote;
    },
    setLoadingLote(state) {
      state.loadingLote = !state.loadingLote;
    },
    setCtlSubasta(state, ctlSubasta) {
      state.ctlSubastas = ctlSubasta;
    },
    setForm(state, form) {
      state.form_enviar = form;
    },
    setSolicitudesLote(state, solicitudes) {
      state.solicitudes_lote = solicitudes;
    },
    formatearFechas(state) {
      state.form_enviar.fechaInicio = moment(
        state.form_enviar.fechaInicio
      ).format("YYYY-MM-DD HH:mm:ss");
      state.form_enviar.fechaFin = moment(state.form_enviar.fechaFin).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    agregarError(state, error) {
      if (!state.errores.includes(error)) {
        state.errores.push(error);
      }
    },
    resetErrores(state) {
      state.errores = [];
    },
  },
  actions: {
    setSalaSeleccionada({ commit }, sala) {
      commit("setSalaSeleccionada", sala);
    },
    async getSalas({ commit, state }) {
      commit("setCargando");
      const { data } = await PacProcesosServices.getSalas(state.procesoCompra, {
        pagination: false,
      });
      commit("setSalas", data);
      commit("setCargando");
    },
    async crearSala({ commit, state, dispatch }, params) {
      commit("setCargando");
      await PacProcesosServices.creacionSala(state.procesoCompra, params);
      await dispatch("getSalas");
      commit("setNombreSala", "");
      commit("setCargando");
    },
    async eliminarSala({ commit, state, dispatch }) {
      commit("setCargando");
      await PacProcesosServices.removeSala(state.sala_seleccionada.id);
      await dispatch("setShowModalRoomDelete", false);
      await dispatch("getSalas");
      commit("setSalaSeleccionada", null);
      commit("setCargando");
    },
    setShowModalRoomDelete({ commit }, value) {
      commit("setShowModalRoomDelete", value);
    },
    setProcesoCompra({ commit }, procesoCompra) {
      commit("setProcesoCompra", procesoCompra);
    },
    async getSubastas({ commit, state }) {
      commit("setCargando");
      const { data } = await PacProcesosServices.getSubastasSala(
        state.sala_seleccionada.id,
        {
          pagination: false,
        }
      );
      commit("setSubastas", data);
      commit("setCargando");
    },
    setShowModalEditCreateSubasta({ commit, dispatch }, value) {
      commit("setShowModalEditCreateSubasta", value);
    },
    setShowModalSubastaDelete({ commit }, value) {
      commit("setShowModalSubastaDelete", value);
    },
    setSubastaSeleccionada({ commit }, subasta) {
      commit("setSubastaSeleccionada", subasta);
    },
    async deleteSubasta({ commit, state, dispatch }) {
      commit("setCargando");
      await PacProcesosServices.deleteSalaSubasta(
        state.subasta_seleccionada.id
      );
      await dispatch("getSubastas");
      await dispatch("setShowModalSubastaDelete", false);
      await dispatch("setSubastaSeleccionada");
      commit("setCargando");
    },
    async getProceso({ commit, state }) {
      commit("setCargando");
      const { data } = await PacProcesosServices.getProceso(
        state.procesoCompra
      );
      commit("setProceso", data);
      commit("setCargando");
    },
    async getLotes({ commit, state }) {
      const parametros = {
        sala: state.sala_seleccionada.id,
        ...(!state.subasta_seleccionada?.id && { origen: "subasta" }),
      };
      const { data } = await PacProcesosServices.obtenerLotesConfigurados(
        state.procesoCompra,
        parametros
      );
      commit("setCtlLotes", data);
    },
    async getMontoTotalLote({ commit, state }) {
      if (+state.form_enviar?.monto_inicial > 0) {        
        return
      };
      const { data } = await PaacProcesosServices.montoTotalLote(
        state.form_enviar.id_lote
      );
      const monto =
        state?.form_enviar?.ctlSubasta?.tipo_operacion === "resta_unitaria"
          ? data?.total_unitaria
          : data?.total;
      commit("setMontoLote", monto);
    },
    async getLoteDetail({ commit, state, dispatch }) {
      commit("setLoadingLote", true);
      const { data } = await PacProcesosServices.obtenerDatosLote(
        state.form_enviar.id_lote
      );
      commit("setLoteSeleccionado", data);

      commit(
        "setLoteDetails",
        data?.SubProcesoObs?.map((col) => ({
          ...col,
          unidad: col?.Presentacion.nombre,
        }))
      );
      await dispatch("getMontoTotalLote");
      commit("setLoadingLote", false);
    },
    async getCatalogoSubastas({ commit }) {
      const { data } = await PaacProcesosServices.catalogoSubasta();
      commit("setCtlSubasta", data);
    },
    async guardarSubasta({ state }) {
      await PacProcesosServices.addSubasta(state.sala_seleccionada.id, {
        ...state.form_enviar,
      });
    },
    async actualizarSubasta({ state }) {
      await PacProcesosServices.putSalaSubasta(state.subasta_seleccionada.id, {
        ...state.form_enviar,
      });
    },
    resetForm({ commit, dispatch }) {
      commit("resetForm");
    },
    async guardarConfiguracion({ state, dispatch, commit }) {
      // commit("formatearFechas");
      if (state.subasta_seleccionada?.id) {
        await dispatch("actualizarSubasta");
      } else {
        await dispatch("guardarSubasta");
      }
      dispatch("setSubastaSeleccionada", {});
      await dispatch("getSubastas");
      dispatch("setShowModalEditCreateSubasta", false);
      dispatch("resetForm");
    },
    async getSubasta({ commit, state, dispatch }) {
      const { data } = await PacProcesosServices.getSubastaID(
        state.subasta_seleccionada.id
      );

      commit("setForm", {
        id: data.id,
        id_lote: data.id_lote_proceso,
        id_tipo_subasta: data.id_tipo_subasta,
        fechaInicio: data.fecha_hora_inicio,
        fechaFin: data.fecha_hora_final,
        tiempo_maximo_desierto: moment
          .duration(data.tiempo_maximo_desierto)
          .asMinutes(),
        tiempo_maximo_oferta: moment
          .duration(data.tiempo_maximo_oferta)
          .asMinutes(),
        saltoOfertas: data.salto_oferta,
        monto_inicial: data.monto_inicial,
        ctlSubasta: data.CtlSubastum,
        cantidadElementos: data.cantidad,
        porcentaje: data.porcentaje_inicial,
        monto_digitado: data.monto_digitado,
      });
      dispatch("getLoteDetail");
    },
    async obtenerSubProcesosObsLote({ commit, state }) {
      const { data } = await PaacProcesosServices.procesosObsLote(
        state.form_enviar.id_lote
      );
      commit("setSolicitudesLote", data);
    },
    agregarError({ commit }, error) {
      commit("agregarError", error);
    },
    validarMontosIniciales({ state, dispatch }) {
      const invalidValues = [null, "", undefined];
      state.solicitudes_lote.forEach((lote) => {
        if (invalidValues.includes(lote.monto_inicial)) {
          dispatch(
            "agregarError",
            `El monto inicial del lote ${lote.solicitud_compra_obs.observacion} no puede ser nulo`
          );
        }
        if (invalidValues.includes(lote.saltos_oferta)) {
          dispatch(
            "agregarError",
            `El salto entre ofertas del lote ${lote.solicitud_compra_obs.observacion} no puede ser nulo`
          );
        }
        if (lote.monto_inicial > lote.precio_unitario) {
          dispatch(
            "agregarError",
            `El monto inicial del lote ${lote.solicitud_compra_obs.observacion} no puede ser mayor al precio unitario`
          );
        }
        if (Number(lote.saltos_oferta) > Number(lote.monto_inicial)) {
          dispatch(
            "agregarError",
            `El salto entre ofertas del lote ${lote.solicitud_compra_obs.observacion} no puede ser mayor al monto inicial`
          );
        }

        if (invalidValues.includes(lote.tiempo_maximo_oferta)) {
          dispatch(
            "agregarError",
            `El tiempo máximo de oferta del lote ${lote.solicitud_compra_obs.observacion} no puede ser nulo`
          );
        }

        if (invalidValues.includes(lote.tiempo_maximo_desierto)) {
          dispatch(
            "agregarError",
            `El tiempo máximo de espera entre ofertas del lote ${lote.solicitud_compra_obs.observacion} no puede ser nulo`
          );
        }

        const diferenciaInicioFin = moment(lote.fecha_fin).diff(
          moment(lote.fecha_inicio),
          "minutes"
        );

        if (lote.tiempo_maximo_oferta > diferenciaInicioFin) {
          dispatch(
            "agregarError",
            `El tiempo máximo de oferta del lote ${lote.solicitud_compra_obs.observacion} no puede ser mayor a la diferencia entre la fecha de inicio y fin`
          );
        }
        if (lote.tiempo_maximo_desierto > diferenciaInicioFin) {
          dispatch(
            "agregarError",
            `El tiempo máximo de espera entre ofertas del lote ${lote.solicitud_compra_obs.observacion} no puede ser mayor a la diferencia entre la fecha de inicio y fin`
          );
        }
      });
    },
    validarSuperposiciones({ state, dispatch }) {
      let elementosOrdenados = [...state.solicitudes_lote].sort(
        (a, b) => new Date(a.fecha_inicio) - new Date(b.fecha_fin)
      );

      for (let i = 0; i < elementosOrdenados.length - 1; i++) {
        let finActual = new Date(elementosOrdenados[i].fecha_fin);
        let inicioSiguiente = new Date(elementosOrdenados[i + 1].fecha_inicio);
        if (isNaN(finActual) || isNaN(inicioSiguiente)) {
          dispatch("agregarError", "Por favor configure todas las fechas");
        } else {
          if (finActual > inicioSiguiente) {
            dispatch(
              "agregarError",
              `La fecha del lote ${
                elementosOrdenados[i].solicitud_compra_obs.observacion
              } se superpone con la fecha del lote ${
                elementosOrdenados[i + 1].solicitud_compra_obs.observacion
              }`
            );
          }
        }
      }
    },
    validarFormulario({ dispatch, commit }) {
      commit("resetErrores");
      dispatch("validarMontosIniciales");
      dispatch("validarSuperposiciones");
    },
    async enviarFormularioUnitarias({ state, dispatch }) {
      dispatch("validarFormulario");
      if (state.errores.length === 0) {
        await PacProcesosServices.addSubastaUnitaria(
          state.sala_seleccionada.id,
          {
            id_lote: state.form_enviar.id_lote,
            id_tipo_subasta: state.form_enviar.ctlSubasta.id,
            procesoCompra: state.procesoCompra,
            subastas: [...state.solicitudes_lote],
          }
        );
        dispatch("setSubastaSeleccionada", {});
        await dispatch("getSubastas");
        dispatch("resetForm");
        return;
      }
      Vue?.prototype.pushAppMessage({
        message: "Hay errores en la configuración enviada",
        type: "warning",
      });
    },
    validarCamposComunes({ state, dispatch }) {
      const tiempo_maximo_desierto = Number(
        state.form_enviar.tiempo_maximo_desierto
      );
      const tiempo_maximo_oferta = Number(
        state.form_enviar.tiempo_maximo_oferta
      );
      if (!moment(state.form_enviar.fechaFin).isValid()) {
        dispatch("agregarError", "La fecha de fin no es válida");
      }
      if (!moment(state.form_enviar.fechaInicio).isValid()) {
        dispatch("agregarError", "La fecha de inicio no es válida");
      }
      if (state.form_enviar.fechaInicio >= state.form_enviar.fechaFin) {
        dispatch(
          "agregarError",
          "La fecha de inicio no puede ser mayor a la fecha de fin"
        );
      }
      if (tiempo_maximo_oferta < 0) {
        dispatch(
          "agregarError",
          "El tiempo máximo de oferta no puede ser negativo"
        );
      }
      if (tiempo_maximo_desierto < 0) {
        dispatch(
          "agregarError",
          "El tiempo máximo de espera entre ofertas no puede ser negativo"
        );
      }
      if (tiempo_maximo_desierto < tiempo_maximo_oferta) {
        dispatch(
          "agregarError",
          "El tiempo máximo de desierto no puede ser menor al tiempo máximo de oferta"
        );
      }
    },
    validarSingular({ state, dispatch }) {
      const porPorcentaje = [
        "suma_porcentaje",
        "resta_porcentaje",
        "resta_porcentaje_publicidad",
      ].includes(state.form_enviar.ctlSubasta?.tipo_operacion);
      if (!porPorcentaje && Number(state.form_enviar.saltoOfertas) <= 0) {
        dispatch("agregarError", "El salto de ofertas debe ser mayor a 0");
      }
      if (!porPorcentaje && state.form_enviar.monto_inicial <= 0) {
        dispatch("agregarError", "El monto inicial debe ser mayor a 0");
      }
      // if (porPorcentaje && state.form_enviar.porcentaje <= 0) {
      //   dispatch("agregarError", "el porcentaje debe ser mayor a 0");
      // }
      if (state.form_enviar.tiempo_maximo_oferta <= 0) {
        dispatch(
          "agregarError",
          "El tiempo máximo de oferta debe ser mayor a 0"
        );
      }
      if (state.form_enviar.tiempo_maximo_desierto <= 0) {
        dispatch(
          "agregarError",
          "El tiempo máximo de desierto debe ser mayor a 0"
        );
      }
    },
    validarFormularioIndividual({ dispatch, state, commit }) {
      commit("resetErrores");
      dispatch("validarCamposComunes");
      dispatch("validarSingular");
      if (state.errores.length > 0) {
        return Vue?.prototype.pushAppMessage({
          message: "Hay errores en la configuración enviada",
          type: "warning",
        });
      }
    },
  },
};
