const administracionobs = [
  {
    path: '/administracion-obs',
    name: 'administracion-obs',
    component: () =>
      import(
        /* webpackChunkName: "administracion-obs" */ './administracionObsView.vue'
      )
  }
]

export default administracionobs
