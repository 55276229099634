// importacion de services
import PacService from "@/services/Pac.services";

//  accion que obtiene la lista de solicitudes de necesidades a través del servicio PacService
export const FetchSolicitudesNecesidadesAc = async ({ commit }, filters_param = {}) => {
    const RESP_SC_CONST = await PacService.getSolicitudesPAC(filters_param);
    if (RESP_SC_CONST) {
        const { data, headers } = RESP_SC_CONST;

        //  condicional en base al estado (1 pendiente) de la solicitud de necesidad, para asignar los valores a la state correspondiente
        if (filters_param.id_estado == '1,6') {
            commit("SetListSolicPendtMt", data);
            commit("SetListSolicPendtPagMt", headers);
        } else {
            commit("SetListSolicAprobMt", data);
            commit("SetListSolicAprobPagMt", headers);
        }
    }
}


