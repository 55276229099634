import { http_client, http_client_with_status } from "@/plugins/http_client";

const buscarFamilia = (idFamilia) => http_client_with_status(`/api/v1/cm-familia/${idFamilia}`, null, 'get');

const getFamiliasList = async (params = {}) => {
  return await http_client(`/api/v1/cm-familia`, params, "get");
};
const obtenerFamilias = async (params = {}) => {
  return await http_client(`/api/v1/cm-familia/familias`, params, "get");
};

const upsertFamilia = async (familia, idFamilia) => {  
  let url = '/api/v1/cm-familia';
  const method = idFamilia ? 'PUT' : 'POST';

  if (idFamilia) url = url + `/${idFamilia}`;

  return await http_client(url, familia, method,{
    'Content-Type': 'multipart/form-data',
});
}

const deleteFamilias = async (id_familia, params = {}) => {
  return await http_client(`/api/v1/cm-familia/${id_familia}`, params, "delete");
};

const putFamiliasEstado = async (id_familia, params = {}) => {
  return await http_client(`/api/v1/cm-familia/estado/${id_familia}`, params, "put");
};

const eliminarCircular = (idFamilia) => http_client_with_status(`/api/v1/cm-familia/eliminar-circular/${idFamilia}`, null, 'delete');

const visualizarCircular = (idFamilia) => http_client_with_status(
  `/api/v1/cm-familia/visualizar-circular/${idFamilia}`, 
  null, 
  'get', 
  {},
  { responseType: 'blob' }
);

const cargarFamilias = (params) => http_client_with_status('/api/v1/cm-familia', params, 'get');

export default {
  buscarFamilia,
  getFamiliasList,
  upsertFamilia,
  deleteFamilias,
  putFamiliasEstado,
  obtenerFamilias,
  eliminarCircular,
  visualizarCircular,
  cargarFamilias,
};
