const etapaRecepcionOfertasUaci = {
    namespaced: true,
    state: {
        fechaFinal: null
    },

    mutations: {
        setFechaFinal (state, payload) { 
            state.fechaFinal = payload
        },
    },
}

export default etapaRecepcionOfertasUaci