import { createLoadable, toggleLoadable, setLoadableResponse, shouldFetch } from '@/utils/loadable';
import SeguimientoServices from '@/services/SeguimientoProcesos.services';

const seguimientoStore = {
    namespaced: true,
    state: {
        departamento: createLoadable(null),
        estados: createLoadable([]),
        departamentos: createLoadable([]),
    },
    mutations: {
        startDepartamentoUsuarioRequest(state) {
            toggleLoadable(state.departamento);
        },
        handleDepartamentoUsuarioResponse(state, response) {
            setLoadableResponse(state.departamento, response);
        },
        resetDepartamentoUsuarioLogueado(state) {
            state.departamento = createLoadable(null);
        },
        startCatalogoEstadosRequest(state) {
            toggleLoadable(state.estados);
        },
        handleCatalogoEstadosResponse(state, response) {
            setLoadableResponse(state.estados, response);
        },
        startCatalogoDepartamentosRequest(state) {
            toggleLoadable(state.departamentos);
        },
        handleCatalogoDepartamentosResponse(state, response) {
            setLoadableResponse(state.departamentos, response);
        },
    },
    actions: {
        cargarDepartamentoUsuarioLogueado: async ({ commit }) => {
            commit('startDepartamentoUsuarioRequest');
            const { data } = await SeguimientoServices.obtenerDepartamentoUsuarioLogueado();
            commit('handleDepartamentoUsuarioResponse', data);
        },
        cargarCatalogoDeEstados: async ({ commit, state }) => {
            if (!shouldFetch(state.estados)) return;
            
            commit('startCatalogoEstadosRequest');
            const { data } = await SeguimientoServices.obtenerEstados();
            commit('handleCatalogoEstadosResponse', data);
        },
        cargarCatalogoDepartamentos: async ({ commit, state }) => {
            if (!shouldFetch(state.departamentos)) return;
            
            commit('startCatalogoDepartamentosRequest');
            const { data } = await SeguimientoServices.obtenerDepartamentos();
            commit('handleCatalogoDepartamentosResponse', data);
        },
    },
  }
  
  export default seguimientoStore