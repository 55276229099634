const aprobacionPacAnioFiscalRoutes = [
    {
      path: "/aprobacion-documentos-pac",
      name: "aprobacion-documentos-pac",
      component: () =>
        import(
          /* webpackChunkName: "aprobacionPacAnioFiscal" */ "./aprobacionPacAnioFiscal.vue"
        ),
    },
  ];
  
  export default aprobacionPacAnioFiscalRoutes;
  