import { http_client } from "@/plugins/http_client";

const url = "/api/v1/instituciones/lineas";
const getLineas = async (params = {}) => await http_client(url, params, "get");
const storeLinea = async (body) => await http_client(url, body, "post");
const getLinea = async (id) => await http_client(`${url}/${id}`);
const updateLinea = async (id, body) =>
  await http_client(`${url}/${id}`, body, "put");
const deleteLinea = async (id) =>
  await http_client(`${url}/${id}`, {}, "delete");

const agregarObsLineaTrabajoUnidad = async (params = {}) => {
  return await http_client(
    "/api/v1/instituciones/unidades/obs",
    params,
    "post"
  );
};

const editarLineaTrabajoUnidad = async (idLineaUnidad, params = {}) => {
  return await http_client(
    `/api/v1/instituciones/unidades/lineas/${idLineaUnidad}`,
    params,
    "put"
  );
};

const eliminarLineaTrabajoUnidad = async (idLineaUnidad, params = {}) => {
  return await http_client(
    `/api/v1/instituciones/unidades/lineas/${idLineaUnidad}`,
    params,
    "delete"
  );
};

const editarObsLineaTrabajo = async (idLinea, params = {}) => {
  return await http_client(
    `/api/v1/instituciones/unidades/obs/${idLinea}`,
    params,
    "put"
  );
};

const eliminarObsLineaTrabajo = async (idObs, params = {}) => {
  return await http_client(
    `/api/v1/instituciones/unidades/obs/${idObs}`,
    params,
    "delete"
  );
};

const getInstitucionesLineasUnidades = async (id_unidad, filters = {}) => {
  return await http_client(`${url}/unidades/${id_unidad}`, filters, "get");
};

const getObjetoPresupuestario = async(filter = {}) => {
  return await http_client(`/api/v1/instituciones/unidades/lineas/obs`, filter, 'get')
};

export default {
  getLineas,
  storeLinea,
  getLinea,
  updateLinea,
  deleteLinea,
  agregarObsLineaTrabajoUnidad,
  editarLineaTrabajoUnidad,
  eliminarLineaTrabajoUnidad,
  editarObsLineaTrabajo,
  eliminarObsLineaTrabajo,
  getInstitucionesLineasUnidades,
  getObjetoPresupuestario,
};
