export const setIdProceso = (state, payload) => {
    state.id_proceso = payload;
};

export const setProveedorOptions = (state, payload) => {
  state.proveedor_options = payload;
};

export const setIdProveedor = (state, payload) => {
  state.id_proveedor = payload;
};

export const setEspecificacion = (state, payload) => {
  state.especificacion = payload;
};


export const setResultadosPreliminares = (state, payload) => {
  state.resultados = payload;
};

export const setCantidadPorPagina = (state, payload) => {
  state.cantidad_por_pagina = payload;
}

export const setPaginaActual = (state, payload) => {
  state.pagina_actual = payload;
}

export const setTotalRegistros = (state, payload) => {
  state.total_registros = payload;
}

export const setParticipante = (state, payload) => {
  state.participante = payload;
}
