import { http_client_with_status } from "@/plugins/http_client";

const publicarDocumento = (data) => http_client_with_status('/api/v1/verificacion-documentos-odc/documentos/publicar', data, 'post', {
    'Content-Type': 'multipart/form-data',
});

const cargarDocumentosPublicados = (options) => http_client_with_status('/api/v1/verificacion-documentos-odc/documentos/publicados', options, 'get');

const eliminarDocumentoPublicado = (idDocumento) => http_client_with_status(`/api/v1/verificacion-documentos-odc/documentos/eliminar/${idDocumento}`, null, 'delete');

const cambiarEstadoDescargaDocumentoPublicado = (idDocumento, data) => 
    http_client_with_status(`/api/v1/verificacion-documentos-odc/documentos/publicados/estado-descarga/${idDocumento}`, data, 'patch');

const visualizarDocumentoPublicado = (idDocumento) => 
    http_client_with_status(`/api/v1/verificacion-documentos-odc/documentos/publicados/${idDocumento}`, null, 'get', {}, { responseType: 'blob' });

const cargarOficialesCumplimiento = (options) => http_client_with_status('/api/v1/verificacion-documentos-odc/oficiales/listar', options, 'get');

const cargarInstituciones = (filtros) => http_client_with_status('/api/v1/verificacion-documentos-odc/catalogo/instituciones', filtros, 'get');

const habilitarOficialCumplimiento = (idOficial, data) => 
    http_client_with_status(`/api/v1/verificacion-documentos-odc/solicitudes-suspension/habilitar/${idOficial}`, data, 'patch', {
        'Content-Type': 'multipart/form-data',
    });

const suspenderOficialCumplimiento = (idOficial, data) => 
    http_client_with_status(`/api/v1/verificacion-documentos-odc/solicitudes-suspension/suspender/${idOficial}`, data, 'patch', {
        'Content-Type': 'multipart/form-data',
    });

const visualizarJustificacionSuspension = (idOficial) => {
    let url = '/api/v1/verificacion-documentos-odc/oficiales/suspension/justificacion';

    if (idOficial) {
        url += `/${idOficial}`;
    }

    return http_client_with_status(url, null, 'get', {}, { responseType: 'blob' });
}

const cargarDocumentosOficialCumplimiento = (idOficial) => {
    let url = '/api/v1/verificacion-documentos-odc/oficiales/documentos';

    if (idOficial) url = url + '/' + idOficial;

    return http_client_with_status(url, null, 'get');
}

const cargarInformacionOficialCumplimiento = (idOficial) => {
    let url = '/api/v1/verificacion-documentos-odc/oficiales/informacion';

    if (idOficial) url = url + '/' + idOficial;

    return http_client_with_status(url, null, 'get');
}

// Verificacion documentos
const guardarDocumentoOficialCumplimiento = (data) => 
    http_client_with_status(
        '/api/v1/verificacion-documentos-odc/documentos-oficial/guardar', 
        data, 
        'post', 
        { 'Content-Type': 'multipart/form-data' },
    );

const eliminarDocumentoOficialCumplimiento = (idDocumento) => 
    http_client_with_status(`/api/v1/verificacion-documentos-odc/documentos-oficial/eliminar/${idDocumento}`, null, 'delete');

const visualizarDocumentoOficialCumplimiento = (idDocumento) => 
    http_client_with_status(
        `/api/v1/verificacion-documentos-odc/documentos-oficial/visualizar/${idDocumento}`, 
        null, 
        'get', 
        {}, 
        { responseType: 'blob' }
    );

const marcarDocumentoComoVerificado = (idApartado, idOficialCumplimiento) => 
    http_client_with_status(
        `/api/v1/verificacion-documentos-odc/documentos-oficial/verificar/${idApartado}/${idOficialCumplimiento}`, 
        null, 
        'patch',
    );

const cargarHistorialDocumentos = (idApartado, idOficialCumplimiento, filtros) => {
    let url = `/api/v1/verificacion-documentos-odc/documentos-oficial/historial/${idApartado}`;

    if (idOficialCumplimiento) url+= '/' + idOficialCumplimiento;

    return http_client_with_status(url, filtros, 'get');
}

// Interacciones
const crearInteraccionSobreDocumentoOdc = (idApartado, idOficialCumplimiento, data) =>  {
    let url =  `/api/v1/verificacion-documentos-odc/documentos-oficial/crear-interaccion/${idApartado}`;

    if (idOficialCumplimiento) url+= '/' + idOficialCumplimiento;

    return http_client_with_status(url, data, 'post',  { 'Content-Type': 'multipart/form-data' });
}

const cargarInteraccionesApartadoDocumental = (idApartado, params, idOficialCumplimiento) => {
    let url = '/api/v1/verificacion-documentos-odc/documentos/interacciones/' + idApartado;

    if (idOficialCumplimiento) url+= '/' + idOficialCumplimiento;

    return http_client_with_status(url, params, 'get');
}

const visualizarDocumentoInteraccion = (idInteraccion) => 
    http_client_with_status(
        `/api/v1/verificacion-documentos-odc/documentos/interacciones/visualizar-adjunto/${idInteraccion}`, 
        null, 
        'get', 
        {},
        { responseType: 'blob' },
    );

// Reportes
const cargarInstitucionesSinOficial = (options) => 
    http_client_with_status('/api/v1/verificacion-documentos-odc/instituciones/sin-oficial', options, 'get');

const generarReporteOficialesCumplimiento = () => 
    http_client_with_status(
        `/api/v1/verificacion-documentos-odc/oficiales/reporte`, 
        null, 
        'get', 
        {}, 
        { responseType: 'blob' },
    );

// Nombramiento y suspensiones
const crearNombramiento = (data) => 
    http_client_with_status('/api/v1/verificacion-documentos-odc/oficiales/nombramiento', data, 'patch');

const listarSolicitudesSuspension = (params) => 
    http_client_with_status('/api/v1/verificacion-documentos-odc/solicitudes-suspension', params, 'get');

const rechazarSolicitudSuspension = (idSolicitud, data) => 
    http_client_with_status(`/api/v1/verificacion-documentos-odc/solicitudes-suspension/rechazar/${idSolicitud}`, data, 'patch');

const aprobarSolicitudSuspension = (idSolicitud) => 
    http_client_with_status(`/api/v1/verificacion-documentos-odc/solicitudes-suspension/aprobar/${idSolicitud}`, null, 'patch');

const enviarCorreoCreadorSolicitud = (idSolicitud, data) => 
    http_client_with_status(`/api/v1/verificacion-documentos-odc/solicitudes-suspension/enviar-correo/${idSolicitud}`, data, 'post', {
        'Content-Type': 'multipart/form-data',
    });

const cargarSolicitudCreacionUsuario = (idSolicitudCreacionUsuario) => 
    http_client_with_status(`/api/v1/verificacion-documentos-odc/solicitud-creacion-usuario/detalles/${idSolicitudCreacionUsuario}`);

const crearSolicitudCambioPerfil = (idUsuario, data) => 
    http_client_with_status(`/api/v1/verificacion-documentos-odc/solicitud-cambio-perfil/crear/${idUsuario}`, data, 'post', {
        'Content-Type': 'multipart/form-data',
    });

const descartarSolicitudCambioPerfil = (idUsuario) => 
    http_client_with_status(`/api/v1/verificacion-documentos-odc/solicitud-cambio-perfil/descartar/${idUsuario}`, null, 'delete');

const cargarSolicitudCambioPerfil = (idSolicitud) => 
    http_client_with_status(`/api/v1/verificacion-documentos-odc/solicitud-cambio-perfil/detalles/${idSolicitud}`, null, 'get');

export default{
    publicarDocumento,
    cargarDocumentosPublicados,
    eliminarDocumentoPublicado,
    cambiarEstadoDescargaDocumentoPublicado,
    visualizarDocumentoPublicado,
    cargarOficialesCumplimiento,
    cargarInstituciones,
    habilitarOficialCumplimiento,
    suspenderOficialCumplimiento,
    visualizarJustificacionSuspension,
    cargarDocumentosOficialCumplimiento,
    cargarInformacionOficialCumplimiento,
    guardarDocumentoOficialCumplimiento,
    eliminarDocumentoOficialCumplimiento,
    visualizarDocumentoOficialCumplimiento,
    cargarInstitucionesSinOficial,
    marcarDocumentoComoVerificado,
    crearInteraccionSobreDocumentoOdc,
    cargarInteraccionesApartadoDocumental,
    visualizarDocumentoInteraccion,
    generarReporteOficialesCumplimiento,
    crearNombramiento,
    listarSolicitudesSuspension,
    rechazarSolicitudSuspension,
    aprobarSolicitudSuspension,
    cargarHistorialDocumentos,
    enviarCorreoCreadorSolicitud,
    cargarSolicitudCreacionUsuario,
    crearSolicitudCambioPerfil,
    descartarSolicitudCambioPerfil,
    cargarSolicitudCambioPerfil,
};
