<template>
    <v-card tile max-width="100%" style="height: 100%; gap: 0;" :style="noTieneDisponibilidad" class="pb-4 d-flex flex-wrap">
        <div class="align-self-start" style="width: 100%; max-width: 100%;">
            <router-link 
                :to="'/catalogo-electronico/producto/' + product.id" 
                class="d-flex justify-center align-center" 
                style="width: 100%; height: 225px;"
            >
                <img v-if="product.foto" :src="product.foto" style="max-width: 100%; max-height: 100%;" class="d-block flex-grow-1 imagen-producto" />
                <v-icon v-else :size="100">mdi-store-cog</v-icon>
            </router-link>
    
            <div class="mx-4">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-card-title v-on="on" v-bind="attrs" class="font-weight-bold text-center nombre-producto">
                            <router-link :to="'/catalogo-electronico/producto/' + product.id" class="text-decoration-none">
                                {{ product.nombre }}
                            </router-link>
                        </v-card-title>
                    </template>
                    <span>{{ product.nombre }}</span>
                </v-tooltip>

                <v-divider></v-divider>

                <v-card-text class="mt-4 px-0 py-0">
                    <div class="descripcion-producto">{{ product.descripcion }}</div>
                </v-card-text>

                <div class="d-flex align-center flex-wrap mt-4" style="gap: 8px;">
                    <!-- SKU -->
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip v-on="on" v-bind="attrs" class="chip-producto">
                                <v-icon small color="primary" class="mr-1">mdi-clipboard-search</v-icon>
                                <span class="text-uppercase">{{ `SKU: ${product.cmsku.sku}` }}</span>
                            </v-chip>
                        </template>
                        <span>SKU: {{ product.cmsku.sku }}</span>
                    </v-tooltip>
        
                    <!-- Existencias -->
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip v-on="on" v-bind="attrs" class="chip-producto">
                                <v-icon small color="primary" class="mr-1">mdi-store</v-icon>
                                <span>{{ product.disponibilidad ?? '0' }}</span>
                            </v-chip>
                        </template>
                        <span>Disponibilidad: {{ product.disponibilidad ?? '0' }}</span>
                    </v-tooltip>
        
                    <!-- Grupo -->
                    <!-- <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip v-on="on" v-bind="attrs" class="chip-producto">
                                <v-icon small color="primary" class="mr-1">mdi-group</v-icon>
                                <span>{{ group }}</span>
                            </v-chip>
                        </template>
                        <span>{{ group }}</span>
                    </v-tooltip> -->

                    <!-- Sub grupo -->
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip v-on="on" v-bind="attrs" class="chip-producto">
                                <v-icon small color="primary" class="mr-1">mdi-family-tree</v-icon>
                                <span>{{ subGroup }}</span>
                            </v-chip>
                        </template>
                        <span>{{ subGroup }}</span>
                    </v-tooltip>

                    <!-- Numero de ofertas por proveedor -->
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip v-on="on" v-bind="attrs" class="chip-producto">
                                <v-icon small color="primary" class="mr-1">mdi-account</v-icon>
                                <span class="text-uppercase">Proveedores: </span>
                                <span>{{ numeroOfertas ?? '0' }}</span>
                            </v-chip>
                        </template>
                        <span>Proveedores: {{ numeroOfertas }}</span>
                    </v-tooltip>
                </div>
            </div>
        </div>

        <v-card-actions class="justify-center mt-4 py-0 align-self-end" style="width: 100%">
            <router-link class="text-decoration-none" style="width: 80%" :to="'/catalogo-electronico/producto/' + product.id">
                <div class="text-center mb-2">
                    <span class="font-weight-bold text-h7 mt-2" style="width: 100%" v-if="precios">Desde: ${{ product.precio_desde }}</span>
                </div>
                <v-btn color="primary" block rounded >
                    Ver producto
                </v-btn>
            </router-link>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name: 'ProductCardComponent',
    props: {
        product: { type: Object, required: true },
    },
    computed: {
        noTieneDisponibilidad() {
            return this.product.disponibilidad === 0  ? { backgroundColor: '#d9d9da' } : {};
        },
        group() {
            return this.$props.product.CmSubGrupo.CmGrupo.nombre;
        },
        subGroup() {
            return this.$props.product.CmSubGrupo.nombre;
        },
        numeroOfertas(){
            return this.product.numero_ofertas
        },
        precios(){
            return this.product.precio_desde
        }
    },
}
</script>
<style scoped>
    .imagen-producto {
        object-fit: contain;
    }

    .chip-producto {
        padding: 8px 16px !important;
        height: auto !important;
    }

    .nombre-producto {
        width: 100%;
        height: 3.6rem;
        cursor: pointer;
        align-items: center;
        box-sizing: content-box;
        padding: 16px 0;
    }

    .descripcion-producto {
        font-size: 0.75rem; 
        line-height: 1.1rem;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .nombre-producto > * {
        text-align: center;
        width: 100%; 
        max-height: 100%;
        font-size: 0.9rem; 
        line-height: 1.2rem; 
        
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word; 
    }

    .nombre-producto:hover {
        text-decoration: underline;
    }

    :deep(.chip-producto .v-chip__content) {
        max-width: 100% !important;
        display: inline-block !important;
        white-space: nowrap !important;
        word-break: break-word !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        font-size: 0.675rem !important;
    }

    :deep(.chip-producto .v-icon) {
        transform: translateY(-2px);
        font-size: 18px !important;
    }

    :deep(.v-image) {
        background-color: rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 960px) {
        .imagen-producto {
            object-fit: cover;
        }
    }
</style>
