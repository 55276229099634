import { http_client_with_status, http_client } from "@/plugins/http_client";

const cargarDocumentos = (params) => http_client_with_status('/api/v1/documentos-anio-fiscal', params, 'get');

const guardarDocumento = (data) => http_client('/api/v1/documentos-anio-fiscal', data, 'post', {
    'Content-Type': 'multipart/form-data',
});

const eliminarDocumento = (idDocumento) => http_client(`/api/v1/documentos-anio-fiscal/${idDocumento}`, null, 'delete');

const visualizarAdjunto = (idDocumento) => http_client_with_status(`/api/v1/documentos-anio-fiscal/${idDocumento}/documento`, null, "get", {}, { responseType: 'blob' });

const aprobarDocumento = (idDocumento) => http_client_with_status(`/api/v1/documentos-anio-fiscal/${idDocumento}/aprobar`, null, 'patch');

const cargarAniosFiscales = (params) => http_client('/api/v1/anios', params, 'get');

const cargarProcesosPorAnio = (idAnioFiscal, params) => http_client_with_status(`api/v1/procesos/procesos/pac/anio/fiscal/${idAnioFiscal}`, params, 'get');

const descargarReportePacPublico = async (id_institucion, params = {}) => {
    return await http_client(
      `/api/v1/procesos/pac/institucion/${id_institucion}/externo`,
      params,
      "get",
      {},
      true,
      "blob"
    );
  };


const descargarReportePacPrivado = async (id_institucion, params = {}) => {
  return await http_client(
    `/api/v1/procesos/pac/institucion/${id_institucion}/interno`,
    params,
    "get",
    {},
    true,
    "blob"
  );
};

const verificarAprobacionDocAnioFiscal = (params) => http_client_with_status('/api/v1/documentos-anio-fiscal/verificar-aprobacion', params, 'get');

export default {
    cargarDocumentos,
    guardarDocumento,
    eliminarDocumento,
    visualizarAdjunto,
    aprobarDocumento,
    cargarAniosFiscales,
    descargarReportePacPublico,
    descargarReportePacPrivado,
    cargarProcesosPorAnio,
    verificarAprobacionDocAnioFiscal,
};
