import SolicitudNecesidadStore from "../AprobacionSolicitudes/store/index.js";
import PacProcesos from "../../../services/PacProcesos.services"

export default {
    namespaced: true,
    state: {
        listadoProcesos: [],
        filters: {
            page: 1,
            per_page: 10,
            total_rows: null,
            paginacion: 'true',
            nombre: null,
            ordenar_por: [],
            id_modalidad: null,
            id_anio_fiscal: null,
        },
        solicitudes:[],
        ids_obs:[],

        listadoObs: [],
    },
    mutations: {
        setListadoProcesos(state, payload) {
            state.listadoProcesos = payload
        },
        setPage(state, payload) {
            state.filters.page = Number(payload)
        },
        setPagination(state, payload) {
            state.filters.page = Number(payload.page)
            state.filters.per_page = Number(payload.per_page)
            state.filters.total_rows = Number(payload.total_rows)
        },
        setListaObsProceso(state, payload){
            state.solicitudes = payload
        },
        setFiltros(state, payload){
            state.filters = payload
        },
    },
    actions: {
        async getPacProcesos({ commit, state }) {
            const response = await PacProcesos.getPacProcesos(state.filters)
            commit("setListadoProcesos", response.data)
            commit("setPagination", response.headers)
        },
    },
    modules: {
        SolicitudNecesidadStore
    }
}