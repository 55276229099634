const cmEstructura = [
  {
    path: "/cm-estructura",
    name: "cm-estructura",
    component: () =>
      import(
        "./CmEstructuraView.vue"
      ),
  },
];

export default cmEstructura;
