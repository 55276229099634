import { http_client } from "@/plugins/http_client";

const registroPublico = "/api/v1/registro/publico";

export default {
  getInstituciones: async (params = {}) => {
    return await http_client(`${registroPublico}/instituciones`, params)
  },
  getProfesiones: async (params = {}) => {
    return await http_client(`${registroPublico}/profesiones`, params)
  },
  postSolicitudTitular: async (params = {}) => {
    return await http_client(`${registroPublico}`, params, 'post')
  },
};
