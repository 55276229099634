import Vue from "vue";
import SolicitudCompra from "../../../../services/SolicitudCompra.services";

export const getSolicitudesCompra = async ({ commit, state }) => {
  Vue.prototype.showLoader();
  const response = await SolicitudCompra.getSolicitudesCompra(state.filters);
  if (response.status === 200) {
    commit("setSolicitudesCompra", response.data);
    commit("setPaginationData", response.headers);
  }
  Vue.prototype.hideLoader();
};

export const getAdministradoresContrato = async ({ commit }, params) => {
  const response = await SolicitudCompra.getAdministradoresContrato(
    params.id_solicitud_compra,
    params.filters
  );
  if (response.status === 200) {
    commit("setListaAdministradoresContrato", response.data);
  }

  return response;
};

export const postAdministradoresContrato = async ({ commit }, params) => {
  const response = await SolicitudCompra.agregarAdministradorContrato(
    params.id_solicitud_compra,
    params.data
  );
  return response;
};

export const deleteAdministradoresContrato = async ({ commit }, params) => {
  const response = await SolicitudCompra.deleteAdministradorContrato(
    params.id_solicitud_compra,
    params.data
  );
  return response;
};

export const verificarEmpleado = async ({ commit }, params) => {
  const response = await SolicitudCompra.verificarEmpleado(
    params
  );
  return response;
};

export const getBitacora = async ({ commit }, params) => {
  const response = await SolicitudCompra.getBitacoraSolicitud(params);
  if (response.status === 200) {
    commit("setBitacora", response.data);
  }
  return response;
};

export const getDocumentoBitacora = async ({ commit }, params) => {
  try {
    const response = await SolicitudCompra.getPdfBitacoraSolicitud(params);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};
