<template>
    <VueCountdown :time="fecha" v-slot="{ days, hours, minutes, seconds }" @end="endCount">
      <div class="d-flex align-center justify-center text-h6" :class="color">
        <span :class="`${color} mr-3`" v-if="titulo != ''">{{ titulo }}</span>
        <v-icon :color="color.replace('--text', '')">mdi-clock-outline</v-icon>&nbsp;
        {{ days ? days + " días" : "" }}
        {{ hours < 10 ? "0" + hours : hours }}:{{ minutes < 10 ? "0" + minutes : minutes}}:{{ seconds < 10 ? "0" + seconds : seconds }}
      </div>
    </VueCountdown>
</template>
<script>
import VueCountdown from "@chenfengyuan/vue-countdown";

export default {
  props: {
    dateNow: {
      type: Object,
      default: null,
    },
    nextDay: {
      type: Object,
      default: null,
    },
    titulo: {
      type: String,
      default: "Tiempo Restante",
    },
    color: {
      type: String,
      default: "redSubasta--text",
    },
  },
  components: { VueCountdown },

  name: "AppCountdown",
  data: () => ({
    fecha: null,
    horas: "",
    minutos: "",
    segundos: "",
  }),
  methods: {
    endCount() {
      this.$emit("status", true);
    },
    init() {
      const value = this.dateNow ? this.dateNow : moment();
      this.fecha = this.nextDay.diff(value);
      if (this.fecha < 0) {
        this.fecha = 0;
      }
    },
  },
  watch: {
    nextDay() {
      this.init();
    },
  },
  created() {
    this.init();
  },
};
</script>
