import { get } from 'lodash';

export const injectParameter = (baseUrl, parameter) => parameter ? `${baseUrl}/${parameter}` : baseUrl

export const concatenateList = (list, separator = ',', path = 'name') => 
    list.reduce((prev, current) => prev ? `${prev}${separator} ${get(current, path)}` : get(current, path), '');

export const joinList = (list, separator = ',') => 
    list.reduce((prev, current) => prev ? `${prev}${current ? separator + current : ''}` : current ?? '', '');

export const getIfValidNumber = (parameter) => parameter && isFinite(parameter) ? Number(parameter) : null;

export const injectAction = (message, operation) => {
    const participles = {
        'insert': 'creado',
        'edit': 'modificado',
        'delete': 'eliminado',
        'toggle-status': 'actualizado',
    };

    return message.replace('{RESULT}', participles[operation]);
}

export const escapeUnixChars = (text) => 
    text.replace(/\\u([\d\w]{4})/gi, (_, grp)  => String.fromCharCode(parseInt(grp, 16)))