import { http_client, http_client_with_status } from "@/plugins/http_client";

const getCertificacionesFondo = async (params ={}) => {
    return await http_client("/api/v1/cdf",params,"get")
}

const getCertificacionFondo = async (id_fondo,filter={}) => {
    return await http_client(`/api/v1/cdf/certificacion/${id_fondo}`,filter,"get")
}

const getCertificacionFondoUnidad = async (id_fondo,filter={}) => {
    return await http_client(`/api/v1/cdf/certificacion/${id_fondo}/unidades`,filter,"get")
}

const putCertificacion = async (id_fondos_procesos_certificado, body) => {
    return await http_client(`/api/v1/cdf/certificacion/${id_fondos_procesos_certificado}`,body,"put")
}

const obtenerProcesosCatalogo = async (params = {}) => {
    return await http_client_with_status(`/api/v1/cdf/listado/procesos/catalogo`, params, "get");
}

const cifradosCatalogo = async (id_proceso, params = {}) => {
    return await http_client_with_status(`/api/v1/cdf/cifrados/${id_proceso}`, params, "get");
}


const obtenerFinanciamientoUnidad = async (id_fondo, params = {}) => {
    return await http_client_with_status(`/api/v1/cdf/obtener/financiamiento/unidad/${id_fondo}`, params, "get");
}

const crearCerticacion = (data) => http_client_with_status('/api/v1/cdf/crear-certificacion', data, 'post', {
    'Content-Type': 'multipart/form-data',
  }); 

  const cargarDocumento = (params) => http_client_with_status('api/v1/cdf/cargar-documento', params, 'get', {},  { responseType: "blob" });




export default {
    getCertificacionesFondo,
    getCertificacionFondo,
    getCertificacionFondoUnidad,
    putCertificacion,
    obtenerProcesosCatalogo,
    cifradosCatalogo,
    obtenerFinanciamientoUnidad,
    crearCerticacion,
    cargarDocumento,
}