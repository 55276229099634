import { http_client } from "@/plugins/http_client";
import { filter } from "lodash";

// petición GET
const guardarRegistro = async (body) =>
  http_client("api/v1/compra/exterior", body, "POST");
const editarRegistro = async (body, idCompra) =>
  http_client(`api/v1/compra/exterior/actualizar/${idCompra}`, body, "put");
const listarRegistro = async (filters) =>
  http_client("api/v1/compra/exterior", filters);
const listarInstituciones = async (filters) =>
  http_client("api/v1/compra/exterior/instituciones", filters);
const listDivisas = async (filters = {}) =>
  http_client("api/v1/compra/exterior/divisas", filters);

export default {
  editarRegistro,
  guardarRegistro,
  listarRegistro,
  listarInstituciones,
  listDivisas,
};
