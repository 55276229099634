const procesoCompraDoc = [
  {
    path: "/pac-procesos-v1",
    name: "pac-procesos-v1",
    component: () =>
      import(/* webpackChunkName: "ProcesosComprav1" */ "./PACListView.vue"),
  },
  {
    path: "/pac-procesos-v1/etapas/:idProceso/:supervisor?",
    name: "pac-procesos-etapas-v1",
    component: () =>
      import(
        /* webpackChunkName: "EtapasProcesoComprav1" */ "./etapasProcesoView.vue"
      ),
  },
  {
    path: "/pac-procesos-v1/configuracion/:idProceso",
    name: "pac-procesos-configuracion-v1",
    component: () =>
      import(
        /* webpackChunkName: "ConfiguracionProcesoComprav1" */ "./ConfiguracionProcesoView.vue"
      ),
      meta: { version: 1 },
  },
  {
    path: "/pac-procesos-v1/:idProceso/cdp",
    name: "proceso-asignar-cdp-v1",
    component: () =>
      import(
        /* webpackChunkName: "asignacionCdpProcesov1" */ "./AsignacionCdpProcesoView.vue"
      ),
  },
  {
    path: "/pac-procesos-v1/detalle/:idProceso",
    name: "pac-procesos-detalle-v1",
    component: () =>
      import(
        /* webpackChunkName: "DetalleProcesoComprav1" */ "./DetailInterPresiView.vue"
      ),
  }
];

export default procesoCompraDoc;
