import {http_client} from "@/plugins/http_client";

const getListaPaises = async(params ={})=>{
    return await http_client("/api/v1/paises", params, "get");
}

export default{
    getListaPaises
}



