export default () => ({
  // Variables de estado del primer paso
  solicitud_con_observaciones: false,
  token: "",
  observaciones: [],
  formulario_uno: {
    captcha: null,
    id_tipo_contribuyente: null,
    tipo_contribuyente: null,
    id_tipo_identificacion: null,
    tipo_identificacion: {},
    numero_documento: null,
    nombre_proveedor: null,
  },
  tipo_contribuyentes: [],
  tipo_identificaciones_disable: true,
  tipo_identificaciones: [],
  mascara_documento: "",
  lng_doc: 0,
  dui_verificado: false,
  verificando_dui: false,
  es_salvadoreno: false,
  // Variables de estado del segundo paso
  formulario_dos: {
    nombre_comercial: null,
    sitio_web: null,
    genero: null,
    id_clasificacion_empresarial: null,
    id_pais: null,
    id_departamento: null,
    id_municipio: null,
    direccion: null,
    area: null,
    telefono: null,
  },
  mascara_telefono: "",
  lng_tel: 0,
  generos: [],
  clasificacion_empresarial: [],
  paises: [],
  pais_disable: true,
  departamentos: [],
  departamentos_disable: true,
  municipios: [],
  municipios_disable: true,
  loading_paso_dos: false,
  // Variables de estado del tercer paso
  id_proveedor: null,
  formulario_tres: {
    correo_electronico: null,
    codigo_verificacion: "",
    terminos: null,
    declaracion: null,
    archivo_declaracion: null,
  },
  enviar_codigo_disable: false,
  loading_verificar_correo: false,
  correo_valido: false,
  loading_guardar_registro: false,

  tiempoEsperaCorreo: 0,
  verified: false,
  verified2: false,
  datos: {},
  segundosDatos: {},
  Proveedorid: "",
  TercerDatos: {},
  paso_actual: 1,
  id_persona: null
});
