import compraEnExterior from "@/services/CompraExterior.services";
import paisesServices from "@/services/Paises.services";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    cargando: false,
    listadoRegistros: [],
    paginacion: {
      page: 1,
      per_page: 10,
      total_elements: 0,
    },
    id_institucion: null,
    formulario: {
      id_pais: null,
      nombre_compra: null,
      insumos_adquiridos: null,
      monto_contratado: null,
      proveedor: null,
      fecha_contratacion: null,
      contratos: null,
      id_divisa: null,
      monto_divisa: null,
      estado: null,
    },
    default_formulario: {
      id_pais: null,
      nombre_compra: null,
      insumos_adquiridos: null,
      monto_contratado: null,
      proveedor: null,
      fecha_contratacion: null,
      contratos: null,
      id_divisa: null,
      monto_divisa: null,
      estado: null,
    },
    paises: [],
    instituciones: [],
    divisas: [],
  },
  mutations: {
    setListadoRegistros(state, payload) {
      state.listadoRegistros = payload;
    },
    setPaginacion(state, payload) {
      state.paginacion = payload;
    },
    setIdInstitucion(state, payload) {
      state.id_institucion = payload;
    },
    setFormulario(state, payload) {
      state.formulario = payload;
    },
    resetForm(state) {
      state.formulario = { ...state?.default_formulario };
    },
    setCargando(state, payload) {
      state.cargando = !state.cargando;
    },
    setCargandoTrue(state, payload) {
      state.cargando = false;
    },
    setPaises(state, payload) {
      state.paises = payload;
    },
    setInstituciones(state, payload) {
      state.instituciones = payload;
    },
    setDivisas(state, payload) {
      state.divisas = payload;
    },
  },
  actions: {
    async getListadoRegistros({ commit, state }) {
      commit("setCargando");
      const { data, headers } = await compraEnExterior.listarRegistro({
        ...state?.paginacion,
        id_institucion: state?.id_institucion,
      });
      commit("setListadoRegistros", data);
      commit("setPaginacion", {
        page: parseInt(headers?.page),
        per_page: parseInt(headers?.per_page),
        total_elements: parseInt(headers?.total_rows),
      });
      commit("setCargando");
    },
    async getPaises({ commit }) {
      const { data } = await paisesServices.getListaPaises();
      commit("setPaises", data);
    },
    async guardarRegistro({ commit, state }, callback) {
      commit("setCargando");
      const formData = new FormData();
      formData.append("id_pais", state?.formulario?.id_pais);
      formData.append("nombre_compra", state?.formulario?.nombre_compra);
      formData.append(
        "insumos_adquiridos",
        state?.formulario?.insumos_adquiridos
      );
      formData.append("monto_contratado", state?.formulario?.monto_contratado);
      formData.append("proveedor", state?.formulario?.proveedor);
      formData.append(
        "fecha_contratacion",
        state?.formulario?.fecha_contratacion
      );
      formData.append("contratos", state?.formulario?.contratos);
      formData.append("id_divisa", state?.formulario?.id_divisa);
      formData.append("monto_divisa", state?.formulario?.monto_divisa);
      formData.append("estado", state?.formulario?.estado);
      compraEnExterior
        .guardarRegistro(formData)
        .catch((error) => {
          Vue?.prototype.pushAppMessage({
            message: "Error al guardar, intenté más tarde",
            type: "warning",
          });
          commit("setCargando");
        })
        .then(() => {
          commit("setCargando");
          commit("resetForm");
          callback();
        });
    },
    async resetForm({ commit }) {
      commit("resetForm");
    },
    setCargandoTrue({ commit }) {
      commit("setCargandoTrue");
    },
    async getInstituciones({ commit }) {
      const { data } = await compraEnExterior.listarInstituciones();
      commit("setInstituciones", data);
    },
    setFiltroInstitucion({ commit }, payload) {
      commit("setIdInstitucion", payload);
    },
    paginar({ commit, dispatch, state }, payload) {
      const { cantidad_por_pagina, pagina } = payload;
      commit("setPaginacion", {
        page: parseInt(pagina),
        per_page: parseInt(cantidad_por_pagina),
        total_elements: parseInt(state?.paginacion?.total_rows),
      });
    },
    async getDivisas({ commit }) {
      const { data } = await compraEnExterior.listDivisas();
      commit("setDivisas", data);
    },
  },
};
