<template>
    <v-dialog
      max-width="600px"
      v-model="internalOpen"
    >
        <div class="feedback-modal position-relative px-4 pt-4 pb-4" style="position: relative; background-color: #FFF;">
            <div class="d-flex align-center" style="position: absolute; top: 16px; left: 16px;">
                <v-btn @click="internalOpen = false" icon color="secondary">
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </div>
            <div>
                <v-icon class="mb-4" :color="icon.color" style="font-size: 72px;">{{ icon.name }}</v-icon>
                <template v-if="title">
                    <h2 class="text-center text-uppercase text-title" style="font-size: 24px !important;">{{ title }}</h2>
                    <p class="mx-0 my-0 body-1" style="font-size: 16px !important;">{{ description }}</p>
                </template>
                <p 
                    v-else 
                    class="mx-0 my-0 body-1 font-weight-medium" 
                    style="font-size: 18px !important;"
                >
                    {{ description }}
                </p>
                <slot></slot>
                <v-btn color="primary" class="feedback-modal-button mx-auto mt-4" @click="internalOpen=false">{{ buttonText }}</v-btn>
            </div>
        </div>
    </v-dialog>
</template>
<script>

export default {
    name: 'FeedbackModalComponent',
    props: {
        isOpen: { type: Boolean, required: false },
        type: { type: String, required: false, default: 'success' },
        title: { type: String, required: false, },
        description: { type: String, required: true },
        buttonText: {  type: String, required: false, default: 'Volver' }
    },
    emits: ['on-close'],
    computed: {
        icon() {
            const feedbackTypesMap = {
                success: {
                    name: 'mdi mdi-check-circle',
                    color: 'green',
                },
                error: {
                    name: 'mdi mdi-close-circle',
                    color: 'red',
                },
                info: {
                    name: 'mdi mdi-information',
                    color: '#404A7A !important',
                },
                warning: {
                    name: 'mdi mdi-alert',
                    color: '#fb8c00 !important',
                },
            };

            return feedbackTypesMap[this.$props.type];
        },
        internalOpen: {
            get () {
                return this.$props.isOpen;
            },
            set (value) {
                if (!value) this.$emit('on-close');
            }
        },
    },
}
</script>
