const routerSupervisor = [
  {
    path: "/supervisor",
    name: "supervisor",
    component: () =>
      import(
        /* webpackChunkName: "SupervisorProcesosCompraView" */ "./ProcesosCompraView.vue"
      ), 
  }
];

export default routerSupervisor;
