const rupesRouter = [
  {
    path: "/rupes",
    name: "rupes",
    component: () =>
      import(
        /* webpackChunkName: "rupes" */ "./rupesView.vue"
      )
  },
  {
    path: "/list-proveedores",
    name: "list-proveedores",
    component: () =>
      import(
        /* webpackChunkName: "list-proveedores" */ "./listProveedoresView.vue"
      )
  },
  {
    path: "/proveedor/:id_proveedor",
    name: "proveedor",
    component: () =>
      import(
        /* webpackChunkName: "proveedor" */ "./proveedorView.vue"
      )
  },
  {
    path: "/proveedor/:id_proveedor/procesos",
    name: "procesos-proveedor",
    component: () =>
      import(
        /* webpackChunkName: "procesos-proveedor" */ "./procesosProveedorView.vue"
      )
  },
  {
    path: "/proveedor/:id_proveedor/contratos",
    name: "contratos-proveedor",
    component: () => 
      import(
        /* webpackChunkName: "contratos-proveedor" */ "./contratosProveedorView.vue" 
      )
  },
  {
    path: "/proveedor/:id_proveedor/sancion",
    name: "sanciones-proveedor",
    component: () => 
      import(
        /* webpackChunkName: "contratos-proveedor" */ "./sancionProveedorView.vue" 
      )
  },
  {
    path: "/proveedor/:id_proveedor/obs",
    name: "obs-proveedor",
    component: () =>
      import(
        /* webpackChunkName: "procesos-proveedor" */ "./obsProveedorView.vue"
      )
  },
  {
    path: "/proveedor/:id_proveedor/obs/:id_obs",
    name: "obs-proveedor-ver",
    component: () =>
      import(
        /* webpackChunkName: "procesos-proveedor" */ "./obsProveedorVerView.vue"
      )
  },
  {
    path: "/proveedor/:id_proveedor/giro",
    name: "giro-empresarial-proveedor",
    component: () =>
      import(
        /* webpackChunkName: "procesos-proveedor" */ "./giroEmpresarialProveedorView.vue"
      )
  },
  {
    path: "/proveedor/:id_proveedor/documentacion",
    name: "documentacion-proveedor",
    component: () =>
      import(
        /* webpackChunkName: "procesos-proveedor" */ "./documentacionProveedorView.vue"
      )
  },
  {
    path: "/proveedor/:id_proveedor/contactos",
    name: "contactos-proveedor",
    component: () =>
      import(
        /* webpackChunkName: "procesos-proveedor" */ "./contactosProveedorView.vue"
      )
  },
  {
    path: "/sanciones-proveedores-no-registrados",
    name: "sanciones-proveedores-no-registrados",
    component: () =>
      import(
        /* webpackChunkName: "sanciones-pre-reg-prov" */ "./sancionesPreRegistroView.vue"
      )
  },
  {
    path: "/sancion-proveedor-no-registrado/:id_sancion?",
    name: "sancion-proveedor-no-registrado",
    component: () =>
      import(
        /* webpackChunkName: "admin-sancion-pre-reg" */ "./adminSancionPreRegView.vue"
      )
  },

];

export default rupesRouter;