export default () => ({
  isEdit: false,
  id_agreement_marco: null,
  id_estado_convenio: null,
  lista_empleados: [],
  lista_document_type: [],
  Listado_convenios: [],
  obs_list: [],
  lista_obs_convenio: [],
  lista_document_convenio: [],
  lista_fases_convenio: [],
  phase_list: [],
  estados_list: [],
  agreement: null,
  general_image: "",
  agreement_obs: [],
  general_data: new FormData(),
  obs_data: [],
  start_date: null,
  image_agreement: null,
  agreement_state: null,
  tab: null,
  agreement_detail: {},
  bad_catalogo_obs: false,
  alert_obs: false,
  bad_documentos: false,
  alert_documentos: false,
  bad_etapas: false,
  alert_etapas: false,
  vigencia_saved:true,
  breadcrumbsItems: [
    {
      text: "Convenios Marco",
      disable: false,
      href: "/convenio_marco",
    },
  ],
  filters: {
    page: 1,
    per_page: 10,
    total_rows: null,
    id_fase: null,
    id_estado: null,
  },
  reviewOffers: [],
  tempProviderId: null,
  readOnlyOffers:false,
});
