<template>
  <div>
    <!-- Use the component in the right place of the template -->
    <div class="rich-text-editor">
      <tiptap-vuetify
        @input="handleContentChange"
        :placeholder="placeholder"
        :extensions="extensions"
        :value="value"
      >
         <v-text-field
          slot-scope="{ editor }"
          v-model="editor.content"
          :maxlength="10" 
          outlined
          multi-line
          :placeholder="placeholder"
         />
      </tiptap-vuetify>
    </div>
  </div>
</template>

<script>
// import the component and the necessary extensions
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  InputRule
} from "tiptap-vuetify";

export default {
  name: "AppRichTextComponent",
  // specify TiptapVuetify component in "components"
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  components: { TiptapVuetify },
  data: () => ({
    // declare extensions you want to use
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
    // starting editor's content
    content: "",
  }),
  methods: {
    // handle editor's content change
    handleContentChange(value) {
      let content = value;
      if (value == "<p></p>") content = null;
      this.$emit("input", content);
    },
  },
};
</script>

<style>
.ProseMirror {
  min-height: 200px;
}
</style>
