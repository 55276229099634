import { http_client } from "@/plugins/http_client"


const getListPresentations = async(params = {})=>{
    return await http_client("/api/v1/presentaciones",params,"get")
}

const createPresentation = async(data)=>{
    return await http_client("/api/v1/presentaciones",data,"post")
}

const deletePresentation = async(id)=>{
    return await http_client(`/api/v1/presentaciones/${id}`,{},"delete")
}

export default{
    getListPresentations,
    createPresentation,
    deletePresentation,
}