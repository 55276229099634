import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import Vue from "vue";
import "vuetify/dist/vuetify.min.css";
import vuetify from "./vuetify";

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "mdi",
});
