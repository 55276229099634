import {http_client} from "@/plugins/http_client";


const getGenerosList = async( params ={}) =>{
    return await http_client("/api/v1/generos", params,"get")
};

export default{
    getGenerosList
}



//Generos