const solicitudCompra = [
  {
    path: "/jefe-area-aprobacion",
    name: "jefe-area-aprobacion",
    component: () =>
      import(
        /* webpackChunkName: "/jefe-area-aprobacion" */ "./aprobacionSolicitudView.vue"
      ),
  },
  {
    path: "/detalle-jefe-area-aprobacion/:id",
    name: "detalle-jefe-area-aprobacion",
    component: () =>
      import(
        /* webpackChunkName: "detalle_solicitud_compra" */ "./detalleAprobacionSolicitudView.vue"
      ),
  },
];

export default solicitudCompra;
