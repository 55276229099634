import { http_client_slim } from "@/plugins/http_client";

const client = http_client_slim("/api/v1/proveedor/empleados");

const obtenerEmpleados = async (data) => client("", { data });

const agregarEmpleado = async (data) => client("", { method: "post", data });

const eliminarEmpleado = async (id) => client(`/${id}`, { method: "delete" });

const editarEmpleado = async (id, data) =>
  client(`/${id}`, { method: "put", data });

const cambiarEstado = async (id, data) =>
  client(`/${id}`, { method: "patch", data });

const restablecer = async (id) =>
  client(`/restablecer/${id}`, { method: "patch" });

const restablecer2fa = async (id) =>
  client(`/restablecer/2fa/${id}`, { method: "patch" });

const removerInactividad = async (id, data) =>
  client(`/remover/inactividad/${id}`, {
    method: "patch",
    data,
  });

export default {
  obtenerEmpleados,
  eliminarEmpleado,
  agregarEmpleado,
  editarEmpleado,
  cambiarEstado,
  removerInactividad,
  restablecer,
  restablecer2fa
};
